export class ErrorUtils {
    static containsErrorCode(exception: any, errorCode: string): boolean {
        let result = false;

        if (exception.status !== undefined) {
            const json = exception.json();
            const errorCodes = Array.isArray(json.errors) ? json.errors.map(error => error.code) : [];
            result = errorCodes.indexOf(errorCode) !== -1;
        }

        return result;
    }
}
