<app-dashboard-header [showDashboardButton]="false"></app-dashboard-header>

<ion-content class="has-header has-footer">
    <div class="card">
        <ion-icon src="assets/images/icon-lock.svg" class="card__icon"></ion-icon>
        <div class="card__header">
            <h2 class="card__title">{{ 'FORGOT_PASSWORD.title' | translate }}</h2>
        </div>
        <p class="card__description">{{ 'FORGOT_PASSWORD.subtitle' | translate }}</p>
        <div class="card__btns">
            <ion-button color="primary" expand="full" shape="round" class="card__btn" (click)="goToChooseLoginPage()">{{ 'BUTTONS.next' |  translate }}</ion-button>
        </div>
    </div>
</ion-content>
