import {Component, Input} from '@angular/core';
import {UploadedConcept} from '../../models/concept';

@Component({
    selector: 'app-select-uploaded-concept',
    templateUrl: './select-uploaded-concept.component.html',
})
export class SelectUploadedConceptComponent {
    @Input() public concept!: UploadedConcept;
}
