import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {AuthService} from './auth-service';
import {ProfileService} from './profile-service';
import {StorageService} from './storage-service';

export interface LANGUAGE_DEFINITION {
    value: string;
    label: string;
    translationFile: any;
}

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private LANGUAGE_KEY = 'language';
    private DEFAULT_LANGUAGE = 'nl';

    private languages: LANGUAGE_DEFINITION[];
    private currentLanguage: string;
    private onChangeSubject = new Subject<string>();

    constructor(
        private translateService: TranslateService,
        private storageService: StorageService,
        private profileService: ProfileService,
        private authService: AuthService,
    ) {
        this.authService.onAuthChanged$().subscribe(() => {
            this.detectLanguageAndSet();
        });
    }

    public init(supportedLanguages: LANGUAGE_DEFINITION[]): void {
        this.languages = supportedLanguages;
        const languagesAsArray = [];
        this.languages.forEach((language) => {
            languagesAsArray.push(language.value);
        });
        this.translateService.addLangs(languagesAsArray);
        this.detectLanguageAndSet();
    }

    public async set(language: string): Promise<void> {
        // When not available the first added language is the fallback
        language = this.getAvailableLanguages().find((availableLanguage) => availableLanguage.value === language) ? language : this.getAvailableLanguages()[0].value;

        if (this.currentLanguage !== language) {
            this.currentLanguage = language;
            this.translateService.setDefaultLang(language);
            this.translateService.setTranslation(language, this.languages.find((availableLanguage) => availableLanguage.value === language).translationFile);
            this.translateService.use(language);
            moment.locale(language);

            await this.updateLanguageInProfile(language);
            await this.setDeviceLanguage(language);

            // Broadcast the changed language
            this.onChangeSubject.next(language);
        }
    }

    public getCurrentLanguage(): string {
        return this.currentLanguage;
    }

    public getAvailableLanguages(): LANGUAGE_DEFINITION[] {
        return this.languages;
    }

    public onChange(): Subject<string> {
        return this.onChangeSubject;
    }

    public async updateLanguageInProfile(currentLanguage: string): Promise<void> {
        if (this.authService.isAuthenticated()) {
            await this.getUserProfileLanguage().then(async (res) => {
                if (res.language !== currentLanguage) {
                    // If the user profile language differs from the stored device language,
                    // Set the device language in the user profile on the server as the device is always leading
                    await this.setUserProfileLanguage(currentLanguage);
                }
            });
        }
    }

    private async detectLanguageAndSet(): Promise<void> {
        const language = await this.storageService.get(this.LANGUAGE_KEY);

        await this.set(language || this.translateService.getBrowserLang());
        if (this.authService.isAuthenticated()) {
            await this.updateLanguageInProfile(language);
        }
    }

    private async setDeviceLanguage(language: string): Promise<void> {
        await this.storageService.set(this.LANGUAGE_KEY, language);
    }

    private async getUserProfileLanguage(): Promise<{ language?: string }> {
        return this.profileService.fetchProfile().then(
            (profile) => {
            return !!profile?.data && !!(profile.data?.language) ? profile.data : { language: this.DEFAULT_LANGUAGE };
        }).catch((err) => {
            const error = err.error;
            if (!(error.status === 403 && error.title === 'Medewerker niet gevonden')) {
                console.error(error.status, error.detail);
            }

            return {language: null};
        });
    }

    private async setUserProfileLanguage(language: string): Promise<void> {
        return this.profileService.fetchProfile().then(
            (profile) => {
                profile.language = language;
                return this.profileService.postProfile(profile);
            }).then((_) => {
                return;
            }).catch((err) => {
                const error = err.error;
                console.error(error.status, error.detail);
            });
    }

}
