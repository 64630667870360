import {Injectable} from '@angular/core';
import {Questionnaire} from '../models/questionnaire';
import {ShowDisclaimer} from '../enums/disclamer-show';
import {StorageService} from './storage-service';

@Injectable({
    providedIn: 'root',
})
export class DisclaimerService {

    static STORAGE_KEY_SHOWED_DISCLAIMER_FOR_IDS = 'showed_disclaimer_for_ids';

    constructor(
        private storageService: StorageService,
    ) {
    }

    public async shouldShowAtStart(questionnaire: Questionnaire): Promise<boolean> {
        if (questionnaire.show_disclaimer === undefined || questionnaire.show_disclaimer === ShowDisclaimer.ALWAYS) {
            return true;
        }
        if (questionnaire.show_disclaimer === ShowDisclaimer.ONCE) {
            return !await this.didShowedFor(questionnaire.id);
        }
        return false;
    }

    public async storeShowedFor(questionnaireId: number): Promise<void> {
        if (!await this.didShowedFor(questionnaireId)) {
            const ids = await this.getStoredIds();
            ids.push(questionnaireId);

            return this.storeIds(ids);
        }

        return Promise.resolve();
    }

    private didShowedFor(questionnaireId: number): Promise<boolean> {
        return this.getStoredIds()
            .then((ids) => ids.indexOf(questionnaireId) !== -1);
    }

    private getStoredIds(): Promise<number[]> {
        return this.storageService
            .get(DisclaimerService.STORAGE_KEY_SHOWED_DISCLAIMER_FOR_IDS)
            .then((data) => Array.isArray(data) ? data : []);
    }

    private storeIds(ids: number[]): Promise<void> {
        return this.storageService
            .set(DisclaimerService.STORAGE_KEY_SHOWED_DISCLAIMER_FOR_IDS, ids);
    }

}
