import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Network} from '@capacitor/network';

@Injectable({
    providedIn: 'root',
})
export class NetworkService {

    private isNetworkOnline$ = new BehaviorSubject(true);

    constructor() {
        Network.addListener('networkStatusChange', status => {
            this.isNetworkOnline$.next(status.connected);
        });
        // obtain the initial status
        Network.getStatus().then((status) => {
            this.isNetworkOnline$.next(status.connected);
        });
    }

    public networkOnline$(): Observable<boolean> {
        return this.isNetworkOnline$.asObservable();
    }

    public isOnline(): boolean {
        return this.isNetworkOnline$.value;
    }
}
