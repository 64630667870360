import {Component} from '@angular/core';
import {NavController} from '@ionic/angular';
import {INewsListItem} from '../../models/news-item';
import {NewsItemsService} from '../../services/news-items-service';
import {LoadingService} from '../../services/loading-service';
import {ToastService} from '../../services/toast-service';
import {SearchUtil} from '../../utils/search-util';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'page-news-list',
    templateUrl: 'news-list.html',
})
export class NewsListPage {
    public newsListItems: INewsListItem[];
    public filteredNewsListItems: INewsListItem[] = [];
    public filtering = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private newsItemService: NewsItemsService,
        private navCtrl: NavController,
        private loadingService: LoadingService,
        private toast: ToastService,
        private translateService: TranslateService,
    ) {
    }

    async ionViewWillEnter() {
        await this.loadingService.start();
        this.subscriptions.push(
            this.newsItemService.getNewsItemList()
                .pipe(
                    tap({
                        next: async (items) => {
                            this.newsListItems = items;
                            await this.loadingService.stop();
                        },
                        error: async () => {
                            await this.loadingService.stop();
                            await this.toast.showCustomMessages(
                                this.translateService.instant('NOTIFICATION.error_general'),
                                this.translateService.instant('NOTIFICATION.offline'),
                            );
                        },
                    }),
                )
                .subscribe(),
        );
    }

    async ionViewWillLeave() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public goToNewsDetails(newsItemId: string) {
        this.navCtrl.navigateForward(['/news-details', newsItemId]);
    }

    public filterNews($event) {
        const filterString = $event.target.value;

        if (!filterString) {
            this.filteredNewsListItems = [];
            this.filtering = false;
        } else {
            this.filteredNewsListItems = this.newsListItems
                .filter((item) =>
                    SearchUtil.hasMatch(item.title, filterString) ||
                    SearchUtil.hasMatch(item.subtitle || '', filterString),
                );
            this.filtering = true;
        }
    }
}
