import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import '@recognizebv/capacitor-plugin-msauth';
import {EventService} from './event-service';
import {AccessType, AuthService} from './auth-service';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root',
})
export class DeprecationService {
    private jwtHelper = new JwtHelperService();

    private _email: string | undefined = undefined;
    private _isAppDeprecated: boolean | undefined = undefined;
    private _isUserEmailDeprecated: boolean | undefined = undefined;

    constructor(
        private eventService: EventService,
        private authService: AuthService,
    ) {
        this.authService.onAuthChanged$().subscribe(() => {
            this.updateEmail();
        });
        this.eventService.subscribe('auth:logout', () => this._email = undefined);
    }

    isUserEmailDeprecated(): boolean {
        if (this._email) {
            return this._isUserEmailDeprecated;
        }
        return false;
    }

    isAppDeprecated() {
        return this._isAppDeprecated;
    }

    private updateEmail() {
        if (this.authService.isAuthenticated()) {
            const currentToken = this.jwtHelper.decodeToken<{ upn: string; type?: string }>(this.authService.token);
            const deprecatedEmails = environment.deprecation.domains;
            this._email = currentToken.upn;
            this._isUserEmailDeprecated = (currentToken.type !== AccessType.EXTERNAL)
                && deprecatedEmails.some(deprecatedEmail => this._email.includes(deprecatedEmail)) || false;

            this._isAppDeprecated = this._isUserEmailDeprecated && new Date() > new Date(environment.deprecation.date);
        } else {
            this._email = undefined;
            this._isAppDeprecated = undefined;
            this._isUserEmailDeprecated = undefined;
        }
    }

}
