import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth-service';
import {catchError, timeout} from 'rxjs/operators';
import {API_SUFFIX} from '../app/app.module';
import {environment} from '../environments/environment';
import {Observable, throwError} from 'rxjs';
import {LoadingService} from './loading-service';
import {ToastService} from './toast-service';
import {TranslateService} from '@ngx-translate/core';
import {TrackingService} from './tracking/tracking.service';
import {NetworkService} from './network.service';

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    private baseUrl = `${environment.api}${API_SUFFIX}`;
    private timeout = environment.apiTimeout;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private trackingService: TrackingService,
        private networkService: NetworkService,
    ) {
    }

    /**
     * Returns the request options for the api call
     *
     * @param noCache
     * @returns
     */
    public getRequestOptions(noCache: boolean) {
        if (noCache) {
            const noCacheHeaders = new HttpHeaders();
            noCacheHeaders.append('Cache-Control', 'no-cache');

            return {
                headers: noCacheHeaders,
            };
        } else {
            return {};
        }
    }

    /**
     * Perform an authenticated get. Note: this is exactly the same as a normal get, since the angular-jwt module takes
     * care of performing authenticated gets. The only addition is that the authService.enforceJwt method is being called
     *
     * @param url
     * @param requestOptions
     */
    authenticatedGet(url: string, requestOptions?: { headers?: HttpHeaders }): Promise<any> {
        return this.authService.enforceJwt()
            .then(() => {
                return this.http.get(this.baseUrl + url, requestOptions)
                    .pipe(
                        timeout(this.timeout),
                        catchError((err) => this.handleHttpError(err)),
                    )
                    .toPromise();
            });
    }

    /**
     * Perform an authenticated post. Note: this is exactly the same as a normal get, since the angular-jwt module takes
     * care of performing authenticated gets. The only addition is that the authService.enforceJwt method is being called
     *
     * @param url
     */
    authenticatedPost(url: string, body: any): Promise<any> {
        return this.authService.enforceJwt()
            .then(() => {
                return this.http.post(this.baseUrl + url, body)
                    .pipe(
                        timeout(this.timeout),
                    )
                    .toPromise();
            });
    }

    authenticatedDelete(url: string, body?: any): Promise<any> {
        return this.authService.enforceJwt()
            .then(() => {
                return this.http.delete(this.baseUrl + url, body)
                    .pipe(
                        timeout(this.timeout),
                    )
                    .toPromise();
            });
    }

    get(url: string): Promise<any> {
        return this.http.get(this.baseUrl + url)
            .pipe(
                timeout(this.timeout),
            )
            .toPromise();
    }

    post(url: string, body: any): Promise<any> {
        return this.http.post(this.baseUrl + url, body)
            .pipe(
                timeout(this.timeout),
            )
            .toPromise();
    }

    handleHttpError(error: HttpErrorResponse, customMessage?: string): Observable<any> {
        console.warn('Failed to execute response. Error: ', error);
        if (!!customMessage) {
            console.warn('Additional info: ' + customMessage);
        }

        this.trackingService.exception(error);

        this.loadingService.stop();
        const errorMessage = 'NOTIFICATION.' + (error?.status === 0 && !this.networkService.isOnline() ? 'offline' : 'error_general');
        this.toastService.showCustomMessages(this.translateService.instant(errorMessage), this.translateService.instant(errorMessage));

        throw throwError('HTTP error. status: ' + error.status);
    }
}
