export class WindowLocationUtils {
    public static getParams(): any {
        const query = window.location.search;

        return (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                const [key, value] = param.split('=');
                params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                return params;
            }, {});
    }

    public static stripToken() {
        if (window.history.pushState) {
            const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
            window.history.pushState({path: newUrl}, '', newUrl);
        }
    }
}
