<app-questionnaire-header></app-questionnaire-header>

<ion-content class="has-header has-footer">
    <div class="content">
        <div class="chapter-header">
            <h2 class="light">{{titleText}}</h2>
            <p class="light">{{descriptionText}}</p>
        </div>
        <app-chapter-item
            *ngFor="let chapter of chapters; let chapterIndex = index"
            [title]="chapter | contentTranslate:'title'"
            [completed]="isChapterCompleted(chapter)"
            [readOnly]="readonly"
            [skipRequirements]="skipRequirements"
            [chapterIndex]="chapterIndex"
            [chapter]="chapter"
            [concept]="concept"
            (selectChapter)="selectChapter($event)"
            (selectQuestion)="selectQuestion($event)"
            (conceptUpdated)="conceptUpdated($event)"
        ></app-chapter-item>
    </div>
</ion-content>

<app-questionnaire-footer
    [disableBackButton]="true"
    [nextButtonEnabled]="isCompleted"
    [nextButtonText]="'BUTTONS.' + (readonly ? 'back' : 'submit')"
    (nextButtonClick)="sendQuestionnaire()"
></app-questionnaire-footer>
