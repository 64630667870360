import {ChangeDetectorRef, Component} from '@angular/core';
import {LoadingController, NavController} from '@ionic/angular';
import {ToastService} from '../../services/toast-service';
import {QuestionnaireDataService} from '../../services/questionnaire-data-service';
import {ErrorUtils} from '../../utils/error-utils';
import {ConceptService} from '../../services/concept-service';
import {ChapterUtils} from '../../utils/chapter-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {Concept} from '../../models/concept';
import {Questionnaire} from '../../models/questionnaire';
import {QuestionnaireRouteService} from '../../services/questionnaire-route.service';
import {QuestionRoute} from '../../models/question-route';
import {TrackingService} from '../../services/tracking/tracking.service';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../services/language.service';
import {NetworkService} from '../../services/network.service';

@Component({
    selector: 'page-send-questionnaire',
    templateUrl: 'send-questionnaire.html',
})
export class SendQuestionnairePage {

    public sending = false;
    public isChapterQuestionnaire: boolean;
    private conceptId: string;
    private questionnaireId: number;
    private readonly: boolean;
    private concept: Concept;
    private questionnaire: Questionnaire;
    private questionRoute: QuestionRoute;

    constructor(
        private navCtrl: NavController,
        private loadingCtrl: LoadingController,
        private toast: ToastService,
        private questionnaireDataService: QuestionnaireDataService,
        private questionnaireRouteService: QuestionnaireRouteService,
        private conceptService: ConceptService,
        private activatedRoute: ActivatedRoute,
        private trackingService: TrackingService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private networkService: NetworkService,
    ) {
    }

    async ionViewWillEnter() {
        this.conceptId = this.activatedRoute.snapshot.paramMap.get('conceptId');
        this.questionnaireId = parseInt(this.activatedRoute.snapshot.paramMap.get('questionnaireId'), 10);
        this.concept = await this.conceptService.getConcept(this.conceptId);

        this.questionnaire = this.concept.questionnaire;
        this.questionRoute = await this.questionnaireRouteService.getRouteForSnapshot(this.questionnaire, this.activatedRoute.snapshot, this.readonly);

        this.isChapterQuestionnaire = ChapterUtils.isChaptersQuestionnaire(this.questionnaire);

        // Force change detection
        this.changeDetectorRef.detectChanges();
    }

    async ionViewDidEnter() {
        await this.conceptService.saveConcept(this.concept, this.router.url);
    }

    async sendQuestionnaire() {
        const loading = await this.loadingCtrl.create();

        try {
            this.sending = true;

            await loading.present();
            this.concept.questionnaire.locale = this.languageService.getCurrentLanguage();
            const response: any = await this.questionnaireDataService.sendQuestionnaire(this.concept);
            await loading.dismiss();

            await this.navCtrl.navigateForward('/concept/:conceptId/questionnaire-sent', {state: {ticketNumber: response.ticket_number}});
        } catch (exception) {
            await loading.dismiss();
            this.trackingService.exception(exception);
            this.sending = false;

            if (ErrorUtils.containsErrorCode(exception, 'user_must_be_approved')) {
                this.toast.showCustomMessages(this.translateService.instant('SEND_QUESTIONNAIRE.account_blocked'));
            } else if (!this.networkService.isOnline()) {
                this.toast.showCustomMessages(this.translateService.instant('SEND_QUESTIONNAIRE.offline'));
            } else if (ErrorUtils.containsErrorCode(exception, 'invalid_signature')) {
                // Clear the questionnaires in case of a signature malfunction
                await this.questionnaireDataService.clearQuestionnaires();
                await this.navCtrl.navigateBack('/dashboard', {state: {invalidSignature: true}});
            } else {
                this.toast.showCustomMessages(this.translateService.instant('SEND_QUESTIONNAIRE.something_went_wrong'));
            }
        }
    }

    public async backToChapters(): Promise<void> {
        if (this.isChapterQuestionnaire) {
            const route = (await this.questionnaireRouteService.getQuestionRoutes(this.questionnaire, false, false))[0];
            const chaptersUrl = `${this.readonly ? '/history' : `/concept/${this.conceptId}`}${route.route}`;

            await this.navCtrl.navigateForward([chaptersUrl], {replaceUrl: true, animationDirection: 'back'});
        } else {
            console.error('Not a chapters questionnaire!');
        }
    }

    public async prev() {
        const prevQuestionRoute = await this.questionnaireRouteService.getPrevQuestionRouteFor(this.questionnaire, this.questionRoute, this.readonly);
        const prevUrl = `${this.readonly ? '/history' : `/concept/${this.conceptId}`}${prevQuestionRoute.route}`;

        await this.navCtrl.navigateBack(prevUrl);
    }
}
