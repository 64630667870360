import {Injectable} from '@angular/core';
import {Questionnaire} from '../models/questionnaire';
import {QuestionRoute} from '../models/question-route';
import {DisclaimerService} from './disclaimer-service';
import {ActivatedRouteSnapshot} from '@angular/router';
import {QuestionDefinitionPathService} from './questiondefinition-path-service';
import {Choice} from '../models/choice';
import {Question} from '../models/question';
import {QuestionPath} from '../utils/questionnaire-utils';

@Injectable()
export class QuestionnaireRouteService {

    constructor(
        private disclaimerService: DisclaimerService,
        private questionDefinitionPathService: QuestionDefinitionPathService,
    ) {
    }

    public async getRouteForSnapshot(questionnaire: Questionnaire, snapshot: ActivatedRouteSnapshot, readonly: boolean = false, startOfQuestionnaire: boolean = false): Promise<QuestionRoute> {
        const url = snapshot.url.join('/');
        return this.getRouteForUrl(questionnaire, url, readonly, startOfQuestionnaire);
    }

    public async getRouteForUrl(questionnaire: Questionnaire, url: string, readonly: boolean = false, startOfQuestionnaire: boolean = false): Promise<QuestionRoute> {
        return this.getQuestionRoutes(questionnaire, startOfQuestionnaire, readonly)
            .then((questionnaireRoutes) => {
                return questionnaireRoutes.find((questionnaireRoute) => {
                    return url.endsWith(questionnaireRoute.route);
                });
            });
    }

    public async getFirstRouteFor(questionnaire: Questionnaire, readonly: boolean = false): Promise<QuestionRoute> {
        return this.getQuestionRoutes(questionnaire, true, readonly)
            .then((routes) => {
                return routes[0];
            });
    }

    public async getNextQuestionRouteFor(questionnaire: Questionnaire, route: QuestionRoute, readonly: boolean = false): Promise<QuestionRoute> {
        return await this.getQuestionRoutes(questionnaire, false, readonly)
            .then((questionnaireRoutes) => {
                const routeIndex = questionnaireRoutes.findIndex((questionnaireRoute) => questionnaireRoute.route === route.route);

                return questionnaireRoutes[routeIndex + 1];
            });
    }

    public async getPrevQuestionRouteFor(questionnaire: Questionnaire, route: QuestionRoute, readonly: boolean = false): Promise<QuestionRoute> {
        return await this.getQuestionRoutes(questionnaire, false, readonly)
            .then((questionnaireRoutes) => {
                const routeIndex = questionnaireRoutes.findIndex((questionnaireRoute) => questionnaireRoute.route === route.route);

                return questionnaireRoutes[routeIndex - 1];
            });
    }

    public async getChapterQuestionRoute(questionnaire: Questionnaire, chapterIndex: number, questionIndex: number): Promise<QuestionRoute> {
        return await this.getQuestionRoutes(questionnaire, false)
            .then((questionnaireRoutes) => {
                return questionnaireRoutes.find((questionnaireRoute) => {
                    if (questionnaireRoute.vintageQuestionPath?.length > 1 && questionnaireRoute.vintageQuestionPath[0].branch !== null && questionnaireRoute.vintageQuestionPath[0].branch.type === 'chapters') {
                        const chapterChoice = questionnaire.questions[0].choices[chapterIndex];
                        return questionnaireRoute.vintageQuestionPath[0].branch.choices[0] === chapterChoice.title
                            && questionnaireRoute.vintageQuestionPath[1].question === questionIndex;
                    } else {
                        return false;
                    }
                });
            });
    }

    /**
     * Generate routes based on questionnaire questions
     *
     *  - /questionnaire/:id/disclaimer                                   (optional if readonly = false)
     *
     *  Iterate each question
     *  - /questionnaire/:id/question/:questionIndex/chapters             (optional if question type = chapters)
     *  - /questionnaire/:id/question/:questionIndex/answer/:answerIndex
     *  - /questionnaire/:id/question/:questionIndex/repeat               (optional if question type = repeated and readonly = false)
     *
     *  - /questionnaire/:id/send                                         (optional if readonly = false)
     *
     * @param questionnaire <Questionnaire>
     * @param atStartOfQuestionnaire <boolean>
     * @param readonly <boolean>
     * @returns QuestionRoute[]
     */
    public async getQuestionRoutes(questionnaire: Questionnaire, atStartOfQuestionnaire: boolean, readonly: boolean = false): Promise<QuestionRoute[]> {
        const routes: QuestionRoute[] = [];
        const routeBase = `/questionnaire/${questionnaire.id}`;
        const allQuestionPaths = this.questionDefinitionPathService.findQuestionPaths(questionnaire);

        const questionPaths = allQuestionPaths
            .reduce((reducedQuestionnairePaths, questionnairePath) => {
                const {path, parentQuestion, parentChoice} = questionnairePath;

                // Filter conditional question paths with no answers at all.
                if (this.isConditionalAndNotVisibleOnParent(parentChoice, parentQuestion)) {
                    reducedQuestionnairePaths = reducedQuestionnairePaths
                        .filter((remainedQuestionnairePath) => {
                            return !remainedQuestionnairePath.path.startsWith(path);
                        });
                }

                return reducedQuestionnairePaths;
            }, allQuestionPaths);

        if (!readonly && atStartOfQuestionnaire && await this.disclaimerService.shouldShowAtStart(questionnaire)) {
            routes.push({route: `${routeBase}/disclaimer`, title: 'Disclaimer', readonly});
        }

        const skipQuestionsPath: string[] = [];
        let hasChapters = false;

        questionPaths.forEach((questionPath) => {
            const {question, path} = questionPath;

            if (!skipQuestionsPath.includes(path)) {
                if (question.type === 'chapters') {
                    hasChapters = true;
                    routes.push({
                        route: `${routeBase}/question-path/${path}/chapters`,
                        ...questionPath,
                        vintageQuestionPath: this.getVintageQuestionPath(questionnaire, path, hasChapters, 0),
                        title: questionPath.question.title,
                        readonly,
                    });

                } else if (question.type === 'repeated') {
                    const repeatingQuestions = questionPaths
                        .filter((repeatedQuestionPath) => {
                            return repeatedQuestionPath.path.startsWith(path) && repeatedQuestionPath.path !== path;
                        })
                        .filter((repeatingQuestion) => {
                            // filter out the repeating question itself, only handle the 1 time only repeating question situation'
                            if (repeatingQuestion.question.type === 'repeated') {
                                skipQuestionsPath.push(repeatingQuestion.path);
                                return false;
                            }
                            return true;
                        });
                    const answers = (question.answers === undefined || question.answers.length === 0) ? ['Nee'] : JSON.parse(JSON.stringify(question.answers));
                    if (answers[answers.length - 1] !== 'Nee') {
                        answers.push('Nee'); // last answer is always Nee :-)
                    }

                    answers.forEach((answer, answerIndex) => {
                        repeatingQuestions.forEach((repeatingQuestion) => {
                            if (!this.isConditionalAndNotVisibleOnQuestion(
                                repeatingQuestion.parentQuestion.answers === undefined || repeatingQuestion.parentQuestion.answers.length < answerIndex ? answer : repeatingQuestion.parentQuestion.answers[answerIndex],
                                repeatingQuestion.parentQuestion,
                                repeatingQuestion.parentChoice)
                            ) {
                                routes.push({
                                    route: `${routeBase}/question-path/${repeatingQuestion.path}/answer/${answerIndex}`, ...repeatingQuestion,
                                    vintageQuestionPath: this.getVintageQuestionPath(questionnaire, repeatingQuestion.path, hasChapters, answerIndex),
                                    title: repeatingQuestion.question.title,
                                    readonly,
                                });
                            }
                        });
                        routes.push({
                            route: `${routeBase}/question-path/${path}/repeat/answer/${answerIndex}`,
                            vintageQuestionPath: this.getVintageQuestionPath(questionnaire, path, hasChapters, answerIndex),
                            ...questionPath,
                            readonly,
                            title: questionPath.question.title,
                        });
                        skipQuestionsPath.push(...repeatingQuestions.map((repeatingQuestion) => repeatingQuestion.path));
                    });

                } else {
                    routes.push(
                        {
                            route: `${routeBase}/question-path/${path}/answer/0`,
                            title: questionPath.question.title,
                            ...questionPath,
                            vintageQuestionPath: this.getVintageQuestionPath(questionnaire, path, hasChapters, 0),
                            readonly,
                        });
                }
            }
        });

        if (!readonly) {
            routes.push({route: `${routeBase}/send`, title: 'Verstuur formulier', readonly});
        }

        return routes;
    }

    private isConditionalAndNotVisibleOnParent(parentChoice: Choice, parentQuestion: Question): boolean {
        if (!!parentQuestion) {
            if (!!parentChoice) {
                return parentQuestion.type !== 'chapters' && parentQuestion.type !== 'repeated' && !(parentQuestion.answers || []).flat().includes(parentChoice.title);
            } else {
                // check if parent question is not of type chapters or repeated, and no answer has been supplied yet.
                return parentQuestion.type !== 'chapters' && parentQuestion.type !== 'repeated' && (parentQuestion.answers || []).length === 0;
            }
        }
        return false;
    }

    private isConditionalAndNotVisibleOnQuestion(answer: string | string[], parentQuestion: Question, parentChoice: Choice): boolean {
        if (!!answer) {
            if (!!parentChoice) {
                const answers = Array.isArray(answer) ? answer : [answer];
                return parentQuestion.type !== 'chapters' && (answers.findIndex((anAnswer) => anAnswer === parentChoice.title) < 0);
            } else {
                // check if parent question is not of type chapters, and no answer has been supplied yet.
                return (parentQuestion.type !== 'chapters' && parentQuestion.type !== 'repeated' && (parentQuestion.answers || []).length === 0);
            }
        }
        return false;
    }

    private getVintageQuestionPath(questionnaire: Questionnaire, path: string, hasChapters: boolean, answerIndex: number): QuestionPath {
        let questions = questionnaire.questions;
        let currentQuestion = null;
        const calculatedPath = [];
        const splittedPath = path.split('-');
        let processChapters = hasChapters;
        for (let index = 0; index < splittedPath.length; index = index + 2) {
            if (splittedPath[index] === 'questions') {
                const questionIndex = parseInt(splittedPath[index + 1], 10);
                calculatedPath.push({question: questionIndex, branch: null});
                currentQuestion = questions[questionIndex];
                if (currentQuestion.type === 'repeated') {
                    const formerPathEntry = calculatedPath.pop();
                    formerPathEntry.branch = {
                        type: 'repeat',
                        repeats: answerIndex,
                    };
                    calculatedPath.push(formerPathEntry);
                    questions = currentQuestion.questions;
                }
            }
            if (splittedPath[index] === 'choices') {
                if (currentQuestion === null) {
                    throw new Error('Invalid path, choices without a question!');
                }

                const choicesIndex = parseInt(splittedPath[index + 1], 10);
                const formerPathEntry = calculatedPath.pop();
                formerPathEntry.branch = {
                    type: processChapters ? 'chapters' : 'choice',
                    choices: [
                        currentQuestion.choices[choicesIndex].title,
                    ],
                };
                questions = currentQuestion.choices[choicesIndex].questions;
                calculatedPath.push(formerPathEntry);
                processChapters = false; // chapters can occur only once
            }
        }

        return calculatedPath;
    }
}
