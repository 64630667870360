<!-- comment input -->
<ng-container *ngIf="allowRemarks">
    <ng-container *ngFor="let remark of remarks; let i = index; trackBy: remarkTrackUpdateID">
        <div *ngIf="(isKeyboardClosed$ | async) || ((isKeyboardOpen$ | async) && (onRemarkFocus$ | async))" class="aar__item remark" [id]="remark.updateID">
            <div class="aar__header">
                <ion-label>{{ 'COMPONENTS.attachments_remarks.note' | translate }}</ion-label>
                <ion-button *ngIf="!disabled && (isKeyboardClosed$ | async)" fill="clear" (click)="removeRemark(i)">
                    <ion-icon slot="icon-only" name="close-outline"></ion-icon>
                </ion-button>
            </div>
            <div class="remark__preview">
                <app-text-autoheight
                    (hasFocus)="hasFocus($event)"
                    [currentValue]="remark.value"
                    (valueChange)="updateRemark(i, $event)"
                    placeholder="{{ 'COMPONENTS.attachments_remarks.place_remark' | translate }}"
                    [disabled]="disabled"
                    [maxLength]="1024"
                ></app-text-autoheight>
            </div>
        </div>
    </ng-container>
</ng-container>

<!-- images -->
<div *ngIf="allowAttachments && (attachmentsVisible || attachments.length > 0)" class="aar__item aar__item--photo">
    <div class="aar__header">
        <ion-label>{{ 'COMPONENTS.attachments_remarks.picture' | translate }}</ion-label>
        <ion-button *ngIf="!disabled && (isKeyboardClosed$ | async)" fill="clear" (click)="removeAllPictures()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
    </div>
    <p *ngIf="isWeb && !disabled" class="card__description">{{ 'COMPONENTS.photo.subtitle' | translate }}</p>
    <div class="app-photo__grid">
        <!-- image previews -->
        <ng-container *ngIf="(onRemarkFocus$ | async) === false">
            <div *ngFor="let attachment of attachments; let i = index" class="app-photo__item" (click)="removePicture(i)">
                <div class="app-photo__preview" [ngStyle]="{'background-image':'url('+attachment+')'}"></div>
                <div *ngIf="!disabled" (click)="removePicture(i)" class="options">{{ 'BUTTONS.remove' | translate }} &#x2716;&#xfe0e;</div>
            </div>
        </ng-container>
        <!-- dropzone -->
        <div *ngIf="attachmentLocations.length < maxQuestionAttachments && isWeb" class="app-photo__item">
            <div class="dropzone dropzone--lean" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDragLeave($event)">
                <ion-icon name="add-outline"></ion-icon>
                <input type="file" accept="image/jpeg, image/png, image/gif" (change)="uploadImageFile($event)" />
            </div>
        </div>
    </div>
    <div *ngIf="attachmentLocations.length < maxQuestionAttachments && !isWeb" class="app-photo__footer">
        <ion-button expand="full" fill="outline" shape="round" class="card__btn" (click)="fromLibrary()">{{ 'COMPONENTS.photo.upload_from_gallery' | translate }}</ion-button>
        <ion-button color="primary" expand="full" shape="round" class="card__btn" (click)="takePicture()">{{ 'COMPONENTS.photo.take_a_photo' | translate }}</ion-button>
    </div>
</div>

<!--buttons-->
<ng-container *ngIf="!disabled && (isKeyboardClosed$ | async)">
    <div *ngIf="allowRemarks || allowAttachments" class="aar__buttons">
        <!--  add comment -->
        <ion-button *ngIf="allowRemarks" fill="clear" (click)="addRemark()">
            <ion-icon slot="start" name="chatbox-ellipses-outline"></ion-icon>
            <span class="aar_button-text">
                {{ 'COMPONENTS.attachments_remarks.note' | translate }}
            </span>
        </ion-button>
        <span *ngIf="allowRemarks && allowAttachments" class="aar__line"></span>
        <!--  add image-->
        <ion-button *ngIf="allowAttachments" fill="clear" (click)="showAttachments()">
            <ion-icon slot="start" name="image-outline"></ion-icon>
            <span class="aar_button-text">
                {{ 'COMPONENTS.attachments_remarks.picture' | translate }}
            </span>
        </ion-button>
    </div>
    <div *ngIf="allowAttachments && attachmentLocations.length >= maxQuestionAttachments" class="image-attachment image-attachment--notification">
        <div>{{ 'COMPONENTS.attachments_remarks.max_attachments_reached' | translate }}</div>
    </div>
</ng-container>
