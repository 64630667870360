<div
    class="manual-choice"
    [ngClass]="{
        'manual-choice--radio-like': abstractChoiceQuestion.question?.type === 'radio',
        'manual-choice--checkbox-like': abstractChoiceQuestion.question?.type === 'checkbox'
    }"
    [formGroup]="newChoiceForm"
>
    <ion-label class="manual-choice__label light">
        {{ 'COMPONENTS.manual_choice.new_choice' | translate }}
    </ion-label>
    <ion-item class="manual-choice__item">
        <ion-input class="manual-choice__input" formControlName="title" type="text"></ion-input>
        <ion-button class="manual-choice__add-button" [disabled]="newChoiceForm.invalid" (click)="addChoice()">
            <ion-icon name="add"></ion-icon>
        </ion-button>
    </ion-item>
    <div
        *ngIf="newChoiceForm.controls.title.errors?.duplicateTitle"
        class="manual-choice__error"
    >{{ 'COMPONENTS.manual_choice.choice_exists' | translate }}</div>
</div>
