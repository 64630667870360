import {Component, Input} from '@angular/core';
import {NavController} from '@ionic/angular';
import {ContactPage} from '../../../pages/contact/contact';
import {SupportPage} from '../../../pages/support/support';
import {ActivatedRoute} from '@angular/router';
import {ConceptService} from '../../../services/concept-service';
import {HistoryService} from '../../../services/history-service';
import {ChapterUtils} from '../../../utils/chapter-utils';
import {QuestionnaireRouteService} from '../../../services/questionnaire-route.service';
import {LANGUAGE_DEFINITION, LanguageService} from '../../../services/language.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-dashboard-header',
    templateUrl: 'dashboard-header.html',
    styleUrls: ['dashboard-header.scss'],
})
export class DashboardHeaderComponent {

    public contactPage = ContactPage;
    public supportPage = SupportPage;

    @Input() public color = 'dark';
    @Input() public showBackButton = false;
    @Input() public showDashboardButton = true;
    @Input() public showLanguageSelection = false;
    @Input() public showChapterButton = false;
    @Input() public showHelpButton = false;
    @Input() public showLanguageButton = false;
    @Input() public showDashboardTitle = false;
    public currentLanguage = 'nl';

    public availableLanguages: LANGUAGE_DEFINITION[] = [];

    constructor(
        private navCtrl: NavController,
        private activatedRoute: ActivatedRoute,
        private conceptService: ConceptService,
        private historyService: HistoryService,
        private languageService: LanguageService,
        private questionnaireRouteService: QuestionnaireRouteService,
    ) {
        this.availableLanguages = environment.supportedLanguages;
        this.showLanguageButton = this.availableLanguages.length > 1;

        this.currentLanguage = this.languageService.getCurrentLanguage();
        this.languageService.onChange().subscribe((language) => {
            this.currentLanguage = language;
        });
    }

    public async navigateBack(): Promise<void> {
        await this.navCtrl.pop();
    }

    async goToChapter() {
        const conceptId = this.activatedRoute.snapshot.paramMap.get('conceptId');
        const questionnaireId = parseInt(this.activatedRoute.snapshot.paramMap.get('questionnaireId'), 10);
        const readonly = (conceptId === undefined || conceptId === null);
        const concept = readonly ? undefined : await this.conceptService.getConcept(conceptId);

        const questionnaire = readonly
            ? await this.historyService.getQuestionnaireHistoryItem(questionnaireId)
            : concept.questionnaire;

        if (ChapterUtils.isChaptersQuestionnaire(questionnaire)) {
            const route = (await this.questionnaireRouteService.getQuestionRoutes(questionnaire, false, false))[0];
            const chaptersUrl = `${readonly ? '/history' : `/concept/${conceptId}`}${route.route}`;

            await this.navCtrl.navigateForward([chaptersUrl], {replaceUrl: true, animationDirection: 'back'});
        } else {
            console.error('Not a chapters questionnaire!');
        }
    }
}
