import {Component, OnDestroy} from '@angular/core';
import {NavController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {OrganisationListItem} from '../../models/organisation-structure';
import {StructureNodeType} from '../../enums/structure-node-type';
import {OrganisationStructureService} from '../../services/organisation-structure-service';
import {QuestionnaireFilterType} from '../../enums/questionnaire-filter-type';
import {map, takeUntil, tap} from 'rxjs/operators';
import {LoadingService} from '../../services/loading-service';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject} from 'rxjs';
import {ConceptService} from '../../services/concept-service';
import {Concept, UploadedConcept} from '../../models/concept';
import {ConceptQuestionnaireService} from '../../services/concept-questionnaire.service';
import {FormControl} from '@angular/forms';
import {UserFavoriteService} from '../../services/user-favorite.service';
import {controlValueChanges} from '../../utils/form-control-value';
import {ToastService} from '../../services/toast-service';
import {UploadedConceptService} from '../../services/uploaded-concept.service';
import {DeprecationService} from '../../services/deprecation-service';

export interface OrganisationListItemWithFavorite extends OrganisationListItem {
    isFavorite: boolean;
}

const itemMatchesSearch = (search: string, data: OrganisationListItem): boolean => {
    const searchString = search.trim();
    if (search === '') {
        // No search string, so all items match
        return true;
    }

    return data.title.toLowerCase().includes(searchString.toLowerCase());
};
const sortByTitleFavoritesFirst = (a: OrganisationListItemWithFavorite, b: OrganisationListItemWithFavorite): number => {
    if (a.isFavorite && !b.isFavorite) {
        return -1;
    } else if (!a.isFavorite && b.isFavorite) {
        return 1;
    } else {
        return a.title.localeCompare(b.title);
    }
};

@Component({
    selector: 'page-select-structure-node',
    templateUrl: 'select-structure-node.html',
})
export class SelectStructureNodePage implements OnDestroy {
    private favorites$ = this.favoriteService.getFavorites();

    readonly searchControl = new FormControl('');
    readonly organisationListItemsSubject = new BehaviorSubject<OrganisationListItem[]>([]);

    readonly filteredOrganisations$: Observable<OrganisationListItemWithFavorite[]> = combineLatest([
        this.organisationListItemsSubject,
        this.favorites$,
        controlValueChanges(this.searchControl),
    ]).pipe(
        map(([organisationListItems, favorites, search]) => {
            return organisationListItems
                .filter(item => item.type === StructureNodeType.ORGANISATION && itemMatchesSearch(search, item))
                .map(item => ({
                    ...item,
                    isFavorite: favorites.some(favorite => favorite.structure_node_type === item.type && favorite.structure_node_id === item.id),
                }))
                .sort(sortByTitleFavoritesFirst);
        }),
    );

    readonly filteredBusinessUnits$: Observable<OrganisationListItemWithFavorite[]> = combineLatest([
        this.organisationListItemsSubject,
        this.favorites$,
        controlValueChanges(this.searchControl),
    ]).pipe(
        map(([organisationListItems, favorites, search]) =>
            organisationListItems
                .filter(item => item.type === StructureNodeType.BUSINESSUNIT && itemMatchesSearch(search, item))
                .map(item => ({
                    ...item,
                    isFavorite: favorites.some(favorite => favorite.structure_node_type === item.type && favorite.structure_node_id === item.id),
                }))
                .sort(sortByTitleFavoritesFirst),
        ),
        tap(filteredBusinessUnits => this.businessUnitsExpanded = filteredBusinessUnits.length > 0),
    );

    readonly filteredProjects$: Observable<OrganisationListItemWithFavorite[]> = combineLatest([
        this.organisationListItemsSubject,
        this.favorites$,
        controlValueChanges(this.searchControl),
    ]).pipe(
        map(([organisationListItems, favorites, search]) =>
            organisationListItems
                .filter(item => item.type === StructureNodeType.PROJECT && itemMatchesSearch(search, item))
                .map(item => ({
                    ...item,
                    isFavorite: favorites.some(favorite => favorite.structure_node_type === item.type && favorite.structure_node_id === item.id),
                }))
                .sort(sortByTitleFavoritesFirst),
        ),
        tap(filteredProjects => this.projectsExpanded = filteredProjects.length > 0),
    );

    public concepts: Concept[] = [];
    public uploadedConcepts: UploadedConcept[] = [];

    public dataLoaded$ = new BehaviorSubject(false);

    structureNodeType: StructureNodeType | null = null;
    structureNodeTypeEnum = StructureNodeType;

    public searchPlaceholderText = '';
    public subTitleText = '';

    public businessUnitsExpanded = false;
    public projectsExpanded = false;
    prefix: QuestionnaireFilterType;
    filterTypeEnum = QuestionnaireFilterType;
    public showError = false;
    private destroy$ = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private navCtrl: NavController,
        private organisationStructureService: OrganisationStructureService,
        private loadingService: LoadingService,
        private translateService: TranslateService,
        private conceptService: ConceptService,
        private uploadedConceptService: UploadedConceptService,
        private favoriteService: UserFavoriteService,
        private toastService: ToastService,
        private deprecationService: DeprecationService,
        public conceptQuestionnaireService: ConceptQuestionnaireService,
    ) {
    }

    async ionViewWillEnter(): Promise<void> {
        this.dataLoaded$.next(false);
        const params = this.route.snapshot.params;

        this.structureNodeType = this.route.snapshot.paramMap.get('structure_node_type') as StructureNodeType ?? null;

        await this.loadingService.start();

        try {
            await Promise.all([
                firstValueFrom(this.organisationStructureService.loadOrganisationStructure()),
                this.refreshConcepts(),
            ]);

            if (this.router.getCurrentNavigation()?.extras?.state?.items) {
                this.organisationListItemsSubject.next(this.router.getCurrentNavigation().extras.state.items || []);
            } else if (params.structure_node_type) {
                this.prefix = QuestionnaireFilterType.ALL;
                if (this.route.routeConfig?.data?.questionnaireTypes) {
                    if (this.route.routeConfig.data.questionnaireTypes.includes('incident')) {
                        this.prefix = QuestionnaireFilterType.INCIDENT;
                    } else if (this.route.routeConfig.data.questionnaireTypes.includes('compliment')) {
                        this.prefix = QuestionnaireFilterType.COMPLIMENT;
                    }
                } else {
                    this.prefix = QuestionnaireFilterType.DIRECT;
                }

                const organisationRoute = this.organisationStructureService.getOrganisationListForNode(this.prefix, params.structure_node_type, parseInt(params.id || '0', 10));
                if (params.questionnaire_id) {
                    organisationRoute.items = organisationRoute.items.map((item) => {
                        item.route += `/${params.questionnaire_id}`;
                        return item;
                    });
                }

                // Currently in the app - The concept badges do not show up for the incident or compliment flow
                // So we keep it that way
                if (this.prefix !== QuestionnaireFilterType.ALL) {
                    organisationRoute.items = organisationRoute.items.map((item) => {
                        item.badgeAmount = 0;
                        return item;
                    });
                }

                this.organisationListItemsSubject.next(organisationRoute.items);
            }

            this.updateSearchPlaceholderText();
        } catch (error) {
            console.error('error occurred loading data, stopping.', error);
        } finally {
            this.dataLoaded$.next(true);
            this.loadingService.stop();
        }

        this.conceptQuestionnaireService.reloadConcepts$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.refreshConcepts());
    }

    /**
     * Select the next organisation node
     *
     * @param item
     */
    public openRoute(item: OrganisationListItem) {
        this.conceptQuestionnaireService.currentQuestionnaireOrigin$.next(this.router.url);
        if (item.type === StructureNodeType.ORGANISATION) {
            this.conceptQuestionnaireService.selectedOrganisationName$.next(item.title);
        }
        this.navCtrl.navigateForward(item.route);
    }

    /**
     * Sets the expanded flag for BU's
     */
    public toggleBusinessUnits() {
        this.businessUnitsExpanded = !this.businessUnitsExpanded;
    }

    /**
     * Sets the expanded flag for Projects
     */
    public toggleProjects() {
        this.projectsExpanded = !this.projectsExpanded;
    }

    isAppDeprecated(): boolean {
        return this.deprecationService.isAppDeprecated();
    }

    public trackOrganisationListItemByTypeAndId(_: unknown, item: OrganisationListItemWithFavorite): string | null {
        if (!item) {
            return null;
        } else {
            return item.type + item.id;
        }
    }

    private async refreshConcepts(): Promise<void> {
        if (this.structureNodeType !== StructureNodeType.CONCERN) {
            // Only load concepts when the structure node type is CONCERN
            this.concepts = [];
            this.uploadedConcepts = [];
            return;
        }

        this.loadingService.start();
        await Promise.allSettled([
            this.conceptService.getConcepts().then((concepts: Concept[]) => {
                this.concepts = concepts.sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at));
            }),
            this.uploadedConceptService.listConcepts().then((uploadedConcepts: UploadedConcept[]) => {
                this.uploadedConcepts = uploadedConcepts;
            }),
        ]);
        this.loadingService.delayedStop();
    }

    private updateSearchPlaceholderText() {
        const searchPlaceholderPrefix = this.translateService.instant('SELECT_STRUCTURE.search_on') + ' ';
        const organisationTypes = [];
        if (this.organisationListItemsSubject.value.find((item) => item.type === StructureNodeType.ORGANISATION)) {
            organisationTypes.push(this.translateService.instant('SELECT_STRUCTURE.organisation'));
        } else {
            if (this.organisationListItemsSubject.value.find((item) => item.type === StructureNodeType.PROJECT)) {
                organisationTypes.push(this.translateService.instant('SELECT_STRUCTURE.project'));
            }

            if (this.organisationListItemsSubject.value.find((item) => item.type === StructureNodeType.BUSINESSUNIT)) {
                organisationTypes.push(this.translateService.instant('SELECT_STRUCTURE.business_unit'));
            }
        }

        this.searchPlaceholderText = searchPlaceholderPrefix + organisationTypes.join(' / ');
        this.subTitleText = this.translateService.instant('SELECT_STRUCTURE.choose_your') + ' ' + organisationTypes.join(' / ');
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async toggleFavorite(event: Event, organisation: OrganisationListItemWithFavorite) {
        event.preventDefault();
        event.stopPropagation();

        try {
            if (organisation.isFavorite) {
                await this.favoriteService.removeFavorite(organisation.type, organisation.id);
            } else {
                await this.favoriteService.addFavorite(organisation.type, organisation.id);
            }
        } catch (error) {
            console.error('Failed to toggle favorite', error);
            await this.toastService.showCustomMessages(
                this.translateService.instant('NOTIFICATION.update_failed'),
                this.translateService.instant('NOTIFICATION.offline'),
            );
        }
    }
}
