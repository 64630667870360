import {Component} from '@angular/core';
import {LANGUAGE_DEFINITION, LanguageService} from '../../services/language.service';
import {assert} from '@angular/compiler-cli/linker';

@Component({
    selector: 'app-language-selection',
    templateUrl: 'language-selection.html',
    styleUrls: ['language-selection.scss'],
})
export class LanguageSelectionComponent {

    public availableLanguages: LANGUAGE_DEFINITION[];
    public currentLanguage: string;

    constructor(
        private languageService: LanguageService,
    ) {
        this.availableLanguages = languageService.getAvailableLanguages();
        this.currentLanguage = this.languageService.getCurrentLanguage();
    }

    public getDefaultLanguage(): string {
        return this.languageService.getCurrentLanguage();
    }

    public setLanguage($event: Event): Promise<void> {
        const customEvent = $event as CustomEvent;
        const newLanguage = customEvent.detail.value;
        this.currentLanguage = newLanguage;
        return this.languageService.set(newLanguage);
    }

    public languageID(index: number, language: LANGUAGE_DEFINITION) {
        return language.value;
    }

}
