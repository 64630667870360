<app-dashboard-header [showDashboardButton]="false" [showBackButton]="true"></app-dashboard-header>

<ion-content class="has-header">
    <div class="card">
        <div class="card__header">
            <h2 class="card__title">{{ 'REGISTRATION_SMS.title' | translate }}</h2>
            <p class="card__description">{{ 'REGISTRATION_SMS.subtitle' | translate }}</p>
        </div>
        <form [formGroup]="verificationForm" (ngSubmit)="verifySms()">
            <div class="fields">
                <ion-item lines="none" class="item__no-padding">
                    <ion-input formControlName="verificationCode" type="number" placeholder="{{ 'REGISTRATION_SMS.verification_code' | translate }}"></ion-input>
                </ion-item>
            </div>
            <div class="card__btns">
                <ion-button type="submit" color="primary" expand="block" shape="round" class="card__btn" [disabled]="!verificationForm.valid">
                    {{ 'REGISTRATION_SMS.verify' | translate }}
                </ion-button>
            </div>
        </form>
    </div>
</ion-content>
