import {Injectable} from '@angular/core';
import {App} from '@capacitor/app';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RedirectAfterLoginService } from './redirect-after-login-service';

@Injectable({
    providedIn: 'root',
})
export class DeeplinkService {

    constructor(
        private router: Router,
        private redirectAfterLoginService: RedirectAfterLoginService,
    ) {
    }

    register() {
        App.addListener('appUrlOpen', data => {
            if (data.url) {
                const partAfterDeeplink = data.url.split('/deeplink/')[1];
                const routeToNavigateTo = 'deeplink/' + partAfterDeeplink;
                // VW-3486: register the deeplink route as route to pick up after a login.
                // A login might be triggred in case the app was (made) inactive.
                this.redirectAfterLoginService.registerRedirectRouteForUrl(routeToNavigateTo);

                // trigger the deeplink request
                this.router.navigate([routeToNavigateTo ]);
            }
        });
    }

}
