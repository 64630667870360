import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ModalController, NavController} from '@ionic/angular';
import {AuthService} from './auth-service';
import {ApiService} from './api-service';
import {Device} from '@capacitor/device';
import {StorageService} from './storage-service';
import {MediaCommunication} from '../enums/media-communication';
import {TranslateService} from '@ngx-translate/core';
import {EventService} from './event-service';
import {NotificationModalComponent} from '../components/notification-modal/notification-modal';
import {TrackingService} from './tracking/tracking.service';
import {FirebaseMessaging, Notification} from '@capacitor-firebase/messaging';
import {Capacitor} from '@capacitor/core';
import {environment} from '../environments/environment';
import {ToastService} from './toast-service';

interface INotification {
    data: INotificationData;
    wasTapped: boolean;
}

interface INotificationData {
    title: string;
    body: string;
    user_id: string | null;
    media_communication_type: MediaCommunication | null;
    media_communication_id: string | null;
    task_id: string | null;
    routes: string | null;
    button_text: string | null;
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {

    static readonly STORAGE_KEY_NOTIFICATION = 'notification';
    static readonly TOPIC_ALL = 'all';

    private fcmReady$ = new BehaviorSubject<boolean>(false);

    constructor(
        private events: EventService,
        private authService: AuthService,
        private apiService: ApiService,
        private storageService: StorageService,
        private modalCtrl: ModalController,
        private translateService: TranslateService,
        private toastService: ToastService,
        private trackingService: TrackingService,
        private navCtrl: NavController,
    ) {
        document.addEventListener('deviceready', async () => {
            this.fcmReady$.next(true);
        });

        this.fcmReady()
            .then(() => {
                FirebaseMessaging.addListener('tokenReceived', () => this.sendTokenToApi());
                FirebaseMessaging.addListener('notificationReceived', (event) => {
                    // when app is in the foreground
                    this.receivedNotification(this.convertNotification(event.notification, false));
                });
                FirebaseMessaging.addListener('notificationActionPerformed', (event) => {
                    // when app is in background and push is tapped
                    // when app is closed and push is tapped
                    this.receivedNotification(this.convertNotification(event.notification, true));
                });
            });
    }

    public init(): void {
        this.events.subscribe('auth:initiated', () => this.register());
        this.events.subscribe('auth:token_stored', () => this.register());
        this.events.subscribe('auth:login_external', () => this.register());
        this.events.subscribe('auth:logout', () => this.unregister());
    }

    private convertNotification(notification: Notification, wasTapped: boolean): INotification {
        const notificationData: Partial<INotificationData> =
            typeof notification.data === 'object'
            && !Array.isArray(notification.data)
            && notification.data !== null
                ? notification.data as Record<string, unknown>
                : {};

        return {
            data: {
                title: notificationData.title || '',
                body: notificationData.body || '',
                user_id: notificationData.user_id || null,
                media_communication_type: notificationData.media_communication_type || null,
                media_communication_id: notificationData.media_communication_id || null,
                task_id: notificationData.task_id || null,
                routes: notificationData.routes || null,
                button_text: notificationData.button_text || null,
            },
            wasTapped,
        };
    }

    private async register(): Promise<void> {
        await this.authService.isReady();
        await this.fcmReady();

        if (this.authService.isAuthenticated()) {
            await FirebaseMessaging.checkPermissions()
                .then(async (status) => {
                    if (status.receive !== 'granted') {
                        await FirebaseMessaging.requestPermissions();
                    }
                })
                .then(() => FirebaseMessaging.checkPermissions())
                .then((status) => {
                    if (status.receive === 'granted') {
                        FirebaseMessaging.subscribeToTopic({topic: NotificationService.TOPIC_ALL});
                        this.sendTokenToApi();
                        this.handleStoredNotification();
                    }
                });
        }
    }

    private async unregister(): Promise<void> {
        await this.authService.isReady();
        await this.fcmReady();

        if (this.authService.isAuthenticated()) {
            await FirebaseMessaging.removeAllDeliveredNotifications();
            await FirebaseMessaging.unsubscribeFromTopic({topic: NotificationService.TOPIC_ALL});
            await FirebaseMessaging.deleteToken();
        }
    }

    private async sendTokenToApi(): Promise<void> {
        await this.authService.isReady();
        await this.fcmReady();

        if (this.authService.isAuthenticated()) {
            const version = environment.version;
            const token = await this.getToken();

            if (!!version && !!token) {
                await this.apiService.authenticatedPost('/push', {
                    pushPlatform: Capacitor.getPlatform().toLowerCase(),
                    pushToken: token,
                    appVersion: version,
                    deviceUuid: (await Device.getId()).identifier,
                }).catch((error) => {
                    this.trackingService.exception(error);
                    console.error(error.message);
                });
            }
        }
    }

    private async getToken(): Promise<string> {
        await this.fcmReady();

        return FirebaseMessaging.checkPermissions()
            .then(() => FirebaseMessaging.getToken())
            .then((result) => result.token)
            .catch(() => '');
    }

    private async receivedNotification(notification: INotification): Promise<void> {
        if (!this.authService.isAuthenticated()) {
            // Store the notification to handle it after login
            await this.storeNotification(notification);

        } else if (!!notification.data.user_id && this.authService.getUserId() !== notification.data.user_id) {
            // Logged in as somebody else
            console.error('Notification send for somebody else', notification);

        } else {
            // Handle notifications
            if (
                !!notification.data.media_communication_id
                && [MediaCommunication.NEWS, MediaCommunication.INSTRUCTION].includes(notification.data.media_communication_type)
            ) {
                await this.handleMediaCommunicationNotification(notification);
            } else if (!!notification.data.task_id) {
                await this.handleTaskNotification(notification);

                // Handle all other notifications
            } else {
                await this.handleDefaultNotification(notification);
            }

            await this.removeStoredNotification();
        }
    }

    private async openRoutes(routes: string[]) {
        let redirected = false;
        for (let i = 0, length = routes.length; i < length; i++) {
            redirected = await this.navCtrl.navigateForward(routes[i]).catch(() => {
                return false;
            });
            if (redirected) {
                break;
            }
        }

        if (!redirected) {
            await this.toastService.showCustomMessages(this.translateService.instant('NOTIFICATION.route_not_found'));
        }
    }

    private async handleStoredNotification(): Promise<void> {
        await this.getStoredNotification()
            .then(async (notification: INotification) => {
                if (notification) {
                    await this.removeStoredNotification();
                    await this.receivedNotification(notification);
                }
            });
    }

    private async storeNotification(notification: INotification): Promise<void> {
        return this.storageService.set(NotificationService.STORAGE_KEY_NOTIFICATION, notification);
    }

    private async getStoredNotification(): Promise<INotification> {
        return this.storageService.get(NotificationService.STORAGE_KEY_NOTIFICATION);
    }

    private async removeStoredNotification(): Promise<void> {
        return this.storageService.remove(NotificationService.STORAGE_KEY_NOTIFICATION);
    }

    private fcmReady(): Promise<void> {
        return new Promise((resolve) => {
            this.fcmReady$.subscribe((isReady: boolean) => {
                if (isReady) {
                    resolve();
                }
            });
        });
    }

    private async handleMediaCommunicationNotification(notification: INotification) {
        const type = notification.data.media_communication_type;
        const url = type === MediaCommunication.NEWS
            ? [`/news-details/${notification.data.media_communication_id}`]
            : [`/media-communication-details/${notification.data.media_communication_id}`];

        if (notification.wasTapped) {
            await this.navCtrl.navigateForward(url);
        } else {
            const modal = await this.modalCtrl.create({
                component: NotificationModalComponent,
                componentProps: {
                    theme: 'primary',
                    heading: this.translateService.instant(`PUSH.${type}.title`),
                    body: notification.data.body,
                    button: this.translateService.instant('NOTIFICATION.open'),
                },
            });

            modal.onDidDismiss()
                .then(async (action) => {
                    if (action.data === 'button') {
                        await this.navCtrl.navigateForward(url);
                    }
                });

            await modal.present();
        }
    }

    private async handleTaskNotification(notification: INotification) {
        const url = `/questionnaire-task/${notification.data.task_id}`;
        const type = notification.data.media_communication_type;

        if (notification.wasTapped) {
            await this.navCtrl.navigateForward(url, { replaceUrl: true});
        } else {
            const modal = await this.modalCtrl.create({
                component: NotificationModalComponent,
                componentProps: {
                    theme: 'primary',
                    heading: this.translateService.instant(`PUSH.task.title`),
                    body: notification.data.body,
                    button: this.translateService.instant('NOTIFICATION.open'),
                },
            });

            modal.onDidDismiss()
                .then(async (action) => {
                    if (action.data === 'button') {
                        await this.navCtrl.navigateForward(url);
                    }
                });

            await modal.present();
        }
    }

    private async handleDefaultNotification(notification: INotification) {
        if (notification.wasTapped && notification.data.routes) {
            await this.openRoutes(notification.data.routes.split(';'));
        } else {
            const modal = await this.modalCtrl.create({
                component: NotificationModalComponent,
                componentProps: {
                    theme: 'primary',
                    heading: this.translateService.instant('NOTIFICATION.title'),
                    subject: notification.data.title,
                    body: notification.data.body,
                    button: this.translateService.instant( notification.data.button_text ? notification.data.button_text : 'NOTIFICATION.close'),
                },
            });

            modal.onDidDismiss()
                .then(async (action) => {
                    if (action.data === 'button' && !!notification.data.routes) {
                        return this.openRoutes(notification.data.routes.split(';'));
                    }
                });

            await modal.present();
        }
    }
}
