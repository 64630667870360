<app-dashboard-header [showDashboardButton]="false" [showBackButton]="true" [showLanguageSelection]="true"></app-dashboard-header>

<ion-content class="has-header">
    <div class="card">
        <div class="card__header">
            <h2 class="card__title">{{ 'EXTERNAL_LOGIN.title' | translate }}</h2>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="fields">
                <ion-item lines="none">
                    <ion-input formControlName="username" type="email" placeholder="{{ 'EXTERNAL_LOGIN.email' | translate }}"></ion-input>
                </ion-item>
                <ion-item lines="none">
                    <ion-input formControlName="password" type="password" placeholder="{{ 'EXTERNAL_LOGIN.password' | translate }}"></ion-input>
                </ion-item>
            </div>
            <div class="login-buttons">
                <ion-button type="button" [routerLink]="['/forgot-password-form']" color="light-primary" class="forgot-password">{{ 'EXTERNAL_LOGIN.forgot_password' | translate }}</ion-button>
                <ion-button type="submit" id="button-login" expand="block" shape="round" color="primary" class="card__btn">{{ 'EXTERNAL_LOGIN.login' | translate }}</ion-button>
                <ion-button type="button" [routerLink]="['/registration']" expand="block" shape="round" fill="outline" class="card__btn card__btn--outline">{{ 'EXTERNAL_LOGIN.create_account' | translate }}</ion-button>
            </div>
        </form>
    </div>
</ion-content>
