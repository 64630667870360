import {Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Choice} from '../../models/choice';
import slugify from 'slugify';
import {Platform} from '@ionic/angular';

@Component({
    selector: 'app-searchable-choices',
    templateUrl: 'searchable-choices.html',
})
export class SearchableChoicesComponent implements OnInit {

    @Input() public choices: Choice[];
    @Output() private choicesFiltered: EventEmitter<Choice[]> = new EventEmitter();
    @ViewChild('searchBar') private searchBar: HTMLIonSearchbarElement;

    constructor(
        private element: ElementRef,
        private platform: Platform,
    ) {
    }

    ngOnInit() {
        this.filterChoices();
        this.element.nativeElement.classList.add(`app-searchable-choices--${this.platform.is('ios') ? 'ios' : 'md'}`);
    }

    filterChoices() {
        const value = this.normalizeValue(this.searchBar?.value);

        if (value === '') {
            this.choicesFiltered.emit(this.choices);
            return;
        }

        this.choicesFiltered.emit(this.choices.filter((choice) => {
            const choiceValue = this.normalizeValue(`${choice.title} ${choice?.subtitle}`);
            const choiceValueSimilar = this.similarValue(choiceValue);

            return choiceValue.includes(value) || choiceValueSimilar.includes(value);
        }));
    }

    normalizeValue(value?: string): string {
        return value?.trim().toLowerCase().replace(/\s\s+/g, ' ') || '';
    }

    /**
     * Convert diacritics like "é" to "e" and remove punctuation marks for the mobile keyboard
     */
    similarValue(value: string): string {
        const options = {replacement: ' ', remove: /[_\-|=*+~.,{}()«»<>„”“^`´’'"?!:;/\\]/g, lower: true};

        return slugify(value, options);
    }

}
