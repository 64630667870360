import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router} from '@angular/router';
import {AuthService} from '../services/auth-service';
import {RouteUtil} from '../utils/route.util';
import {TrackingService} from '../services/tracking/tracking.service';
import { RedirectAfterLoginService } from '../services/redirect-after-login-service';

@Injectable({
    providedIn: 'root',
})
export class UnauthorizedRedirectGuard implements CanActivate, CanActivateChild {

    constructor(
        private authService: AuthService,
        private redirectAfterLoginService: RedirectAfterLoginService,
        private router: Router,
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        await this.authService.isReady();
        if (!this.authService.isAuthenticated()) {
            this.redirectAfterLoginService.registerRedirectRoute(route);
            try {
                await this.authService.requestNewJwt();
                return true;
            } catch (e) {
                await this.router.navigate(['/choose-login']);
                return false;
            }
        } else {
            return true;
        }
    }

    async canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
        return this.canActivate(route);
    }

}
