import {Injectable} from '@angular/core';
import {Concept} from '../models/concept';
import {AuthService} from './auth-service';
import {StorageService} from './storage-service';
import {UUID} from 'angular2-uuid';
import {TrackingService} from './tracking/tracking.service';
import {BehaviorSubject} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {EventService} from './event-service';

@Injectable({
    providedIn: 'root',
})
export class ConceptService {

    private cachedConcepts: Concept[] = null;
    private isLoadingFromStorage = false;
    private doneLoadingFromStorage$ = new BehaviorSubject<boolean>(false);
    private updateConceptsDebounceId: number;

    constructor(
        private storageService: StorageService,
        private authService: AuthService,
        private trackingService: TrackingService,
        private events: EventService,
    ) {
        this.events.subscribe('auth:before_logout', () => this.flushConcepts());
    }

    private loadConceptsFromStorage(): void {
        this.isLoadingFromStorage = true;
        this.doneLoadingFromStorage$.next(false);
        this.storageService.get(this.authService.getUserId())
            .then((concepts) => {
                this.cachedConcepts = (concepts && Array.isArray(concepts)) ? concepts : [];
                this.doneLoadingFromStorage$.next(true);
            });
    }

    private isReady(): Promise<void> {
        return new Promise((resolve) => {
            if (!this.isLoadingFromStorage && this.authService.isAuthenticated()) {
                this.loadConceptsFromStorage();
            }

            this.doneLoadingFromStorage$
                .pipe(
                    filter(isReady => !!isReady),
                    take(1),
                )
                .subscribe(() => resolve());
        });
    }

    private async flushConcepts(): Promise<void> {
        await this.storeConcepts();

        this.isLoadingFromStorage = false;
        this.doneLoadingFromStorage$.next(false);
        this.cachedConcepts = null;
    }

    private updateConcepts(concepts: Concept[]): void {
        this.cachedConcepts = concepts;

        if (this.updateConceptsDebounceId) {
            window.clearTimeout(this.updateConceptsDebounceId);
        }
        this.updateConceptsDebounceId = window.setTimeout(() => this.storeConcepts(), 4000);
    }

    public async storeConcepts(): Promise<void> {
        if (this.authService.isAuthenticated()) {
            await this.storageService.set(this.authService.getUserId(), this.cachedConcepts);
        }
    }

    public getConcept(conceptId: UUID): Promise<Concept> {
        return this.getConcepts()
            .then((concepts) => {
                return concepts.find((concept) => concept.id === conceptId);
            });
    }

    /**
     * Gets the concept form from the storage
     *
     * @returns
     */
    public async getConcepts(): Promise<Concept[]> {
        await this.isReady();

        return this.cachedConcepts;
    }

    /**
     * Returns the number of concepts for a questionnaire ID
     *
     * @param id
     */
    public getConceptCountForQuestionnaireID(id: number): Promise<number> {
        return this.getConcepts()
            .then(concepts => {
                return concepts.reduce((previousValue, concept) => {
                    return concept.questionnaire.id === id ? previousValue + 1 : previousValue;
                }, 0);
            });
    }

    /**
     * Saves a concept
     *
     * @param concept
     * @param currentRoute
     * @returns
     */
    public saveConcept(concept: Concept, currentRoute: string|undefined): Promise<void> {
        return this.getConcepts()
            .then((concepts) => {
                const currentConceptIndex = concepts.findIndex(it => it.id === concept.id);
                const index = currentConceptIndex !== -1 ? currentConceptIndex : concepts.length;

                if (currentRoute && !currentRoute.startsWith('/concept/')) {
                    console.error('Concept tried to save an improper latest route: ' + currentRoute);
                    currentRoute = '';
                }

                if (!!currentRoute) {
                    concept.latest_route = currentRoute;
                }
                concept.updated_at = new Date().toISOString();

                concepts[index] = concept;

                this.updateConcepts(concepts);
            });
    }

    /**
     * Deletes a concept and it's saved images from the storage
     *
     * @param concept
     * @returns
     */
    async deleteConcept(concept: Concept): Promise<any> {
        const images = this.flatten(
            concept.questionnaire.questions.map(
                (question) => this.findStoredImagesPerQuestion(question),
            ),
        );

        return this.deleteStoredImages(images)
            .then(() => {
                return this.getConcepts()
                    .then((concepts) => {
                        this.updateConcepts(concepts.filter(it => it.id !== concept.id));
                    });
            })
            .catch((err) => {
                this.trackingService.exception(err);
                throw new Error(err);
            });
    }

    /**
     * Flatten an n-dimensional array
     *
     * @param arr
     *
     */
    private flatten(arr) {
        return arr.reduce((flat, toFlatten) =>
                flat.concat(Array.isArray(toFlatten) ? this.flatten(toFlatten) : toFlatten)
            , []);
    }

    /**
     * Finds all stored image arrays per questionnaire
     *
     * @param question
     * @param images
     *
     * @returns
     */
    private findStoredImagesPerQuestion(question, images = []) {
        if (question.type === 'image') {
            images.push(this.findStoredImages(question.answers));
        } else if (question.attachments) {
            images.push(this.findStoredImages(question.attachments));
        }
        if (question.choices && question.choices.length > 0) {
            for (const choice of question.choices) {
                this.findStoredImagesPerQuestion(choice, images);
            }
        } else if (question.questions && question.questions.length > 0) {
            for (const q of question.questions) {
                this.findStoredImagesPerQuestion(q, images);
            }
        }
        return images;
    }

    /**
     * Finds all image file names per image array
     *
     * @param attachments
     * @returns
     */
    private findStoredImages(attachments: string[][]) {
        if (attachments) {
            return attachments.filter((images) => images && images.length > 0)
                .map((images) => images.map((fileName) => fileName));
        } else {
            return [];
        }
    }

    /**
     * Deletes the stored images per concept
     *
     * @param images
     * @returns
     */
    private async deleteStoredImages(images): Promise<boolean> {
        try {
            for (const image of images) {
                await this.storageService.remove(image);
            }
            return true;
        } catch (err) {
            this.trackingService.exception(err);
            throw new Error(err);
        }
    }

}
