<app-dashboard-header [showDashboardButton]="false" [showHelpButton]="true"></app-dashboard-header>

<ion-content>
    <div class="card card--compact">
        <ion-item [routerLink]="['/contact']" class="select-item">
            <div class="select-item__wrap">
                <ion-icon src="assets/images/icon-user.svg" class="select-item__icon"></ion-icon>
                <div class="select-item__text">
                    <p class="select-item__title">{{ 'CONTACT.title' | translate }}</p>
                </div>
                <ion-icon src="assets/images/icon-arrow-right.svg" class="select-item__icon-circle"></ion-icon>
            </div>
        </ion-item>
        <ion-item [routerLink]="['/questionnaire-history']" class="select-item">
            <div class="select-item__wrap">
                <ion-icon src="assets/images/icon-clock.svg" class="select-item__icon"></ion-icon>
                <div class="select-item__text">
                    <p class="select-item__title">{{ 'DASHBOARD_MORE.tile.history' | translate }}</p>
                </div>
                <ion-icon src="assets/images/icon-arrow-right.svg" class="select-item__icon-circle"></ion-icon>
            </div>
        </ion-item>
        <ion-item *ngIf="hasNewsItems" [routerLink]="['/news-list']" class="select-item">
            <div class="select-item__wrap">
                <ion-icon src="assets/images/icon-news.svg" class="select-item__icon"></ion-icon>
                <div class="select-item__text">
                    <p class="select-item__title">{{ 'DASHBOARD_MORE.tile.news' | translate }}</p>
                </div>
                <ion-icon src="assets/images/icon-arrow-right.svg" class="select-item__icon-circle"></ion-icon>
            </div>
        </ion-item>
        <ion-item *ngIf="cardGamePlayable" (click)="drawPlayCards()" class="select-item">
            <div class="select-item__wrap">
                <ion-icon src="assets/images/icon-cards.svg" class="select-item__icon"></ion-icon>
                <div class="select-item__text">
                    <p class="select-item__title">{{ 'DASHBOARD_MORE.tile.cardGame' | translate }}</p>
                </div>
                <ion-icon src="assets/images/icon-arrow-right.svg" class="select-item__icon-circle"></ion-icon>
            </div>
        </ion-item>
        <ion-item *ngIf="hasTasks" [routerLink]="['/questionnaire-tasks']" class="select-item">
            <div class="select-item__wrap">
                <ion-icon src="assets/images/icon-task.svg" class="select-item__icon"></ion-icon>
                <div class="select-item__text">
                    <p class="select-item__title">{{ 'DASHBOARD_MORE.tile.tasks' | translate }}</p>
                </div>
                <ion-icon src="assets/images/icon-arrow-right.svg" class="select-item__icon-circle"></ion-icon>
            </div>
        </ion-item>
    </div>
</ion-content>
