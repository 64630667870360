import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {CanLeavePage} from '../models/can-leave-page';

@Injectable({
    providedIn: 'root',
})
export class PageLeaveGuard implements CanDeactivate<CanLeavePage> {

    canDeactivate(component: CanLeavePage): Promise<boolean> {
        return component.canLeave();
    }

}
