import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AzureAuthService} from './azure-auth-service';
import {ToastService} from './toast-service';
import {StorageService} from './storage-service';
import {HttpClient} from '@angular/common/http';
import {API_SUFFIX} from '../app/app.module';
import {environment} from '../environments/environment';
import {EventService} from './event-service';
import {filter, take, timeout} from 'rxjs/operators';
import {WindowLocationUtils} from '../utils/window-location-utils';
import {BehaviorSubject, Observable} from 'rxjs';
import {TrackingService} from './tracking/tracking.service';
import {TranslateService} from '@ngx-translate/core';

export enum AccessType {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
}
type AccessTypes = AccessType.INTERNAL | AccessType.EXTERNAL;

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    static readonly ACCESS_TOKEN = 'accessToken';
    static readonly ACCESS_TYPE = 'accessType';
    static readonly ACCESS_VERIFIED = 'accessVerified';
    static readonly REFRESH_TOKEN = 'refreshToken';
    static readonly CURRENT_USER = 'currentUser';

    private _accessToken: string;
    private accessType: AccessTypes;
    private jwtHelper: JwtHelperService = new JwtHelperService();
    private isReady$ = new BehaviorSubject<boolean>(false);
    private onAuthChanged = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private azureAuth: AzureAuthService,
        private trackingService: TrackingService,
        private toast: ToastService,
        private events: EventService,
        private translateService: TranslateService,
    ) {
    }

    /**
     * Initialises the access token
     *
     * @returns Promise<any>
     */
    public initAccessToken(): Promise<any> {
        return this.storageService.get(AuthService.ACCESS_TOKEN)
            .then((accessToken) => {
                this._accessToken = accessToken;

                return this.storageService.get(AuthService.ACCESS_TYPE);
            })
            .then((accessType) => {
                this.accessType = accessType;

                if (!this.isAuthenticated()) {
                    return this.requestNewJwt();
                }
            })
            .then(() => {
                this.events.publish('auth:initiated');

                return Promise.resolve();
            })
            .catch((err) => {
                console.log('err', err);
                this.trackingService.exception(err);
                return Promise.reject(err);
            })
            .finally(() => {
                this.isReady$.next(true);
            });
    }

    /**
     * Returns if the user is authenticated
     *
     * @returns boolean
     */
    public isAuthenticated(): boolean {
        return !!this._accessToken && !this.jwtHelper.isTokenExpired(this._accessToken, 30);
    }

    public isReady(): Promise<void> {
        return new Promise((resolve) => {
            this.isReady$
                .pipe(
                    filter(isReady => !!isReady),
                    take(1),
                )
                .subscribe(() => resolve());
        });
    }

    /**
     * Returns the access token
     *
     * @returns string
     */
    get token(): string {
        if (this.isAuthenticated()) {
            return this._accessToken;
        }
        return null;
    }

    /**
     * Authenticate internal users
     *
     * @returns Promise<any>
     */
    public internalUserAuthenticate(): Promise<any> {
        return this.azureAuth.authenticate()
            .then(token => {
                return this.loginComplete(token);
            })
            .catch((error) => {
                return new Promise((_, reject) => {
                    reject(error);
                });
            });
    }

    /**
     * Requests a new JWT token for auth
     *
     * @returns Promise<any>
     */
    public requestNewJwt(): Promise<any> {
        if (this.isInternalUser()) {
            return this.requestNativeNewJwt();
        } else if (this.isExternalUser()) {
            return this.requestBackendNewJwt();
        } else {
            return new Promise((resolve, reject) => {
                reject('Not logged in');
            });
        }
    }

    /**
     * Gets a JWT from Azure
     *
     * @returns Promise<any>
     */
    private requestNativeNewJwt(): Promise<any> {
        return this.azureAuth.nativeRefreshToken()
            .then(result => {
                return this.loginComplete(result);
            });
    }

    /**
     * Finalises the login process
     *
     * @param token string
     * @param refreshToken string
     * @returns Promise<any>
     */
    public loginComplete(token: string, refreshToken?: string): Promise<any> {
        return this.storeToken(token, refreshToken);
    }

    /**
     * Checks and enforces a valid JWT token for a authenticated call
     */
    public enforceJwt(): Promise<any> {
        if (this.token) {
            return Promise.resolve();
        } else {
            return this.requestNewJwt()
                .catch((err) => {
                    if (err.status === null || (err.status !== 403 && err.status !== 401)) {
                        this.toast.showCustomMessages(this.translateService.instant('NOTIFICATION.error_logged_out'));
                    }

                    this.events.publish('auth:failed');

                    return this.logout();
                });
        }
    }

    /**
     * Redirection call
     *
     * @returns Promise<boolean>
     */
    async handleRedirect() {
        const token = WindowLocationUtils.getParams().access_token;
        if (token) {
            WindowLocationUtils.stripToken();
            await this.loginComplete(token);

            return true;
        }

        return false;
    }

    /**
     * Logout call
     *
     * @returns Promise<any>
     */
    public async logout(): Promise<any> {
        this.events.publish('auth:before_logout');
        this.onAuthChanged.next(true);

        if (this.isInternalUser()) {
            await this.azureAuth.logout();
        }

        return this.clearAuthenticationSettings();
    }

    /**
     * Clears all auth tokens
     *
     * @returns Promise<[any , any , any , any]>
     */
    private clearAuthenticationSettings(): Promise<any> {
        this._accessToken = null;

        return Promise.all([
            this.storageService.remove(AuthService.ACCESS_TOKEN),
            this.storageService.remove(AuthService.REFRESH_TOKEN),
            this.storageService.remove(AuthService.ACCESS_TYPE),
            this.storageService.remove(AuthService.ACCESS_VERIFIED),
            this.storageService.remove(AuthService.CURRENT_USER),
        ]);
    }

    /**
     * Stores the token
     *
     * @param accessToken string
     * @param refreshToken string
     * @returns Promise<any>
     */
    private storeToken(accessToken: string, refreshToken?: string): Promise<any> {
        const decodedToken = this.jwtHelper.decodeToken(accessToken);
        const accessType: AccessTypes = decodedToken.type === AccessType.EXTERNAL ? AccessType.EXTERNAL : AccessType.INTERNAL;

        return Promise.all([
            this.storageService.set(AuthService.ACCESS_TYPE, accessType),
            this.storageService.set(AuthService.ACCESS_TOKEN, accessToken),
            this.storageService.set(AuthService.REFRESH_TOKEN, refreshToken),
            this.storageService.set(AuthService.CURRENT_USER, decodedToken.upn)])
            .then(() => {
                this._accessToken = accessToken;
                this.accessType = accessType;

                this.events.publish('auth:token_stored');
                this.onAuthChanged.next(true);
            });
    }

    /**
     * Gets a new JWT based on refresh token
     *
     * @returns Promise<void>
     */
    private requestBackendNewJwt(): Promise<any> {
        return this.storageService.get(AuthService.REFRESH_TOKEN)
            .then((refreshToken) => {
                if (typeof refreshToken === 'string') {
                    return this.http.post<any>(`${environment.api}${API_SUFFIX}/login/refresh_token`, {refresh_token: refreshToken})
                        .pipe(
                            timeout(environment.apiTimeout),
                        )
                        .toPromise()
                        .then((response) => {
                            return this.storeToken(response.data.access_token, response.data.refresh_token);
                        });
                }
            });
    }

    /**
     * Gets the verified access
     *
     * @returns Promise<boolean>
     */
    public isAccessVerified(): Promise<boolean> {
        return this.storageService.get(AuthService.ACCESS_VERIFIED)
            .then(accessVerified => {
                return accessVerified !== null;
            });
    }

    /**
     * Sets the verified access
     *
     * @returns Promise<void>
     */
    public setAccessVerified(): Promise<any> {
        return this.storageService.set(AuthService.ACCESS_VERIFIED, 'true');
    }

    /**
     * Returns is the user is a internal user
     *
     * @returns boolean
     */
    public isInternalUser() {
        return this.accessType === AccessType.INTERNAL;
    }

    /**
     * Returns is the user is a external user
     *
     * @returns boolean
     */
    public isExternalUser() {
        return this.accessType === AccessType.EXTERNAL;
    }

    /**
     * Returns the userID for the authenticated user
     *
     * @returns string
     */
    public getUserId() {
        if (this._accessToken) {
            const decodedToken = this.jwtHelper.decodeToken(this._accessToken);

            return this.isExternalUser() ? decodedToken.uid : decodedToken.oid;
        }
    }

    onAuthChanged$(): Observable<boolean> {
        return this.onAuthChanged.asObservable();
    }
}
