<ion-content  class="has-header has-footer">
    <div class="questionnaire-header"></div>
    <div class="card">
        <div class="card__header">
            <ion-icon src="assets/images/icon-form-checkmark.svg" class="card__icon card__icon--primary card__icon--modal"></ion-icon>
            <h2 class="card__title">{{ 'QUESTIONNAIRE_SENT.title' | translate }}</h2>
            <p class="card__description">{{ 'QUESTIONNAIRE_SENT.subtitle' | translate }}</p>
            <div class="card__ticket">
                <ion-icon src="assets/images/icon-ticket.svg" class="card__ticket-icon"></ion-icon>
                <span class="card__ticket-text">{{ ticketNumber }}</span>
            </div>
        </div>
        <div class="card__btns">
            <ion-button expand="block" shape="round" color="primary" class="card__btn" (click)="goToDashboard()">{{ 'BUTTONS.next' | translate }}</ion-button>
        </div>
    </div>
</ion-content>
