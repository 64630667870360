import {Injectable} from '@angular/core';
import {Drivers, Storage} from '@ionic/storage';
import {environment} from '../environments/environment';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import {filter, take} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {EventService} from './event-service';
import {Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class StorageService {

    static THEME = 'theme';
    static OVERLAY_SHOWN = 'overlayShown';
    static QUESTIONNAIRES = 'questionnaires';
    static OLD_CONCEPTS = 'concepts';
    static APP_VERSION = 'appVersion';
    static QUESTIONNAIRE_REMINDERS = 'reminders';
    static QUESTIONNAIRE_TASK_STATUSES = 'task_statuses';
    static HAS_NEWS_ITEMS = 'has_news_items';
    static HAS_INSTRUCTION_ITEMS = 'has_instruction_items';
    static NEWS_ITEMS = 'news_items';
    static NEWS_LIST_ITEMS = 'news_list_items';
    static MEDIA_COMMUNICATION_CATEGORIES = 'media_communication_categories';
    static MEDIA_COMMUNICATION_ITEMS = 'media_communication_items';
    static MEDIA_COMMUNICATION_LIST_ITEMS = 'media_communication_list_items';
    static ACCESS_TYPE = 'accessType';
    static ACCESS_TOKEN = 'accessToken';
    static REFRESH_TOKEN = 'refreshToken';
    static CURRENT_USER = 'currentUser';
    static NETWORK_CACHE = 'NETWORK_CACHE';

    private storage: Storage;
    private isStorageLoading = false;
    private isReady$ = new BehaviorSubject<boolean>(false);

    constructor(
        private events: EventService,
        private platform: Platform,
    ) {
        this.events.subscribe('auth:logout', () => this.clearStorage());

        this.storage = new Storage({
            name: environment.storage,
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
            size: 100000000, // 100 MB in bytes (iOS = no limit) (Android = 100 MB limit for sqlite)
        });
    }

    private storageReady(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (!this.isStorageLoading) {
                this.isStorageLoading = true;
                this.platform.ready()
                    .then(() => this.storage.defineDriver(CordovaSQLiteDriver))
                    .then(() => this.storage.create())
                    .then(() => {
                        this.isReady$.next(true);
                    });
            }

            this.isReady$
                .pipe(
                    filter(isReady => !!isReady),
                    take(1),
                )
                .subscribe(() => resolve());
        });
    }

    /**
     * Gets an item from the storage
     *
     * @param key
     * @returns
     */
    public get(key: string): Promise<any> {
        return this.storageReady()
            .then(() => {
                return this.storage.get(key);
            });
    }

    /**
     * gets a value from the storage
     *
     * @param key
     * @param value
     * @returns
     */
    public set(key: string, value: any): Promise<void> {
        return this.storageReady()
            .then(() => {
                return this.storage.set(key, value);
            });
    }

    /**
     * gets all keys in the storage
     *
     * @returns
     */
    public keys(): Promise<string[]> {
        return this.storageReady()
            .then(() => {
                return this.storage.keys();
            });
    }

    /**
     * Clears a value from the storage
     *
     * @param key
     * @returns
     */
    public remove(key: string): Promise<any> {
        return this.storageReady()
            .then(() => {
                return this.storage.remove(key);
            });
    }

    public async has(key: string): Promise<boolean> {
        return this.keys()
            .then((keys) => {
                return keys.includes(key);
            });
    }

    private clearStorage(): Promise<void> {
        return this.storageReady()
            .then(() => {
                // VW-2727 - We cannot clear the whole storage so do it key based
                // After some app updates you need to login again which triggers clearing the storage
                return this.storage.keys();
            })
            .then((storageKeys) => {
                storageKeys.forEach((storageKey) => {
                    const canClearStorageKey = !!this.getClearableStorageKeyPrefixes().find((clearableStorageKeyPrefix) => storageKey.startsWith(clearableStorageKeyPrefix));

                    if (canClearStorageKey) {
                        this.remove(storageKey);
                    }
                });
            });
    }

    private getClearableStorageKeyPrefixes(): string[] {
        return [
            StorageService.THEME,
            StorageService.OVERLAY_SHOWN,
            StorageService.QUESTIONNAIRES,
            StorageService.OLD_CONCEPTS,
            StorageService.APP_VERSION,
            StorageService.QUESTIONNAIRE_REMINDERS,
            StorageService.QUESTIONNAIRE_TASK_STATUSES,
            StorageService.HAS_NEWS_ITEMS,
            StorageService.HAS_INSTRUCTION_ITEMS,
            StorageService.NEWS_ITEMS,
            StorageService.NEWS_LIST_ITEMS,
            StorageService.MEDIA_COMMUNICATION_CATEGORIES,
            StorageService.MEDIA_COMMUNICATION_ITEMS,
            StorageService.MEDIA_COMMUNICATION_LIST_ITEMS,
            StorageService.ACCESS_TYPE,
            StorageService.ACCESS_TOKEN,
            StorageService.REFRESH_TOKEN,
            StorageService.CURRENT_USER,
            StorageService.NETWORK_CACHE,
        ];
    }

}
