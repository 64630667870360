import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-select-item',
    templateUrl: 'select-item.html',
    styleUrls: ['select-item.scss'],
})
export class SelectItemComponent {

    @Input() public title: string;
    @Input() public subTitle: string;
    @Input() public hasSubTitleExclamationIcon: boolean;
    @Input() public imageData: string;
    @Input() public numberOfConcepts: number;
    @Input() public deadlineAt = '';
    @Input() public hasImagePlaceholder = true;
}
