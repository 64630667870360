import {AfterViewInit, Directive, ElementRef} from '@angular/core';
import {Platform} from '@ionic/angular';

@Directive({
    selector: '[ionAutoMode]',
})
export class IonAutoModeDirective implements AfterViewInit {

    constructor(
        private platform: Platform,
        private element: ElementRef,
    ) {
    }

    ngAfterViewInit() {
        this.element.nativeElement.mode = this.platform.is('ios') ? 'ios' : 'md';
    }

}
