import {Component} from '@angular/core';
import {NavController} from '@ionic/angular';
import {QuestionnaireListItem} from '../../models/questionnaire';
import {QuestionnaireDataService} from '../../services/questionnaire-data-service';
import {HistoryService} from '../../services/history-service';
import {LoadingService} from '../../services/loading-service';
import {ToastService} from '../../services/toast-service';
import {DateFormatPipe} from '../../pipes/date-format';
import {QuestionnaireRouteService} from '../../services/questionnaire-route.service';
import {QuestionnaireChapterService} from '../../services/questionnaire-chapter.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'page-questionnaire-history',
    templateUrl: 'questionnaire-history.html',
})
export class QuestionnaireHistoryPage {

    public questionnaireList: QuestionnaireListItem[] = [];

    constructor(
        private navCtrl: NavController,
        private historyService: HistoryService,
        private questionnairesDataService: QuestionnaireDataService,
        private loadingService: LoadingService,
        private toast: ToastService,
        private dateFormatPipe: DateFormatPipe,
        private questionnaireRouteService: QuestionnaireRouteService,
        private questionnaireChapterService: QuestionnaireChapterService,
        private translateService: TranslateService,
    ) {
    }

    async ionViewWillEnter() {
        this.questionnaireList = [];
        await this.loadingService.start();
        this.historyService.getQuestionnaireHistory()
            .toPromise()
            .then((questionnaireList) => {
                this.questionnaireList = questionnaireList;
            })
            .catch(() => {
                this.toast.showCustomMessages(
                    this.translateService.instant('NOTIFICATION.error_general'),
                    this.translateService.instant('NOTIFICATION.offline'),
                );
            })
            .finally(() => {
                this.loadingService.stop();
            });
    }

    public trackQuestionnaireListItemById(index, item: QuestionnaireListItem): number | null {
        if (!item) {
            return null;
        } else {
            return item.id;
        }
    }

    public getSubTitle(questionnaireListItem: QuestionnaireListItem): string {
        const formatDate = (date: string | Date) => this.dateFormatPipe.transform(date, this.translateService.instant('DATE.date'));

        if (questionnaireListItem.status !== 'COMPLETED') {
            return `${this.translateService.instant('QUESTIONNAIRE_HISTORY.submitted_at')} ${formatDate(questionnaireListItem.createdAt)}`;
        } else {
            if (questionnaireListItem.processedAt) {
                return `${this.translateService.instant('QUESTIONNAIRE_HISTORY.processed_at')} ${formatDate(questionnaireListItem.processedAt)}, ${this.translateService.instant('QUESTIONNAIRE_HISTORY.submitted_at')} ${formatDate(questionnaireListItem.createdAt)}`;
            } else {
                return `${this.translateService.instant('QUESTIONNAIRE_HISTORY.processed_submitted_at')} ${formatDate(questionnaireListItem.createdAt)}`;
            }
        }
    }

    /**
     * Navigate to the questionnaire
     *
     * @param questionnaireListItem
     */
    public async navigateToQuestionnaire(questionnaireListItem: QuestionnaireListItem) {
        await this.loadingService.start();

        const questionnaire = await this.historyService.getQuestionnaireHistoryItem(questionnaireListItem.id);
        this.questionnaireChapterService.clearExpandedChaptersForQuestionnaire(questionnaire);
        await this.loadingService.stop();

        const {route} = await this.questionnaireRouteService.getFirstRouteFor(questionnaire, true);
        await this.navCtrl.navigateForward(`/history${route}`);
    }

}
