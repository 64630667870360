import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NavController, LoadingController} from '@ionic/angular';
import {ExternalUserService} from '../../services/external-user-service';
import {ToastService} from '../../services/toast-service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'page-registration-verify-sms',
    templateUrl: 'registration-verify-sms.html',
})
export class RegistrationVerifySmsPage {

    public verificationForm: UntypedFormGroup;
    private emailAddress: string;

    constructor(
        fb: UntypedFormBuilder,
        private externalUserService: ExternalUserService,
        private loadingCtrl: LoadingController,
        private toast: ToastService,
        private navCtrl: NavController,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
    ) {
        this.createForm(fb);
        activatedRoute.queryParams.subscribe(params => {
            if (this.router.getCurrentNavigation().extras.state) {
                this.emailAddress = this.router.getCurrentNavigation().extras.state.emailAddress;
            }
        });
    }

    async verifySms() {
        if (this.verificationForm.valid) {
            const loading = await this.loadingCtrl.create();

            try {
                await loading.present();

                await this.externalUserService.postVerifySms(
                    JSON.stringify({
                        email_address: this.emailAddress,
                        verification_code: this.verificationForm.value.verificationCode,
                    }));

                await loading.dismiss();

                await this.navCtrl.navigateForward(['/registration-complete']);
            } catch (exception) {
                await loading.dismiss();

                if (typeof exception === 'object' && exception.status === 400) {
                    await this.toast.showCustomMessages(this.translateService.instant('REGISTRATION_SMS.incorrect_code'));
                } else {
                    await this.toast.showCustomMessages(this.translateService.instant('REGISTRATION_SMS.error_something_wrong'));
                }
            }
        }
    }

    private createForm(fb: UntypedFormBuilder) {
        this.verificationForm = fb.group({
            verificationCode: ['', [
                Validators.minLength(6),
                Validators.maxLength(6),
            ]],
        });
    }

}
