<app-generic-header [showOrganizationName]="false"></app-generic-header>

<ion-content class="has-header">
    <div *ngIf="mediaCommunicationItem" class="content__full-height">
        <div class="card card--full-height">
            <div class="media-communication-details__image-wrapper" *ngIf="mediaCommunicationItem.picture">
                <div class="media-communication-details__image" (click)="openPictureLink()">
                    <div
                        class="media-communication-details__icon-video"
                        inlineSVG="assets/images/icon-play.svg"
                        *ngIf="isVideoType(mediaCommunicationItem.picture_type)">
                    </div>
                    <img [src]="mediaCommunicationItem.picture">
                </div>
            </div>
            <div class="card__title">{{mediaCommunicationItem.title}}</div>
            <div class="card__description">{{mediaCommunicationItem.subtitle}}</div>
            <div class="media-communication-details__content" [innerHTML]="mediaCommunicationItem.content | safeHTML" (click)="openHtmlUrl($event)"></div>
        </div>
    </div>
</ion-content>
