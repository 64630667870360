<app-generic-header></app-generic-header>

<ion-content class="has-header">
    <ng-container *ngIf="(dataLoaded$ | async) === true">
        <div *ngIf="(noAccess$ | async) === true; else showQuestionnaires" class="card">
            <h2 class="card__title">{{ 'ERROR.no_access.title' | translate }}</h2>
            <p class="card__description">{{ 'ERROR.no_access.explanation' | translate }}</p>
        </div>
    </ng-container>
</ion-content>

<ng-template #showQuestionnaires>
    <div class="card" *ngIf="concepts.length > 0 || uploadedConcepts.length > 0">
        <div class="card__header">
            <h2 class="card__title">{{ 'SELECT_QUESTIONNAIRE.open_forms' | translate }}</h2>
            <p class="card__description">{{ 'SELECT_QUESTIONNAIRE.open_forms_subtitle' | translate }}</p>
        </div>
        <div class="card__concepts">
            <app-select-concept *ngFor="let concept of concepts; trackBy: conceptQuestionnaireService.trackConceptById"
                                [concept]="concept"
                                (click)="conceptQuestionnaireService.selectConcept(concept)"></app-select-concept>
            <ng-container *ngIf="uploadedConcepts.length > 0">
                <hr>
                <h3>Online beschikbaar</h3>
                <app-select-uploaded-concept *ngFor="let concept of uploadedConcepts; trackBy: conceptQuestionnaireService.trackUploadedConceptById"
                                             [concept]="concept"
                                             (click)="conceptQuestionnaireService.selectUploadedConcept(concept)"></app-select-uploaded-concept>
            </ng-container>
        </div>
    </div>

    <div class="card">
        <div class="card__header">
            <h2 class="card__title">{{ 'SELECT_QUESTIONNAIRE.new_form' | translate }}</h2>
            <p class="card__description">{{ 'SELECT_QUESTIONNAIRE.new_form_subtitle' | translate }}</p>
        </div>

        <!-- planned questionnaires -->
        <ng-container  *ngIf="plannedQuestionnaires.length > 0">
            <h2 class="card__toggle">
                {{ 'SELECT_QUESTIONNAIRE.planned_forms' | translate }}
            </h2>
            <app-select-item *ngFor="let questionnaire of plannedQuestionnaires; trackBy: trackQuestionnaireListItemById"
                             [title]="questionnaire | contentTranslate:'title'"
                             [subTitle]="questionnaire | contentTranslate:'description'"
                             [deadlineAt]="getDeadlineForQuestionnaire( questionnaire )"
                             (click)="selectQuestionnaireListItem(questionnaire)"></app-select-item>
        </ng-container>

        <!-- other questionnaires -->
        <h2 class="card__toggle">{{ 'SELECT_QUESTIONNAIRE.available_forms' | translate }}</h2>
        <app-select-item *ngFor="let questionnaire of otherQuestionnaires; trackBy: trackQuestionnaireListItemById"
                         [title]="questionnaire | contentTranslate:'title'"
                         [subTitle]="questionnaire | contentTranslate:'description'"
                         (click)="selectQuestionnaireListItem(questionnaire)"></app-select-item>

        <!-- workplace questionnaires -->
        <h2 *ngIf="workplaceQuestionnaires.length > 0" class="card__toggle">{{ 'SELECT_QUESTIONNAIRE.workplace_forms' | translate }}</h2>
        <app-select-item *ngFor="let questionnaire of workplaceQuestionnaires; trackBy: trackQuestionnaireListItemById"
                         [title]="questionnaire  | contentTranslate:'title'"
                         [subTitle]="questionnaire | contentTranslate:'description'"
                         (click)="selectQuestionnaireListItem(questionnaire)"></app-select-item>
    </div>
</ng-template>
