import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Choice} from '../../models/choice';
import {ConceptService} from '../../services/concept-service';
import {ChapterUtils} from '../../utils/chapter-utils';
import {Concept} from '../../models/concept';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {QuestionnaireChapterService} from '../../services/questionnaire-chapter.service';

@Component({
    selector: 'app-chapter-item',
    templateUrl: 'chapter-item.html',
})
export class ChapterItemComponent implements OnInit {

    @Input() public title: string;
    @Input() public completed: boolean;
    @Input() public readOnly: boolean;
    @Input() public chapterIndex: number;
    @Input() public chapter: Choice;
    @Input() public skipRequirements: boolean;
    @Input() public concept?: Concept;
    @Output() private selectChapter: EventEmitter<any> = new EventEmitter();
    @Output() private selectQuestion: EventEmitter<any> = new EventEmitter();
    @Output() private conceptUpdated: EventEmitter<any> = new EventEmitter();
    public expanded$: Observable<boolean>;
    private chapterId: string;
    private checkmarkIdle = false; // turns off the checkmark when set to true

    constructor(
        private questionnaireChapterService: QuestionnaireChapterService,
        private conceptService: ConceptService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        /*
            skipRequirements is a new feature added to chapters.

            When set to 'true' the client can toggle chapter requirements to true or false,
            this will then set all questions in the chapter to required=true/false. (toggle)
        */

        if (!this.readOnly && this.skipRequirements) {
            // only do this if we have skipRequirements set to true (backwards compatibility)
            if (!this.chapter.hasOwnProperty('requireChapter')) {
                this.chapter.requireChapter = false;
            }
            this.setRequireChapter();
        }

        this.chapterId = `${this.router.url}_${this.chapterIndex}_${this.chapter.title}`;
        this.expanded$ = this.questionnaireChapterService.ifChapterExpanded(this.chapterId);
    }

    public gotoChapter(chapter: Choice) {
        this.selectChapter.emit(chapter);
    }

    public gotoQuestion(chapter: Choice, question, questionIndex) {
        const ChapterQuestion = {
            choice: chapter,
            question,
            questionIndex,
        };
        this.selectQuestion.emit(ChapterQuestion);
    }

    public isQuestionCompleted(question) {
        return ChapterUtils.isQuestionCompleted(question);
    }

    public isQuestionAnswerEmpty(question) {
        return ChapterUtils.isQuestionAnswerEmpty(question);
    }

    public toggleChapter() {
        this.questionnaireChapterService.toggleChapterExpanded(this.chapterId);
    }

    public toggleSkipRequirement() {
        if (this.chapter.hasOwnProperty('requireChapter')) {
            this.chapter.requireChapter = !this.chapter.requireChapter;
        } else {
            this.chapter.requireChapter = false;
        }

        this.setRequireChapter();
    }

    /**
     * set the requireChapter flag to true/false respectively, and trigger a refresh.
     */
    private setRequireChapter() {
        this.checkmarkIdle = true;
        this.chapter.questions.forEach((question) => {
            this.loopQuestions(question, this.chapter.requireChapter);
        });

        this.conceptService.saveConcept(this.concept, this.router.url).then((_) => {
            this.conceptUpdated.emit(this.chapter);
            this.checkmarkIdle = false;
        });
    }

    private loopQuestions(question, toggle) {
        if (question.hasOwnProperty('required')) {
            question.required = toggle;
        }
        if (question.choices && question.choices.length > 0) {
            for (const choice of question.choices) {
                this.loopQuestions(choice, toggle);
            }
        } else {
            if (question.questions && question.questions.length > 0) {
                for (const q of question.questions) {
                    this.loopQuestions(q, toggle);
                }
            }
        }
    }

    public isCompletedCheckmarkVisible() {
        if (!this.checkmarkIdle) {
            if (!this.chapter.hasOwnProperty('requireChapter') ||
                this.chapter.hasOwnProperty('requireChapter') && this.chapter.requireChapter
            ) {
                return this.completed;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    public chapterAnswersEmpty(): boolean {
        for (const question of this.chapter.questions) {
            if (!ChapterUtils.isQuestionAnswerEmpty(question)) {
                return false;
            }

            if (!this.childQuestionAnswersEmpty(question)) {
                return false;
            }
        }
        return true;
    }

    private childQuestionAnswersEmpty(question): boolean {
        if (question.choices && question.choices.length > 0) {
            for (const choice of question.choices) {
                if (!this.childQuestionAnswersEmpty(choice)) {
                    return false;
                }
            }
        } else {
            if (question.questions && question.questions.length > 0) {
                for (const q of question.questions) {
                    if (!this.childQuestionAnswersEmpty(q)) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
}
