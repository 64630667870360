export class CaseUtils {

    public static objectKeysToSnakeCase(input: any) {
        const result = {};

        for (const key of Object.keys(input)) {
            result[CaseUtils.toSnakeCase(key)] = input[key];
        }

        return result;
    }

    public static toSnakeCase(input: string): string {
        // Convert input string from camelCase to snake_case
        return input.replace(/[A-Z]/g, (letter, index) => {
            return (index > 0 ? '_' : '') + letter.toLowerCase();
        });
    }

}
