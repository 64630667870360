import {Component, OnDestroy} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {DashboardStatus} from '../../models/dashboard-status';
import {DashboardStatusService} from '../../services/dashboard-status-service';
import {Subject} from 'rxjs';
import {CardGameService} from '../../services/card-game-service';
import {LanguageService} from '../../services/language.service';
import {PropUtils} from '../../utils/prop-utils';

@Component({
    selector: 'page-dashboard-more',
    templateUrl: './dashboard-more.html',
    styleUrls: ['./dashboard-more.scss'],
})
export class DashboardMorePage implements OnDestroy {
    hasNewsItems = false;
    hasInstructionItems = false;
    cardGamePlayable = false;
    hasTasks = false;
    remindersCount = 0;
    openTasksCount: number | string = 0;
    private destroy$ = new Subject<void>();

    constructor(private statusService: DashboardStatusService,
                private cardGameService: CardGameService,
                private languageService: LanguageService) {
    }

    ionViewWillEnter() {
        // watch for dashboard changes
        this.statusService.onChange()
            .pipe(takeUntil(this.destroy$))
            .subscribe((status: DashboardStatus) => {
                this.hasNewsItems = status.has_news_items;
                this.hasInstructionItems = status.has_instruction_items;
                this.cardGamePlayable = status.card_game_playable && this.localeAllowsCardGame();

                this.openTasksCount = PropUtils.parseValue(status.task_statuses.open_actions);
                const closedTasksCount = PropUtils.parseValue(status.task_statuses.closed_action);
                this.remindersCount = status.questionnaire_reminders.length;
                this.hasTasks = (this.remindersCount + this.openTasksCount + closedTasksCount) > 0;
                this.openTasksCount = this.openTasksCount > 99 ? '99+' : this.openTasksCount;
            });
    }

    public async drawPlayCards() {
        await this.cardGameService.preLoadModals();
    }

    private localeAllowsCardGame(): boolean {
        return this.languageService.getCurrentLanguage() === 'nl';
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
