import {IonContent, ModalController, NavController} from '@ionic/angular';
import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {Question} from '../../models/question';
import {SearchableSettings} from '../../enums/searchable-settings';
import {ConceptService} from '../../services/concept-service';
import {ChapterUtils} from '../../utils/chapter-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {HistoryService} from '../../services/history-service';
import {QuestionnaireRouteService} from '../../services/questionnaire-route.service';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';
import {AbstractQuestionnairePage} from '../abstract-questionnaire/abstract-questionnaire';
import {TrackingService} from '../../services/tracking/tracking.service';
import {ContentTranslatePipe} from '../../pipes/content-translate';
import {NetworkService} from '../../services/network.service';
import {CameraService} from '../../services/camera-service';
import {Observable} from 'rxjs';
import {Photo} from '@capacitor/camera';
import {RemarkFocusService} from '../../services/remark-focus-service';

@Component({
    selector: 'page-questionnaire',
    templateUrl: 'questionnaire.html',
})
export class QuestionnairePage extends AbstractQuestionnairePage {

    @ViewChild(IonContent, {static: false}) content: HTMLIonContentElement;
    private validity = true;

    public pasteObservable: Observable<Photo> = null;

    constructor(
        navCtrl: NavController,
        modalCtrl: ModalController,
        conceptService: ConceptService,
        activatedRoute: ActivatedRoute,
        historyService: HistoryService,
        trackingService: TrackingService,
        questionnaireRouteService: QuestionnaireRouteService,
        router: Router,
        deviceKeyboardService: DeviceKeyboardService,
        remarkFocusService: RemarkFocusService,
        private contentTranslatePipe: ContentTranslatePipe,
        private changeDetectorRef: ChangeDetectorRef,
        networkService: NetworkService,
        private cameraService: CameraService,
    ) {
        super(navCtrl, modalCtrl, conceptService, activatedRoute, historyService, trackingService, questionnaireRouteService, router, deviceKeyboardService, contentTranslatePipe, networkService, remarkFocusService);
    }

    async ionViewWillEnter() {
        await super.ionViewWillEnter();
        this.pasteObservable = this.cameraService.generatePasteObservable();

        // Live migration for legacy history forms (should be done by backend?)
        this.currentQuestion.allowAttachment = this.currentQuestion.allowAttachment || false;
        this.currentQuestion.allowRemarks = this.currentQuestion.allowRemarks || false;

        if (this.answerIndex !== 0) {
            this.prefillRepeatedQuestionAnswer(this.currentQuestion);
        }

        // Force change detection
        this.changeDetectorRef.detectChanges();
    }

    async ionViewWillLeave() {
        this.pasteObservable = null;

        // Force change detection
        this.changeDetectorRef.detectChanges();
    }

    public async next(): Promise<void> {
        await this.updateConcept();
        const nextQuestionRoute = await this.questionnaireRouteService.getNextQuestionRouteFor(this.questionnaire, this.questionRoute, this.readonly);
        if (nextQuestionRoute) {
            if (this.isChapterQuestionnaire && !ChapterUtils.questionsForSameChapter(this.vintageQuestionPath, nextQuestionRoute.vintageQuestionPath)) {
                await this.backToChapters();
            } else {
                const nextUrl = `${this.readonly ? '/history' : `/concept/${this.conceptId}`}${nextQuestionRoute.route}`;
                await this.navCtrl.navigateForward(nextUrl);
            }

        } else if (this.readonly && !nextQuestionRoute && this.questionRoute.parentQuestion?.type === 'chapters') {
            // VW-2754 - Last question in chapters history so navigate back to chapters
            const route = (await this.questionnaireRouteService.getQuestionRoutes(this.questionnaire, false, false))[0];
            const chaptersUrl = `/history${route.route}`;

            await this.navCtrl.navigateBack([chaptersUrl], {replaceUrl: true});

        } else {
            await this.navCtrl.navigateBack(this.readonly ? '/questionnaire-history' : '/dashboard');
        }
    }

    public async prev(): Promise<void> {
        await this.updateConcept();
        if (this.isChapterQuestionnaire && ChapterUtils.isFirstChapterQuestion(this.vintageQuestionPath)) {
            await this.backToChapters();
        } else {

            const prevQuestionRoute = await this.questionnaireRouteService.getPrevQuestionRouteFor(this.questionnaire, this.questionRoute, this.readonly);
            if (prevQuestionRoute) {
                const prevUrl = `${this.readonly ? '/history' : `/concept/${this.conceptId}`}${prevQuestionRoute.route}`;

                await this.navCtrl.navigateBack(prevUrl);
            } else {
                this.navCtrl.back();
            }
        }
    }

    prefillRepeatedQuestionAnswer(question: Question) {
        if (question.answers && question.answers[this.answerIndex] == null) {
            question.answers[this.answerIndex] = '';
        }
    }

    async setCurrentQuestionValue(value: any) {
        this.setValueByQuestion(this.currentQuestion, value);
        await this.updateConcept();
    }

    setCurrentQuestionAttachment(attachment: Array<string>) {
        this.setAttachmentByQuestion(this.currentQuestion, attachment);
    }

    setCurrentQuestionRemarks(remarks: Array<string>) {
        this.setRemarksByQuestion(this.currentQuestion, remarks);
    }

    getQuestionValue(question: Question) {
        if (Array.isArray(question.answers) && question.answers[this.answerIndex] === undefined) {
            question.answers[this.answerIndex] = '';
        }
        return Array.isArray(question.answers) ? question.answers[this.answerIndex] : '';
    }

    getQuestionRequirements(question: Question) {
        return Array.isArray(question.requirements) ? question.requirements : [];
    }

    isValid() {
        return this.readonly || this.offLineAndLatLongQuestion() || this.validAnswer();
    }

    setValidity(validity: boolean) {
        this.validity = validity;
    }

    requiresSearchableChoices(question: Question): boolean {
        return question.choices.length >= SearchableSettings.MIN_ITEM_COUNT;
    }

    public async backToChapters(): Promise<void> {
        if (this.isChapterQuestionnaire) {
            const route = (await this.questionnaireRouteService.getQuestionRoutes(this.questionnaire, false, false))[0];
            const chaptersUrl = `${this.readonly ? '/history' : `/concept/${this.conceptId}`}${route.route}`;

            await this.navCtrl.navigateForward([chaptersUrl], {replaceUrl: true, animationDirection: 'back'});
        } else {
            console.error('Not a chapters questionnaire!');
        }
    }

    private setValueByQuestion(question: Question, value: any) {
        if (!Array.isArray(question.answers)) {
            question.answers = [];
        }

        question.answers[this.answerIndex] = value;
    }

    private setAttachmentByQuestion(question: Question, attachment: Array<string>) {
        if (!Array.isArray(question.attachments)) {
            question.attachments = [];
        }

        question.attachments[this.answerIndex] = attachment;
    }

    private setRemarksByQuestion(question: Question, remarks: Array<string>) {
        if (!Array.isArray(question.remarks)) {
            question.remarks = [];
        }

        question.remarks[this.answerIndex] = remarks;
    }

    private offLineAndLatLongQuestion(): boolean {
        return (this.currentQuestion.type === 'latlng' && !this.networkService.isOnline());
    }

    private validAnswer(): boolean {
        if (this.currentQuestion.type === 'information') {
            // information type does not require an answer.
            return true;
        }
        const answer = Array.isArray(this.currentQuestion.answers) ? this.currentQuestion.answers[this.answerIndex] : null;
        const hasAnswer = ![null, undefined].includes(answer);

        return this.validity &&
            (
                !this.currentQuestion.required
                || (hasAnswer && (Array.isArray(answer) ? answer.length > 0 : answer !== ''))
            );
    }
}
