<app-generic-header [showOrganizationName]="false"></app-generic-header>

<ion-content class="has-header">
    <div class="content__full-height" *ngIf="newsItem">
        <div class="card card--full-height">
            <div class="news-details__image-wrapper" *ngIf="newsItem.picture">
                <div class="news-details__image" (click)="openPictureLink()">
                    <div class="news-details__icon-video" inlineSVG="assets/images/icon-play.svg" *ngIf="isVideoType(newsItem.picture_type)"></div>
                    <img [src]="newsItem.picture">
                </div>
            </div>
            <div class="card__title">{{newsItem.title}}</div>
            <div class="card__description">{{newsItem.subtitle}}</div>
            <div [innerHTML]="newsItem.content | safeHTML" (click)="openHtmlUrl($event)"></div>
        </div>
    </div>
</ion-content>
