<!--    <ion-button class="footer-button-back ion-no-margin ion-no-border"-->
<!--            [hidden]="hideChaptersButton" (click)="toChapters()">-->
<!--        <ion-icon name="arrow-back"></ion-icon> {{ 'COMPONENTS.questionnaire_footer.chapters' | translate }}-->
<!--    </ion-button>-->

<ion-footer class="questionnaire-footer" [class.questionnaire-footer--compact]="isKeyboardOpen$">
    <ion-toolbar>
        <ion-buttons class="questionnaire-footer__btns" slot="start">
            <ion-button [disabled]="disableBackButton" class="questionnaire-footer__btn questionnaire-footer__btn--icon" (click)="prev()">
                <ion-icon src="assets/images/icon-arrow-back.svg" slot="icon-only" class="questionnaire-footer__btn-icon"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title *ngIf="progress" class="questionnaire-footer__text">
            <span class="questionnaire-footer__text--step">{{ progress.currentQuestionIndex | number:'2.0' }}</span> {{ 'QUESTIONNAIRE.from' | translate }} {{ progress.totalQuestions | number:'2.0' }}
        </ion-title>
        <ion-buttons class="questionnaire-footer__btns" slot="end">
            <ion-button [disabled]="!nextButtonEnabled" class="questionnaire-footer__btn questionnaire-footer__btn--icon questionnaire-footer__btn--next" [class.questionnaire-footer__btn--disabled]="!nextButtonEnabled" (click)="next()">
                <ion-icon src="assets/images/icon-arrow-forward.svg" slot="icon-only" class="questionnaire-footer__btn-icon"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
