import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {Photo} from '@capacitor/camera';
import {UUID} from 'angular2-uuid';
import {AbstractQuestionComponent} from '../../classes/abstract-question.component';
import {StorageService} from '../../services/storage-service';
import {TrackingService} from '../../services/tracking/tracking.service';
import {CameraService} from 'src/services/camera-service';
import {ToastService} from '../../services/toast-service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-photo',
    templateUrl: 'photo.html',
})
export class PhotoComponent extends AbstractQuestionComponent implements OnInit {
    photoLoaded = false;
    public photos: Array<string> = [];
    public maxQuestionPhotos = 10;
    public isWeb = false;

    @Input() set addPhoto(photo: Photo) {
        if (photo && !this.disabled && !(this.currentValue.length >= this.maxQuestionPhotos)) {
            this.getPicture(photo);
        }
    }

    constructor(
        fb: UntypedFormBuilder,
        private storageService: StorageService,
        private trackingService: TrackingService,
        private cameraService: CameraService,
        private toastService: ToastService,
        private translateService: TranslateService,
    ) {
        super(fb);
        this.isWeb = cameraService.isWeb();
    }

    async ngOnInit() {
        if (!Array.isArray(this.currentValue)) {
            if (this.currentValue === '') {
                this.currentValue = [];
            } else {
                this.currentValue = [this.currentValue];
            }
        }

        for (const fileName of this.currentValue) {
            if (fileName.match(/^http/)) {
                this.photos.push(fileName);
            } else {
                await this.storageService.get(fileName).then((file) => {
                    this.photos.push(file);
                });
            }
        }
    }

    public async takePicture() {
        const image = await this.cameraService.takePicture();
        this.getPicture(image);
    }

    public async fromLibrary() {
        const image = await this.cameraService.fromLibrary();
        this.getPicture(image);
    }

    private async getPicture(photo: Photo) {
        try {
            const fileName = UUID.UUID();
            await this.storageService.set(fileName, `data:image/jpeg;base64,${photo.base64String}`);

            this.photoLoaded = false;
            this.currentValue = this.initialValue(this.currentValue);
            if (Array.isArray(this.currentValue)) {
                this.currentValue.push(fileName);
                this.photos.push(`data:image/jpeg;base64,${photo.base64String}`);
            }
            this.valueChange.emit(this.currentValue);
        } catch (error) {
            this.trackingService.exception(error);
            console.error(error);
        }
    }

    public removePicture(index?: number) {
        if (this.disabled) {
            return;
        }

        if (Array.isArray(this.currentValue)) {
            this.currentValue.splice(index, 1);
            this.photos.splice(index, 1);
            if (this.currentValue.length === 0) {
                this.currentValue = '';
            }
        } else {
            this.currentValue = '';
            this.photos = [];
        }
        this.valueChange.emit(this.currentValue);
    }

    public async uploadImageFile(event: Event) {
        const files = (event.target as HTMLInputElement).files;
        if (files && files.length > 0) {
            const photo = await this.cameraService.fromFileUpload(files[0]).catch(() => {
                this.toastService.showCustomMessages(this.translateService.instant('NOTIFICATION.image_file_needed'));
            });

            if (photo) {
                await this.getPicture(photo);
            }
            (event.target as HTMLInputElement).value = null;
        }
    }

    public onDragOver(event: DragEvent) {
        const element = (event.currentTarget as HTMLElement);
        if (!element.classList.contains('dragging')) {
            element.classList.add('dragging');
        }
    }

    public onDragLeave(event: DragEvent) {
        const element = (event.currentTarget as HTMLElement);
        if (element.classList.contains('dragging')) {
            element.classList.remove('dragging');
        }
    }

    /**
     * Determine the use of multiple images depending on current value
     *
     * @param value
     * @returns
     */
    public isMultiple(value): boolean {
        return value.length > 1;
    }

    private initialValue(value) {
        return value === undefined || value === '' ? [] : value;
    }

}
