import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../environments/environment';
import {TrackingService} from './tracking/tracking.service';
import '@recognizebv/capacitor-plugin-msauth';
import {StorageService} from './storage-service';
import {MsAuthPlugin} from '@recognizebv/capacitor-plugin-msauth';

@Injectable({
    providedIn: 'root',
})
export class AzureAuthService {

    static readonly StorageKeyAccessToken = 'MS_AUTH_ACCESS_TOKEN';

    private accessToken: string;
    private isReady$ = new BehaviorSubject<boolean>(false);

    constructor(
        private trackingService: TrackingService,
        private storageService: StorageService,
    ) { }

    public authenticate(): Promise<string> {
        return this.nativeRefreshToken();
    }

    public async nativeRefreshToken(): Promise<string> {
        const config: {
            tenant: string;
            clientId: string;
            scopes: string[];
            keyHash: string;
            redirectUri: string;
            knownAuthorities: string[];
        } = { clientId: environment.msAuth.clientId,
            tenant: environment.msAuth.tenantId,
            scopes: environment.msAuth.scopes,
            keyHash: environment.msAuth.keystoreHash,
            redirectUri: environment.msAuth.redirectUri,
            knownAuthorities: [],
        };

        return new Promise(async (resolve, reject) => {
            await MsAuthPlugin.login(config)
                .then((result) => {
                    this.accessToken = result.accessToken;
                })
                .catch((error) => {
                    console.error(error);
                    this.accessToken = '';
                    this.trackingService.exception(error);
                    reject(error);
                    throw error;
                });

            await this.storageService.set(AzureAuthService.StorageKeyAccessToken, this.accessToken);
            resolve(this.accessToken);
        });
    }

    /**
     * Clears the tokes from the azure cache
     *
     * @returns
     */
    public async logout(): Promise<boolean> {
        return new Promise(async (resolve) => {
            try {
                await MsAuthPlugin.logout({
                    clientId: environment.msAuth.clientId,
                    tenant: environment.msAuth.tenantId,
                    keyHash: environment.msAuth.keystoreHash,
                });
            } catch (error) {
                this.trackingService.exception(error);
                console.warn('Clearing Azure auth failed', error);
            }

            await this.storageService.remove(AzureAuthService.StorageKeyAccessToken);
            resolve(true);
        });
    }

    /**
     * If an account exists, ensure a logout takes place
     */
    public async forceClear() {
        await this.logout();
    }

    private initReady(): Promise<void> {
        return new Promise((resolve) => {
            this.storageService.get(AzureAuthService.StorageKeyAccessToken)
                .then((accessToken) => {
                    this.accessToken = accessToken;
                    this.storageService.set(AzureAuthService.StorageKeyAccessToken, this.accessToken);
                })
                .finally(() => {
                    this.isReady$.next(true);
                });
        });
    }
}
