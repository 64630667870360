<div *ngIf="(googleMapsApiLoaded$ | async) === true && (isKeyboardOpen$ | async) === false">
    <google-map width="100%" height="100%" [options]="options" [zoom]="zoom"
                (mapClick)="handleMapClickEvent($event)">
        <map-marker *ngFor="let markerPosition of markerPositions"
                    [position]="markerPosition"
                    [options]="markerOptions"></map-marker>
        </google-map>
</div>

<form [formGroup]="geocodingForm" novalidate class="geocoding">
    <div class="card__btns">
        <ion-button expand="full" color="primary" shape="round" class="card__btn" [hidden]="isKeyboardOpen$ | async" [disabled]="disabled" (click)="setMyLocationAsCoords()">
            <ion-icon src="assets/images/icon-map-center.svg"></ion-icon>
            {{ 'COMPONENTS.lat_long.current_location' | translate }}
        </ion-button>
    </div>
    <ion-label class="geocoding-or-label" [hidden]="isKeyboardOpen$ | async">{{ 'COMPONENTS.lat_long.or' | translate }}</ion-label>
    <ion-item lines="none" class="item__no-padding">
        <ion-input [disabled]="disabled" formControlName="city" type="text" placeholder="{{ 'COMPONENTS.lat_long.city' | translate }}"></ion-input>
    </ion-item>
    <ion-item lines="none" class="item__no-padding">
        <ion-input [disabled]="disabled" formControlName="address" type="text" placeholder="{{ 'COMPONENTS.lat_long.address' | translate }}"></ion-input>
    </ion-item>
    <span [hidden]="!geocodingError" class="invalid">{{ 'COMPONENTS.lat_long.invalid_address' | translate }}</span>
</form>
