<app-dashboard-header [showBackButton]="true" [showDashboardButton]="false"></app-dashboard-header>

<ion-content class="has-header has-footer">
    <div class="card">
        <div class="card__header">
            <h2 class="card__title">{{ 'FORGOT_PASSWORD_FORM.title' | translate }}</h2>
        </div>
        <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
            <div class="fields">
                <ion-item lines="none" class="item__no-padding">
                    <ion-input type="email" formControlName="email_address" placeholder="{{ 'FORGOT_PASSWORD_FORM.email' | translate }}" inputfocus></ion-input>
                </ion-item>
            </div>
            <div class="card__btns">
                <ion-button type="submit" color="primary" expand="block" shape="round" class="card__btn" [disabled]="!form.valid">{{ 'FORGOT_PASSWORD_FORM.next' | translate }}</ion-button>
            </div>
        </form>
    </div>
</ion-content>
