<div class="title-container">
    <div>
        <form [formGroup]="questionForm" novalidate>
            <input [type]="mode === 'date' ? 'date' : 'datetime-local'" class="title datetime-input"
                   formControlName="current" [max]="maximumDate"
                   [readonly]="disabled"
            >
        </form>
    </div>
</div>
