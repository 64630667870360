<form [formGroup]="questionForm" novalidate>
    <app-manual-choice-addition
        *ngIf="manualAdditionsAllowed"
        [abstractChoiceQuestion]="this"
    ></app-manual-choice-addition>

    <div class="choice single-choice" *ngIf="(question?.choices || []).length > 0 || manualAdditionsAllowed === false">
        <span class="choice-description light mb-4">{{ 'COMPONENTS.radio.choose' | translate }}</span>
        <ng-container *ngFor="let choice of question.choices">
            <ion-chip (click)="choose(choice)" [color]="currentValue === choice.title ? 'checked' : 'default'" [outline]="true">
                {{ choice | contentTranslate:'title' }}
                <div *ngIf="choice | contentTranslate:'subtitle'" class="choice-subtitle">
                    {{ choice | contentTranslate:'subtitle' }}
                </div>
                <ion-icon *ngIf="currentValue === choice.title; else notSelected" name="checkmark-circle-outline"></ion-icon>
            </ion-chip>
        </ng-container>
    </div>
    <div *ngIf="question?.choices?.length === 0">{{ 'COMPONENTS.radio.no_choice_selected' | translate }}</div>
</form>

<ng-template #notSelected>
    <ion-icon name="ellipse-outline"></ion-icon>
</ng-template>
