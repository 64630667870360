import {Directive, HostListener, AfterContentChecked, ElementRef} from '@angular/core';

@Directive({
    selector: 'ion-textarea[autosize]',
})
export class IonTextareaAutosizeDirective implements AfterContentChecked {

    constructor(
        public element: ElementRef,
    ) {
    }

    @HostListener('input', ['$event.target'])
    onInput(): void {
        this.adjust();
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(): void {
        this.adjust();
    }

    ngAfterContentChecked(): void {
        this.adjust();
    }

    private adjust(): void {
        const textarea = this.element.nativeElement.querySelector('textarea');
        if (textarea) {
            textarea.style.overflow = 'hidden';
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }

}
