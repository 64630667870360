import { Injectable } from '@angular/core';
import { DeeplinkData } from '../pages/deeplink-questionnaire-opener/deeplink-questionnaire-opener';
import { OrganisationStructureService } from './organisation-structure-service';
import { StructureNodeType } from '../enums/structure-node-type';

@Injectable({
    providedIn: 'root',
})
export class DeeplinkRouteParserService {

    constructor(private organisationStructureService: OrganisationStructureService) {
    }

    public parseData(deeplinkData: DeeplinkData, routePrefix: string = '/direct'): string[] {
        let redirectRoute: string;
        // Navigate to the necessary route to handle the questionnaire concept management.
        // automatically selecting a child node (e.g. since there's only one project behind an organisation is handled from here.
        if (deeplinkData.projectId) {
            redirectRoute = `${routePrefix}/project/${deeplinkData.projectId}/questionnaires`;
        } else if (deeplinkData.businessUnitId) {
            const organisationNodeRoute = this.organisationStructureService.getOrganisationListForNode(routePrefix, StructureNodeType.BUSINESSUNIT, deeplinkData.businessUnitId);
            redirectRoute =  organisationNodeRoute.route;
        } else if (deeplinkData.organisationId) {
            const organisationNodeRoute = this.organisationStructureService.getOrganisationListForNode(routePrefix, StructureNodeType.ORGANISATION, deeplinkData.organisationId);
            redirectRoute = organisationNodeRoute.route;
        } else {
            // no organisation node specified in deeplink, show the organisation list
            const concernNodeRoute = this.organisationStructureService.getOrganisationListForNode(routePrefix, StructureNodeType.CONCERN);
            redirectRoute = concernNodeRoute.route;
        }
        if (deeplinkData.questionnaireId) {
            redirectRoute  += `/${deeplinkData.questionnaireId}`;
        }

        return [redirectRoute];
    }

    public parseRouteUrl(route: string): string[] {
        if (route.startsWith('incident/organisation/')) {
            // incident form, for a specific organisation.
            const routeData = this.parseRouteData(route);
            return this.parseData(routeData, '/incident');
        } else if (route.startsWith('all/organisation/')) {
            // link towards the questionnaires of an organisation
            const routeData = this.parseRouteData(route);
            return this.parseData(routeData, '/all');
        } else {
            // no specific route, just forward it to the app and hope
            // the app knows the route. Works well for e.g. history page.
            return [route];
        }
    }

    private parseRouteData(route: string): DeeplinkData {
        const data: DeeplinkData = { };
        const routeParts = route.split('/');
        // handle route: <type>>/organisation/{orgId}
        if (routeParts.length > 2 && routeParts[1] === 'organisation') {
            data.organisationId = parseInt(routeParts[2], 10);
        }
        // handle route: <type>>/organisation/{orgId}/businessunit/{buId}
        if (routeParts.length > 4 && routeParts[3] === 'businessunit') {
            data.businessUnitId = parseInt(routeParts[4], 10);
        }
        // handle route: <type>>/organisation/{orgId}/project/{projectId}
        if (routeParts.length > 4 && routeParts[3] === 'project') {
            data.projectId = parseInt(routeParts[4], 10);
        }
        // handle route: <type>>/organisation/{orgId}/businessunit/{buId}/project/{projectId}
        if (routeParts.length > 6 && routeParts[5] === 'project') {
            data.projectId = parseInt(routeParts[6], 10);
        }
        return data;
    }
}
