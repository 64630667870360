import {Component, Input, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {ConceptQuestionnaireService} from '../../../services/concept-questionnaire.service';
import {take} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {DeviceKeyboardService} from '../../../services/device-keyboard.service';

@Component({
    selector: 'app-questionnaire-header',
    templateUrl: './questionnaire-header.html',
    styleUrls: ['./questionnaire-header.scss'],
})
export class QuestionnaireHeaderComponent implements OnInit {
    public isKeyboardOpen$: Observable<boolean>;
    @Input() public color = 'dark';
    @Input() public showChapterButton = false;
    selectedOrganisationName$: BehaviorSubject<string>;

    constructor(private navCtrl: NavController,
                private conceptQuestionnaireService: ConceptQuestionnaireService,
                private deviceKeyboardService: DeviceKeyboardService) {
    }

    ngOnInit(): void {
        this.isKeyboardOpen$ = this.deviceKeyboardService.isKeyboardOpen();
        this.selectedOrganisationName$ = this.conceptQuestionnaireService.selectedOrganisationName$;
    }

    public async navigateBack(): Promise<void> {

        if (!this.showChapterButton) {
            this.conceptQuestionnaireService.currentQuestionnaireOrigin$
                .pipe(take(1))
                .subscribe(async (origin: string | null) => {
                    if (origin) {
                        await this.navCtrl.navigateBack([origin]);
                    } else {
                        await this.navCtrl.pop();
                    }
                });
        } else {
            await this.navCtrl.pop();
        }
    }
}
