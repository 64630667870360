import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy, NavParams} from '@ionic/angular';
import {AppRoutingModule} from './app-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {Nl2BrPipeModule} from 'nl2br-pipe';
import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';

// Components
import {AppComponent} from './app.component';
import {AttachmentsAndRemarksComponent} from '../components/attachments-and-remarks/attachments-and-remarks.component';
import {ChapterItemComponent} from '../components/chapter-item/chapter-item';
import {CheckboxComponent} from '../components/checkbox/checkbox';
import {CheckboxSearchableComponent} from '../components/checkbox-searchable/checkbox-searchable';
import {DatetimeComponent} from '../components/datetime/datetime';
import {LanguageSelectionComponent} from '../components/language-selection/language-selection';
import {LatLngComponent} from '../components/lat-lng/lat-lng';
import {ManualChoiceAdditionComponent} from '../components/manual-choice-addition/manual-choice-addition';
import {NotificationModalComponent} from '../components/notification-modal/notification-modal';
import {NumberComponent} from '../components/number/number';
import {PhotoComponent} from '../components/photo/photo';
import {QuestionnaireFooterComponent} from '../components/questionnaire-footer/questionnaire-footer';
import {QuestionnaireOverlayComponent} from '../components/questionnaire-overlay/questionnaire-overlay';
import {RadioComponent} from '../components/radio/radio';
import {RadioSearchableComponent} from '../components/radio-searchable/radio-searchable';
import {ScrolltoComponent} from '../components/scrollto/scrollto';
import {SearchableChoicesComponent} from '../components/searchable-choices/searchable-choices';
import {SelectConceptComponent} from '../components/select-concept/select-concept';
import {SelectItemComponent} from '../components/select-item/select-item';
import {TextComponent} from '../components/text/text';
import {TextAutoheightComponent} from '../components/text-autoheight/text-autoheight';
import {DashboardFooterComponent} from '../components/dashboard-footer/dashboard-footer.component';
import {DashboardHeaderComponent} from '../components/headers/dashboard/dashboard-header';
import {QuestionnaireHeaderComponent} from '../components/headers/questionnaire/questionnaire-header';
import {GenericHeaderComponent} from '../components/headers/generic/generic-header';
import {DashboardFooterLayoutComponent} from '../components/dashboard-footer-layout/dashboard-footer-layout.component';

// Pages
import {ChooseLoginPage} from '../pages/choose-login/choose-login';
import {ContactPage} from '../pages/contact/contact';
import {DashboardPage} from '../pages/dashboard/dashboard';
import {DeeplinkQuestionnaireOpenerPage} from '../pages/deeplink-questionnaire-opener/deeplink-questionnaire-opener';
import {DeeplinkRedirectPage} from '../pages/deeplink-redirect/deeplink-redirect';
import {ExternalAccountLoginPage} from '../pages/external-account-login/external-account-login';
import {ForgotPasswordCompletePage} from '../pages/forgot-password-complete/forgot-password-complete';
import {ForgotPasswordFormPage} from '../pages/forgot-password-form/forgot-password-form';
import {MediaCommunicationDetailsPage} from '../pages/media-communication-details/media-communication-details';
import {MediaCommunicationListPage} from '../pages/media-communication-list/media-communication-list';
import {NewsDetailsPage} from '../pages/news-details/news-details';
import {NewsListPage} from '../pages/news-list/news-list';
import {PasswordChangeFormPage} from '../pages/password-change-form/password-change-form';
import {QuestionnairePage} from '../pages/questionnaire/questionnaire';
import {QuestionnaireChaptersPage} from '../pages/questionnaire-chapters/questionnaire-chapters';
import {QuestionnaireHistoryPage} from '../pages/questionnaire-history/questionnaire-history';
import {QuestionnaireSentPage} from '../pages/questionnaire-sent/questionnaire-sent';
import {QuestionnaireTaskPage} from '../pages/questionnaire-task/questionnaire-task';
import {QuestionnaireTasksPage} from '../pages/questionnaire-tasks/questionnaire-tasks';
import {RegistrationPage} from '../pages/registration/registration';
import {RegistrationCompletePage} from '../pages/registration-complete/registration-complete';
import {RegistrationVerifySmsPage} from '../pages/registration-verify-sms/registration-verify-sms';
import {SelectStructureNodePage} from '../pages/select-structure-node/select-structure-node';
import {SelectQuestionnairePage} from '../pages/select-questionnaire/select-questionnaire';
import {SendQuestionnairePage} from '../pages/send-questionnaire/send-questionnaire';
import {SupportPage} from '../pages/support/support';
import {QuestionnaireDisclaimerPage} from '../pages/questionnaire-disclaimer/questionnaire-disclaimer';
import {QuestionnaireRepeatQuestionPage} from '../pages/questionnaire-repeat-question/questionnaire-repeat-question';
import {CardGamePage} from '../pages/card-game/card-game';
import {ScanQrPage} from 'src/pages/scan-qr/scan-qr';
import {DashboardMorePage} from '../pages/dashboard-more/dashboard-more';

// Services
import {ApiService} from '../services/api-service';
import {AzureAuthService} from '../services/azure-auth-service';
import {BrowserService} from '../services/browser-service';
import {DashboardStatusService} from '../services/dashboard-status-service';
import {NotificationService} from '../services/notification-service';
import {AuthService} from '../services/auth-service';
import {ConceptService} from '../services/concept-service';
import {DeeplinkService} from '../services/deeplink-service';
import {DeeplinkRouteParserService} from '../services/deeplink-route-parser-service';
import {DisclaimerService} from '../services/disclaimer-service';
import {EventService} from '../services/event-service';
import {ExternalUserService} from '../services/external-user-service';
import {LoadingService} from '../services/loading-service';
import {MediaCommunicationItemsService} from '../services/media-communication-items.service';
import {NewsItemsService} from '../services/news-items-service';
import {PositionService} from '../services/position-service';
import {ProfileService} from '../services/profile-service';
import {QuestionnaireDataService} from '../services/questionnaire-data-service';
import {StorageService} from '../services/storage-service';
import {ThemeService} from '../services/theme-service';
import {ToastService} from '../services/toast-service';
import {HistoryService} from '../services/history-service';
import {QuestionnaireListService} from '../services/questionnaire-list-service';
import {DynamicChoiceListService} from '../services/dynamic-choice-list-service';
import {QuestionnaireAssembleService} from '../services/questionnaire-assemble-service';
import {OrganisationStructureService} from '../services/organisation-structure-service';
import {ProgressiveApiService} from '../services/progressive-api-service';
import {QuestionnaireRouteService} from '../services/questionnaire-route.service';
import {QuestionDefinitionPathService} from '../services/questiondefinition-path-service';
import {DeviceKeyboardService} from '../services/device-keyboard.service';
import {QuestionnaireChapterService} from '../services/questionnaire-chapter.service';
import {PrivacyPolicyService} from '../services/privacy-policy.service';
import {NetworkService} from 'src/services/network.service';
import {RedirectAfterLoginService} from '../services/redirect-after-login-service';
import {RemarkFocusService} from '../services/remark-focus-service';
import {StatusbarService} from '../services/statusbar.service';
import {DeprecationService} from '../services/deprecation-service';

// Pipes
import {DateFormatPipe} from '../pipes/date-format';
import {SafeHTMLPipe} from '../pipes/safe-html';
import {ContentTranslatePipe} from '../pipes/content-translate';
import {PluralizationPipe} from '../pipes/pluralization';
import {ConceptProgressPipe} from '../pipes/concept-progress.pipe';

// Directives
import {InputfocusDirective} from '../directives/input-focus';
import {IonAutoModeDirective} from '../directives/ion-auto-mode';
import {IonTextareaAutosizeDirective} from '../directives/ion-textarea-autosize';
import {ClickableDirective} from '../directives/clickable';

// Other
import {JwtOptionsFactory} from '../functions/jwt-options-factory';
import {navAnimation} from '../functions/nav-animation';
import {TrackingModule} from '../services/tracking/tracking.module';
import {LanguageService} from '../services/language.service';
import {environment} from '../environments/environment';
import {GoogleMapsModule} from '@angular/google-maps';
import {SelectUploadedConceptComponent} from '../components/select-uploaded-concept/select-uploaded-concept.component';


// NOTE: the other config options are set in environment.ts
export const API_SUFFIX = '/api/v16';

@NgModule({
    declarations: [
        // Components
        AppComponent,
        DashboardHeaderComponent,
        AttachmentsAndRemarksComponent,
        ChapterItemComponent,
        CheckboxComponent,
        CheckboxSearchableComponent,
        DatetimeComponent,
        DashboardFooterComponent,
        DashboardFooterLayoutComponent,
        LanguageSelectionComponent,
        LatLngComponent,
        ManualChoiceAdditionComponent,
        NotificationModalComponent,
        NumberComponent,
        PhotoComponent,
        QuestionnaireFooterComponent,
        QuestionnaireOverlayComponent,
        QuestionnaireHeaderComponent,
        RadioComponent,
        RadioSearchableComponent,
        ScrolltoComponent,
        SearchableChoicesComponent,
        SelectConceptComponent,
        SelectItemComponent,
        GenericHeaderComponent,
        TextComponent,
        TextAutoheightComponent,
        // Pages
        ChooseLoginPage,
        ContactPage,
        DashboardPage,
        DashboardMorePage,
        DeeplinkRedirectPage,
        DeeplinkQuestionnaireOpenerPage,
        ExternalAccountLoginPage,
        ForgotPasswordCompletePage,
        ForgotPasswordFormPage,
        MediaCommunicationDetailsPage,
        MediaCommunicationListPage,
        NewsDetailsPage,
        NewsListPage,
        PasswordChangeFormPage,
        QuestionnairePage,
        QuestionnaireChaptersPage,
        QuestionnaireHistoryPage,
        QuestionnaireSentPage,
        QuestionnaireTaskPage,
        QuestionnaireTasksPage,
        RegistrationPage,
        RegistrationCompletePage,
        RegistrationVerifySmsPage,
        SelectStructureNodePage,
        SelectQuestionnairePage,
        SendQuestionnairePage,
        SupportPage,
        QuestionnaireDisclaimerPage,
        QuestionnaireRepeatQuestionPage,
        CardGamePage,
        ScanQrPage,
        // Pipes
        DateFormatPipe,
        SafeHTMLPipe,
        ContentTranslatePipe,
        PluralizationPipe,
        ConceptProgressPipe,
        // Directives
        InputfocusDirective,
        IonAutoModeDirective,
        IonTextareaAutosizeDirective,
        ClickableDirective,
        SelectUploadedConceptComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        InlineSVGModule.forRoot({
            baseUrl: document.baseURI,
        }),
        IonicModule.forRoot({
            mode: 'md',
            navAnimation,
        }),
        AppRoutingModule,
        TranslateModule.forRoot(),
        HttpClientModule,
        HttpClientJsonpModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: JwtOptionsFactory,
                deps: [AuthService],
            },
        }),
        Nl2BrPipeModule,
        TrackingModule,
        GoogleMapsModule,
        TranslateModule,
    ],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        NavParams,
        // Services
        ApiService,
        ProgressiveApiService,
        AzureAuthService,
        AuthService,
        AzureAuthService,
        BrowserService,
        ConceptService,
        DashboardStatusService,
        DeeplinkService,
        DeeplinkRouteParserService,
        DisclaimerService,
        EventService,
        ExternalUserService,
        LanguageService,
        LoadingService,
        MediaCommunicationItemsService,
        NewsItemsService,
        NotificationService,
        PositionService,
        ProfileService,
        QuestionnaireDataService,
        StorageService,
        ThemeService,
        ToastService,
        HistoryService,
        QuestionnaireListService,
        DynamicChoiceListService,
        QuestionnaireAssembleService,
        QuestionDefinitionPathService,
        OrganisationStructureService,
        QuestionnaireRouteService,
        DeviceKeyboardService,
        NetworkService,
        QuestionnaireChapterService,
        PrivacyPolicyService,
        RedirectAfterLoginService,
        RemarkFocusService,
        StatusbarService,
        DeprecationService,
        // Pipes
        DateFormatPipe,
        SafeHTMLPipe,
        ContentTranslatePipe,
    ],
    exports: [
        PluralizationPipe,
        ConceptProgressPipe,
    ],
    bootstrap: [
        AppComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
})
export class AppModule {

    constructor(
        private deviceKeyboardService: DeviceKeyboardService,
        private notificationService: NotificationService,
        private dashboardStatusService: DashboardStatusService,
        private languageService: LanguageService,
    ) {
        languageService.init(environment.supportedLanguages);
        this.notificationService.init();
        this.dashboardStatusService.init();
    }

}
