<ion-item class="select-item">
    <div class="select-item__wrap">
        <div class="select-item__image" *ngIf="hasImagePlaceholder">
            <img *ngIf="imageData" [src]="imageData" />
        </div>
        <div class="select-item__text">
            <p class="select-item__title">{{title}}</p>
            <p *ngIf="subTitle" class="select-item__description">
                <ion-icon *ngIf="hasSubTitleExclamationIcon" class="select-item__icon-circle"></ion-icon>
                {{subTitle}}
            </p>
            <p *ngIf="deadlineAt" class="select-item__description">{{ 'COMPONENTS.select_item.submit_before' | translate }} <strong>{{ deadlineAt }}</strong></p>
        </div>
        <ng-content></ng-content>
        <ion-badge *ngIf="numberOfConcepts > 0">{{numberOfConcepts}}</ion-badge>
        <ion-icon src="assets/images/icon-arrow-right.svg" class="select-item__icon-circle"></ion-icon>
    </div>
</ion-item>
