import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Directive, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Directive()
export class AbstractQuestionComponent implements OnInit {

    public questionForm: UntypedFormGroup;
    @Output() protected valueChange: EventEmitter<any> = new EventEmitter();
    @Output() protected validityChange: EventEmitter<any> = new EventEmitter();
    @Input() public currentValue: string | Array<string>;
    @Input() public disabled = false;

    pwa = false;
    constructor(
        protected fb: UntypedFormBuilder,
    ) {
        this.pwa = !Capacitor.isNativePlatform();
    }

    ngOnInit(): void {
        this.createForm(this.fb);
    }

    protected createForm(fb: UntypedFormBuilder): void {
        this.questionForm = fb.group({
            current: fb.control({value: this.currentValue, disabled: this.disabled}, Validators.required),
        });

        this.questionForm.valueChanges.subscribe((value) => {
            this.validityChange.emit(this.questionForm.valid);

            if ((this as any).changed != null) {
                (this as any).changed();
            }
        });
    }
}
