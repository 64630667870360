import {Component} from '@angular/core';
import {NavController} from '@ionic/angular';
import {QuestionnaireTask} from '../../models/questionnaire-task';
import {QuestionnaireReminder} from '../../models/questionnaire-reminder';
import {QuestionnaireDataService} from '../../services/questionnaire-data-service';
import {LoadingService} from '../../services/loading-service';
import {ToastService} from '../../services/toast-service';
import {DashboardStatusService} from '../../services/dashboard-status-service';
import {ConceptService} from '../../services/concept-service';
import {DateFormatPipe} from '../../pipes/date-format';
import {TranslateService} from '@ngx-translate/core';

interface QuestionnaireReminderWithConcepts extends QuestionnaireReminder {
    concepts?: number;
}

@Component({
    selector: 'page-questionnaire-tasks',
    templateUrl: 'questionnaire-tasks.html',
})
export class QuestionnaireTasksPage {

    public tasksOpen: QuestionnaireTask[] = [];
    public tasksCompleted: QuestionnaireTask[] = [];
    public reminders: QuestionnaireReminderWithConcepts[] = [];

    constructor(
        private navCtrl: NavController,
        private questionnairesDataService: QuestionnaireDataService,
        private loadingService: LoadingService,
        private toast: ToastService,
        private statusService: DashboardStatusService,
        private conceptService: ConceptService,
        private translateService: TranslateService,
    ) {
    }

    ionViewWillEnter() {
        this.getData();
    }

    private resetData(): void {
        this.tasksOpen = [];
        this.tasksCompleted = [];
        this.reminders = [];
    }

    private getData() {
        this.loadingService.start();

        Promise.all([
            this.getTasks(),
            this.getReminders(),
        ])
            .catch(() => {
                this.resetData();
                this.toast.showCustomMessages(
                    this.translateService.instant('NOTIFICATION.error_general'),
                    this.translateService.instant('NOTIFICATION.offline'),
                );
            })
            .finally(() => {
                this.loadingService.stop();
            });
    }

    /**
     * Gets the questionnaire history
     *
     * @returns
     */
    private getTasks() {
        return this.questionnairesDataService.getQuestionnaireTasks()
            .then((response) => {

                if (response.data && Array.isArray(response.data)) {

                    this.tasksOpen = response.data.filter((task: QuestionnaireTask) => {
                        return task.status === 'open';
                    }).sort((task1: QuestionnaireTask, task2: QuestionnaireTask) => {
                        return this.getTimestamp(task2.created_at) - this.getTimestamp(task1.created_at);
                    });

                    this.tasksCompleted = response.data.filter((task: QuestionnaireTask) => {
                        return task.status !== 'open';
                    }).sort((task1: QuestionnaireTask, task2: QuestionnaireTask) => {
                        return this.getTimestamp(task2.filled_in_at) - this.getTimestamp(task1.filled_in_at);
                    });

                } else {
                    this.resetData();
                }
            });
    }

    private getTimestamp(date: string): number {
        // Current unix timestamp in seconds
        const timestamp = Math.floor(Date.now() / 1000);
        return isNaN(timestamp) ? 0 : timestamp;
    }

    private getDateProgress(dateMin: string, dateMax: string): number {
        const timestampDateMin = this.getTimestamp(dateMin);
        const timestampDateMax = this.getTimestamp(dateMax);
        const currentTimestamp = Math.floor(Date.now() / 1000);
        return ((currentTimestamp - timestampDateMin) * 100) / (timestampDateMax - timestampDateMin);
    }

    public hasSubTitleIcon(task: QuestionnaireTask): boolean {
        return this.pastDeadline(task) || this.nearDeadline(task);
    }

    public getSubTitle(task: QuestionnaireTask): string {
        const dateFormatPipe = new DateFormatPipe();

        if (this.pastDeadline(task)) {
            return this.translateService.instant('QUESTIONNAIRE_TASKS.deadline_passed') + dateFormatPipe.transform(task.deadline, this.translateService.instant('DATE.long'));
        } else if (this.nearDeadline(task)) {
            return this.translateService.instant('QUESTIONNAIRE_TASKS.deadline_at') + dateFormatPipe.transform(task.deadline, this.translateService.instant('DATE.long'));
        }

        return this.translateService.instant('QUESTIONNAIRE_TASKS.created_at') + dateFormatPipe.transform(task.created_at, this.translateService.instant('DATE.long'));
    }

    public pastDeadline(task: QuestionnaireTask): boolean {
        const dateProgress = this.getDateProgress(task.created_at, task.deadline);
        return this.getTimestamp(task.deadline) > 0 && dateProgress >= 100;
    }

    public nearDeadline(task: QuestionnaireTask): boolean {
        const dateProgress = this.getDateProgress(task.created_at, task.deadline);
        return this.getTimestamp(task.deadline) > 0 && dateProgress >= 50 && dateProgress < 100;
    }

    private getReminders() {
        return this.statusService.getQuestionReminders().then(reminders => {
            Promise.all(reminders.map(async reminder => {
                const result: QuestionnaireReminderWithConcepts = reminder;

                result.concepts = await this.conceptService.getConceptCountForQuestionnaireID(reminder.questionnaire_id);

                return result;
            })).then(result => this.reminders = result);
        });
    }

    /**
     * Open a new page for task details
     *
     * @param task<QuestionnaireTask>
     */
    public openQuestionnaireTask(task: QuestionnaireTask) {
        return this.navCtrl.navigateForward(['/questionnaire-task', task.id], {
            state: {
                task,
            },
        });
    }

    public openReminder(reminder: QuestionnaireReminder) {
        this.navCtrl.navigateForward(['/deeplink/questionnaire/opener'], {
            state: {
                questionnaireId: reminder.questionnaire_id,
                sectorId: reminder.sector_id,
                regionId: reminder.region_id,
                organisationId: reminder.organisation_id,
                businessUnitId: reminder.business_unit_id,
                projectId: reminder.project_id,
            },
        });
    }
}
