<div [ngClass]="{'multiple':isMultiple(photos),'single':!isMultiple(photos)}">
    <p *ngIf="isWeb && !disabled" class="card__description">{{ 'COMPONENTS.photo.subtitle' | translate }}</p>
    <div class="app-photo__grid">
        <!-- image previews -->
        <div class="app-photo__item thumbnail" *ngFor="let photo of photos; let i = index" (click)="removePicture(i)">
            <div class="app-photo__preview" [ngClass]="{'preview-disabled':disabled}" [ngStyle]="{'background-image':'url('+photo+')'}"></div>
            <div class="options" *ngIf="!disabled">{{ 'BUTTONS.remove' | translate }} &#x2715;</div>
        </div>
        <!-- dropzone -->
        <div *ngIf="!disabled" class="app-photo__item">
            <div *ngIf="currentValue.length < maxQuestionPhotos && isWeb" class="add">
                <div class="dropzone" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDragLeave($event)">
                    <ion-icon name="add-outline"></ion-icon>
                    <input type="file" accept="image/jpeg, image/png, image/gif" (change)="uploadImageFile($event)" />
                </div>
            </div>
            <div *ngIf="currentValue.length >= maxQuestionPhotos" class="add">
                <p class="card__description">{{ 'COMPONENTS.photo.max_reached' | translate }}</p>
            </div>
        </div>
    </div>
    <div *ngIf="currentValue.length < maxQuestionPhotos && !isWeb" class="app-photo__footer">
        <ion-button expand="full" fill="outline" shape="round" class="card__btn" (click)="fromLibrary()">{{ 'COMPONENTS.photo.upload_from_gallery' | translate }}</ion-button>
        <ion-button color="primary" expand="full" shape="round" class="card__btn" (click)="takePicture()">{{ 'COMPONENTS.photo.take_a_photo' | translate }}</ion-button>
    </div>
</div>
