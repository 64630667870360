import {Component} from '@angular/core';
import {NavController} from '@ionic/angular';

@Component({
    selector: 'page-registration-complete',
    templateUrl: 'registration-complete.html',
})
export class RegistrationCompletePage {

    constructor(
        private navCtrl: NavController,
    ) {
    }

    goToChooseLoginPage() {
        this.navCtrl.navigateRoot(['/choose-login']);
    }
}
