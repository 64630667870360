import {Component} from '@angular/core';
import {NavController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'page-questionnaire-sent',
    templateUrl: 'questionnaire-sent.html',
})
export class QuestionnaireSentPage {

    public ticketNumber: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private navCtrl: NavController,
    ) {
        activatedRoute.queryParams.subscribe(params => {
            if (this.router.getCurrentNavigation().extras.state) {
                this.ticketNumber = this.router.getCurrentNavigation().extras.state.ticketNumber;
            }
        });
    }

    public goToDashboard() {
        this.navCtrl.navigateBack(['/dashboard']);
    }

}
