import {Injectable} from '@angular/core';
import {ApiService} from './api-service';
import {QuestionnairesResponse} from '../models/questionnaires';
import {Questionnaire} from '../models/questionnaire';
import {StorageService} from './storage-service';
import {Concept} from '../models/concept';
import {ConceptService} from './concept-service';
import {AlertController} from '@ionic/angular';
import {TrackingService} from './tracking/tracking.service';
import {TranslateService} from '@ngx-translate/core';
import {NetworkService} from './network.service';
import {Question} from 'src/models/question';
import {blobToDataURL} from '../utils/blob-to-data-url';

@Injectable({
    providedIn: 'root',
})
export class QuestionnaireDataService {
    private currentQuestionnairesData: QuestionnairesResponse;

    constructor(private apiService: ApiService,
                private storageService: StorageService,
                private conceptService: ConceptService,
                private alertController: AlertController,
                private trackingService: TrackingService,
                private translateService: TranslateService,
                private networkService: NetworkService,
    ) {
    }

    /**
     * Loads the questionnaires data form the server
     *
     * @returns
     */
    private loadConcernDataForUser(noCache: boolean) {
        return this.apiService.authenticatedGet('/questionnaires', this.apiService.getRequestOptions(noCache))
            .then((response) => {
                this.currentQuestionnairesData = response.data;
                this.enrichQuestionnairesWithConcernFormInfo(this.currentQuestionnairesData);

                return this.storageService.set(StorageService.QUESTIONNAIRES, this.currentQuestionnairesData);
            })
            .catch(async () => {
                return this.retryLoadConcernData(noCache);
            });
    }

    /**
     * Loads the data for offline use
     *
     * @returns
     */
    private loadOfflineConcernData() {
        return this.storageService.get(StorageService.QUESTIONNAIRES)
            .then((data) => {
                this.currentQuestionnairesData = data;
            });
    }

    // TODO is this a backend job?
    public enrichQuestionnairesWithConcernFormInfo(questionnairesResponse: QuestionnairesResponse) {
        for (const questionnaire of questionnairesResponse.questionnaires) {
            if (questionnaire.parent_form != null) {
                for (const parentQuestionnaire of questionnairesResponse.questionnaires) {
                    if (parentQuestionnaire.id === questionnaire.parent_form.id) {
                        questionnaire.concern_questionnaire = parentQuestionnaire.concern_questionnaire;
                        questionnaire.compliment_questionnaire = parentQuestionnaire.compliment_questionnaire;
                        break;
                    }
                }
            }
        }
    }

    /**
     *
     * @param files
     * @returns
     */
    async removeFromLocalStorage(files: string[]) {
        for (const file of files) {
            await this.storageService.remove(file);
        }
    }

    /**
     *
     * @param attachments
     * @returns
     */
    async uploadAttachments(attachments: string[]) {
        const newAttachments: string[] = [];
        const filesToRemove: string[] = [];
        for (const fileName of attachments) {
            const file = fileName.match(/base64/) ? fileName : await this.storageService.get(fileName);
            await this.apiService.authenticatedPost('/questionnaire/img/upload', {attachments: [file]})
                .then((response) => {
                    // Take the link from the response json (first entry in this case)
                    const fileLink = response.data[0];

                    // Push into the new attachments array
                    newAttachments.push(fileLink);

                    // Push into the remove files array
                    if (!fileName.match(/base64/)) {
                        filesToRemove.push(fileName);
                    }
                }).catch((err) => {
                    this.trackingService.exception(err);
                    throw new Error(err);
                });
        }

        return {newAttachments, filesToRemove};
    }

    /**
     * Loop through the questions recursively, and extract the images per question
     *
     * @param question
     * @param filesToRemove
     * @returns Promise<{question: Question; filesToRemove: Array<string>}>
     */
    private async findAndUploadAttachmentsPerQuestion(question: Question): Promise<{
        question: Question;
        filesToRemove: string[];
    }> {
        const filesToRemove: string[] = [];
        if (question.type === 'image' && question.answers) {
            const newAttachments = [];
            for (const answer of question.answers) {
                if (answer.length > 0) {
                    const uploaded = await this.uploadAttachments(answer);
                    filesToRemove.push(...uploaded.filesToRemove);
                    newAttachments.push(uploaded.newAttachments);
                } else {
                    newAttachments.push(undefined);
                }
            }
            question.answers = newAttachments;
        } else if (question.attachments) {
            const newAttachments = [];
            for (const attachments of question.attachments) {
                if (attachments && Array.isArray(attachments) && attachments.length > 0) {
                    const uploaded = await this.uploadAttachments(attachments);
                    filesToRemove.push(...uploaded.filesToRemove);
                    newAttachments.push(uploaded.newAttachments);
                } else {
                    newAttachments.push(undefined);
                }
            }
            question.attachments = newAttachments;
        }

        // VW-2961 - Handle these cases separately rather than excluding one another so both choices and questions can be iterated over
        if (question.choices && question.choices.length > 0) {
            for (const choice of question.choices) {
                if (choice.hasOwnProperty('requireChapter')) {
                    delete choice.requireChapter;
                }

                for (let i = 0; i < choice.questions.length; i++) {
                    const uploaded = await this.findAndUploadAttachmentsPerQuestion(choice.questions[i]);
                    choice.questions[i] = uploaded.question;
                    filesToRemove.push(...uploaded.filesToRemove);
                }
            }
        }

        if (question.questions && question.questions.length > 0) {
            for (let i = 0; i < question.questions.length; i++){
                const uploaded = await this.findAndUploadAttachmentsPerQuestion(question.questions[i]);
                question.questions[i] = uploaded.question;
                filesToRemove.push(...uploaded.filesToRemove);
            }
        }
        return {question, filesToRemove};
    }

    /**
     * Modifies the question object by replacing the image urls with filenames of the downloaded images
     */
    async findAndDownloadAttachmentsPerQuestion(question: Question): Promise<void> {
        if (question.type === 'image' && question.answers) {
            const newAttachments = [];
            for (const answer of question.answers) {
                newAttachments.push(await this.downloadSasAttachments(answer));
            }
            question.answers = newAttachments;
        } else if (question.attachments) {
            const newAttachments = [];
            for (const attachments of question.attachments) {
                newAttachments.push(await this.downloadSasAttachments(attachments));
            }
            question.attachments = newAttachments;
        }

        // VW-2961 - Handle these cases separately rather than excluding one another so both choices and questions can be iterated over
        if (question.choices && question.choices.length > 0) {
            for (const choice of question.choices) {
                for (const childQuestion of choice.questions) {
                    await this.findAndDownloadAttachmentsPerQuestion(childQuestion);
                }
            }
        }

        if (question.questions && question.questions.length > 0) {
            for (const childQuestion of question.questions) {
                await this.findAndDownloadAttachmentsPerQuestion(childQuestion);
            }
        }
    }

    private async downloadSasAttachments(attachmentsSASUrls: string[]): Promise<string[]> {
        const existingFiles = await this.storageService.keys();

        const newAttachments: string[] = [];
        for (const fileName of attachmentsSASUrls) {
            const fileUrl = new URL(fileName);

            const storageKey = fileUrl.pathname;
            if (existingFiles.includes(storageKey)) {
                // File already exists in storage, was probably downloaded before
                newAttachments.push(storageKey);
                continue;
            }


            const fileResponse = await fetch(fileName);
            if (!fileResponse.ok) {
                throw new Error(`Failed to download file ${fileName}`);
            }

            const fileBlob = await fileResponse.blob();
            this.storageService.set(storageKey, await blobToDataURL(fileBlob));

            existingFiles.push(storageKey);
            newAttachments.push(storageKey);
        }

        return newAttachments;
    }

    /**
     * Uploads the attachments per question and returns the updates questions array
     *
     * @param questions
     *
     */
    async findAndUploadAttachments(questions: Question[]) {
        try {
            const filesToRemove: string[] = [];
            for (let question of questions) {
                const updated = await this.findAndUploadAttachmentsPerQuestion(question);
                question = updated.question;
                filesToRemove.push(...updated.filesToRemove);
            }

            return {questions, filesToRemove};
        } catch (err) {
            this.trackingService.exception(err);
            throw new Error(err);
        }
    }

    /**
     * Sends a questionnaire to the BE
     *
     * @param concept
     * @returns
     */
    public sendQuestionnaire(concept: Concept): Promise<any> {
        // Deep copy the questionnaire to avoid modifying the original object
        const questionnaire: Questionnaire = JSON.parse(JSON.stringify(concept.questionnaire));
        return this.findAndUploadAttachments(questionnaire.questions)
            .then((data) => {
                questionnaire.questions = data.questions;
                return this.apiService.authenticatedPost('/questionnaire', questionnaire)
                    .then((response) =>
                        this.conceptService.deleteConcept(concept).then(() =>
                            this.removeFromLocalStorage(data.filesToRemove).then(() => response.data),
                        ),
                    ).catch((err) => {
                        console.error(err);
                        this.trackingService.exception(err);
                        throw new Error(err);
                    });
            }).catch((err) => {
                this.trackingService.exception(err);
                console.error('Something went terribly wrong, please try again', err);
            });
    }

    /**
     * Clears all the stored questionnaires from the storage
     *
     * @returns
     */
    public clearQuestionnaires(): Promise<void> {
        this.currentQuestionnairesData = null;

        return this.storageService.remove(StorageService.QUESTIONNAIRES);
    }

    /**
     * Return the tasks data for filled in questionnaires
     *
     * @returns
     */
    public getQuestionnaireTasks(): Promise<any> {
        return this.apiService.authenticatedGet('/actions', this.apiService.getRequestOptions(true));
    }

    public getQuestionnaireTask(taskId: string): Promise<any> {
        return this.apiService.authenticatedGet(`/actions/${taskId}`, this.apiService.getRequestOptions(true))
            .then(response => response.data);
    }

    private retryLoadConcernData(noCache: boolean) {
        return this.storageService.has(StorageService.QUESTIONNAIRES)
            .then((hasOfflineData) => {
                return new Promise(async (resolve) => {
                        const alertMessage = this.getAlertMessageForRetryConcernData(hasOfflineData);
                        const alert = await this.alertController.create({
                            header: this.translateService.instant(hasOfflineData ? 'NOTIFICATION.update_failed' : 'NOTIFICATION.retrieval_failed'),
                            subHeader: alertMessage,
                            buttons: [
                                {
                                    text: this.translateService.instant('BUTTONS.cancel'),
                                    role: 'cancel',
                                    handler: async () => {
                                        (await alert).dismiss();
                                        resolve(false);
                                    },
                                },
                                {
                                    text: this.translateService.instant('BUTTONS.again'),
                                    handler: async () => {
                                        (await alert).dismiss();
                                        resolve(true);
                                    },
                                },
                            ],
                        });
                        return alert.present();
                    },
                ).then((retry: boolean) => {
                    if (retry) {
                        return this.loadConcernDataForUser(noCache);
                    } else {
                        return this.loadOfflineConcernData();
                    }
                });
            });
    }

    private getAlertMessageForRetryConcernData(hasOfflineData: boolean): string {
        if (hasOfflineData) {
            if (!this.networkService.isOnline()) {
                return this.translateService.instant('NOTIFICATION.error_forms_no_network');
            } else {
                return this.translateService.instant('NOTIFICATION.error_forms_slow_network');
            }
        } else {
            if (!this.networkService.isOnline()) {
                return this.translateService.instant('NOTIFICATION.error_forms_retrieval_no_network');
            } else {
                return this.translateService.instant('NOTIFICATION.error_forms_retrieval_slow_network');
            }
        }
    }
}
