<ion-footer class="wave-footer">
    <ion-toolbar>
        <ion-grid>
            <ion-row>
                <ion-col size="4" class="ion-text-center wave-footer__col">
                    <ion-buttons class="ion-justify-content-center">
                        <ion-button [routerLink]="['/dashboard']" routerDirection="forward" class="wave-footer__btn" [class.wave-footer__btn--active]="isDashboardRoute">
                            <span class="wave-footer__btn-span">
                                <ion-icon src="assets/images/icon-home.svg" class="wave-footer__icon-badge"></ion-icon>
                                <span class="wave-footer__btn-text">{{ 'DASHBOARD.tile.home' | translate }}</span>
                            </span>
                        </ion-button>
                    </ion-buttons>
                </ion-col>
                <ion-col size="4" class="ion-text-center wave-footer__col">
                    <ion-buttons class="ion-justify-content-center">
                        <ion-button (click)="organisationStructureService.determineQuestionnairePage('all')" class="wave-footer__btn" [class.wave-footer__btn--active]="!isDashboardRoute && !isMoreRoute">
                            <span class="wave-footer__btn-span">
                                <ion-icon src="assets/images/icon-form.svg" class="wave-footer__icon-badge" [class.wave-footer__icon-badge--active]="hasFormsBadge"></ion-icon>
                                <span class="wave-footer__btn-text">{{ 'DASHBOARD.tile.forms' | translate }}</span>
                            </span>
                        </ion-button>
                    </ion-buttons>
                </ion-col>
                <ion-col size="4" class="ion-text-center wave-footer__col">
                    <ion-buttons class="ion-justify-content-center">
                        <ion-button [routerLink]="['/dashboard-more']" routerDirection="forward" class="wave-footer__btn" [class.wave-footer__btn--active]="isMoreRoute">
                            <span class="wave-footer__btn-span">
                                <ion-icon src="assets/images/icon-more.svg" class="wave-footer__icon-badge" [class.wave-footer__icon-badge--active]="hasOpenTasks"></ion-icon>
                                <span class="wave-footer__btn-text">{{ 'DASHBOARD.tile.more' | translate }}</span>
                            </span>
                        </ion-button>
                    </ion-buttons>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-toolbar>
</ion-footer>
