import {Component} from '@angular/core';
import {LoadingController, NavController} from '@ionic/angular';
import {AuthService} from '../../services/auth-service';
import {AzureAuthService} from '../../services/azure-auth-service';
import {TrackingService} from '../../services/tracking/tracking.service';
import {LanguageService} from '../../services/language.service';
import {ToastService} from '../../services/toast-service';
import {TranslateService} from '@ngx-translate/core';
import { RedirectAfterLoginService } from '../../services/redirect-after-login-service';

@Component({
    selector: 'page-choose-login',
    templateUrl: 'choose-login.html',
    styleUrls: ['choose-login.scss'],
})
export class ChooseLoginPage {

    constructor(
        private auth: AuthService,
        private azureAuth: AzureAuthService,
        private loadingCtrl: LoadingController,
        private navCtrl: NavController,
        private languageService: LanguageService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private trackingService: TrackingService,
        private redirectAfterLoginService: RedirectAfterLoginService,
    ) {
    }

    async azureAuthenticate() {
        const loading = await this.loadingCtrl.create();

        try {
            await loading.present();
            const language = this.languageService.getCurrentLanguage();
            await this.auth.internalUserAuthenticate();
            await loading.dismiss();
            await this.azureAuthCompleted();
            await this.languageService.updateLanguageInProfile(language);
        } catch (exception) {
            console.error('Auth exception', exception);
            if (exception.errorCode === 'popup_window_error') {
                this.toastService.showCustomMessages(this.translateService.instant('NOTIFICATION.error_popup_blocked'));
            }

            this.trackingService.exception(exception);
            await loading.dismiss();
        }
    }

    goToExternalAccountLogin() {
        this.navCtrl.navigateForward(['external-account-login']);
    }

    async ionViewDidEnter() {
        try {
            if (await this.auth.handleRedirect()) {
                await this.azureAuthCompleted();
            } else {
                this.azureAuth.forceClear(); // force logout with Azure to clean existing references to accounts
            }
        } catch (exception) {
            console.error('Error handling authentication redirect', exception);
            this.trackingService.exception(exception);
        }
    }

    async azureAuthCompleted() {
        await this.redirectAfterLoginService.redirect();
    }
}
