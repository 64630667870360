import {Injectable} from '@angular/core';

export type EventHandler = (...args: any[]) => any;

@Injectable({
    providedIn: 'root',
})
export class EventService {

    private events = new Map<string, EventHandler[]>();

    public subscribe(topic: string, handler: EventHandler): void {
        if (!this.events.has(topic)) {
            this.events.set(topic, []);
        }

        this.events.get(topic).push(handler);
    }

    public publish(topic: string, ...args: any[]): void {
        const handlers = this.events.get(topic);

        if (!handlers) {
            return;
        }

        handlers.map((handler) => {
            try {
                handler(...args);
            } catch (e) {
                console.error(e);
            }
        });
    }

}
