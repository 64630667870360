<app-generic-header [showOrganizationName]="false"></app-generic-header>

<ion-content class="has-header">
    <div class="card">
        <h2 class="card__title">{{ 'DASHBOARD.tile.instruction' | translate }}</h2>
        <p class="card__description">{{ getSubTitle() | translate }}</p>
        <ion-searchbar
            class="card__search"
            (ionInput)="showCategories ? filterMediaCommunication($event) : filterMediaCommunicationList($event)"
            placeholder="{{ getSearchPlaceholder() | translate }}"
            ionAutoMode
        ></ion-searchbar>
        <!-- Media Communication Categories -->
        <ng-container *ngIf="showCategories">
            <app-select-item *ngFor="let category of (filtering ? filteredCategories : categories)"
                             [title]="category.title"
                             [subTitle]="category.subtitle"
                             [hasImagePlaceholder]="false"
                             (click)="goToMediaCommunicationSubjectList(category.id)"
            ></app-select-item>
        </ng-container>

        <!-- Media Communication Items -->
        <ng-container *ngIf="!showCategories">
            <app-select-item *ngFor="let item of (filtering ? filteredMediaCommunicationListItems : mediaCommunicationListItems)"
                             [title]="item.title"
                             [subTitle]="item.subtitle"
                             [hasImagePlaceholder]="false"
                             (click)="goToMediaCommunicationDetails(item.id)"
            ></app-select-item>
        </ng-container>
    </div>
</ion-content>
