<ion-header class="app-bar">
    <ion-toolbar [color]="color" class="app-bar__toolbar">
        <ng-container *ngIf="showBackButton; else header">
            <ion-button fill="clear" class="app-bar__back" (click)="navigateBack()" slot="start">
                <ion-icon name="chevron-back-outline" class="app-bar__back-icon"></ion-icon>
            </ion-button>
        </ng-container>
        <app-language-selection *ngIf="showLanguageSelection && availableLanguages.length > 1" slot="end"></app-language-selection>
    </ion-toolbar>
    <ion-toolbar *ngIf="showDashboardTitle" class="dashboard__header">
        <ion-title class="dashboard__title">{{ 'DASHBOARD.tile.title' | translate}}</ion-title>
        <ion-text class="dashboard__description">{{ 'DASHBOARD.tile.subtitle' | translate}}</ion-text>
    </ion-toolbar>
</ion-header>

<ng-template #header>
    <ion-title slot="start" [routerLink]="['/dashboard']" class="app-bar__title" routerDirection="back">
        <ion-icon src="assets/images/logo.svg" class="logo"></ion-icon>
    </ion-title>
    <ion-buttons slot="end">
        <ion-button *ngIf="showHelpButton" class="app-bar__help" [routerLink]="['/support']" routerDirection="forward">
            <ion-icon src="assets/images/icon-help.svg" class="app-bar__help-icon"></ion-icon>
        </ion-button>
    </ion-buttons>
</ng-template>
