<ion-content
    class="notification"
    [ngClass]="theme ? 'notification--theme-' + theme : ''"
>
    <div class="notification__wrapper">
        <ion-row>
            <ion-col size="12">
                <div class="notification__close-early" (click)="close('window')"><ion-icon name="close"></ion-icon></div>
                <div class="notification__heading">{{ (heading || 'NOTIFICATION.title') | translate }}</div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <div class="notification__subject" *ngIf="subject">{{ subject | translate }}</div>
                <div class="notification__body" *ngIf="body" [innerHTML]="body | translate | nl2br:true"></div>
            </ion-col>
            <ion-col size="12">
                <div class="notification__button" (click)="close('button')">{{ (button || 'NOTIFICATION.close') | translate }}</div>
            </ion-col>
        </ion-row>
    </div>
</ion-content>
