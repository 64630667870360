import {Component, Input} from '@angular/core';
import {NavParams, ModalController} from '@ionic/angular';
import {NotificationModalTheme} from '../../models/notification-modal-theme';

@Component({
    selector: 'app-notification-modal',
    templateUrl: 'notification-modal.html',
})
export class NotificationModalComponent {

    @Input() theme?: NotificationModalTheme;
    @Input() heading: string;
    @Input() subject: string;
    @Input() body: string;
    @Input() button: string;

    constructor(
        public modalCtrl: ModalController,
        private navParams: NavParams,
    ) {
        this.heading = this.navParams.get('heading');
        this.subject = this.navParams.get('subject');
        this.body = this.navParams.get('body');
        this.button = this.navParams.get('button');
    }

    public close(action = 'close') {
        this.modalCtrl.dismiss(action);
    }

}
