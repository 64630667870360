import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';

@Component({
    selector: 'app-text-autoheight',
    templateUrl: 'text-autoheight.html',
})
export class TextAutoheightComponent {

    @Output() private valueChange: EventEmitter<any> = new EventEmitter();

    @Output() private hasFocus: EventEmitter<boolean> = new EventEmitter();

    @Input() public currentValue = '';
    @Input() public disabled = false;
    @Input() public placeholder = '';
    @Input() public maxLength: number;

    private blurDisabled = false;

    changed() {
        this.valueChange.emit(this.currentValue);
    }

    inputChanged() {
        this.changed();
    }

    gotFocus() {
        this.blurDisabled = true;
        this.hasFocus.emit(true);
        // give the screen sometime to rerender
        setTimeout(() => {
            this.blurDisabled = false;
        }, 750);
    }

    lostFocus() {
        if (!this.blurDisabled) {
            this.hasFocus.emit(false);
        }
    }
}
