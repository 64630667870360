import {Component, OnDestroy} from '@angular/core';
import {DateFormatPipe} from '../../pipes/date-format';
import {AlertController, NavController, Platform} from '@ionic/angular';
import {Questionnaire, QuestionnaireListItem} from '../../models/questionnaire';
import {Concept, UploadedConcept} from '../../models/concept';
import {QuestionnaireReminder} from '../../models/questionnaire-reminder';
import {OrganisationStructureParams} from '../../models/organisation-params';
import {TranslateService} from '@ngx-translate/core';
import {ConceptService} from '../../services/concept-service';
import {DashboardStatusService} from '../../services/dashboard-status-service';
import {QuestionnaireDataService} from '../../services/questionnaire-data-service';
import {LoadingService} from '../../services/loading-service';
import {ToastService} from '../../services/toast-service';
import {QuestionnairesFilter} from '../../utils/questionnaires-filter';
import {QuestionnaireUtils} from '../../utils/questionnaire-utils';
import {BehaviorSubject, combineLatest, firstValueFrom, merge, Subject, Subscription, switchMap} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {QuestionnaireListService} from '../../services/questionnaire-list-service';
import {OrganisationStructureService} from '../../services/organisation-structure-service';
import {QuestionnaireAssembleService} from '../../services/questionnaire-assemble-service';
import {QuestionnaireFilterType} from '../../enums/questionnaire-filter-type';
import {StructureNodeType} from '../../enums/structure-node-type';
import {map, take, takeUntil} from 'rxjs/operators';
import {ConceptQuestionnaireService} from '../../services/concept-questionnaire.service';
import {UploadedConceptService} from '../../services/uploaded-concept.service';
import {promise} from 'protractor';

@Component({
    selector: 'page-select-questionnaire',
    templateUrl: 'select-questionnaire.html',
})
export class SelectQuestionnairePage implements OnDestroy {
    public workplaceQuestionnaires: QuestionnaireListItem[] = [];
    public plannedQuestionnaires: QuestionnaireListItem[] = [];
    public otherQuestionnaires: QuestionnaireListItem[] = [];
    public concepts: Concept[] = [];
    public uploadedConcepts: UploadedConcept[] = [];
    public reminders: QuestionnaireReminder[] = [];
    public noAccess$ = new BehaviorSubject(false);
    public unstartedRemindersCount = 0;
    public dataLoaded$ = new BehaviorSubject(false);
    private questionnaireListItems: QuestionnaireListItem[] = [];
    private subscriptions: Subscription[] = [];

    private loadingError = false;

    private organisationStructureParams?: OrganisationStructureParams;
    private questionnaireIdToSelect?: number;
    private destroy$ = new Subject<void>();

    readonly refreshUploadedConcepts = new BehaviorSubject<void>(null);
    readonly uploadedConcepts$ = combineLatest([
        this.route.paramMap.pipe(
            map(paramMap => ({
                structureNodeType: paramMap.get('structure_node_type') as StructureNodeType,
                structureNodeId: parseInt(paramMap.get('id'), 10),
            })),
        ),
        merge([this.refreshUploadedConcepts, this.conceptQuestionnaireService.reloadConcepts$]),
    ]).pipe(
        switchMap(async ([filter]) => {
            const concepts = await this.uploadedConceptService.listConcepts();
            return concepts.filter(item => {
                if (filter.structureNodeType === StructureNodeType.PROJECT) {
                    return item.questionnaire_project_id === filter.structureNodeId;
                } else if (filter.structureNodeType === StructureNodeType.BUSINESSUNIT) {
                    return item.questionnaire_businessunit_id === filter.structureNodeId;
                } else if (filter.structureNodeType === StructureNodeType.ORGANISATION) {
                    return item.questionnaire_organisation_id === filter.structureNodeId;
                } else {
                    console.warn('Cant filter concepts by structure node type', filter.structureNodeType);
                    return true;
                }
            });
        }),
    );

    constructor(
        private navCtrl: NavController,
        private route: ActivatedRoute,
        private router: Router,
        private conceptService: ConceptService,
        private uploadedConceptService: UploadedConceptService,
        public conceptQuestionnaireService: ConceptQuestionnaireService,
        private statusService: DashboardStatusService,
        private organisationStructureService: OrganisationStructureService,
        private questionnaireListService: QuestionnaireListService,
        private questionnaireAssembleService: QuestionnaireAssembleService,
        private questionnaireDataService: QuestionnaireDataService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private alertCtrl: AlertController,
        private translateService: TranslateService,
        private platform: Platform,
    ) {
        this.questionnaireIdToSelect = undefined;
    }

    async ionViewWillEnter() {
        this.dataLoaded$.next(false);
        this.noAccess$.next(false);
        const structureNodeType = this.route.snapshot.paramMap.get('structure_node_type') as StructureNodeType;
        const structureNodeId = parseInt(this.route.snapshot.paramMap.get('id'), 10);

        await this.loadingService.start(this.translateService.instant('SELECT_QUESTIONNAIRE.retrieve_forms'));

        try {
            await Promise.all([
                firstValueFrom(this.organisationStructureService.loadOrganisationStructure()),
                this.refreshConcepts(),
            ]);

            this.organisationStructureParams = this.organisationStructureService.getOrganisationStructureParamsFor(structureNodeType, structureNodeId);
            if (!this.organisationStructureParams) {
                // VW-3453: no access, via a scanned QR code
                this.noAccess$.next(true);
            } else {
                const questionnaireFilterType = this.getQuestionnaireFilterType();
                if (questionnaireFilterType === QuestionnaireFilterType.DIRECT) {
                    this.questionnaireIdToSelect = parseInt(this.route.snapshot.paramMap.get('questionnaire_id'), 10);
                }

                // For incident, we skip the loading of the list to make it go as fast as possible
                // As we have all the data preloaded
                if (questionnaireFilterType === QuestionnaireFilterType.INCIDENT) {
                    this.selectIncidentQuestionnaire(structureNodeType, structureNodeId);

                    // For all other questionnaire types we can wait for the list as they aren't as vital
                } else {
                    const items = await firstValueFrom(this.questionnaireListService.getStructureNodeQuestionnaires(structureNodeType, structureNodeId, questionnaireFilterType));
                    this.handleQuestionnaireSelection(questionnaireFilterType, items);
                }
            }
        } catch (error) {
            console.error('error occurred loading data, stopping.', error);
            this.navCtrl.back();
        } finally {
            this.dataLoaded$.next(true);
            this.loadingService.stop();
        }


        this.conceptQuestionnaireService.reloadConcepts$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.refreshConcepts());
    }

    /**
     * Selects the questionnaire list item and create a concept for editing
     */
    public async selectQuestionnaireListItem(questionnaireListItem: QuestionnaireListItem, destroyPreviousRoute = false) {
        await this.loadingService.start(this.translateService.instant('SELECT_QUESTIONNAIRE.retrieve_form'));

        const questionnaire = await firstValueFrom(this.questionnaireAssembleService.getAssembledQuestionnaireById(questionnaireListItem.id));
        return this.selectQuestionnaire(questionnaire, destroyPreviousRoute);
    }

    /**
     * Get the deadline string for a questionnaire
     */
    public getDeadlineForQuestionnaire(questionnaire: QuestionnaireListItem): string {
        const questionnaireReminders = [];
        this.reminders.forEach((reminder: QuestionnaireReminder) => {
            if (reminder.questionnaire_id === questionnaire.id) {
                questionnaireReminders.push(reminder);
            }
        });

        let deadlineString = '';

        // Get all the reminders that match this questionnaire
        // And sort them by earliest first
        questionnaireReminders.sort((reminderA: QuestionnaireReminder, reminderB: QuestionnaireReminder) => {
            if (reminderA.deadline_date > reminderB.deadline_date) {
                return -1;
            } else if (reminderA.deadline_date < reminderB.deadline_date) {
                return 1;
            } else {
                return 0;
            }
        });

        if (questionnaireReminders.length) {
            const dateFormatPipe = new DateFormatPipe();
            deadlineString = dateFormatPipe.transform(questionnaireReminders[0].deadline_date, this.translateService.instant('DATE.long'));
        }

        return deadlineString;
    }

    public trackQuestionnaireListItemById(index, item: QuestionnaireListItem): number | null {
        if (!item) {
            return null;
        } else {
            return item.id;
        }
    }

    async ionViewWillLeave() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    private async handleQuestionnaireSelection(questionnaireFilterType: QuestionnaireFilterType, questionnaireListItems: QuestionnaireListItem[]) {

        switch (questionnaireFilterType) {
            case QuestionnaireFilterType.COMPLIMENT:
                const complimentQuestionnaire = questionnaireListItems.find((questionnaireListItem) => {
                    return questionnaireListItem.compliment_questionnaire;
                });

                if (complimentQuestionnaire) {
                    await this.selectQuestionnaireListItem(complimentQuestionnaire, true);
                } else {
                    this.displayLoadingError(questionnaireFilterType);
                }
                break;
            case QuestionnaireFilterType.DIRECT:
                const directQuestionnaire = questionnaireListItems.find((questionnaireListItem) => {
                    if (questionnaireListItem.id === this.questionnaireIdToSelect) {
                        return true;
                    }
                    if (questionnaireListItem.parent_form_ids) {
                        return questionnaireListItem.parent_form_ids.includes(this.questionnaireIdToSelect);
                    }
                    return false;
                });
                if (directQuestionnaire) {
                    await this.selectQuestionnaireListItem(directQuestionnaire, true);
                } else {
                    this.displayLoadingError(questionnaireFilterType);
                }
                break;
            default:
                await this.loadingService.stop();
                this.questionnaireListItems = questionnaireListItems;
                this.filterQuestionnaires();
                this.dataLoaded$.next(true);
        }
    }

    private getQuestionnaireFilterType(): QuestionnaireFilterType {
        if (this.route.routeConfig?.data?.questionnaireTypes) {
            if (this.route.routeConfig.data.questionnaireTypes.includes(QuestionnaireFilterType.INCIDENT)) {
                return QuestionnaireFilterType.INCIDENT;
            } else if (this.route.routeConfig.data.questionnaireTypes.includes(QuestionnaireFilterType.COMPLIMENT)) {
                return QuestionnaireFilterType.COMPLIMENT;
            }

            return QuestionnaireFilterType.ALL;
        } else {
            return QuestionnaireFilterType.DIRECT;
        }
    }

    /**
     * Filters the questionnaires list
     */
    private filterQuestionnaires() {
        this.workplaceQuestionnaires = this.questionnaireListItems.filter(it => it.type === 'workplace');
        this.otherQuestionnaires = this.questionnaireListItems.filter(it => it.type !== 'workplace');

        this.refreshConcepts();
    }

    private async refreshConcepts(): Promise<void> {
        this.loadingService.start();
        await Promise.allSettled([
            this.loadConcepts(),
            this.loadUploadedConcepts(),
        ]);
        this.loadingService.delayedStop();
    }

    /**
     * Gets the concepts from the storage
     *
     * @returns Promise<void>
     */
    private async loadConcepts() {
        const concepts = await this.conceptService.getConcepts();
        this.concepts = QuestionnairesFilter.filterConcepts(concepts, this.organisationStructureParams);
        this.concepts.sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at));
        this.updateBadges(concepts);
    }

    private async loadUploadedConcepts() {
        const structureNodeType = this.route.snapshot.paramMap.get('structure_node_type') as StructureNodeType;
        const structureNodeId = parseInt(this.route.snapshot.paramMap.get('id'), 10);

        const concepts = await this.uploadedConceptService.listConcepts();
        this.uploadedConcepts = concepts.filter(item => {
            if (structureNodeType === StructureNodeType.PROJECT) {
                return item.questionnaire_project_id === structureNodeId;
            } else if (structureNodeType === StructureNodeType.BUSINESSUNIT) {
                return item.questionnaire_businessunit_id === structureNodeId;
            } else if (structureNodeType === StructureNodeType.ORGANISATION) {
                return item.questionnaire_organisation_id === structureNodeId;
            } else {
                console.warn('Cant filter concepts by structure node type', structureNodeType);
                return true;
            }
        });
    }

    private updateBadges(concepts: Array<Concept>) {
        // Update the badges
        this.statusService.getQuestionReminders().then((reminders: QuestionnaireReminder[]) => {
            this.reminders = reminders;

            const specificReminders = QuestionnairesFilter.filterQuestionnaireReminders(reminders, this.organisationStructureParams);

            // Add the organisation specific reminders - But only if we are inside a project or business unit node
            // Use an empty array in the case we are on an organisation overview for questionnaires
            const isDeeperThanOrganisationNode = (this.organisationStructureParams.businessUnitId || this.organisationStructureParams.projectId);
            const organisationReminders = isDeeperThanOrganisationNode ? reminders.filter((reminder: QuestionnaireReminder) => {

                // a reminder is valid for this organisation in case:
                // reminder is on sector level, this organisation belongs to that sector
                // reminder is on region level, this organisation belongs to that region
                // reminder is on org level, and this organisation is the one.
                return ((reminder.business_unit_id === null && reminder.project_id === null) &&
                    (reminder.sector_id === null || (reminder.sector_id === this.organisationStructureParams.sectorId)) &&
                    (reminder.region_id === null || (reminder.region_id === this.organisationStructureParams.regionId)) &&

                    (reminder.organisation_id === null || (reminder.organisation_id === this.organisationStructureParams.organisationId)));
            }) : [];

            let plannedQuestionnaireTurnedConceptCount = 0;

            // Count the amount of reminders already turned into concepts
            specificReminders.concat(organisationReminders).forEach((reminder: QuestionnaireReminder) => {
                let found = false;
                concepts.forEach((concept: Concept) => {
                    if (!found && reminder.questionnaire_id === concept.questionnaire.id) {
                        found = true;
                    }
                });

                if (found) {
                    plannedQuestionnaireTurnedConceptCount++;
                }
            });

            // The total unstarded reminders count is the total planned forms for this page
            // Minus the amount of planned concepts of the questionnaires that have a deadline for this user
            this.unstartedRemindersCount = (specificReminders.length + organisationReminders.length) - plannedQuestionnaireTurnedConceptCount;

            // Do filtering based on the reminders
            const conceptIds = concepts.map((concept: Concept) => {
                return concept.questionnaire.id;
            });
            const reminderIds = reminders.map((reminder: QuestionnaireReminder) => {
                return reminder.questionnaire_id;
            });

            // Planned questionnaires are only in the planned questionnaire field if
            // No concept has been created of it and the id is inside the reminders
            this.plannedQuestionnaires = this.questionnaireListItems.filter(it => reminderIds.indexOf(it.id) > -1 && conceptIds.indexOf(it.id) === -1);

            // All others are on the basis that they dont occur in the reminders or if a concept of them exists
            this.workplaceQuestionnaires = this.workplaceQuestionnaires.filter(it => reminderIds.indexOf(it.id) === -1 || conceptIds.indexOf(it.id) > -1);
            this.otherQuestionnaires = this.otherQuestionnaires.filter(it => it.type !== 'workplace' &&
                (reminderIds.indexOf(it.id) === -1 || (reminderIds.indexOf(it.id) > -1 && conceptIds.indexOf(it.id) > -1)));
        });
    }

    /**
     * Selects the questionnaire and create a concept for editing
     */
    private async selectQuestionnaire(questionnaire: Questionnaire, destroyPreviousRoute: boolean, animated = true) {
        const concept = await this.createNewConcept(questionnaire);

        concept.questionnaire.businessunit_id = this.organisationStructureParams.businessUnitId;
        concept.questionnaire.project_id = this.organisationStructureParams.projectId;

        return this.conceptQuestionnaireService.openConcept(concept, destroyPreviousRoute, animated);
    }

    /**
     * creates a new concept. Note: the questionnaire is already refreshed (via either selectQuestionnaireListItem or
     * via selectIncidentQuestionnaire).
     *
     * @param questionnaire
     * @returns
     */
    private createNewConcept(questionnaire: Questionnaire): Concept {
        const concept = QuestionnaireUtils.createNewQuestionnaireConcept(questionnaire, this.organisationStructureParams.organisationId);

        // refresh the dashboard status, since we added a concept.
        this.statusService.refresh();
        return concept;
    }

    private displayLoadingError(questionnaireFilterType: QuestionnaireFilterType) {
        this.loadingService.stop().then(() => {
            if (!this.loadingError) {
                if (questionnaireFilterType === QuestionnaireFilterType.ALL) {
                    this.toastService.showCustomMessages(this.translateService.instant('SELECT_QUESTIONNAIRE.something_went_wrong'));
                } else {
                    let postfix = 'questionnaireIdToSelect';
                    if (questionnaireFilterType === QuestionnaireFilterType.INCIDENT) {
                        postfix = 'directIncidentOnly';
                    } else if (questionnaireFilterType === QuestionnaireFilterType.COMPLIMENT) {
                        postfix = 'directComplimentOnly';
                    }

                    this.alertCtrl.create({
                        mode: this.platform.is('ios') ? 'ios' : 'md',
                        header: this.translateService.instant('ERROR.form.warning'),
                        subHeader: this.translateService.instant('ERROR.form.' + postfix),
                        buttons: [this.translateService.instant('BUTTONS.ok')],
                    }).then((alert) => alert.present());
                }
            }
            this.loadingError = true;
        });
    }

    private selectIncidentQuestionnaire(structureNodeType: StructureNodeType, structureNodeId: number) {
        this.organisationStructureService.getIncidentFormForNode(structureNodeType, structureNodeId)
            .pipe(take(1))
            .subscribe({
                next: async (incidentForm) => {
                    await this.selectQuestionnaire(incidentForm, true, false);
                    this.dataLoaded$.next(true);
                }, error: (_) => {
                    this.displayLoadingError(QuestionnaireFilterType.INCIDENT);
                    this.dataLoaded$.next(true);
                },
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
