export class OrganisationStructureParams {
    sectorId: number;
    regionId: number;
    organisationId: number;
    businessUnitId: number;
    projectId: number;

    constructor(sectorId: number, regionId: number, organisationId: number, businessUnitId: number = null, projectId: number = null) {
        this.sectorId = sectorId;
        this.regionId = regionId;
        this.organisationId = organisationId;
        this.businessUnitId = businessUnitId;
        this.projectId = projectId;
    }

    /**
     * Clones this object
     *
     * @returns
     */
    public clone() {
        return new OrganisationStructureParams(this.sectorId, this.regionId, this.organisationId, this.businessUnitId, this.projectId);
    }
}
