<app-generic-header [showOrganizationName]="false"></app-generic-header>

<ion-content class="has-header">
    <div class="card">
        <h2 class="card__title">{{ 'CONTACT.title' | translate }}</h2>
        <p class="card__description">{{ 'CONTACT.subtitle' | translate }}</p>
        <form [formGroup]="form" novalidate>
            <ion-item lines="none" class="item__no-padding">
                <ion-input [readonly]="true" [value]="email"></ion-input>
            </ion-item>
            <ion-item lines="none" class="item__no-padding">
                <ion-input formControlName="phone" type="tel" [readonly]="!isExternal" placeholder="{{ 'CONTACT.fill_phone_number' | translate }}"></ion-input>
            </ion-item>
            <ion-item lines="none" class="item__no-padding">
                <ion-select interface="popover" formControlName="language" [class]="'language-select language-select--' + currentLanguage" (ionChange)="setLanguageFlag($event)">
                    <ng-container *ngFor="let availableLanguage of availableLanguages; let i = index; trackBy: languageID">
                        <ion-select-option [class]="'language-select--' + availableLanguage.value" [value]="availableLanguage.value">{{ availableLanguage.label }}</ion-select-option>
                    </ng-container>
                </ion-select>
            </ion-item>
            <div class="card__btns card__btns--space">
                <ion-button [hidden]="!form.dirty" expand="block" fill="outline" shape="round" class="card__btn" (click)="submit()">{{ 'CONTACT.save' | translate }}</ion-button>
                <ion-button id="button-logout" expand="block" shape="round" class="card__btn" (click)="logout()">{{ 'CONTACT.logout' | translate }}</ion-button>
                <ion-button [hidden]="!isExternal" expand="block" fill="outline" shape="round" class="card__btn" (click)="openPasswordChangePage()">{{ 'CONTACT.change_password' | translate }}</ion-button>
                <ion-button [hidden]="!isExternal" expand="block" fill="outline" shape="round" class="card__btn" (click)="openConfirmRemoveExternalAccountModal()">{{ 'CONTACT.remove_account' | translate }}</ion-button>
            </div>
        </form>
    </div>
</ion-content>
