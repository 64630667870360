import {AbstractQuestionComponent} from './abstract-question.component';
import {Component, Input} from '@angular/core';
import {Question} from '../models/question';
import {Choice} from '../models/choice';

@Component({
    template: '',
})
export abstract class AbstractChoiceQuestionComponent extends AbstractQuestionComponent {

    @Input() public question: Question;
    @Input() public manualAdditionsAllowed?: boolean;

    addManualChoice(newChoiceTitle: string) {
        const newChoice = this.addChoice(newChoiceTitle);
        this.choose(newChoice, true);
    }

    public abstract choose(choice: Choice, newAddition?: boolean): void;

    private addChoice(newChoiceTitle: string) {
        const newChoice = {
            title: newChoiceTitle,
            addedManually: true,
            questions: [],
        };
        this.question.choices.push(newChoice);

        return newChoice;
    }
}
