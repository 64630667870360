<div class="expand-wrapper">
    <span class="expand-trigger">
        <div (click)="toggleChapter()"><span [ngClass]="{'expanded': (expanded$ | async)}" inlineSVG="assets/images/icon-arrow-down.svg"></span></div>
    </span>
    <button class="item-expand" [ngClass]="{'item-skip-requirements':!readOnly && skipRequirements}" (click)="gotoChapter(chapter)">
        <div class="container">
            <div class="name">
                <h2 class="text-color-dark">{{title}}</h2>
            </div>
            <span *ngIf="isCompletedCheckmarkVisible()" class="checkmark-blue"
                  inlineSVG="assets/images/{{readOnly ? 'icon-checkmark_grey.svg' : chapterAnswersEmpty() ? 'icon-checkmark_grey_empty.svg' : 'icon-checkmark_blue.svg'}}">
            </span>
        </div>
    </button>
    <label class="toggle-skip-requirements" *ngIf="!readOnly && skipRequirements">
        <input type="checkbox" [checked]="chapter.requireChapter ? 'checked' : false" (change)="toggleSkipRequirement()" />
        <span></span>
    </label>
</div>
<ng-container *ngIf="expanded$ | async">
    <button class="chapter-question" *ngFor="let question of chapter.questions; let questionIndex = index" (click)="gotoQuestion(chapter, question, questionIndex)">
        <div class="container">
            <span class="icon-list text-color-dark">&#9632;</span>
            <div class="name">
                <h2 class="text-color-dark">{{question | contentTranslate:'title'}}</h2>
            </div>
            <span *ngIf="isQuestionCompleted(question)" class="checkmark-blue"
                  inlineSVG="assets/images/{{readOnly ? 'icon-checkmark_grey.svg' : isQuestionAnswerEmpty(question) ? 'icon-checkmark_grey_empty.svg' : 'icon-checkmark_blue.svg'}}"></span>
        </div>
    </button>
</ng-container>
