import {Injectable} from '@angular/core';
import {ApiService} from './api-service';
import {Profile} from '../models/profile';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    constructor(private api: ApiService) {
    }

    /**
     * Get the profile of the user
     *
     * @returns
     */
    public fetchProfile(): Promise<any> {
        return this.api.authenticatedGet('/profile', this.api.getRequestOptions(true));
    }

    /**
     * Sets the new profile of the user
     *
     * @param profile
     * @returns
     */
    public postProfile(profile: Profile): Promise<any> {
        return this.api.authenticatedPost('/profile', profile);
    }
}
