import {Injectable} from '@angular/core';

import {
    IMediaCommunicationCategory,
    IMediaCommunicationItem,
    IMediaCommunicationListItem,
} from '../models/media-communication-item';
import {ProgressiveApiService, ProgressiveStrategy} from './progressive-api-service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MediaCommunicationItemsService {
    constructor(
        private progressiveApiService: ProgressiveApiService,
    ) {
    }

    public getMediaCommunicationCategoryList(): Observable<IMediaCommunicationCategory[]> {
        return this.progressiveApiService.authenticatedGet<IMediaCommunicationCategory[]>('/instructions/categories', ProgressiveStrategy.FALLBACK);
    }

    public getMediaCommunicationItemList(categoryId: string): Observable<IMediaCommunicationListItem[]> {
        return this.progressiveApiService.authenticatedGet<IMediaCommunicationListItem[]>(`/instructions/category/${categoryId}`, ProgressiveStrategy.FALLBACK);
    }

    public getMediaCommunicationItem(mediaCommunicationItemId: any): Observable<IMediaCommunicationItem> {
        return this.progressiveApiService.authenticatedGet<IMediaCommunicationItem>(`/instruction/${mediaCommunicationItemId}`, ProgressiveStrategy.FALLBACK);
    }
}
