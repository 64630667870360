import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class RedirectAfterLoginService {

    private redirectRoute = '';

    constructor(private navCtrl: NavController) {
        this.reset();
    }

    public reset(): void {
        this.redirectRoute = '/dashboard';
    }

    public async redirect(): Promise<void> {
        await this.navCtrl.navigateRoot(this.redirectRoute);
    }

    public registerRedirectRoute(route: ActivatedRouteSnapshot): void {
        this.redirectRoute = route.pathFromRoot
            .map(path => path.url.map(segment => segment.toString()).join('/'))
            .join('/');
    }

    public registerRedirectRouteForUrl(url: string): void {
        this.redirectRoute = url;
    }
}
