import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Choice} from '../../models/choice';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {SearchableSettings} from '../../enums/searchable-settings';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';
import {Observable} from 'rxjs';
import {AbstractChoiceQuestionComponent} from '../../classes/abstract-choice-question.component';

@Component({
    selector: 'app-checkbox-searchable',
    templateUrl: 'checkbox-searchable.html',
})
export class CheckboxSearchableComponent extends AbstractChoiceQuestionComponent implements OnInit, AfterViewInit {

    @Input() public content: HTMLIonContentElement;
    public filteredChoices: Choice[] = [];
    public listChoices: Choice[] = [];
    public selectedChoices: Choice[];
    public selectedChoiceTitles: string[] = [];
    public isKeyboardClosed$: Observable<boolean>;
    public maxListChoices: number = SearchableSettings.MAX_ITEM_RESULT;

    constructor(
        fb: UntypedFormBuilder,
        private deviceKeyboardService: DeviceKeyboardService,
    ) {
        super(fb);
    }

    ngOnInit() {
        super.ngOnInit();
        this.isKeyboardClosed$ = this.deviceKeyboardService.isKeyboardClosed();
    }

    ngAfterViewInit(): void {
        this.setSelectedChoices();
    }

    choicesFiltered(filteredChoices: Choice[]) {
        this.filteredChoices = filteredChoices;
        this.listChoices = [];
        this.appendToListChoices();
    }

    public choose(choice: Choice, newAddition?: boolean): void {
        if (this.disabled) {
            return;
        }

        if (!!newAddition) {
            const current: UntypedFormArray = this.questionForm.get('current') as UntypedFormArray;
            current.push(this.fb.control(true));
        } else {
            const choiceIndex = this.question.choices.indexOf(choice);

            if (choiceIndex !== -1) {
                const control = this.getFormControl(choiceIndex);
                control.setValue(!control.value);
            }
        }

        this.setSelectedChoices();
        this.valueChange.emit(this.selectedChoiceTitles);
    }

    protected createForm(fb: UntypedFormBuilder): void {
        const controls = [];

        this.question.choices.forEach((choice) => {
            controls.push(fb.control(this.currentValue.indexOf(choice.title) !== -1));
        });
        this.questionForm = fb.group({
            current: fb.array(controls, this.validateArray),
        });

        this.setSelectedChoices();
    }

    validateArray(current: UntypedFormArray) {
        let valid = false;

        current.controls.forEach((control: UntypedFormControl) => {
            valid = valid || control.value;
        });

        return valid ? null : {validateArray: {valid}};
    }

    setSelectedChoices(): void {
        const current: UntypedFormArray = this.questionForm.get('current') as UntypedFormArray;

        this.selectedChoiceTitles = current.value.map((isSelected, index) => isSelected ? this.question.choices[index].title : null).filter(element => element);
        this.selectedChoices = this.question.choices.filter((choice) => this.selectedChoiceTitles.indexOf(choice.title) >= 0);
    }

    isChoiceSelected(choice: Choice): boolean {
        return this.questionForm.get('current').value === choice.title;
    }

    appendToListChoices(event?): void {
        this.listChoices = this.filteredChoices.slice(0, Math.min(SearchableSettings.MAX_ITEM_RESULT, this.listChoices.length + SearchableSettings.INFINITE_SCROLL_ITEM_INCREMENT));
        if (event) {
            event.target.complete();
        }
    }

    scrollingToBottom() {
        // Add items to the list before scrolling down
        this.listChoices = this.filteredChoices.slice(0, SearchableSettings.MAX_ITEM_RESULT);
    }

    getFormControl(i): UntypedFormControl {
        return ((this.questionForm.get('current') as any).controls as UntypedFormArray)[i];
    }

}
