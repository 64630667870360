<ion-content class="has-header has-footer">
    <div class="card card--no-header">
        <ion-icon src="assets/images/icon-disclaimer.svg" class="card__icon card__icon--danger card__icon--modal"></ion-icon>
        <div class="card__header">
            <h2 class="card__title">
                {{ 'DISCLAIMER.heading' | translate }}
            </h2>
            <h3>{{ 'DISCLAIMER.title' | translate }}</h3>
            <p class="card__description">{{ 'DISCLAIMER.content' | translate }}</p>
        </div>
        <div class="card__btns card__btns--space">
            <ion-button expand="block" shape="round" color="primary" class="card__btn" (click)="next()">
                {{ 'DISCLAIMER.buttons.agree' | translate }}
            </ion-button>
            <ion-button expand="block" shape="round" fill="outline" class="card__btn card__btn--outline" (click)="navigateBack()">
                {{ 'SEND_QUESTIONNAIRE.cancel' | translate }}
            </ion-button>
        </div>
    </div>
</ion-content>
