import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LoadingController} from '@ionic/angular';
import {PasswordChange} from '../../models/password-change';
import {ExternalUserService} from '../../services/external-user-service';
import {ToastService} from '../../services/toast-service';
import {AuthService} from '../../services/auth-service';
import {EventService} from '../../services/event-service';
import {ValidatorUtils} from '../../utils/validator-utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'page-password-change-form',
    templateUrl: 'password-change-form.html',
})
export class PasswordChangeFormPage {
    public form: UntypedFormGroup;

    constructor(
        fb: UntypedFormBuilder,
        private externalUserService: ExternalUserService,
        private loadingCtrl: LoadingController,
        private toast: ToastService,
        private authService: AuthService,
        private events: EventService,
        private translateService: TranslateService,
    ) {
        this.createForm(fb);
    }

    // noinspection JSMethodCanBeStatic
    async submit() {
        if (!this.form.valid) {
            return;
        }

        const loader = await this.loadingCtrl.create();
        await loader.present();
        try {
            await this.externalUserService.postPasswordChange(this.getSanitizedFormData());
            await loader.dismiss();

            await this.authService.logout();
            this.toast.showCustomMessages(this.translateService.instant('PASSWORD_CHANGE.password_changed'));

            this.events.publish('auth:logout');
        } catch (exception) {
            await loader.dismiss();
            this.toast.showCustomMessages(this.translateService.instant('PASSWORD_CHANGE.current_password_incorrect'));
        }
    }

    private getSanitizedFormData(): PasswordChange {
        return {
            currentPassword: this.form.get('currentPassword').value,
            newPassword: this.form.get('newPassword').get('plainPassword').value,
            newPasswordRepeat: this.form.get('newPassword').get('plainPasswordRepeat').value,
        };
    }

    private createForm(fb: UntypedFormBuilder) {
        this.form = fb.group({
            currentPassword: fb.control('', [Validators.required]),
            newPassword: fb.group({
                plainPassword: ['', [ValidatorUtils.PasswordValidator, ValidatorUtils.PasswordLengthValidator]],
                plainPasswordRepeat: ['', [ValidatorUtils.PasswordValidator, ValidatorUtils.PasswordLengthValidator, Validators.required]],
            }, {validator: PasswordChangeFormPage.passwordsEqual}),
        });
    }

    private static passwordsEqual(group: UntypedFormGroup) {
        if (group.get('plainPassword').value === group.get('plainPasswordRepeat').value) {
            return null;
        } else {
            return {
                passwordsEqual: {valid: false},
            };
        }
    }

    showPasswordRepeatError(): boolean {
        let result = false;

        if (this.form !== undefined) {
            const passwordsGroup = this.form.get('newPassword') as UntypedFormGroup;
            const repeatedControl = passwordsGroup.get('plainPasswordRepeat') as UntypedFormControl;

            result = (repeatedControl.touched || repeatedControl.dirty) && PasswordChangeFormPage.passwordsEqual(passwordsGroup) != null;
        }

        return result;
    }
}
