import {Component, Input} from '@angular/core';
import {CardGameService} from '../../services/card-game-service';
import {ICardGameItem} from '../../models/card-game';

@Component({
    selector: 'page-card-game',
    templateUrl: 'card-game.html',
})
export class CardGamePage {
    @Input() public card: ICardGameItem;

    constructor(
        private cartGameService: CardGameService,
    ) {
    }

    public cardGameItems: ICardGameItem[];

    public dismiss() {
        this.cartGameService.dismissAllModals();
    }

    public drawNewCard() {
        this.cartGameService.addBottomCard();
    }

}
