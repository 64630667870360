<form [formGroup]="questionForm" novalidate>
    <app-manual-choice-addition
        *ngIf="manualAdditionsAllowed"
        [abstractChoiceQuestion]="this"
    ></app-manual-choice-addition>

    <div class="choice-container" *ngIf="(question?.choices || []).length > 0 || manualAdditionsAllowed === false">
        <p class="card__description">{{ 'COMPONENTS.checkbox.multiple_choices_possible' | translate }}</p>
        <div *ngFor="let choice of question.choices; let i = index" [class.choice]="true" [class.checked]="getFormArray(questionForm.get('current')).controls[i].value" (click)="choose(choice)">
            <span class="choice-description">
                {{ choice | contentTranslate:'title' }}
                <div *ngIf="choice | contentTranslate:'subtitle'" class="choice-subtitle">
                    {{ choice | contentTranslate:'subtitle' }}
                </div>
            </span>
        </div>
        <div *ngIf="question.choices.length === 0">{{ 'COMPONENTS.checkbox.no_choices_selected' | translate }}</div>
    </div>
</form>
