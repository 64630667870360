import {Camera, CameraResultType, CameraSource, Photo} from '@capacitor/camera';
import {Injectable} from '@angular/core';
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {Capacitor} from '@capacitor/core';
import {defer, Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CameraService {

    constructor() {
        if (Capacitor.getPlatform() === 'web') {
            defineCustomElements(window);

            document.addEventListener('paste', async (evt: ClipboardEvent) => {
                const clipboardItems = evt.clipboardData.items;
                const items = [].slice.call(clipboardItems).filter((clipBoardItem) => {
                    return clipBoardItem.type.indexOf('image') !== -1;
                });

                if (items.length > 0) {
                    const item = items[0];
                    const photo = await this.fromFileUpload(item.getAsFile());

                    if (photo) {
                        this.pasteEvent$.next(photo);
                    }
                }
            });
        }
    }

    public pasteEvent$ = new Subject<Photo>();

    public async takePicture() {
        return await Camera.getPhoto({
            quality: 50,
            source: CameraSource.Camera,
            width: 1920,
            height: 1920,
            correctOrientation: true,
            resultType: CameraResultType.Base64,
        });
    }

    public generatePasteObservable(): Observable<Photo> {
        return defer(() => this.pasteEvent$.asObservable());
    }

    public async fromLibrary() {
        //need to use getPhoto() instead of pickImages() to have a base64String
        return await Camera.getPhoto({
            quality: 50,
            source: CameraSource.Photos,
            width: 1920,
            height: 1920,
            correctOrientation: true,
            resultType: CameraResultType.Base64,
        });
    }

    public isWeb() {
        return Capacitor.getPlatform() === 'web';
    }

    public async fromFileUpload(file: File) {
        return await new Promise<Photo>(async (resolve, reject) => {
            let format = '';
            if (file.type === 'image/jpeg') {
                format = 'jpeg';
            } else if (file.type === 'image/png') {
                format = 'png';
            } else if (file.type === 'image/gif') {
                format = 'gif';
            } else {
                reject('invalid_image_type');
                return;
            }

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const b64 = (reader.result as string).split(',')[1];
                resolve({
                    base64String: b64,
                    format,
                } as Photo);
            });

            reader.readAsDataURL(file);
        });
    }
}
