import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AbstractChoiceQuestionComponent} from '../classes/abstract-choice-question.component';

export class ValidatorUtils {
    static PasswordValidator = Validators.pattern(/(?=(.*\d))(?=.*[A-Z])(?=.*[a-z])(?=.*[@%_;:~`!=\$\\\/\'\"#\^\&\*\(\)\[\]\{\}\,\.\+\-\?\|\<\>])/);
    static PasswordLengthValidator = Validators.minLength(8);

    static nonDuplicateChoiceTitle = (abstractChoiceQuestion: AbstractChoiceQuestionComponent): ValidatorFn => {
        return (choiceTitleControl: AbstractControl): ValidationErrors | null => {
            const titleValueToCheck = choiceTitleControl.value.trim();
            const duplicateTitle = !!abstractChoiceQuestion.question.choices.find((choice) => choice.title.trim() === titleValueToCheck);

            return duplicateTitle ? {duplicateTitle: {value: choiceTitleControl.value}} : null;
        };
    };
}
