export const TRANSLATION_PL = {
    DATE: {
        long: 'DD MMMM YYYY [-] HH:mm',
        date: 'DD-MM-YYYY',
    },
    LOGIN: {
        title: 'Witaj!',
        slogan: 'Zachowaj czujność! Bezpieczeństwo jest najważniejsze!',
        buttons: {
            external: 'Jestem pracownikiem zewnętrznym',
            internal: 'Jestem pracownikiem VolkerWessels',
        },
    },
    BUTTONS: {
        remove: 'Usuń',
        ok: 'OK',
        yes: 'Tak',
        yes_and_send: 'Tak, wyślij',
        no: 'Nie',
        cancel: 'Anuluj',
        again: 'Ponów',
        back: 'Wstecz',
        submit: 'Wyślij',
        skip: 'Pomiń',
        next: 'Dalej',
    },
    DASHBOARD: {
        tile: {
            home: 'Strona główna',
            forms: 'Formularze',
            compliment: 'Zgłoszenie do wyróżnienia',
            compliment_desc: 'Chcę pogratulować koledze za bezpieczną pracę.',
            scan_qr: 'Zeskanuj kod QR',
            scan_qr_desc: 'Chcę kod QR, abym mógł przejść do odpowiedniego formularza.',
            instruction: 'Program WAVE',
            more: 'Więcej',
            title: 'Cześć, co zamierzasz zrobić?',
            subtitle: 'Proszę dokonać wyboru poniżej',
        },
        report: {
            incident: 'Zgłoś incydent lub sytuację MOHS',
            incident_desc: 'Chcę zgłosić incydent, niebezpieczną praktykę, środowisko i/lub eksplozję lub pożar.',
        },
        tasks: {
            notification: {
                singular: 'You have <b>{{value}} open</b> task',
                plural: 'You have <b>{{value}} open</b> tasks',
            },
        },
        deprecation: {
            title: 'Uwaga',
            text: 'Od 1 września będziesz musiał korzystać z aplikacji ETT WAVE. Kliknij tutaj, aby ją pobrać.',
        },
    },
    DASHBOARD_MORE: {
        tile: {
            history: 'Historia',
            news: 'Wiadomości',
            cardGame: 'Akcelerator WAVE',
            tasks: 'Zadania',
        },
        tasks: {
            notification: {
                singular: '{{value}} open task',
                plural: '{{value}} open tasks',
            },
        },
    },
    HEADER: {
        structure: 'New form',
    },
    PUSH: {
        news: {
            title: 'Uzyskaj wiadomości',
        },
        task: {
            title: 'Przydzielone zadanie',
        },
        instruction: {
            title: 'Uzyskaj program WAVE',
        },
    },
    DISCLAIMER: {
        heading: 'ZASTRZEŻENIE PRAWNE',
        title: 'Ważne:',
        content: 'Proszę nie podawać żadnych danych osobowych (imion, nazwisk, numerów telefonu i numerów tablic rejestracyjnych), o których podanie nie prosiliśmy. ' +
            'W razie konieczności wgrania zdjęcia należy się upewnić, że nie można rozpoznać znajdujących się na nim osób.',
        buttons: {
            agree: 'Tak, zapoznałem/-am się z zastrzeżeniem prawnym',
        },
    },
    HELP: {
        title: 'Centrum pomocy',
        app_version: 'Wersja aplikacji:',
        intro: 'Poniżej znajdują się najczęściej zadawane pytania na temat aplikacji WAVE. Jeśli nie ma wśród nich odpowiedzi na Twoje pytanie, prosimy o kontakt z działem pomocy IT w Twojej firmie.',
        phone_number: 'Numer telefonu',
        tenant_phone_numbers: {
            'be36ab0a-ee39-47de-9356-a8a501a9c832]': '088 186 0606]', // VWT
            '0ef544fb-3d5c-4c60-932e-735bcef00405]': '088 186 7777]', // BVGO
            'cdc477bf-b6e3-4345-b1be-3b225394e17e': '088 186 2555]', // VW Infra
            '[957801e8-a608-42e6-afc7-a2e6399ec203]': '085 800 1221]', // V&SH
        },
        buttons: {
            privacy: 'Oświadczenie o ochronie prywatności',
            instruction_film: 'Film instruktażowy',
        },
    },
    CONTACT: {
        title: 'Dane kontaktowe',
        subtitle: 'See your account information, log out or change the app language.',
        email_address: 'Adres e-mail',
        phone_number: 'Numer telefonu',
        fill_phone_number: 'Wpisz swój numer telefonu',
        language_selection: 'Wybierz język',
        save: 'Zapisz zmiany',
        logout: 'Wyloguj',
        change_password: 'Zmień hasło',
        remove_account: 'Usuń konto',
        confirm_external_modal: {
            heading: 'Usuń konto',
            body: 'Czy na pewno chcesz usunąć to konto?',
            button: 'Tak, usuń konto',
        },
        removing_account: 'Usuwanie konta',
        account_removed: 'Twoje konto zostało usunięte.',
    },
    DEEPLINK_QUESTIONNAIRE: {
        title: 'Otwórz formularz',
        unfortunately: 'Niestety... ',
        ok_and_close: 'OK, zamknij stronę',
    },
    EXTERNAL_LOGIN: {
        title: 'Zewnętrzne konto',
        email: 'E-mail',
        password: 'Hasło (min. 8 znaków)',
        login: 'Zaloguj',
        forgot_password: 'Nie pamiętam hasła',
        create_account: 'Utwórz konto',
    },
    FAQ: {
        title: 'Najczęściej zadawane pytania',
        sections: [{
            title: '',
            questions: [{
                question: 'Do kogo mogę się zwrócić z pytaniami na temat formularzy?',
                answer: 'W razie pytań na temat treści formularzy dostępnych w aplikacji można się skontaktować z administratorem funkcyjnym (zwykle będzie to kolega/koleżanka ds. QHSE (jakości, zdrowia, bezpieczeństwa i środowiska) lub specjalista ds. bezpieczeństwa) w swojej firmie.',
                open: false,
            }, {
                question: 'Co mam zrobić, jeśli nie potrafię odpowiedzieć na każde pytanie?',
                answer: 'Możesz oczywiście nie znać odpowiedzi na wszystkie pytania. Udziel tylko takich odpowiedzi, których jesteś pewny/-a. W przeciwnym wypadku zaznacz, że nie znasz odpowiedzi.',
                open: false,
            }, {
                question: 'Do kogo mogę się zwrócić z technicznymi pytaniami na temat aplikacji?',
                answer: 'W razie technicznych pytań na temat aplikacji możesz skontaktować się z działem pomocy IT w swojej firmie. Numer telefonu jest podany u góry tej strony.',
                open: false,
            }, {
                question: 'Co oznacza liczba w pomarańczowym kółku przy „Formularzach”?',
                answer: 'Na Twoim pulpicie pojawia się taka liczba, jeśli jeden lub więcej formularzy nie zostało wypełnionych w całości i/lub wysłanych. Liczba ta oznacza liczbę takich formularzy. ' +
                    'Formularze, które jeszcze nie zostały wypełnione w całości i/lub wysłane, znajdują się u dołu strony z formularzami. Możesz je wypełnić i wysłać po kliknięciu w nie. Możesz również usunąć formularze, które jeszcze nie zostały wypełnione w całości i/lub wysłane.',
                open: false,
            }, {
                question: 'Czy mogę dokonać zmian w już wysłanym zgłoszeniu?',
                answer: 'Nie, niestety nie jest to możliwe. Natomiast możesz się zawsze skontaktować z administratorem funkcyjnym (zwykle będzie to kolega/koleżanka ds. QHSE lub specjalista ds. bezpieczeństwa) w firmie i przekazać mu wszelkie zmiany.',
                open: false,
            }, {
                question: 'Co się dzieje ze zgłoszeniem po jego wysłaniu?',
                answer: 'Zgłoszenia są automatycznie przesyłane do osoby odpowiedzialnej za dany projekt. Kim będzie odbiorca będzie zależało od konkretnego formularza. ' +
                    'Administrator funkcyjny (zwykle będzie to kolega/koleżanka ds. QHSE lub specjalista ds. bezpieczeństwa) otrzymuje kopię formularza. Ty również otrzymasz kopię formularza na swój adres e-mail.',
                open: false,
            }, {
                question: 'Czy mogę usunąć zgłoszenie?',
                answer: 'Tak. Jednak nie jest to możliwe z poziomu aplikacji. W celu usunięcia zgłoszenia należy się skontaktować z administratorem funkcyjnym (zwykle będzie to kolega/koleżanka ds. QHSE lub specjalista ds. bezpieczeństwa) w firmie.',
                open: false,
            }, {
                question: 'Czy mogę pracować w aplikacji w trybie offline?',
                answer: 'Tak. Możesz w każdej chwili wypełnić formularz i zapisać go w aplikacji. Jeśli formularz nie został wypełniony w całości, dostaniesz wiadomość, że nie można go wysłać. Formularz zostanie zapisany jako kopia robocza. ' +
                    'Kopia robocza nie zostanie automatycznie wysłana po połączeniu się z internetem. Należy otworzy kopię roboczą i wtedy ją wysłać. ' +
                    'Niestety nie wszystkie formularze są dostępne do wypełnienia przez zewnętrznych użytkowników w trybie offline. W trybie offline mogą oni jedynie zgłosić niebezpieczne sytuacje. ' +
                    'W przypadku innych formularzy muszą pracować w trybie online.',
                open: false,
            }, {
                question: 'Czy podwykonawca może używać tej aplikacji?',
                answer: 'Tak. Podwykonawca może się zalogować jako zewnętrzny użytkownik.',
                open: false,
            }, {
                question: 'Czy mogę zmienić numer telefonu?',
                answer: 'Tak. Wszyscy pracownicy VolkerWessels mogą zmienić swój numer telefonu. Można to zrobić w swoim profilu w aplikacji.',
                open: false,
            }],
        }],
    },
    NOTIFICATION: {
        title: 'Zgłoś',
        warning: 'Ostrzeżenie',
        close: 'Zamknij',
        open: 'Otwórz',
        error_general: 'Wystąpił nieoczekiwany błąd',
        error_retrieving_profile: 'Coś poszło nie tak podczas uzyskiwania dostępu do profilu',
        something_wrong_saving: 'Coś poszło nie tak podczas wysyłania formularza',
        something_wrong_task: 'Coś poszło nie tak podczas wysyłania zadania. Wypełnij sekcję uwag lub spróbuj ponownie później.',
        something_wrong_logout: 'Coś poszło nie tak podczas wylogowywania.',
        offline: 'Pracujesz w trybie offline. Dlatego ta funkcja jest obecnie niedostępna.',
        account_removed_failed: 'Coś poszło nie tak podczas usuwania konta. Spróbuj ponownie później.',
        inactive_form: 'Ten formularz jest już nieaktualny. Wypełnij go ponownie.',
        no_access: 'Nie masz dostępu do WAVE. Skontaktuj się z administratorem',
        error_message: 'Komunikat o błędzie:',
        login_failed: 'Logowanie nie powiodło się',
        error_logged_out: 'Niestety coś poszło nie tak podczas uzyskiwania dostępu do danych. Nastąpi wylogowanie',
        retrieving_options: 'Opcje/kontakt w sprawie uzyskiwania dostępu',
        aggregating: 'Dane zbiorcze',
        update_failed: 'Edytowanie nie powiodło się',
        retrieval_failed: 'Uzyskanie dostępu nie powiodło się',
        error_forms_no_network: 'Nie można edytować formularzy (brak połączenia z internetem). Czy chcesz spróbować ponownie edytować formularze?',
        error_forms_slow_network: 'Nie można edytować formularzy (mała prędkość połączenia internetowego). Czy chcesz spróbować ponownie edytować formularze?',
        error_forms_retrieval_no_network: 'Nie można uzyskać dostępu do formularzy (brak połączenia z internetem). Sprawdź połączenie z internetem i spróbuj ponownie.',
        error_forms_retrieval_slow_network: 'Nie można uzyskać dostępu do formularzy (mała prędkość połączenia internetowego). Sprawdź połączenie z internetem i spróbuj ponownie.',
        error_popup_blocked: 'Zezwól na wyskakujące okienka, aby się zalogować',
        image_file_needed: 'Plik nie jest prawidłowym obrazem',
        route_not_found: 'Przekierowanie nie powiodło się',
        route_not_found_explanation: 'Przekierowanie do strony lub formularza nie powiodło się. Przyczyną może być to, że jest dla Ciebie niedostępny, strona lub formularz już nie istnieje lub formularz nie jest dostępny w Twoim języku',
    },
    FORGOT_PASSWORD: {
        title: 'Nie pamiętam hasła',
        subtitle: 'Jeśli podałeś/-aś adres e-mail, otrzymasz wiadomość e-mail z instrukcjami dotyczącymi zmiany hasła.',
    },
    FORGOT_PASSWORD_FORM: {
        title: 'Podaj adres e-mail',
        email: 'E-mail',
        next: 'Dalej',
    },
    NEWS: {
        title: 'Wiadomości',
        subtitle: 'WAVE nieuws berichten',
        search: 'Search by news',
    },
    MEDIA_COMMUNICATION: {
        subtitle: {
            theme: 'Choose a theme',
            subject: 'Choose a topic',
        },
        search: {
            theme: 'Search by theme',
            subject: 'Search by topic',
        },
    },
    SEARCH_PLACEHOLDER: 'Szukaj',
    PASSWORD_CHANGE: {
        title: 'Zmień hasło',
        current_password: 'Aktualne hasło',
        password_explanation: 'Hasło musi zawierać co najmniej 8 znaków, w tym dużą literę, cyfrę i znak specjalny',
        new_password: 'Nowe hasło',
        repeat_new_password: 'Powtórz nowe hasło',
        passwords_dont_match: 'Hasła są niezgodne',
        password_changed: 'Twoje hasło zostało zmienione. Zaloguj się ponownie.',
        current_password_incorrect: 'Wpisane hasło jest niezgodne z Twoim aktualnym hasłem',
        confirm: 'Potwierdź',
    },
    QUESTIONNAIRE: {
        part: 'Element',
        map_offline: 'Pracujesz w trybie offline. Ta karta jest dostępna tylko w trybie online.',
        from: 'from',
    },
    QUESTIONNAIRE_HISTORY: {
        title: 'Wysłane formularze',
        no_forms: 'Nie wysłano jeszcze żadnych zgłoszeń.',
        submitted_at: 'Wysłano dnia',
        processed_at: 'Rozpatrzono dnia',
        processed_submitted_at: 'Rozpatrzono, wysłano dnia',
    },
    QUESTIONNAIRE_REPEAT_QUESTION: {
        part: 'Element',
    },
    QUESTIONNAIRE_SENT: {
        title: 'Dziękujemy',
        subtitle: 'Formularz jest przetwarzany. Kopia formularza zostanie przesłana na Twój adres e-mail. Wypełniony formularz znajduje się również w sekcji Historia w tej aplikacji.',
    },
    QUESTIONNAIRE_TASK: {
        created_on: 'Utworzono dnia:',
        completed_on: 'Ukończono dnia:',
        place_remark: 'Miejsce na Twoje uwagi',
        remark: 'Uwagi:',
        task_denied: 'Nie rozpatrzono',
        task_completed: 'Rozpatrzono',
        are_you_sure:  'Czy na pewno chcesz utworzyć "{{statusLabel}}" to zadanie?',
        page_leave: 'Opuść stronę',
        page_leave_explanation: 'Wypełniłeś/-aś sekcję „uwag” dla tego zadania. Jeśli opuścisz tę stronę, ta zmiana nie zostanie zachowana. Czy na pewno chcesz opuścić tę stronę?',
    },
    QUESTIONNAIRE_TASKS: {
        title: 'Zadania w trakcie realizacji',
        subtitle: 'Click on a task to execute it',
        planned_forms: 'Zaplanowane formularze',
        planned_forms_subtitle: 'Below are forms that must be sent by a certain date',
        closed_tasks: 'Ukończone zadania',
        closed_tasks_subtitle: 'Below are tasks which have been completed',
        closed_on: 'Ukończono dnia:',
        deadline_passed: 'Termin minął:',
        deadline_at: 'Termin minął dnia:',
        created_at: 'Utworzono dnia:',
    },
    CARD_GAME: {
        question: {
            label: 'Pytanie',
        },
        toolbar: {
            button_label: 'Nowa karta',
            close: 'Zamknij',
        },
    },
    ERROR: {
        form: {
            warning: 'Ostrzeżenie',
            not_found: 'Żadne formularze nie są jeszcze dostępne w Twoim języku.',
            directIncidentOnly: 'Formularz dla „Zgłoszenia incydentu lub sytuacji MOHS” jest obecnie niedostępny. Proszę skontaktować się ze swoim administratorem systemu.',
            directComplimentOnly: 'Ten formularz nie jest dostępny w Twoim języku.',
            questionnaireIdToSelect: 'To zadanie jest obecnie niedostępne dla wybranej jednostki organizacyjnej. Proszę wybrać inną jednostkę organizacyjną lub skontaktować się ze swoim administratorem systemu.',
        },
        no_access: {
            title: 'Brak praw',
            explanation: 'Nie masz dostępu do tych formularzy. Wróć do ekranu głównego.',
        },
    },
    REGISTRATION: {
        title: 'Utwórz nowe konto',
        email: 'E-mail',
        first_name: 'Imię',
        sur_name: 'Nazwisko',
        mobile_phone: 'Numer telefonu kom.',
        password: 'Hasło',
        password_explanation: 'Hasło musi zawierać co najmniej 8 znaków, w tym dużą literę, cyfrę i znak specjalny',
        repeat_password: 'Powtórz hasło',
        passwords_dont_match: 'Hasła są niezgodne',
        approve: 'Wyrażam zgodę na',
        privacy: 'Polityka prywatności',
        create_account: 'Utwórz konto',
        account_exists: 'Ten adres e-mail jest już przypisany do innego konta',
        error_something_wrong: 'Coś poszło nie tak podczas rejestracji.',
    },
    REGISTRATION_COMPLETE: {
        title: 'Potwierdź swój adres e-mail',
        subtitle: 'Dziękujemy za utworzenie konta. Za chwilę otrzymasz wiadomość e-mail z prośbą o potwierdzenie adresu e-mail',
    },
    REGISTRATION_SMS: {
        title: 'Weryfikacja numeru telefonu',
        subtitle: 'Tutaj wpisz kod weryfikacyjny otrzymany w wiadomości SMS. Ten kod zostanie wysłany na podany numer telefonu.',
        verification_code: 'Kod weryfikacyjny',
        verify: 'Sprawdź kod weryfikacyjny',
        incorrect_code: 'Nieprawidłowy kod weryfikacyjny',
        error_something_wrong: 'Coś poszło nie tak podczas weryfikacji.',
    },
    SELECT_QUESTIONNAIRE: {
        open_forms: 'Niewypełnione formularze',
        open_forms_subtitle: 'Click on a form to continue',
        new_form: 'New form',
        new_form_subtitle: 'Click to start a new form',
        planned_forms: 'Zaplanowane formularze',
        available_forms: 'Dostępne formularze',
        workplace_forms: 'Kontrola miejsca pracy',
        retrieve_forms: 'Uzyskaj dostęp do formularzy',
        retrieve_form: 'Uzyskaj dostęp do formularza',
        choose_action: 'Wybierz działanie',
        open: 'Otwórz',
        upload: 'Umieść w Internecie',
        download_and_open: 'Pobierz i otwórz formularz',
        remove: 'Usuń',
        something_went_wrong: 'Coś poszło nie tak podczas uzyskiwania dostępu do formularzy.',
    },
    SELECT_STRUCTURE: {
        choose_project: 'Wybierz swój projekt',
        choose_business_unit: 'Wybierz swoją jednostkę biznesową',
        no_organisations: 'Brak dostępnych organizacji.',
        search_on: 'Szukaj według',
        choose_your: 'Select your',
        organisation: 'firma',
        project: 'projektu',
        projects: 'Projekty',
        business_unit: 'jednostki biznesowej',
        business_units: 'Jednostki biznesowe',
    },
    SEND_QUESTIONNAIRE: {
        title: 'Czy chcesz wysłać formularz?',
        description: 'Wysyłając go, nie możesz już nic zmieniać w przesłanym formularzu.',
        account_blocked: 'Nie można wysłać tego formularza do czasu zatwierdzenia konta',
        offline: 'Nie możesz wysłać formularza, bo pracujesz w trybie offline. Wyślij formularz, jak tylko uzyskasz dostęp do internetu.',
        something_went_wrong: 'Coś poszło nie tak podczas wysyłania formularza',
        confirm: 'Tak Wyślij',
        cancel: 'Nie ma powrotu',
    },
    SCAN_QR: {
        incorrect_result: 'Nieprawidłowy kod QR. Zeskanuj prawidłowy kod QR WAVE.',
        no_result: 'Nie zeskanowano kodu QR.',
        no_permission: 'Aplikacja nie może uzyskać dostępu do aparatu. Zezwól aplikacji przed zeskanowaniem kodu QR.',
        try_again: 'Przeskanuj ponownie',
    },
    COMPONENTS: {
        attachments_remarks: {
            add: 'Dodaj',
            place_remark: 'Miejsce na Twoje uwagi',
            max_attachments_reached: 'Osiągnięto limit załączników.',
            note: 'Note',
            picture: 'Picture',
        },
        checkbox: {
            multiple_choices_possible: 'Można zaznaczyć kilka opcji',
            no_choices_selected: 'Nie zaznaczono żadnej opcji.',
        },
        checkbox_searchable: {
            selected_choices: 'Wybrana/-e opcja/-e',
            no_choices_found: 'Nie znaleziono żadnych opcji.',
            visible_choices: 'Wyświetlono {{maxListChoices}} z {{filteredChoices}} opcji.',
            limit_choices: 'Wyszukaj, żeby skrócić listę opcji.',
        },
        lat_long: {
            current_location: 'Użyj obecnej lokalizacji',
            or: 'lub',
            city: 'Miasto/miejscowość',
            address: 'Adres',
            invalid_address: 'Taki adres nie istnieje.',
        },
        manual_choice: {
            new_choice: 'Wpisz nową opcję',
            choice_exists: 'Ta opcja jest już podana.',
        },
        photo: {
            subtitle: 'Dodaj zdjęcia klikając na znak plus',
            gallery: 'Galeria zdjęć',
            drop_explanation: 'Kliknij, przeciągnij lub wklej zdjęcia tutaj, aby je dodać',
            max_reached: 'Osiągnięto limit zdjęć do załączenia.',
            take_a_photo: 'Robić zdjęcie',
            upload_from_gallery: 'Prześlij z galerii',
        },
        questionnaire_footer: {
            chapters: 'Rozdziały',
            previous: 'Poprzedni',
        },
        questionnaire_overlay: {
            help_text: 'Po udzieleniu odpowiedzi na pytanie kliknij „Dalej”, żeby przejść do następnego pytania.',
        },
        radio: {
            choose: 'Wybierz',
            no_choice_selected: 'Nie wybrano żadnej opcji.',
        },
        radio_searchable: {
            selected_choice: 'Wybrana opcja',
            no_choice_found: 'Nie znaleziono żadnych opcji.',
            visible_choices: 'Wyświetlono {{maxListChoices}} z {{filteredChoices}} opcji.',
            limit_choices: 'Wyszukaj, żeby skrócić listę opcji.',
        },
        searchable_choices: {
            search: 'Szukaj',
        },
        select_concept: {
            last_change: 'Ostatnia zmiana:',
        },
        select_item: {
            submit_before: 'Prześlij przed',
        },
    },
};
