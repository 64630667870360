import {Component, Input, OnInit} from '@angular/core';
import {NavController} from '@ionic/angular';
import {ConceptQuestionnaireService} from '../../../services/concept-questionnaire.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-generic-header',
    templateUrl: './generic-header.html',
    styleUrls: ['./generic-header.scss'],
})
export class GenericHeaderComponent implements OnInit {
    @Input() public color = 'dark';
    @Input() public showChapterButton = false;
    @Input() public showOrganizationName = true;
    selectedOrganizationName$: BehaviorSubject<string>;

    constructor(private navCtrl: NavController,
                private conceptQuestionnaireService: ConceptQuestionnaireService) {
    }

    ngOnInit(): void {
        this.selectedOrganizationName$ = this.conceptQuestionnaireService.selectedOrganisationName$;
    }

    public async navigateBack(): Promise<void> {
        await this.navCtrl.pop();
    }
}
