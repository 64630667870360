import {Injectable} from '@angular/core';
import {ProgressiveApiService, ProgressiveStrategy} from './progressive-api-service';
import {Questionnaire, QuestionnaireListItem} from '../models/questionnaire';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable()
export class HistoryService {

    constructor(
        private progressiveApiService: ProgressiveApiService,
    ) {
    }

    /**
     * Return a list view of the history data
     * Uses the fallback strategy as we want to check for new items from the server first
     *
     * @returns
     */
    public getQuestionnaireHistory(): Observable<QuestionnaireListItem[]> {
        return this.progressiveApiService.authenticatedGet('/history', ProgressiveStrategy.FALLBACK);
    }

    /**
     * Returns all the data of a filled in questionnaire
     *
     * @returns
     */
    public getQuestionnaireHistoryItem(id): Promise<Questionnaire> {
        return this.progressiveApiService.authenticatedGet<Questionnaire>('/history/' + id, ProgressiveStrategy.PROGRESSIVE)
            .pipe(take(1))
            .toPromise();
    }

}
