import {Injectable} from '@angular/core';
import {LoadingController, Platform} from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {

    private loading: HTMLIonLoadingElement;
    private isLoading = false;
    private delayedStopTimeout: number | undefined;

    constructor(
        private loadingCtrl: LoadingController,
        private platform: Platform,
    ) {
    }

    /**
     * Starts the loading spinner
     *
     * @returns
     */
    public async start(message?: string): Promise<any> {
        if (this.delayedStopTimeout) {
            clearTimeout(this.delayedStopTimeout);
            this.delayedStopTimeout = undefined;
        }

        if (!this.isLoading) {
            try {
                this.isLoading = true;

                if (this.loading) {
                    await this.loading.dismiss();
                }
                this.loading = await this.loadingCtrl.create({
                    mode: this.platform.is('ios') ? 'ios' : 'md',
                    message,
                });

                await this.loading.present();
            } catch (err) {
                this.isLoading = false;
            }
        }

        return Promise.resolve();
    }

    public async setMessage(message?: string): Promise<any> {
        if (this.isLoading && this.loading) {
            this.loading.message = message;
        }

        return Promise.resolve();
    }

    /**
     * Stops the loading spinner
     *
     * @returns
     */
    public stop(): Promise<any> {
        if (this.loading) {
            return this.loading.dismiss()
                .then(() => {
                    this.isLoading = false;
                    this.loading = undefined;
                });
        } else {
            this.isLoading = false;
            return Promise.resolve();
        }
    }

    /**
     * Stops the loading spinner after a delay, if loader is re-started in the meantime that will cancel the delayed stop
     */
    public delayedStop(delay: number = 100) {
        if (this.delayedStopTimeout) {
            clearTimeout(this.delayedStopTimeout);
        }

        this.delayedStopTimeout = window.setTimeout(() => {
            this.stop();
        }, delay);
    }
}
