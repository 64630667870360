<div class="card-game-card">
    <ion-header>
        <h2>
            {{ card.category }}
        </h2>
    </ion-header>

    <ion-content>
        <div class="card-game-card__content">
            <p class="card-game-card__label">{{ 'CARD_GAME.question.label' | translate }}</p>
            <p class="card-game-card__question">{{ card.statement }}</p>
            <p class="card-game-card__question">{{ card.question }}</p>
        </div>

        <div class="card__btns">
            <ion-button fill="outline" expand="block" shape="round" class="card__btn" slot="start" (click)="dismiss()">{{ 'CARD_GAME.toolbar.close' | translate }}</ion-button>
            <ion-button color="primary" expand="block" shape="round" class="card__btn" slot="end" (click)="drawNewCard()">{{ 'CARD_GAME.toolbar.button_label' | translate }}</ion-button>
        </div>
    </ion-content>
</div>

