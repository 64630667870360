import {Questionnaire} from '../models/questionnaire';
import {Concept} from '../models/concept';
import {QuestionnaireReminder} from '../models/questionnaire-reminder';
import {OrganisationStructureParams} from '../models/organisation-params';
import {QuestionnaireUtils} from './questionnaire-utils';

export class QuestionnairesFilter {
    /**
     * Filters the questionnaires list based on the ID params
     *
     * @param questionnaires
     * @param organisationStructureParams
     * @param directIncidentForm
     * @param directComplimentForm
     * @param questionnaireIdToSelect
     * @returns
     */
    public static filterQuestionnaires(questionnaires: Questionnaire[], organisationStructureParams: OrganisationStructureParams, directIncidentForm = false, directComplimentForm = false, questionnaireIdToSelect?: number) {
        if (organisationStructureParams.sectorId) {
            questionnaires = questionnaires.filter(it => {
                return (it.sector_id === organisationStructureParams.sectorId || it.sector_id == null);
            });
        }

        if (organisationStructureParams.regionId) {
            questionnaires = questionnaires.filter(it => {
                return (it.region_id === organisationStructureParams.regionId || it.region_id == null);
            });
        }

        if (organisationStructureParams.organisationId) {
            questionnaires = questionnaires.filter(it => {
                return (it.organisation_id === organisationStructureParams.organisationId || it.organisation_id == null);
            });
        }

        if (organisationStructureParams.projectId) {
            questionnaires = questionnaires.filter(it => {
                return (it.project_id === organisationStructureParams.projectId || it.project_id == null);
            });
        } else {
            questionnaires = questionnaires.filter(it => {
                return it.project_id == null;
            });

        }
        if (organisationStructureParams.businessUnitId) {
            questionnaires = questionnaires.filter(it => {
                return (it.businessunit_id === organisationStructureParams.businessUnitId || it.businessunit_id == null);
            });
        } else {
            questionnaires = questionnaires.filter(it => {
                return it.businessunit_id == null;
            });
        }

        if (directIncidentForm === true) {
            questionnaires = questionnaires.filter(it => {
                return it.concern_questionnaire === true;
            });
        }

        if (directComplimentForm === true) {
            questionnaires = questionnaires.filter( it => {
                return it.compliment_questionnaire === true;
            });
        }

        if (questionnaireIdToSelect) {
            questionnaires = questionnaires.filter( it => {
                return QuestionnaireUtils.isQuestionnaireOrChildOf(questionnaires, it, questionnaireIdToSelect);
            });
        }

        questionnaires = this.filterOverriddenForms(questionnaires);

        return questionnaires;
    }

    /**
     * Filters the concepts list based on the ID params
     *
     * @param concepts
     * @param organisationStructureParams
     * @param questionnaire
     * @returns
     */
    public static filterConceptsForDeeplink(concepts: Concept[], organisationStructureParams: OrganisationStructureParams, questionnaire: Questionnaire) {
        return this.filterConcepts(concepts, organisationStructureParams, questionnaire);
    }
    /**
     * Filters the concepts list based on the ID params and a potential questionnaire
     *
     * @param concepts
     * @param organisationStructureParams
     * @param questionnaire
     * @returns
     */
    public static filterConcepts(concepts: Concept[], organisationStructureParams: OrganisationStructureParams, questionnaire?: Questionnaire) {

        // Filters based on organisationStructure
        concepts = QuestionnairesFilter.filterConceptOrganisations(concepts, organisationStructureParams);

        if (organisationStructureParams.projectId) {
            concepts = concepts.filter(it => {
                return (it.questionnaire.project_id === organisationStructureParams.projectId || it.questionnaire.project_id == null);
            });
            // do not filter on project / bu in case we're looking for a specific questionnaire...
        } else if (!questionnaire) {
            concepts = concepts.filter(it => {
                return it.questionnaire.project_id == null;
            });
        }

        if (organisationStructureParams.businessUnitId) {
            concepts = concepts.filter(it => {
                return (it.questionnaire.businessunit_id === organisationStructureParams.businessUnitId || it.questionnaire.businessunit_id == null);
            });
        } else if (!questionnaire) {
            concepts = concepts.filter(it => {
                return it.questionnaire.businessunit_id == null;
            });
        }

        if (questionnaire) {
            concepts = this.filterConceptsForQuestionnaire(concepts, questionnaire);
        }

        return concepts;
    }

    /**
     * Filters the concepts list based on the ID of the questionnaire
     *
     * @param concepts
     * @param questionnaire
     * @returns
     */
    public static filterConceptsForQuestionnaire(concepts: Concept[], questionnaire: Questionnaire) {
        return concepts.filter(it => {
            return it.questionnaire.id === questionnaire.id;
        });
    }

    /**
     * Filters the concepts list based on the ID params for the badges
     *
     * @param concepts
     * @param organisationStructureParams
     * @param directIncidentForm
     * @param directComplimentForm
     * @returns
     */
    public static filterBadgesConcepts(concepts: Concept[], organisationStructureParams: OrganisationStructureParams, directIncidentForm: boolean = false, directComplimentForm: boolean = false, questionnaireIdToSelect: number = undefined) {
        // Filters based on organisationStructure
        concepts = QuestionnairesFilter.filterConceptOrganisations(concepts, organisationStructureParams);

        if (organisationStructureParams.projectId) {
            concepts = concepts.filter(it => {
                return (it.questionnaire.project_id === organisationStructureParams.projectId);
            });
        }
        if (organisationStructureParams.businessUnitId) {
            concepts = concepts.filter(it => {
                return (it.questionnaire.businessunit_id === organisationStructureParams.businessUnitId);
            });
        }

        if (directIncidentForm === true) {
            concepts = concepts.filter(it => {
                return it.questionnaire.concern_questionnaire === true;
            });
        }

        if (directComplimentForm === true) {
            concepts = concepts.filter(it => {
                return it.questionnaire.compliment_questionnaire === true;
            });
        }

        if (!!questionnaireIdToSelect) {
            concepts = concepts.filter(it => it.questionnaire.id === questionnaireIdToSelect);
        }

        return concepts;
    }

    /**
     * Filters out form that have a parent form (inheritance)
     *
     * @param questionnaires
     * @returns
     */
    public static filterOverriddenForms(questionnaires: Questionnaire[]) {
        const parentIds = this.buildParentIdList(questionnaires);

        return questionnaires
            .filter(
                it => {
                    for (const id of parentIds) {
                        if (it.id === id) {
                            return false;
                        }
                    }
                    return true;
                },
            );
    }

    /**
     * Filter out the needed questionnaire reminders based on their place in the structure
     *
     * @param reminders
     * @param organisationStructureParams
     * @returns QuestionnaireReminder[]
     */
    public static filterQuestionnaireReminders(reminders: QuestionnaireReminder[], organisationStructureParams: OrganisationStructureParams): QuestionnaireReminder[] {
        if ( reminders === undefined ) {
            return [];
        }

        if (organisationStructureParams.projectId) {
            reminders = reminders.filter(reminder => {
                return (reminder.project_id === organisationStructureParams.projectId);
            });
        }

        if (organisationStructureParams.businessUnitId) {
            reminders = reminders.filter(reminder => {
                return (reminder.business_unit_id === organisationStructureParams.businessUnitId);
            });
        }

        if (organisationStructureParams.organisationId) {
            reminders = reminders.filter(reminder => {
                return (reminder.organisation_id === organisationStructureParams.organisationId);
            });
        }

        if (organisationStructureParams.regionId) {
            reminders = reminders.filter(reminder => {
                return (reminder.region_id === organisationStructureParams.regionId);
            });
        }

        if (organisationStructureParams.sectorId) {
            reminders = reminders.filter(reminder => {
                return (reminder.sector_id === organisationStructureParams.sectorId);
            });
        }

        return reminders;
    }

    /**
     * Filters the concepts only on organisation
     *
     * @param concepts
     * @param organisationStructureParams
     * @returns
     */
    public static filterConceptOrganisations(concepts: Concept[], organisationStructureParams: OrganisationStructureParams) {

        if (organisationStructureParams.organisationId) {
            concepts = concepts.filter(it => {
                return (it.questionnaire.organisation_id === organisationStructureParams.organisationId);
            });
            return concepts;
        }

        return concepts;
    }

    /**
     * Builds a list of parent forms to be removed
     *
     * @param questionnaires
     * @returns
     */
    private static buildParentIdList(questionnaires: Questionnaire[]) {
        const list: Array<number> = [];

        for (const questionnaire of questionnaires) {
            if (questionnaire.parent_form) {
                list.push(questionnaire.parent_form.id);
            }
        }

        return list;
    }
}
