export const TRANSLATION_RO = {
    DATE: {
        long: 'DD MMMM YYYY [-] HH:mm',
        date: 'DD-MM-YYYY',
    },
    LOGIN: {
        title: 'Bine ați venit!',
        slogan: 'Fiți atent! Siguranța are prioritate!',
        buttons: {
            external: 'Sunt un angajat al unei terțe părți',
            internal: 'Sunt un angajat al VolkerWessels',
        },
    },
    BUTTONS: {
        remove: 'Ștergere',
        ok: 'OK',
        yes: 'Da',
        yes_and_send: 'Da și trimitere',
        no: 'Nu',
        cancel: 'Anulare',
        again: 'Repetare',
        back: 'Înapoi',
        submit: 'Trimitere',
        skip: 'Omitere',
        next: 'Următorul',
    },
    DASHBOARD: {
        tile: {
            home: 'Acasă',
            forms: 'Formulare',
            compliment: 'Acordare distincție',
            compliment_desc: 'Vreau să felicit un coleg pentru că lucrează în siguranță.',
            scan_qr: 'Scanați QR',
            scan_qr_desc: 'Vreau un cod QR pentru a putea accesa formularul potrivit.',
            instruction: 'Programul WAVE',
            more: 'Mai mult',
            title: 'Bună, ce ai de gând să faci?',
            subtitle: 'Vă rugăm să alegeți mai jos',
        },
        report: {
            incident: 'Raportare incident sau MOHS',
            incident_desc: 'Doresc să raportez un incident, o practică nesigură, un mediu și/sau o explozie sau un incendiu.',
        },
        tasks: {
            notification: {
                singular: 'You have <b>{{value}} open</b> task',
                plural: 'You have <b>{{value}} open</b> tasks',
            },
        },
        deprecation: {
            title: 'Vă rugăm să rețineți',
            text: 'De la 1 septembrie, va trebui să utilizați aplicația ETT WAVE. Faceți clic aici pentru a o descărca.',
        },
    },
    DASHBOARD_MORE: {
        tile: {
            history: 'Istoric',
            news: 'Noutăți',
            cardGame: 'Acceleratorul WAVE',
            tasks: 'Sarcini',
        },
        tasks: {
            notification: {
                singular: '{{value}} open task',
                plural: '{{value}} open tasks',
            },
        },
    },
    HEADER: {
        structure: 'New form',
    },
    PUSH: {
        news: {
            title: 'Primire articole de știri',
        },
        task: {
            title: 'Sarcina atribuită',
        },
        instruction: {
            title: 'Primire program WAVE',
        },
    },
    DISCLAIMER: {
        heading: 'CLAUZĂ DE DECLINARE A RESPONSABILITĂȚII',
        title: 'Important:',
        content: 'Nu introduceți niciun fel de date cu caracter personal (cum ar fi numele, numărul de telefon, numărul de înmatriculare), cu excepția cazului în care vi le solicităm în mod expres. ' +
            'Dacă este necesară încărcarea unei fotografii, asigurați-vă că persoanele din imagine nu pot fi recunoscute.',
        buttons: {
            agree: 'Da, am citit clauza de declinare a responsabilității',
        },
    },
    HELP: {
        title: 'Centrul de asistență',
        app_version: 'Versiunea aplicației:',
        intro: 'Cele mai frecvente întrebări despre aplicația WAVE sunt enumerate mai jos. Dacă nu găsiți aici un răspuns la întrebarea dvs., vă rugăm să contactați biroul de asistență IT din cadrul companiei dvs.',
        phone_number: 'Număr de telefon',
        tenant_phone_numbers: {
            'be36ab0a-ee39-47de-9356-a8a501a9c832': '088 186 0606', // VWT
            '0ef544fb-3d5c-4c60-932e-735bcef00405': '088 186 7777', // BVGO
            'cdc477bf-b6e3-4345-b1be-3b225394e17e': '088 186 2555', // VW Infra
            '957801e8-a608-42e6-afc7-a2e6399ec203': '085 800 1221', // V&SH
        },
        buttons: {
            privacy: 'Declarația de confidențialitate',
            instruction_film: 'Video de instruire',
        },
    },
    CONTACT: {
        title: 'Date de contact',
        subtitle: 'See your account information, log out or change the app language.',
        email_address: 'Adresa de e-mail',
        phone_number: 'Număr de telefon',
        fill_phone_number: 'Introduceți numărul dvs. de telefon',
        language_selection: 'Alegere limbă',
        save: 'Salvare modificări',
        logout: 'Deconectare',
        change_password: 'Schimbare parolă',
        remove_account: 'Ștergere cont',
        confirm_external_modal: {
            heading: 'Ștergere cont',
            body: 'Sunteți sigur că doriți să ștergeți contul?',
            button: 'Da și ștergere cont',
        },
        removing_account: 'Ștergerea contului',
        account_removed: 'Contul dvs. a fost șters cu succes.',
    },
    DEEPLINK_QUESTIONNAIRE: {
        title: 'Deschidere formular',
        unfortunately: 'Din păcate...',
        ok_and_close: 'OK și închidere pagină',
    },
    EXTERNAL_LOGIN: {
        title: 'Cont extern',
        email: 'E-mail',
        password: 'Parolă (8 sau mai multe caractere)',
        login: 'Conectare',
        forgot_password: 'Am uitat parola',
        create_account: 'Creare cont',
    },
    FAQ: {
        title: 'Întrebări frecvente',
        sections: [{
            title: '',
            questions: [{
                question: 'Cui mă pot adresa în cazul în care am întrebări legate de formulare?',
                answer: 'Pentru întrebările legate de conținutul formularelor din aplicație, puteți contacta administratorul funcțional (de obicei, un coleg QHSE sau un expert în domeniul siguranței) din cadrul companiei dvs.',
                open: false,
            }, {
                question: 'Ce trebuie să fac dacă nu pot răspunde la toate întrebările?',
                answer: 'Este firesc să nu știți răspunsul la fiecare întrebare. Răspundeți numai dacă sunteți sigur. În caz contrar, menționați faptul că nu știți răspunsul.',
                open: false,
            }, {
                question: 'Cui mă pot adresa în cazul în care am întrebări tehnice legate de aplicație?',
                answer: 'Pentru întrebări tehnice legate de aplicație, puteți contacta biroul de asistență IT din cadrul companiei dvs. Puteți găsi numărul de telefon în partea de sus a acestei pagini.',
                open: false,
            }, {
                question: 'Ce înseamnă numărul scris cu portocaliu de sub secțiunea „Formulare”?',
                answer: 'Pe panoul de control apare un număr atunci când unul sau mai multe formulare nu au fost completate și/sau trimise. Numărul indică câte formulare există. ' +
                    'Puteți găsi formularele care nu au fost încă completate și/sau trimise în partea de jos a paginii de formulare. Făcând clic pe acestea, puteți completa și trimite formularele. De asemenea, este posibil să ștergeți formularele care nu au fost încă completate și/sau trimise.',
                open: false,
            }, {
                question: 'Pot aduce modificări unui raport pe care l-am trimis?',
                answer: 'Nu, din păcate, acest lucru nu este posibil. Cu toate acestea, puteți contacta întotdeauna administratorul funcțional (de obicei, un coleg CSSM sau un expert în domeniul siguranței) din cadrul companiei dvs. pentru a transmite orice fel de modificare.',
                open: false,
            }, {
                question: 'Ce se întâmplă cu raportul meu după ce îl trimit?',
                answer: 'Rapoartele sunt trimise automat persoanei responsabile de proiect. Aceasta poate varia în funcție de formular. ' +
                    'Administratorul funcțional (de obicei, un coleg CSSM sau un expert în domeniul siguranței) primește o copie a formularului. În plus, veți primi și o copie a formularului prin e-mail.',
                open: false,
            }, {
                question: 'Pot șterge un raport?',
                answer: 'Da, puteți. Totuși, acest lucru nu este posibil prin intermediul aplicației. Contactați administratorul funcțional (de obicei, un coleg CSSM sau un expert în domeniul siguranței) din cadrul companiei dvs. pentru a șterge raportul.',
                open: false,
            }, {
                question: 'Pot să folosesc aplicația offline?',
                answer: 'Da, puteți. Puteți oricând să completați un formular și să îl salvați în aplicație. În cazul în care formularul nu este complet, veți primi un mesaj care vă va avertiza că acesta nu poate fi trimis. Formularul va fi salvat sub formă de proiect. ' +
                    'Proiectul nu va fi trimis automat imediat ce vă conectați la internet. Trebuie să deschideți proiectul și apoi, să îl trimiteți. ' +
                    'Din păcate, utilizatorii externi nu pot completa toate formularele în cazul în care lucrează offline. Aceștia pot doar raporta situațiile nesigure offline. ' +
                    'Pentru celelalte formulare, aceștia trebuie să lucreze online.',
                open: false,
            }, {
                question: 'Poate un subcontractant să utilizeze aplicația?',
                answer: 'Da, poate. Un subcontractant se poate conecta ca utilizator extern.',
                open: false,
            }, {
                question: 'Pot să îmi schimb numărul de telefon?',
                answer: 'Da, puteți. Toți angajații VolkerWessels își pot schimba numărul de telefon. Puteți face acest lucru în profilul dvs. din cadrul aplicației.',
                open: false,
            }],
        }],
    },
    NOTIFICATION: {
        title: 'Raport',
        warning: 'Avertisment',
        close: 'Închidere',
        open: 'Deschidere',
        error_general: 'A apărut o eroare neașteptată',
        error_retrieving_profile: 'Ceva nu a mers bine la accesarea profilului dvs.',
        something_wrong_saving: 'Ceva nu a mers bine la trimiterea formularului',
        something_wrong_task: 'Ceva nu a mers bine la trimiterea unei sarcini Introduceți comentariile sau încercați din nou mai târziu',
        something_wrong_logout: 'Ceva nu a mers bine la deconectare.',
        offline: 'În acest moment lucrați offline. Prin urmare, această funcție nu este disponibilă în momentan',
        account_removed_failed: 'Ceva nu a mers bine la ștergerea contului dvs. Încercați din nou mai târziu.',
        inactive_form: 'Acest formular nu mai este valabil. Completați din nou formularul.',
        no_access: 'Nu aveți acces la WAVE. Contactați-l pe administratorul dvs.',
        error_message: 'Mesaj de eroare:',
        login_failed: 'Conectarea a eșuat',
        error_logged_out: 'Din păcate, ceva nu a mers bine la accesarea datelor. Sunteți deconectat',
        retrieving_options: 'Accesare opțiuni/contacte',
        aggregating: 'Agregare date',
        update_failed: 'Editarea a eșuat',
        retrieval_failed: 'Accesarea a eșuat',
        error_forms_no_network: 'Nu se pot edita formularele (nu există conexiune la rețea). Doriți să încercați să editați din nou formularele?',
        error_forms_slow_network: 'Nu se pot edita formularele (conexiune la rețea lentă). Doriți să încercați să editați din nou formularele?',
        error_forms_retrieval_no_network: 'Nu se pot deschide formularele (nu există conexiune la rețea). Verificați conexiunea la rețea și încercați din nou.',
        error_forms_retrieval_slow_network: 'Nu se pot accesa formularele (conexiune de rețea lentă). Verificați conexiunea la rețea și încercați din nou.',
        error_popup_blocked: 'Permite ferestre pop-up pentru a te autentifica',
        image_file_needed: 'Fișierul nu este o imagine validă',
        route_not_found: 'Redirecționarea eșuată',
        route_not_found_explanation: 'Redirecționarea către pagină sau formular nu a reușit. Cauza poate fi că este inaccesibil pentru dvs., pagina sau formularul nu mai există sau formularul nu este disponibil în limba dvs.',
    },
    FORGOT_PASSWORD: {
        title: 'Am uitat parola',
        subtitle: 'Dacă adresa dvs. de e-mail este cunoscută, veți primi un e-mail cu instrucțiuni pentru resetarea parolei.',
    },
    FORGOT_PASSWORD_FORM: {
        title: 'Introduceți adresa de e-mail',
        email: 'E-mail',
        next: 'Următorul',
    },
    NEWS: {
        title: 'Noutăți',
        subtitle: 'WAVE nieuws berichten',
        search: 'Search by news',
    },
    MEDIA_COMMUNICATION: {
        subtitle: {
            theme: 'Choose a theme',
            subject: 'Choose a topic',
        },
        search: {
            theme: 'Search by theme',
            subject: 'Search by topic',
        },
    },
    SEARCH_PLACEHOLDER: 'Căutare',
    PASSWORD_CHANGE: {
        title: 'Schimbare parolă',
        current_password: 'Parola curentă',
        password_explanation: 'Parola trebuie să fie compusă din cel puțin 8 caractere și să conțină o majusculă, un număr și un caracter special',
        new_password: 'Parola nouă',
        repeat_new_password: 'Repetați noua parolă',
        passwords_dont_match: 'Parolele nu se potrivesc',
        password_changed: 'Parola dvs. a fost schimbată Trebuie să vă conectați din nou',
        current_password_incorrect: 'Parola introdusă nu se potrivește cu parola actuală',
        confirm: 'Confirmare',
    },
    QUESTIONNAIRE: {
        part: 'Componentă',
        map_offline: 'Lucrați offline. Cardul este disponibil numai în modul online.',
        from: 'from',
    },
    QUESTIONNAIRE_HISTORY: {
        title: 'Formularele trimise',
        no_forms: 'Nu au fost trimise încă rapoarte.',
        submitted_at: 'Trimis la',
        processed_at: 'Gestionat la',
        processed_submitted_at: 'Gestionat, trimis la',
    },
    QUESTIONNAIRE_REPEAT_QUESTION: {
        part: 'Componentă',
    },
    QUESTIONNAIRE_SENT: {
        title: 'Vă mulțumim',
        subtitle: 'Formularul este în curs de procesare. O copie a formularului vă va fi trimisă prin e-mail. Puteți găsi, de asemenea, formularul completat în secțiunea Istoric din această aplicație.',
    },
    QUESTIONNAIRE_TASK: {
        created_on: 'Creat la:',
        completed_on: 'Completat la:',
        place_remark: 'Scrieți aici comentariul (comentariile) dvs.',
        remark: 'Comentariu (comentarii):',
        task_denied: 'Negestionat',
        task_completed: 'Gestionat',
        are_you_sure: 'Sunteți sigur că doriți să configurați „{{statusLabel}}” această sarcină?',
        page_leave: 'Părăsire pagină',
        page_leave_explanation: 'Ați completat câmpul „comentarii” pentru această sarcină. Dacă părăsiți pagina, această modificare se va pierde. Sunteți sigur că doriți să părăsiți pagina?',
    },
    QUESTIONNAIRE_TASKS: {
        title: 'Sarcini în curs de desfășurare',
        subtitle: 'Click on a task to execute it',
        planned_forms: 'Formularele planificate',
        planned_forms_subtitle: 'Below are forms that must be sent by a certain date',
        closed_tasks: 'Sarcinile finalizate',
        closed_tasks_subtitle: 'Below are tasks which have been completed',
        closed_on: 'Completat la:',
        deadline_passed: 'Termen limită depășit:',
        deadline_at: 'Termen limită depășit la:',
        created_at: 'Creat la:',
    },
    CARD_GAME: {
        question: {
            label: 'Întrebare',
        },
        toolbar: {
            button_label: 'Card nou',
            close: 'Închidere',
        },
    },
    ERROR: {
        form: {
            warning: 'Avertisment',
            not_found: 'Încă nu sunt disponibile formulare în limba dvs.',
            directIncidentOnly: 'Formularul pentru „Raportare incident sau MOHS” nu este momentan disponibil. Vă rugăm să îl contactați pe administratorul de sistem.',
            directComplimentOnly: 'Acest formular nu este disponibil în limba dvs.',
            questionnaireIdToSelect: 'Această sarcină nu este disponibilă în prezent pentru unitatea organizațională selectată. Vă rugăm să selectați o altă unitate organizațională sau să contactați administratorul de sistem.',
        },
        no_access: {
            title: 'Fără drepturi',
            explanation: 'Nu aveți acces la aceste formulare. Reveniți la ecranul de start.',
        },
    },
    REGISTRATION: {
        title: 'Creare cont nou',
        email: 'E-mail',
        first_name: 'Prenume',
        sur_name: 'Nume',
        mobile_phone: 'Numărul de telefon mobil',
        password: 'Parolă',
        password_explanation: 'Parola trebuie să fie compusă din cel puțin 8 caractere și să conțină o majusculă, un număr și un caracter special',
        repeat_password: 'Repetare parolă',
        passwords_dont_match: 'Parolele nu se potrivesc',
        approve: 'Sunt de acord cu',
        privacy: 'Politica de confidențialitate',
        create_account: 'Creare cont',
        account_exists: 'Există deja un cont asociat cu această adresă de e-mail',
        error_something_wrong: 'Ceva nu a mers bine cu înregistrarea.',
    },
    REGISTRATION_COMPLETE: {
        title: 'Confirmați adresa de e-mail',
        subtitle: 'Vă mulțumim pentru că v-ați creat un cont. În câteva momente, veți primi un e-mail în care vi se va cere să vă confirmați adresa de e-mail',
    },
    REGISTRATION_SMS: {
        title: 'Verificare număr de telefon',
        subtitle: 'Introduceți aici codul de verificare pe care l-ați primit prin mesaj text. Acest cod va fi trimis la numărul de telefon specificat.',
        verification_code: 'Cod de verificare',
        verify: 'Controlare cod de verificare',
        incorrect_code: 'Cod de verificare invalid',
        error_something_wrong: 'Ceva nu a mers bine la verificare.',
    },
    SELECT_QUESTIONNAIRE: {
        open_forms: 'Formularele care nu au fost încă completate',
        open_forms_subtitle: 'Click on a form to continue',
        new_form: 'New form',
        new_form_subtitle: 'Click to start a new form',
        planned_forms: 'Formularele planificate',
        available_forms: 'Formulare disponibile',
        workplace_forms: 'Inspecția de la locul de muncă',
        retrieve_forms: 'Accesare formulare',
        retrieve_form: 'Accesare formular',
        choose_action: 'Selectați acțiunea dvs.',
        open: 'Deschidere',
        upload: 'Pune online',
        download_and_open: 'Descărcați și deschideți formularul',
        remove: 'Ștergere',
        something_went_wrong: 'Ceva nu a mers bine la accesarea formularelor',
    },
    SELECT_STRUCTURE: {
        choose_project: 'Selectați proiectul dvs.',
        choose_business_unit: 'Selectați unitatea dvs. de afaceri',
        no_organisations: 'Nu există organizații disponibile.',
        search_on: 'Căutare după',
        choose_your: 'Select your',
        organisation: 'companie',
        project: 'proiect',
        projects: 'Proiecte',
        business_unit: 'unitate de afaceri',
        business_units: 'Unitati de afaceri',
    },
    SEND_QUESTIONNAIRE: {
        title: 'Doriți să trimiteți formularul?',
        description: 'Prin trimiterea acestuia nu mai puteți modifica nimic din acest formular trimis.',
        account_blocked: 'Acest formular nu poate fi trimis până când contul dvs. nu este aprobat',
        offline: 'Lucrați offline, ceea ce înseamnă că nu puteți trimite formularul. Trimiteți formularul de îndată ce sunteți din nou online',
        something_went_wrong: 'Ceva nu a mers bine la trimiterea formularului',
        confirm: 'Da, trimite',
        cancel: 'Nu, înapoi',
    },
    SCAN_QR: {
        incorrect_result: 'Cod QR incorect. Scanați un cod QR WAVE corect.',
        no_result: 'Niciun cod QR scanat.',
        no_permission: 'Aplicația nu poate accesa camera. Acordați permisiunea aplicației înainte de a scana un cod QR.',
        try_again: 'Scanați din nou',
    },
    COMPONENTS: {
        attachments_remarks: {
            add: 'Adăugați',
            place_remark: 'Scrieți aici comentariul (comentariile) dvs.',
            max_attachments_reached: 'Numărul maxim de atașamente a fost atins.',
            note: 'Note',
            picture: 'Picture',
        },
        checkbox: {
            multiple_choices_possible: 'Sunt posibile mai multe opțiuni',
            no_choices_selected: 'Nu a fost selectată nicio opțiune (opțiuni).',
        },
        checkbox_searchable: {
            selected_choices: 'Opțiunea (opțiunile) selectată (selectate)',
            no_choices_found: 'Nu a fost găsită nicio opțiune (opțiuni).',
            visible_choices: '{{maxListChoices}} din opțiunile {{filteredChoices}} sunt vizibile.',
            limit_choices: 'Limitați lista de opțiuni prin căutare.',
        },
        lat_long: {
            current_location: 'Utilizați locația curentă',
            or: 'Sau',
            city: 'Oraș/municipiu',
            address: 'Adresă',
            invalid_address: 'Aceasta nu este o adresă validă.',
        },
        manual_choice: {
            new_choice: 'Introduceți o nouă opțiune',
            choice_exists: 'Această opțiune există deja.',
        },
        photo: {
            subtitle: 'Adăugați fotografii făcând clic pe semnul plus',
            gallery: 'Galerie foto',
            drop_explanation: 'Faceți clic, trageți sau inserați fotografii aici pentru a le adăuga',
            max_reached: 'A fost atins numărul maxim de fotografii care pot fi atașate.',
            take_a_photo: 'Fă o poză',
            upload_from_gallery: 'Încărcați din galerie',
        },
        questionnaire_footer: {
            chapters: 'Capitole',
            previous: 'Anterior',
        },
        questionnaire_overlay: {
            help_text: 'După ce ați răspuns la întrebare, faceți clic pe „Înainte” pentru a trece la întrebarea următoare.',
        },
        radio: {
            choose: 'Realizați o selecție',
            no_choice_selected: 'Nu a fost selectată nicio opțiune.',
        },
        radio_searchable: {
            selected_choice: 'Opțiunea selectată',
            no_choice_found: 'Nu a fost găsită nicio opțiune.',
            visible_choices: '{{maxListChoices}} din opțiunile {{filteredChoices}} sunt vizibile.',
            limit_choices: 'Limitați lista de opțiuni prin căutare.',
        },
        searchable_choices: {
            search: 'Căutare',
        },
        select_concept: {
            last_change: 'Ultima editare:',
        },
        select_item: {
            submit_before: 'Trimitere înainte de',
        },
    },
};
