import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LoadingController, NavController} from '@ionic/angular';
import {PasswordReset} from '../../models/password-reset';
import {ExternalUserService} from '../../services/external-user-service';
import {ToastService} from '../../services/toast-service';
import {TranslateService} from '@ngx-translate/core';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';

@Component({
    selector: 'page-forgot-password-form',
    templateUrl: 'forgot-password-form.html',
})
export class ForgotPasswordFormPage {

    public form: UntypedFormGroup;

    constructor(
        fb: UntypedFormBuilder,
        private loadingCtrl: LoadingController,
        private externalUser: ExternalUserService,
        private toast: ToastService,
        private deviceKeyboardService: DeviceKeyboardService,
        private navCtrl: NavController,
        private translateService: TranslateService,
    ) {
        this.createForm(fb);
    }

    ionViewWillEnter() {
        this.deviceKeyboardService.showFormAccessoryBar();
    }

    ionViewWillLeave() {
        this.deviceKeyboardService.hideFormAccessoryBar();
    }

    async submit() {
        if (this.form.valid) {
            const loader = await this.loadingCtrl.create();

            try {
                await loader.present();
                const formData = this.form.value as PasswordReset;
                await this.externalUser.postPasswordResetRequest(formData);
                await loader.dismiss();
                await this.navCtrl.navigateForward(['/forgot-password-complete']);
            } catch (exception) {
                console.error('An error occurred on password reset', exception);
                await loader.dismiss();
                await this.toast.showCustomMessages(this.translateService.instant('NOTIFICATION.error_general'));
            }
        }
    }

    private createForm(fb: UntypedFormBuilder) {
        this.form = fb.group({
            email_address: fb.control('', Validators.compose([Validators.required, Validators.email])),
        });
    }
}
