import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'pluralization',
})
export class PluralizationPipe implements PipeTransform {

    transform(translationKey: string, value: number | string): string {
        return `${translationKey}.${value === 0 ? 'none' : value === 1 ? 'singular' : 'plural'}`;
    }
}
