<app-dashboard-header [showBackButton]="true"></app-dashboard-header>

<ion-content class="has-header">
    <div class="deeplink-header">
        <div *ngIf="error">
            <h2 class="light">{{ 'DEEPLINK_QUESTIONNAIRE.title' | translate }}</h2>
            <hr>
            <p><strong>{{ 'DEEPLINK_QUESTIONNAIRE.unfortunately' | translate }}</strong> {{errorMessage}} <em><small>[ CODE: {{errorCode}} ]</small></em></p>
            <ion-button color="dark" class="mt-4" expand="full" (click)="closePage()">{{ 'DEEPLINK_QUESTIONNAIRE.ok_and_close' | translate }}</ion-button>
        </div>
    </div>
</ion-content>
