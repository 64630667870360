import {Injectable} from '@angular/core';
import {ConceptService} from './concept-service';
import {QuestionnaireDataService} from './questionnaire-data-service';
import {ApiService} from './api-service';
import {Concept, UploadedConcept} from '../models/concept';
import {Questionnaire} from '../models/questionnaire';

@Injectable({
    providedIn: 'root',
})
export class UploadedConceptService {

    constructor(
        private apiService: ApiService,
        private conceptService: ConceptService,
        private questionnaireDataService: QuestionnaireDataService,
    ) {

    }

    /**
     * Upload a concept to the server
     * Steps to follow:
     * 1. Upload all attachments and images and replace the answer and attachment values with urls
     * 2. Upload the resulting concept to the server
     * 3. Delete the local copy of the concept
     */
    async uploadConcept(concept: Concept) {
        const clonedQuestionnaire: Questionnaire = JSON.parse(JSON.stringify(concept.questionnaire));
        const {questions, filesToRemove} = await this.questionnaireDataService.findAndUploadAttachments(clonedQuestionnaire.questions);
        clonedQuestionnaire.questions = questions;

        await this.apiService.authenticatedPost('/questionnaire-concepts', {
            ...concept,
            questionnaire: clonedQuestionnaire,
        });

        await this.conceptService.deleteConcept(concept);
        await this.questionnaireDataService.removeFromLocalStorage(filesToRemove);
    }

    async listConcepts(): Promise<UploadedConcept[]> {
        return await this.apiService.authenticatedGet('/questionnaire-concepts').then((response) => response.data);
    }


    /**
     * Download a concept from the server by id
     * Steps to follow:
     * 1. Clone the concept object
     * 2. Download all attachments and images and replace the answer and attachment values with local paths
     * 3. Save the concept to the local storage
     * 4. Delete the concept from the server
     */
    async downloadConcept(uploadedConcept: UploadedConcept) {
        const concept = await this.getConceptData(uploadedConcept);

        for (const question of concept.questionnaire.questions) {
            await this.questionnaireDataService.findAndDownloadAttachmentsPerQuestion(question);
        }

        await this.conceptService.saveConcept(concept, undefined);
        await this.deleteConcept(uploadedConcept);

        return concept;
    }

    private async getConceptData(uploadedConcept: UploadedConcept): Promise<Concept> {
        return await this.apiService.authenticatedGet(`/questionnaire-concepts/${uploadedConcept.id}`).then((response) => response.data);
    }

    async deleteConcept(uploadedConcept: UploadedConcept) {
        await this.apiService.authenticatedDelete(`/questionnaire-concepts/${uploadedConcept.id}`);
    }
}
