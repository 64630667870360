import {Injectable} from '@angular/core';
import {INewsItem, INewsListItem} from '../models/news-item';
import {ProgressiveApiService, ProgressiveStrategy} from './progressive-api-service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NewsItemsService {
    constructor(
        private progressiveApiService: ProgressiveApiService,
    ) {
    }

    public getNewsItemList(): Observable<INewsListItem[]> {
        return this.progressiveApiService.authenticatedGet<INewsListItem[]>('/news', ProgressiveStrategy.FALLBACK);
    }

    public getNewsItem(newsItemId: any): Observable<INewsItem> {
        return this.progressiveApiService.authenticatedGet<INewsItem>(`/news/${newsItemId}`, ProgressiveStrategy.FALLBACK);
    }
}
