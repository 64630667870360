import { Component } from '@angular/core';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { BehaviorSubject } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Component({
    selector: 'page-scan-qr',
    templateUrl: 'scan-qr.html',
})
export class ScanQrPage {
    public scanInProgress$ = new BehaviorSubject(false);
    public hasPermission$ = new BehaviorSubject(false);
    public hasScanResult$ = new BehaviorSubject(false);
    public hasIncorrectScanResult$ = new BehaviorSubject(false);
    public scanResult = '';

    constructor(private router: Router) {
    }

    async ionViewWillEnter() {
        return this.startScan();
    }

    async ionViewWillLeave() {
        if (this.scanInProgress$.value === true) {
            await this.stopScan();
        }
    }

    public startScan() {
        if (Capacitor.getPlatform() === 'web') {
            console.error('Not yet implemented on web');
            this.scanResult = undefined;
            this.hasScanResult$.next(false);
            return;
        }

        // check or request permission
        BarcodeScanner.checkPermission({ force: true })
            .then((status) => {
                this.hasPermission$.next(status.granted === true);
                if (!status.granted) {
                    return;
                }

                this.scanInProgress$.next(true);
                this.scanResult = undefined;
                this.hasScanResult$.next(false);

                document.querySelector('body').classList.add('scanner-active');
                // make background of WebView transparent
                BarcodeScanner.hideBackground().then(() => {

                    BarcodeScanner.startScan() // start scanning and wait for a result
                        .then(async (result) => {
                            await this.stopScan();

                            this.scanInProgress$.next(false);

                            // if the result has content
                            if (result.hasContent) {
                                this.hasScanResult$.next(true);
                                this.scanResult = result.content;
                                if (this.validScanResult()) {
                                    this.navigateToScannedQr();
                                } else {
                                    this.hasIncorrectScanResult$.next(true);
                                }
                            } else {
                                this.hasScanResult$.next(false);
                            }
                        });
                });
            });
    };

    private async stopScan() {
        document.querySelector('body').classList.remove('scanner-active');

        await BarcodeScanner.showBackground();
        await BarcodeScanner.stopScan();
        this.scanInProgress$.next(false);
    };

    private validScanResult(): boolean {
        if (this.scanResult === undefined) {
            return false;
        }
        const matchingDeepLinks = this.getDeeplinkMatches(this.scanResult);
        return matchingDeepLinks.length > 0;
    }

    private navigateToScannedQr(): void {
        const matchingDeepLinks = this.getDeeplinkMatches(this.scanResult);
        if (matchingDeepLinks.length > 0) {
            const urlToNavigateTo = new URL(this.scanResult);
            this.router.navigateByUrl(urlToNavigateTo.pathname);
            // set as incorrect. If we had a correct one, we would have been navigated anyway. If the route was incorrect, we stay on the same..
            this.hasIncorrectScanResult$.next(true);
        }
    }

    private getDeeplinkMatches(url: string): string[] {
        return environment.deeplinks
            .filter((deeplink) => url.toLowerCase().startsWith(deeplink.toLowerCase()));
    }
}
