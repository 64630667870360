import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Photo} from '@capacitor/camera';
import {UUID} from 'angular2-uuid';
import {Question} from '../../models/question';
import {IRemark} from '../../models/remark';
import {StorageService} from '../../services/storage-service';
import {InputfocusDirective} from '../../directives/input-focus';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';
import {Observable} from 'rxjs';
import {TrackingService} from '../../services/tracking/tracking.service';
import {CameraService} from 'src/services/camera-service';
import {ToastService} from '../../services/toast-service';
import {TranslateService} from '@ngx-translate/core';
import {RemarkFocusService} from '../../services/remark-focus-service';

@Component({
    selector: 'app-attachments-and-remarks',
    templateUrl: 'attachments-and-remarks.component.html',
})
export class AttachmentsAndRemarksComponent implements OnInit {

    public attachmentLocations: Array<string> = [];
    public attachments: Array<string> = [];
    public remarks: Array<IRemark> = [];
    public allowAttachments: boolean;
    public allowRemarks: boolean;
    public isKeyboardOpen$: Observable<boolean>;
    public isKeyboardClosed$: Observable<boolean>;
    public onRemarkFocus$: Observable<boolean>;
    attachmentsVisible = false;
    private remarksUpdateID = 0;

    @Input() public currentQuestion: Question;
    @Input() public answerIndex: number;
    @Output() private attachmentsChanged: EventEmitter<Array<string>> = new EventEmitter();
    @Output() private remarksChanged: EventEmitter<Array<string>> = new EventEmitter();
    @Input() public disabled = false;

    public maxQuestionAttachments = 10;
    public maxQuestionRemarks = 1;
    // private camera = Camera;
    public isWeb = false;

    @Input() set addPhotoAttachment(photoAttachment: Photo) {
        if (photoAttachment && this.allowAttachments) {
            if (this.allowAttachments) {
                this.getPicture(photoAttachment);
            }
        }
    }

    constructor(
        private storageService: StorageService,
        private deviceKeyboardService: DeviceKeyboardService,
        private trackingService: TrackingService,
        private cameraService: CameraService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private remarkFocusService: RemarkFocusService,
    ) {
        this.isWeb = this.cameraService.isWeb();
    }

    loadPhoto(fileName) {
        if (fileName.match(/^http/)) {
            return this.attachments.push(fileName);
        } else {
            return this.storageService.get(fileName).then((file) => {
                this.attachments.push(file);
            });
        }
    }

    async ngOnInit() {
        this.allowAttachments = this.currentQuestion.allowAttachment;
        this.allowRemarks = this.currentQuestion.allowRemarks;

        this.isKeyboardOpen$ = this.deviceKeyboardService.isKeyboardOpen();
        this.isKeyboardClosed$ = this.deviceKeyboardService.isKeyboardClosed();
        this.onRemarkFocus$ = this.remarkFocusService.remarkFocus();

        this.attachmentLocations = this.initialValue(this.currentQuestion.attachments, this.answerIndex);
        for (const location of this.attachmentLocations) {
            if (location) {
                if (Array.isArray(location)) {
                    for (const fileName of location) {
                        await this.loadPhoto(fileName);
                    }
                } else {
                    await this.loadPhoto(location);
                }
            }
        }

        this.remarks = this.initialValue(this.currentQuestion.remarks, this.answerIndex).map((remark) => {
            return this.newRemark(remark);
        });
    }

    public async takePicture() {
        const image = await this.cameraService.takePicture();
        this.getPicture(image);
    }

    public async fromLibrary() {
        const image = await this.cameraService.fromLibrary();
        this.getPicture(image);
    }

    public async uploadImageFile(event: Event) {
        const files = (event.target as HTMLInputElement).files;
        if (files && files.length > 0) {
            const photo = await this.cameraService.fromFileUpload(files[0]).catch(() => {
                this.toastService.showCustomMessages(this.translateService.instant('NOTIFICATION.image_file_needed'));
            });

            if (photo) {
                await this.getPicture(photo);
            }
            (event.target as HTMLInputElement).value = null;
        }
    }

    private async getPicture(photo: Photo) {
        try {
            const fileName = UUID.UUID();
            await this.storageService.set(fileName, `data:image/jpeg;base64,${photo.base64String}`);

            this.attachmentLocations.push(fileName);
            this.attachments.push(`data:image/jpeg;base64,${photo.base64String}`);
            this.attachmentsChanged.emit(this.attachmentLocations);
        } catch (error) {
            this.trackingService.exception(error);
            console.error(error);
        }
    }

    public removePicture(index: number) {
        const attachment = this.attachmentLocations.splice(index, 1);
        this.attachments.splice(index, 1);
        this.storageService.remove(attachment[0]).then(() => {
            this.attachmentsChanged.emit(this.attachmentLocations);
        });
    }

    removeAllPictures(): void {
        this.attachmentLocations.forEach((attachment) => {
            this.storageService.remove(attachment[0]);
        });
        this.attachmentLocations = [];
        this.attachments = [];
        this.attachmentsChanged.emit(this.attachmentLocations);
        this.attachmentsVisible = false;
    }

    private initialValue(value: Array<Array<string>>, index: number): string[] {
        if (value === undefined || value[index] === undefined) {
            // value === undefined        -> currentQuestion.attachments does not exists
            // value[index] === undefined -> currentQuestion.attachments[answerIndex] does not exists
            // value[index] === ''        -> currentQuestion.attachments[answerIndex] is legacy single value (Array<string>) and prepare for multiple (saved input from concept or history forms)
            return [];
        } else {
            const currentItem = value[index];
            if (Array.isArray(currentItem)) {
                // currentQuestion.attachments[answerIndex] is multiple (Array<Array<string>>)
                return currentItem;
            } else {
                // currentQuestion.attachments[answerIndex] is legacy single value (Array<string>) and convert to multiple (Array<Array<string>>) (saved input from concept or history forms)
                return [currentItem];
            }
        }
    }

    public addRemark() {
        if (this.remarks.length < this.maxQuestionRemarks) {
            const remark = this.newRemark('');
            this.remarks.push(remark);
            this.focusOnRemark(remark);
            this.emitRemarks();
        }
    }

    public updateRemark(index: number, remarkValue: string) {
        this.remarks[index].value = remarkValue;
        this.emitRemarks();
    }

    public removeRemark(index: number) {
        this.remarks.splice(index, 1);
        this.emitRemarks();
    }

    private emitRemarks() {
        this.remarksChanged.emit(this.remarks.map((remark) => {
            return remark.value;
        }));
    }

    private newRemark(remarkValue: string): IRemark {
        this.remarksUpdateID++;
        return {
            updateID: `${this.uniqueID(32)}_${this.remarksUpdateID}`,
            value: remarkValue,
        };
    }

    showAttachments(): void {
        this.attachmentsVisible = true;
    }

    private uniqueID(length: number): string {
        let uniqueString = '';
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');

        for (let i = 0; i < length; i++) {
            uniqueString += chars[Math.floor(Math.random() * chars.length)];
        }

        return uniqueString;
    }

    public remarkTrackUpdateID(index: number, remark: IRemark) {
        return remark.updateID;
    }

    private focusOnRemark(remark: IRemark) {
        InputfocusDirective.setFocus(`#${remark.updateID} textarea`);
    }

    public onDragOver(event: DragEvent) {
        const element = (event.currentTarget as HTMLElement);
        if (!element.classList.contains('dragging')) {
            element.classList.add('dragging');
        }
    }

    public onDragLeave(event: DragEvent) {
        const element = (event.currentTarget as HTMLElement);
        if (element.classList.contains('dragging')) {
            element.classList.remove('dragging');
        }
    }

    hasFocus(focus: boolean) {
        this.remarkFocusService.setHasFocus(focus);
    }
}
