import {Component, Input, OnInit} from '@angular/core';
import {Concept} from '../../models/concept';
import {OrganisationStructureService} from '../../services/organisation-structure-service';

@Component({
    selector: 'app-select-concept',
    templateUrl: 'select-concept.html',
})
export class SelectConceptComponent implements OnInit {
    @Input() public concept: Concept;
    parentName = '';

    constructor(private organisationStructureService: OrganisationStructureService) {
    }

    ngOnInit(): void {
        this.parentName = this.organisationStructureService.getQuestionnaireParentNameById(this.concept.questionnaire);
    }
}
