import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {EventService} from './event-service';
import {Questionnaire} from '../models/questionnaire';

@Injectable({
    providedIn: 'root',
})
export class QuestionnaireChapterService {

    private expandedChapterIds: Map<string, BehaviorSubject<boolean>> = new Map();

    constructor(
        private events: EventService,
    ) {
        this.events.subscribe('auth:logout', () => this.clear());
    }

    public ifChapterExpanded(chapterId: string): Observable<boolean> {
        this.setIfNotDefined(chapterId);

        return this.expandedChapterIds.get(chapterId).asObservable();
    }

    public toggleChapterExpanded(chapterId: string): void {
        this.setIfNotDefined(chapterId);

        const subject = this.expandedChapterIds.get(chapterId);
        subject.next(!subject.value);
    }

    public clearExpandedChaptersForQuestionnaire(questionnaire: Questionnaire): void {
        Array.from(this.expandedChapterIds.keys())
            .forEach((key) => {
                if (key.startsWith(`/history/questionnaire/${questionnaire.id}/`)) {
                    this.expandedChapterIds.delete(key);
                }
            });
    }

    private setIfNotDefined(chapterId: string): void {
        if (!this.expandedChapterIds.has(chapterId)) {
            this.expandedChapterIds.set(chapterId, new BehaviorSubject(false));
        }
    }

    private clear(): void {
        this.expandedChapterIds.clear();
        this.expandedChapterIds = new Map();
    }

}
