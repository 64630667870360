import {Component, Input} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {Choice} from '../../models/choice';
import {AbstractChoiceQuestionComponent} from '../../classes/abstract-choice-question.component';

@Component({
    selector: 'app-radio',
    templateUrl: 'radio.html',
})
export class RadioComponent extends AbstractChoiceQuestionComponent {

    constructor(fb: UntypedFormBuilder) {
        super(fb);
    }

    public choose(choice: Choice, newAddition?: boolean): void {
        if (this.disabled) {
            return;
        }

        if (this.question.choices.indexOf(choice) !== -1) {
            this.questionForm.get('current').setValue(choice.title);
        }
        this.valueChange.emit(this.questionForm.get('current').value);
    }
}
