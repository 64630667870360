import {Component} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Profile} from '../../models/profile';
import {AuthService} from '../../services/auth-service';
import {ProfileService} from '../../services/profile-service';
import {LoadingService} from '../../services/loading-service';
import {ToastService} from '../../services/toast-service';
import {EventService} from '../../services/event-service';
import {NotificationModalComponent} from '../../components/notification-modal/notification-modal';
import {ExternalUserService} from '../../services/external-user-service';
import {TrackingService} from '../../services/tracking/tracking.service';
import {LANGUAGE_DEFINITION, LanguageService} from '../../services/language.service';
import {TranslateService} from '@ngx-translate/core';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';

@Component({
    selector: 'page-contact',
    templateUrl: 'contact.html',
    styleUrls: ['contact.scss'],
})
export class ContactPage {
    public email: string;
    public form: UntypedFormGroup;
    public isExternal = false;
    private profileSynced = false;
    public availableLanguages: LANGUAGE_DEFINITION[];
    public currentLanguage;

    constructor(
        private authService: AuthService,
        private profileService: ProfileService,
        private loadingService: LoadingService,
        private toast: ToastService,
        private navCtrl: NavController,
        private deviceKeyboardService: DeviceKeyboardService,
        private trackingService: TrackingService,
        fb: UntypedFormBuilder,
        private events: EventService,
        private modalCtrl: ModalController,
        private externalUserService: ExternalUserService,
        private languageService: LanguageService,
        private translateService: TranslateService,
    ) {
        this.createForm(fb);

        this.availableLanguages = languageService.getAvailableLanguages();

        this.isExternal = authService.isExternalUser();
    }

    createForm(fb: UntypedFormBuilder) {
        this.form = fb.group({
            phone: fb.control(''),
            language: fb.control(''),
        });
    }

    async ionViewWillEnter() {
        this.deviceKeyboardService.showFormAccessoryBar();

        // Make sure the loading only happens when entering the contact page
        // Not when returning to it from the password change page
        if (!this.profileSynced) {
            await this.loadingService.start();

            try {
                const response = await this.profileService.fetchProfile();
                const profile: Profile = response.data;
                this.email = profile.username;
                this.currentLanguage = profile.language;
                this.form.get('language').setValue(profile.language);
                this.form.get('phone').setValue(profile.phone);
                await this.loadingService.stop();
                this.profileSynced = true;

            } catch (exception) {
                console.error('An error occurred while fetching profile', exception);
                await this.loadingService.stop();
                this.trackingService.exception(exception);

                this.toast.showCustomMessages(this.translateService.instant('NOTIFICATION.error_retrieving_profile'), this.translateService.instant('NOTIFICATION.offline'));
            }
        }
    }

    ionViewWillLeave() {
        this.deviceKeyboardService.hideFormAccessoryBar();
    }

    async submit() {
        if (this.form.valid && this.form.dirty) {
            const profile = this.form.value as Profile;
            this.form.markAsPristine();

            await this.loadingService.start();

            try {
                await this.profileService.postProfile(profile);
                await this.languageService.set(profile.language);
                await this.loadingService.stop();
            } catch (exception) {
                await this.loadingService.stop();
                await this.toast.showCustomMessages(this.translateService.instant('NOTIFICATION.something_wrong_saving'));
            }
        }
    }

    /**
     * Logs out the current user
     *
     * @returns
     */
    public logout() {
        return this.loadingService.start()
            .then(() => {
                return this.authService.logout();
            })
            .then(() => {
                return this.loadingService.stop();
            })
            .then(() => {
                this.events.publish('auth:logout');
            })
            .catch(err => {
                this.loadingService.stop();
                this.trackingService.exception(err);

                return this.toast.showCustomMessages(this.translateService.instant('NOTIFICATION.something_wrong_logout'));
            });
    }

    public openPasswordChangePage() {
        return this.navCtrl.navigateForward(['/password-change-form']);
    }

    public openConfirmRemoveExternalAccountModal(): void {
        this.modalCtrl.create({
            component: NotificationModalComponent,
            componentProps: {
                theme: 'danger',
                heading: 'CONTACT.confirm_external_modal.heading',
                body: 'CONTACT.confirm_external_modal.body',
                button: 'CONTACT.confirm_external_modal.button',
            },
        })
            .then((modal) => {
                modal.onDidDismiss()
                    .then((action) => {
                        if (action.data === 'button') {
                            this.removeExternalAccount();
                        }
                    });

                modal.present();
            });
    }

    private async removeExternalAccount(): Promise<void> {
        await this.loadingService.start(this.translateService.instant('CONTACT.removing_account'));
        await this.externalUserService.removeRegisteredAccount()
            .then(async () => {
                await this.toast.showCustomMessages(
                    this.translateService.instant('CONTACT.account_removed'),
                    '',
                    'success',
                );
                await this.authService.logout();
                this.events.publish('auth:logout');
            })
            .catch(async () => {
                await this.toast.showCustomMessages(
                    this.translateService.instant('NOTIFICATION.account_removed_failed'),
                    this.translateService.instant('NOTIFICATION.offline'),
                    'error',
                );
            });
        await this.loadingService.stop();
    }

    public languageID(index: number, language: LANGUAGE_DEFINITION) {
        return language.value;
    }

    public setLanguageFlag($event: Event) {
        const customEvent = $event as CustomEvent;
        this.currentLanguage = customEvent.detail.value;
    }

}
