<form [formGroup]="questionForm" novalidate>
    <app-searchable-choices
        [choices]="question.choices"
        (choicesFiltered)="choicesFiltered($event)"
    ></app-searchable-choices>

    <app-manual-choice-addition
        *ngIf="manualAdditionsAllowed"
        [abstractChoiceQuestion]="this"
    ></app-manual-choice-addition>

    <div class="choice-block-wrapper" *ngIf="(isKeyboardClosed$ | async) && selectedChoice">
        <div class="choice single-choice">
            <span class="choice-description light mb-4">{{ 'COMPONENTS.radio_searchable.selected_choice' | translate }}</span>
            <ion-chip color="darker" class="choice-block">
                {{ selectedChoice | contentTranslate:'title' }}
                <div *ngIf="selectedChoice | contentTranslate:'subtitle'" class="choice-subtitle">
                    {{ selectedChoice | contentTranslate:'subtitle' }}
                </div>
                <ion-icon name="checkmark-circle-outline"></ion-icon>
            </ion-chip>
        </div>
    </div>
    <div class="choice-block-wrapper">
        <div class="choice single-choice">
            <span class="choice-description light mb-4">{{ 'COMPONENTS.radio.choose' | translate }}</span>
            <ng-container *ngFor="let choice of listChoices">
                <ion-chip (click)="choose(choice)" [color]="isChoiceSelected(choice) ? 'checked' : 'default'"  [outline]="true">
                    {{ choice | contentTranslate:'title' }}
                    <div *ngIf="choice | contentTranslate:'subtitle'" class="choice-subtitle">
                        {{ choice | contentTranslate:'subtitle' }}
                    </div>
                    <ion-icon *ngIf="isChoiceSelected(choice); else notSelected" name="checkmark-circle-outline"></ion-icon>
                </ion-chip>
            </ng-container>
        </div>
        <div class="mb-4" *ngIf="listChoices.length === 0">{{ 'COMPONENTS.radio_searchable.no_choice_found' | translate }}</div>
        <div class="mb-4 choices-limited" *ngIf="listChoices.length >= maxListChoices">{{ 'COMPONENTS.radio_searchable.visible_choices' | translate: { maxListChoices,filteredChoices: filteredChoices.length } }}<br>{{ 'COMPONENTS.radio_searchable.limit_choices' | translate }}</div>
        <app-scrollto *ngIf="listChoices.length > 10" [content]="content" (scrollingToBottom)="scrollingToBottom()"></app-scrollto>
        <ion-infinite-scroll threshold="50%" (ionInfinite)="appendToListChoices($event)">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </div>
</form>

<ng-template #notSelected>
    <ion-icon name="ellipse-outline"></ion-icon>
</ng-template>
