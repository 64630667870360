<ion-header class="app-bar">
    <ion-toolbar [color]="color" class="app-bar__toolbar" [class.app-bar__toolbar--compact]="(isKeyboardOpen$ | async)">
        <ion-button fill="clear" class="app-bar__back" (click)="navigateBack()" slot="start">
            <ion-icon slot="start" name="chevron-back-outline" class="app-bar__back-icon"></ion-icon>
        </ion-button>
        <div class="app-bar__toolbar-main" slot="end">
            <ion-title class="questionnaire-header__recognize">{{ (selectedOrganisationName$ | async) }}</ion-title>
        </div>
    </ion-toolbar>
</ion-header>
