import {ErrorHandler, Injectable} from '@angular/core';
import {ApplicationInsights, IApplicationInsights, IPageViewTelemetry} from '@microsoft/applicationinsights-web';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TrackingService {

    private applicationInsights: IApplicationInsights;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {
        if (!environment.applicationInsights.instrumentationKey) {
            console.warn('Can\'t start Microsoft Application Insights because the instrumentation key is missing.');
            return;
        }

        this.applicationInsights = new ApplicationInsights({
            config: environment.applicationInsights,
        }).loadAppInsights();
    }

    public init(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.pageView({
                    uri: event.url,
                }, this.route.snapshot.data);
            }
        });
    }

    private isInsightsAvailable(): boolean {
        return !!this.applicationInsights;
    }

    public pageView(pageView: IPageViewTelemetry, data: {[key: string]: any} = {}): void {
        if (!this.isInsightsAvailable()) { return; }

        this.applicationInsights.trackPageView(pageView, data);
    }

    public exception(exception, data: {[key: string]: any} = {}): void {
        if (!this.isInsightsAvailable()) { return; }

        this.applicationInsights.trackException({exception}, data);
    }

}

@Injectable()
export class TrackingErrorHandler implements ErrorHandler {

    constructor(
        private trackingService: TrackingService,
    ) {
    }

    public handleError(error): void {
        this.trackingService.exception(error);

        throw error; // Rethrow the error otherwise it's swallowed
    }

}
