import {Component, Input, AfterViewInit, OnInit} from '@angular/core';
import {Choice} from '../../models/choice';
import {UntypedFormBuilder} from '@angular/forms';
import {SearchableSettings} from '../../enums/searchable-settings';
import {Observable} from 'rxjs';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';
import {AbstractChoiceQuestionComponent} from '../../classes/abstract-choice-question.component';

@Component({
    selector: 'app-radio-searchable',
    templateUrl: 'radio-searchable.html',
})
export class RadioSearchableComponent extends AbstractChoiceQuestionComponent implements OnInit, AfterViewInit {

    @Input() public content: HTMLIonContentElement;
    public filteredChoices: Choice[] = [];
    public listChoices: Choice[] = [];
    public selectedChoice: Choice;
    public isKeyboardClosed$: Observable<boolean>;
    public maxListChoices: number = SearchableSettings.MAX_ITEM_RESULT;

    constructor(
        fb: UntypedFormBuilder,
        private deviceKeyboardService: DeviceKeyboardService,
    ) {
        super(fb);
    }

    ngOnInit() {
        super.ngOnInit();
        this.isKeyboardClosed$ = this.deviceKeyboardService.isKeyboardClosed();
    }

    ngAfterViewInit(): void {
        this.setSelectedChoice();
    }

    choicesFiltered(filteredChoices: Choice[]) {
        this.filteredChoices = filteredChoices;
        this.listChoices = [];
        this.appendToListChoices();
    }

    public choose(choice: Choice, newAddition?: boolean): void {
        if (this.disabled) { return; }

        if (this.question.choices.indexOf(choice) !== -1) {
            this.questionForm.get('current').setValue(choice.title);
        }

        this.setSelectedChoice();
        this.valueChange.emit(this.questionForm.get('current').value);
    }

    setSelectedChoice(): void {
        this.selectedChoice = this.question.choices.find((choice) => this.questionForm.get('current').value === choice.title);
    }

    isChoiceSelected(choice: Choice): boolean {
        return this.questionForm.get('current').value === choice.title;
    }

    appendToListChoices(event?): void {
        this.listChoices = this.filteredChoices.slice(0, Math.min(SearchableSettings.MAX_ITEM_RESULT, this.listChoices.length + SearchableSettings.INFINITE_SCROLL_ITEM_INCREMENT));
        if (event) {
            event.target.complete();
        }
    }

    scrollingToBottom() {
        // Add items to the list before scrolling down
        this.listChoices = this.filteredChoices.slice(0, SearchableSettings.MAX_ITEM_RESULT);
    }

}
