<app-dashboard-header [showDashboardButton]="false" [showBackButton]="true" [showLanguageSelection]="true"></app-dashboard-header>

<ion-content class="has-header">
    <div class="card">
        <div class="card__header">
            <h2 class="card__title">{{ 'REGISTRATION.title' | translate }}</h2>
        </div>
        <form [formGroup]="registrationForm" (ngSubmit)="createAccount()">
            <div class="fields">
                <ion-item lines="none" class="item__no-padding">
                    <ion-input formControlName="emailAddress" type="email" placeholder="{{ 'REGISTRATION.email' | translate }}"></ion-input>
                </ion-item>
                <ion-item lines="none" class="item__no-padding">
                    <ion-input formControlName="firstName" type="text" placeholder="{{ 'REGISTRATION.first_name' | translate }}"></ion-input>
                </ion-item>
                <ion-item lines="none" class="item__no-padding">
                    <ion-input formControlName="lastName" type="text" placeholder="{{ 'REGISTRATION.sur_name' | translate }}"></ion-input>
                </ion-item>
                <ion-item lines="none" class="item__no-padding">
                    <ion-input formControlName="mobileNumber" type="phone" placeholder="{{ 'REGISTRATION.mobile_phone' | translate }}"></ion-input>
                </ion-item>
            </div>
            <ion-item lines="none" class="explanation">
                <ion-label class="light ion-text-wrap">{{ 'REGISTRATION.password_explanation' | translate }}</ion-label>
            </ion-item>
            <div class="fields">
                <ion-item lines="none" class="item__no-padding">
                    <ion-input [formControl]="toFormControl(registrationForm.get('passwords').get('plainPassword'))" type="password" placeholder="{{ 'REGISTRATION.password' | translate }}"></ion-input>
                </ion-item>
                <ion-item lines="none" class="item__no-padding">
                    <ion-input [formControl]="toFormControl(registrationForm.get('passwords').get('plainPasswordRepeat'))" type="password" placeholder="{{ 'REGISTRATION.repeat_password' | translate }}"></ion-input>
                </ion-item>
            </div>
            <p *ngIf="showPasswordRepeatError()" class="text-color-danger ml-5">
                {{ 'REGISTRATION.passwords_dont_match' | translate }}
            </p>
            <ion-item lines="none" class="item__no-padding privacy-policy-checkbox">
                <ion-checkbox formControlName="acceptPrivacyPolicy"></ion-checkbox>
                <ion-label class="light">{{ 'REGISTRATION.approve' | translate }} <a (click)="openPrivacyPolicy()">{{ 'REGISTRATION.privacy' | translate }}</a></ion-label>
            </ion-item>
            <ion-button type="submit" expand="block" color="primary" class="card__btn" [disabled]="!registrationForm.valid">
                {{ 'REGISTRATION.create_account' | translate }}
            </ion-button>
        </form>
    </div>
</ion-content>
