export const TRANSLATION_TR = {
    DATE: {
        long: 'DD MMMM YYYY [-] HH:mm',
        date: 'DD-MM-YYYY',
    },
    LOGIN: {
        title: 'Hoş geldiniz!’',
        slogan: 'Tetikte Olun! Önce Güvenlik!',
        buttons: {
            external: 'Üçüncü taraf çalışanım',
            internal: 'VolkerWessels çalışanıyım',
        },
    },
    BUTTONS: {
        remove: 'Sil',
        ok: 'Tamam',
        yes: 'Evet',
        yes_and_send: 'Evet, gönder',
        no: 'Hayır',
        cancel: 'İptal',
        again: 'Tekrar',
        back: 'Geri',
        submit: 'Gönder',
        skip: 'Geç',
        next: 'İleri',
    },
    DASHBOARD: {
        tile: {
            home: 'Ana sayfa',
            forms: 'Formlar',
            compliment: 'Ödüller',
            compliment_desc: 'Güvenli çalıştığı için bir meslektaşıma iltifat etmek istiyorum.',
            scan_qr: 'QR\'yi tarayın',
            scan_qr_desc: 'Doğru forma gidebilmek için bir QR kodu istiyorum.',
            instruction: 'WAVE programı',
            more: 'Daha fazla',
            title: 'Merhaba, ne yapacaksın?',
            subtitle: 'Lütfen aşağıdan bir seçim yapın',
        },
        report: {
            incident: 'Olay raporla veya MOHS',
            incident_desc: 'Bir olayı, güvenli olmayan uygulamayı, ortamı ve/veya patlamayı veya yangını bildirmek istiyorum.',
        },
        tasks: {
            notification: {
                singular: 'You have <b>{{value}} open</b> task',
                plural: 'You have <b>{{value}} open</b> tasks',
            },
        },
        deprecation: {
            title: 'Lütfen dikkat',
            text: '1 Eylül\'den itibaren ETT WAVE uygulamasını kullanmanız gerekecektir. İndirmek için buraya tıklayın.',
        },
    },
    DASHBOARD_MORE: {
        tile: {
            history: 'Geçmiş',
            news: 'Haberler',
            cardGame: 'WAVE hızlandırıcı',
            tasks: 'Görevler',
        },
        tasks: {
            notification: {
                singular: '{{value}} open task',
                plural: '{{value}} open tasks',
            },
        },
    },
    HEADER: {
        structure: 'New form',
    },
    PUSH: {
        news: {
            title: 'Haber öğesi al',
        },
        task: {
            title: 'Görev atandı',
        },
        instruction: {
            title: 'WAVE programı al',
        },
    },
    DISCLAIMER: {
        heading: 'FERAGATNAME',
        title: 'Önemli:',
        content: 'Özel olarak istenmedikçe kişisel verilerinizi (isim, telefon numarası, plaka numarası gibi) girmeyin. ' +
            'Eğer bir fotoğraf yüklenmesi gerekli ise, herhangi bir bireyin görünür olmadığından emin olun.',
        buttons: {
            agree: 'Evet, feragatnameyi okudum',
        },
    },
    HELP: {
        title: 'Yardım Merkezi',
        app_version: 'Uygulama sürümü:',
        intro: 'WAVE uygulaması hakkında sıkça sorulan sorular aşağıda listelenmiştir: Sorunuzun cevabını burada bulamadıysanız, lütfen şirketin BT yardım hizmetleri ile iletişime geçin.',
        phone_number: 'Telefon numarası',
        tenant_phone_numbers: {
            'be36ab0a-ee39-47de-9356-a8a501a9c832': '088 186 0606', // VWT
            '0ef544fb-3d5c-4c60-932e-735bcef00405': '088 186 7777', // BVGO
            'cdc477bf-b6e3-4345-b1be-3b225394e17e': '088 186 2555', // VW Infra
            '957801e8-a608-42e6-afc7-a2e6399ec203': '085 800 1221', // V&SH
        },
        buttons: {
            privacy: 'Gizlilik beyanı',
            instruction_film: 'Talimat videosu',
        },
    },
    CONTACT: {
        title: 'İrtibat bilgileri',
        subtitle: 'See your account information, log out or change the app language.',
        email_address: 'E-posta adresi',
        phone_number: 'Telefon numarası',
        fill_phone_number: 'Telefon numaranızı girin',
        language_selection: 'Dil seçimi',
        save: 'Değişiklikleri kaydet',
        logout: 'Çıkış yap',
        change_password: 'Şifre değiştir',
        remove_account: 'Hesabı sil',
        confirm_external_modal: {
            heading: 'Hesabı sil',
            body: 'Hesabı silmek istediğinizden emin misiniz?',
            button: 'Evet, hesabı sil',
        },
        removing_account: 'Hesabı siliniyor',
        account_removed: 'Hesabınız başarıyla silindi.',
    },
    DEEPLINK_QUESTIONNAIRE: {
        title: 'Formu aç',
        unfortunately: 'Ne yazık ki...',
        ok_and_close: 'Tamam, sayfayı kapat',
    },
    EXTERNAL_LOGIN: {
        title: 'Harici hesap',
        email: 'E-posta',
        password: 'Şifre (8 veya daha fazla karakter)',
        login: 'Giriş yap',
        forgot_password: 'Şifremi unuttum',
        create_account: 'Hesap oluştur',
    },
    FAQ: {
        title: 'SSS',
        sections: [{
            title: '',
            questions: [{
                question: 'Formlarla ilgili bir sorum olursa kime sorabilirim?',
                answer: 'Uygulamadaki formların içerikleri hakkındaki sorular için, şirketiniz dahilindeki işlevsel yönetici (Genellikle QHSE (Kalite Sağlık Güvenlik Çevre) çalışanı veya güvenlik uzmanıdır) ile irtibata geçebilirsiniz.',
                open: false,
            }, {
                question: 'Tüm soruları cevaplayamazsam ne yapmalıyım?',
                answer: 'Tabii ki her sorunun cevabını bilmeyebilirsiniz. Sadece eminseniz cevaplayın. Aksi takdirde, cevabı bilmediğinizi belirtin.',
                open: false,
            }, {
                question: 'Uygulama ile ilgili teknik bir sorum olursa kime sorabilirim?',
                answer: 'Uygulama ile ilgili teknik sorular için, şirketinizin BT yardım merkezi ile irtibata geçebilirsiniz. Telefon numarasını bu sayfanın en üstünde bulabilirsiniz.',
                open: false,
            }, {
                question: '“Formlar” altındaki turuncu numara nedir?',
                answer: 'Bir veya birden fazla form tamamlanmamış ve/veya gönderilmemişse ana sayfanızda bir numara görünecektir. Bu numara formların sayısını belirtir. ' +
                    'Tamamlanmamış ve/veya gönderilmemiş formları sayfanın alt kısmında bulabilirsiniz. Bunlara tıklayarak, formları tamamlayabilir ve gönderebilirsiniz. Tamamlanmamış ve/veya gönderilmemiş formları silmek de mümkündür.',
                open: false,
            }, {
                question: 'Göndermiş olduğum bir rapor üzerinde değişiklik yapabilir miyim?',
                answer: 'Hayıt, maalesef bu mümkün değildir. Ancak, şirketiniz dahilindeki işlevsel yönetici (Genellikle QHSE (Kalite Sağlık Güvenlik Çevre) çalışanı veya güvenlik uzmanıdır) ile irtibata geçip bu değişiklikleri iletebilirsiniz.',
                open: false,
            }, {
                question: 'Raporumu teslim ettikten sonra ne oluyor?',
                answer: 'Raporlar otomatik olarak projeden sorumlu kişiye gönderilir. Bu kişinin kim olduğu rapora göre değişiklik gösterebilir. ' +
                    'işlevsel yönetici (Genellikle QHSE (Kalite Sağlık Güvenlik Çevre) çalışanı veya güvenlik uzmanıdır) formun bir kopyasını alır. Ek olarak, siz de formun bir kopyasını mail yoluyla alırsınız.',
                open: false,
            }, {
                question: 'Bir raporu silebilir miyim?',
                answer: 'Evet, silebilirsiniz. Ancak, bu uygulama aracılığıyla mümkün değildir. Formu silmek için işlevsel yönetici (Genellikle QHSE (Kalite Sağlık Güvenlik Çevre) çalışanı veya güvenlik uzmanıdır) ile irtibata geçin.',
                open: false,
            }, {
                question: 'Uygulamayı çevrimdışı kullanabilir miyim?',
                answer: 'Evet, kullanabilirsiniz. Formu her zaman uygulama üzerinden tamamlayabilir ve kaydedebilirsiniz. Form tamamlanmamışsa, gönderilemeyeceğine dair bir mesaj alırsınız Form taslak olarak kaydedilecektir. ' +
                    'Taslak siz internete bağlandığınızda otomatik olarak gönderilmeyecektir. Taslağı açmanız ve göndermeniz gereklidir. ' +
                    'Ne yazık ki, harici kullanıcılar çevrimdışı olarak tüm formları dolduramazlar. Sadece güvenli olmayan durumları raporlayabilirler. ' +
                    'Diğer formlar için çevrimiçi kullanmaları gereklidir.',
                open: false,
            }, {
                question: 'Bir alt yüklenici uygulamayı kullanabilir mi?',
                answer: 'Evet, kullanabilir. Bir alt yüklenici harici kullanıcı olarak giriş yapabilir.',
                open: false,
            }, {
                question: 'Telefon numaramı değiştirebilir miyim?',
                answer: 'Evet, değiştirebilirsiniz. Tüm VolkerWessels çalışanları telefon numaralarını değiştirebilir. Bunu uygulamadaki profilinizden yapabilirsiniz.',
                open: false,
            }],
        }],
    },
    NOTIFICATION: {
        title: 'Rapor',
        warning: 'Uyarı',
        close: 'Kapat',
        open: 'Açık',
        error_general: 'Beklenmedik bir hata oluştu',
        error_retrieving_profile: 'Profile erişirken bir hata oluştu',
        something_wrong_saving: 'Formu teslim ederken bir hata oluştu',
        something_wrong_task: 'Görev gönderilirken bir hata oluştu Yorumları tamamla veya tekrar dene',
        something_wrong_logout: 'Çıkıl yapılırken bir hata oluştu.',
        offline: 'Şu anda çevrimdışı çalışıyorsunuz. Sonuç olarak, bu işlev şu anda mevcut değildir.',
        account_removed_failed: 'Hesabınızı silerken bir hata oluştu. Daha sonra tekrar deneyin.',
        inactive_form: 'Bu form artık geçerli değildir. Formu tekrar doldurun.',
        no_access: 'WAVE’ye erişiminiz yoktur. Yöneticinizle iletişime geçin',
        error_message: 'Hata mesajı',
        login_failed: 'Giriş yapılamadı',
        error_logged_out: 'Ne yazık ki, verilere erişirken bir hata oluştu. Çıkış yapıyorsunuz',
        retrieving_options: 'Erişim seçenekleri/iletişim',
        aggregating: 'Yığın veriler',
        update_failed: 'Düzenleme yapılamadı',
        retrieval_failed: 'Erişim başarısız',
        error_forms_no_network: 'Form düzenleme başarısız (ağ bağlantısı yok). Formu düzenleme tekrar denensin mi?',
        error_forms_slow_network: 'Form düzenleme başarısız (ağ bağlantısı yavaş). Formu düzenleme tekrar denensin mi?',
        error_forms_retrieval_no_network: 'Form erişimi başarısız (ağ bağlantısı yok). Ağ bağlantısını kontrol edin ve tekrar deneyin.',
        error_forms_retrieval_slow_network: 'Form erişimi başarısız (ağ bağlantısı yavaş). Ağ bağlantısını kontrol edin ve tekrar deneyin.',
        error_popup_blocked: 'Oturum açmak için pop-up\'lara izin ver',
        image_file_needed: 'Dosya geçerli bir resim değil',
        route_not_found: 'Yönlendirme başarısız oldu',
        route_not_found_explanation: 'Sayfaya veya forma yönlendirme başarısız oldu. Bunun nedeni sizin için erişilemez olması, sayfanın veya formun artık mevcut olmaması veya formun sizin dilinizde mevcut olmaması olabilir.',
    },
    FORGOT_PASSWORD: {
        title: 'Şifremi unuttum',
        subtitle: 'E-posta adresiniz biliniyorsa, şifrenizi sıfırlamak için gerekli talimatları içeren bir e-posta alacaksınız.',
    },
    FORGOT_PASSWORD_FORM: {
        title: 'E-posta adresini girin',
        email: 'E-posta',
        next: 'İleri',
    },
    NEWS: {
        title: 'Haberler',
        subtitle: 'WAVE nieuws berichten',
        search: 'Search by news',
    },
    MEDIA_COMMUNICATION: {
        subtitle: {
            theme: 'Choose a theme',
            subject: 'Choose a topic',
        },
        search: {
            theme: 'Search by theme',
            subject: 'Search by topic',
        },
    },
    SEARCH_PLACEHOLDER: 'Ara',
    PASSWORD_CHANGE: {
        title: 'Şifre değiştir',
        current_password: 'Mevcut şifre',
        password_explanation: 'Şifre en az 8 karakterden oluşmalı ve bir büyük harf, bir sayı ve bir özel karakter içermelidir',
        new_password: 'Yeni şifre',
        repeat_new_password: 'Tekrar yeni şifre',
        passwords_dont_match: 'Şifreler uyuşmuyor',
        password_changed: 'Şifreniz değiştirildi Tekrar giriş yapmalısınız',
        current_password_incorrect: 'Girilen şifre mevcut şifre ile uyuşmuyor',
        confirm: 'Onayla',
    },
    QUESTIONNAIRE: {
        part: 'Bileşen',
        map_offline: 'Çevrimdışı çalışıyorsunuz. Kart sadece çevrimiçi modda mevcuttur.',
        from: 'from',
    },
    QUESTIONNAIRE_HISTORY: {
        title: 'Gönderilmiş formlar',
        no_forms: 'Henüz bir rapor gönderilmedi.',
        submitted_at: 'Teslim edilme tarihi',
        processed_at: 'İşlenme tarihi',
        processed_submitted_at: 'İşlenme, teslim edilme tarihi',
    },
    QUESTIONNAIRE_REPEAT_QUESTION: {
        part: 'Bileşen',
    },
    QUESTIONNAIRE_SENT: {
        title: 'Teşekkürler',
        subtitle: 'Formunuz işleniyor. Formun bir kopyası size e-posta olarak gönderilecektir. Tamamlanan formları bu uygulamanın Geçmiş bölümünden bulabilirsiniz.',
    },
    QUESTIONNAIRE_TASK: {
        created_on: 'Oluşturulma tarihi:',
        completed_on: 'Tamamlanma tarihi:',
        place_remark: 'Yorum(lar)ınızı buraya yapın',
        remark: 'Yorum(lar):',
        task_denied: 'İşlenmedi',
        task_completed: 'İşlendi',
        are_you_sure: 'Bu görevde "{{statusLabel}}" ayarlamak istediğinize emin misiniz?',
        page_leave: 'Sayfadan ayrıl',
        page_leave_explanation: 'Görevin “yorumlar” bölümünü tamamladınız. Sayfadan ayrılısanız, değişiklikler kaybedilecektir. Sayfadan ayrılmak istediğinizden emin misiniz?',
    },
    QUESTIONNAIRE_TASKS: {
        title: 'Devam edilen görevler',
        subtitle: 'Click on a task to execute it',
        planned_forms: 'Planlanan formlar',
        planned_forms_subtitle: 'Below are forms that must be sent by a certain date',
        closed_tasks: 'Tamamlanan görevler',
        closed_tasks_subtitle: 'Below are tasks which have been completed',
        closed_on: 'Tamamlanma tarihi:',
        deadline_passed: 'Son teslim tarihi geçti:',
        deadline_at: 'Son teslim tarihi şu tarihte geçti:',
        created_at: 'Oluşturulma tarihi:',
    },
    CARD_GAME: {
        question: {
            label: 'Sorular',
        },
        toolbar: {
            button_label: 'Yeni kart',
            close: 'Kapat',
        },
    },
    ERROR: {
        form: {
            warning: 'Uyarı',
            not_found: 'Henüz dilinizde mevcut form yok.',
            directIncidentOnly: '“Olay raporla veya MOHS” için form şu anda mevcut değil Lütfen sistem yöneticinizle iletişime geçin.',
            directComplimentOnly: 'Bu form sizin dilinizde mevcut değil.',
            questionnaireIdToSelect: 'Bu görev seçilen kuruluş birimi için şu anda mevcut değildir. Lütfen başka bir kuruluş birimi seçin veya sistem yöneticiniz ile iletişime geçin.',
        },
        no_access: {
            title: 'hak yok',
            explanation: 'Bu formlara erişiminiz yok. Ana ekrana geri dönün.',
        },
    },
    REGISTRATION: {
        title: 'Yeni hesap oluştur',
        email: 'E-posta',
        first_name: 'Adı',
        sur_name: 'Soyadı',
        mobile_phone: 'Telefon numarası',
        password: 'Şifre',
        password_explanation: 'Şifre en az 8 karakterden oluşmalı ve bir büyük harf, bir sayı ve bir özel karakter içermelidir',
        repeat_password: 'Tekrar şifre',
        passwords_dont_match: 'Şifreler uyuşmuyor',
        approve: 'Kabul ediyorum',
        privacy: 'Gizlilik politikası',
        create_account: 'Hesap oluştur',
        account_exists: 'Bu e-posta adresine bağlı başka bir hesap bulunuyor',
        error_something_wrong: 'Kayıt işleminde bir hata oluştu',
    },
    REGISTRATION_COMPLETE: {
        title: 'e-posta adresinizi doğrulayın',
        subtitle: 'Hesap oluşturduğunuz için teşekkür ederiz Birazdan e-posta adresinizi doğrulamanız için bir e-posta alacaksınız',
    },
    REGISTRATION_SMS: {
        title: 'Telefon numarası doğrulama',
        subtitle: 'Mesaj olarak aldığınız doğrulama kodunu girin. Bu kod belirtilen telefon numarasına gönderilecektir.',
        verification_code: 'Doğrulama kodu',
        verify: 'Doğrulama kodunu kontrol edin',
        incorrect_code: 'Geçersiz doğrulama kodu',
        error_something_wrong: 'Doğrulama sırasında bir hata oluştu',
    },
    SELECT_QUESTIONNAIRE: {
        open_forms: 'Henüz tamamlanmamış formlar',
        open_forms_subtitle: 'Click on a form to continue',
        new_form: 'New form',
        new_form_subtitle: 'Click to start a new form',
        planned_forms: 'Planlanan formlar',
        available_forms: 'Mevcut formlar',
        workplace_forms: 'İş yeri denetimi',
        retrieve_forms: 'Formlara erişim',
        retrieve_form: 'Forma erişim',
        choose_action: 'Eyleminizi seçin',
        open: 'Açık',
        upload: 'Çevrimiçi hale getirin',
        download_and_open: 'Formu indirin ve açın',
        remove: 'Sil',
        something_went_wrong: 'Formlara erişirken bir hata oluştu',
    },
    SELECT_STRUCTURE: {
        choose_project: 'Projenizi seçin',
        choose_business_unit: 'İş biriminizi seçin',
        no_organisations: 'Hiçbir kuruluş mevcut değil.',
        search_on: 'Şuna göre ara:',
        choose_your: 'Select your',
        organisation: 'şirket',
        project: 'proje',
        projects: 'Projeler',
        business_unit: 'İş birimi',
        business_units: 'İş birimleri',
    },
    SEND_QUESTIONNAIRE: {
        title: 'Formu göndermek istiyor musunuz?',
        description: 'Bu formu göndererek artık bu gönderilen formdaki hiçbir şeyi değiştiremezsiniz.',
        account_blocked: 'Bu form hesabınız onaylanana kadar gönderilemez',
        offline: 'Çevrimdışı çalışıyorsunuz, yani formu gönderemezsiniz. Çevrimiçi olur olmaz formu gönderin',
        something_went_wrong: 'Formu teslim ederken bir hata oluştu',
        confirm: 'Evet Gönder',
        cancel: 'Geri dönüş yok',
    },
    SCAN_QR: {
        incorrect_result: 'Yanlış QR kodu. Doğru bir WAVE QR kodunu tarayın.',
        no_result: 'QR kodu taranmadı.',
        no_permission: 'Uygulama kameraya erişemiyor. QR kodunu taramadan önce uygulamaya izin verin.',
        try_again: 'Tekrar tara',
    },
    COMPONENTS: {
        attachments_remarks: {
            add: 'Ekle',
            place_remark: 'Yorum(lar)ınızı buraya yapın',
            max_attachments_reached: 'Maksimum ek sayısına ulaşıldı.',
            note: 'Note',
            picture: 'Picture',
        },
        checkbox: {
            multiple_choices_possible: 'Birden fazla seçenek mevcut',
            no_choices_selected: 'Hiçbir seçenek seçilmedi.',
        },
        checkbox_searchable: {
            selected_choices: 'Seçilen seçenek(ler)',
            no_choices_found: 'Hiçbir seçenek bulunmadı.',
            visible_choices: '{{filteredChoices}} seçeneklerinin {{maxListChoices}} görünür.',
            limit_choices: 'Seçenek listesini arama yaparak kısıtlayın',
        },
        lat_long: {
            current_location: 'Mevcut konumu kullan',
            or: 'veya',
            city: 'Şehir/Eyalet',
            address: 'Adres',
            invalid_address: 'Geçerli adres değil.',
        },
        manual_choice: {
            new_choice: 'Yeni seçenek girin',
            choice_exists: 'Bu seçenek zaten mevcut',
        },
        photo: {
            subtitle: 'Artı işaretine tıklayarak fotoğraf ekleyin',
            gallery: 'Fotoğraf galerisi',
            drop_explanation: 'Fotoğrafları eklemek için buraya tıklayın, sürükleyin veya yapıştırın',
            max_reached: 'Eklenebilecek maksimum fotoğraf sayısına ulaşıldı.',
            take_a_photo: 'Fotoğraf çekmek',
            upload_from_gallery: 'Galeriden yükle',
        },
        questionnaire_footer: {
            chapters: 'Bölümler',
            previous: 'Geri',
        },
        questionnaire_overlay: {
            help_text: 'Soruyu cevapladıktan sonra, bir sonraki soruya geçmek için “İleri” tuşuna tıklayın.',
        },
        radio: {
            choose: 'Bir seçin yapın',
            no_choice_selected: 'Hiçbir seçenek seçilmedi.',
        },
        radio_searchable: {
            selected_choice: 'Seçilen seçenek',
            no_choice_found: 'Hiçbir seçenek bulunmadı.',
            visible_choices: '{{filteredChoices}} seçeneklerinin {{maxListChoices}} görünür.',
            limit_choices: 'Seçenek listesini arama yaparak kısıtlayın',
        },
        searchable_choices: {
            search: 'Ara',
        },
        select_concept: {
            last_change: 'En son düzenlenen',
        },
        select_item: {
            submit_before: 'aha önce teslim edilen',
        },
    },
};
