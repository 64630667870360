<ion-item lines="none" class="select-concept">
    <div class="select-concept__item">
        <div class="select-concept__row">
            <div class="select-concept__meta">
                <ion-label class="select-concept__title">{{ concept.questionnaire_title || '' }}</ion-label>
                <ion-label class="select-concept__description">{{ concept.questionnaire_parent_name || '' }}</ion-label>
            </div>
            <div class="select-concept__badge">
                <ion-badge>{{ concept.updated_at | dateFormat: ('DATE.date' | translate) }}</ion-badge>
            </div>
        </div>
        <ion-progress-bar [value]="0" class="select-concept__progress"></ion-progress-bar>
    </div>
</ion-item>
