<app-generic-header [showOrganizationName]="false"></app-generic-header>

<ion-content class="has-header">
    <div class="card">
        <div class="card__header">
            <h2 class="card__title">{{ 'HELP.title' | translate }}</h2>
            <p class="card__description">{{ 'HELP.app_version' | translate }} {{versionNumber}}</p>
        </div>
        <div class="card__header">
            <p class="card__description">{{ 'HELP.intro' | translate }}</p>

            <p *ngIf="hasPhoneNumber" class="card__description">
                <span>{{ 'HELP.phone_number' | translate }}:</span>
                <a [href]="'tel:' +  tenantPhoneNumbers[currentTenantId]" class="card__link">
                    {{ tenantPhoneNumbers[currentTenantId] }}
                </a>
            </p>
            <p *ngIf="hasInstructionFilm()" (click)="openInstructionFilm()" class="card__link">{{ 'HELP.buttons.instruction_film' | translate }}</p>
        </div>
        <h2 class="card__title">{{ 'FAQ.title' | translate }}</h2>
        <div class="support__items">
            <div *ngFor="let section of sections" class="faq-section">
                <p *ngIf="section.title" class="section-title"><strong>{{section.title}}</strong></p>
                <div [id]="'question' + i" *ngFor="let question of section.questions; let i = index" class="question"
                     [class.open-faq-item]="question.open">
                    <div class="question-header" (click)="toggleQuestion('question'+i, question)">
                        <p>{{question.question}}</p>
                        <ion-icon src="assets/images/icon-arrow-right.svg" class="select-item__icon-circle select-item__icon-circle--faq" [class.select-item__icon-circle--faq-active]="question.open"></ion-icon>
                    </div>
                    <p class="question-answer">{{question.answer}}</p>
                </div>
            </div>
        </div>
        <p (click)="openPrivacyPolicy()" class="card__link">{{ 'HELP.buttons.privacy' | translate }}</p>
    </div>
</ion-content>
