import {Component, ViewChild} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {IonContent} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {BrowserService} from '../../services/browser-service';
import {AuthService} from '../../services/auth-service';
import {PrivacyPolicyService} from '../../services/privacy-policy.service';
import {FaqSection} from '../../models/faq';

@Component({
    selector: 'page-support',
    templateUrl: './support.html',
})
export class SupportPage {

    public currentTenantId: string;
    public hasPhoneNumber = false;
    public versionNumber: string;
    public tenantPhoneNumbers: Record<string, unknown>;
    public sections: FaqSection[];

    @ViewChild(IonContent, {static: false}) content: IonContent;

    constructor(
        private browserService: BrowserService,
        private authService: AuthService,
        private translateService: TranslateService,
        private privacyPolicyService: PrivacyPolicyService,
    ) {
        try {
            const tokenPayload = new JwtHelperService().decodeToken(this.authService.token);
            this.currentTenantId = tokenPayload.tid;
        } catch (e) {
        }

        this.versionNumber = environment.version;

        this.tenantPhoneNumbers = this.translateService.instant('HELP.tenant_phone_numbers');

        if (typeof this.tenantPhoneNumbers[this.currentTenantId] !== 'string') {
            // unknown tenant, or external user. fall-back to common
            this.currentTenantId = 'common';
        }

        this.hasPhoneNumber = typeof this.tenantPhoneNumbers[this.currentTenantId] === 'string';

        this.sections = this.translateService.instant('FAQ.sections') as FaqSection[];
    }

    public toggleQuestion(answerId, question: any) {
        question.open = !question.open;

        if (question.open) {
            this.content.scrollToPoint(0, document.getElementById(answerId).offsetTop, 100);
        }
    }

    public openPrivacyPolicy(): void {
        this.privacyPolicyService.openPrivacyPolicyUrl();
    }

    public hasInstructionFilm(): boolean {
        return environment.instructionFilmUrl !== '';
    }

    public openInstructionFilm() {
        this.browserService.openUrl(environment.instructionFilmUrl);
    }

}
