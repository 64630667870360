<app-generic-header [showOrganizationName]="false"></app-generic-header>

<ion-content class="has-header">
    <ng-container *ngIf="tasksOpen && tasksOpen.length > 0">
        <div class="card">
            <div class="card__header">
                <h2 class="card__title">{{ 'QUESTIONNAIRE_TASKS.title' | translate }}</h2>
                <p class="card__description">{{ 'QUESTIONNAIRE_TASKS.subtitle' | translate }}</p>
            </div>
            <app-select-item
                *ngFor="let task of tasksOpen"
                [title]="task.ticket_number + ': ' + task.comments"
                [hasSubTitleExclamationIcon]="hasSubTitleIcon(task)"
                [subTitle]="getSubTitle(task)"
                (click)="openQuestionnaireTask(task)"
                [ngClass]="{'icon-warning': nearDeadline(task), 'icon-danger': pastDeadline(task)}"
            ></app-select-item>
        </div>
    </ng-container>

    <ng-container *ngIf="reminders && reminders.length > 0">
        <div class="card">
            <div class="card__header">
                <h2 class="card__title">{{ 'QUESTIONNAIRE_TASKS.planned_forms' | translate }}</h2>
                <p class="card__description">{{ 'QUESTIONNAIRE_TASKS.planned_forms_subtitle' | translate }}</p>
            </div>
            <app-select-item
                *ngFor="let reminder of reminders"
                [title]="reminder.questionnaire_title"
                [deadlineAt]="reminder.deadline_date | dateFormat:('DATE.date' | translate)"
                [numberOfConcepts]="reminder.concepts"
                (click)="openReminder(reminder)"
            ></app-select-item>
        </div>
    </ng-container>

    <ng-container *ngIf="tasksCompleted && tasksCompleted.length > 0">
        <div class="card">
            <div class="card__header">
                <h2 class="card__title">{{ 'QUESTIONNAIRE_TASKS.closed_tasks' | translate }}</h2>
                <p class="card__description">{{ 'QUESTIONNAIRE_TASKS.closed_tasks_subtitle' | translate }}</p>
            </div>
            <app-select-item
                *ngFor="let task of tasksCompleted"
                [title]="task.ticket_number + ': ' + task.comments"
                [subTitle]="( 'QUESTIONNAIRE_TASKS.closed_on' | translate) + (task.filled_in_at|dateFormat:('DATE.date' | translate))"
                (click)="openQuestionnaireTask(task)"
            ></app-select-item>
        </div>
    </ng-container>
</ion-content>
