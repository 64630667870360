import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {SupportPage} from '../pages/support/support';
import {SendQuestionnairePage} from '../pages/send-questionnaire/send-questionnaire';
import {SelectQuestionnairePage} from '../pages/select-questionnaire/select-questionnaire';
import {SelectStructureNodePage} from '../pages/select-structure-node/select-structure-node';
import {RegistrationVerifySmsPage} from '../pages/registration-verify-sms/registration-verify-sms';
import {RegistrationCompletePage} from '../pages/registration-complete/registration-complete';
import {RegistrationPage} from '../pages/registration/registration';
import {QuestionnaireTasksPage} from '../pages/questionnaire-tasks/questionnaire-tasks';
import {QuestionnaireTaskPage} from '../pages/questionnaire-task/questionnaire-task';
import {QuestionnaireSentPage} from '../pages/questionnaire-sent/questionnaire-sent';
import {QuestionnaireHistoryPage} from '../pages/questionnaire-history/questionnaire-history';
import {QuestionnaireChaptersPage} from '../pages/questionnaire-chapters/questionnaire-chapters';
import {QuestionnairePage} from '../pages/questionnaire/questionnaire';
import {PasswordChangeFormPage} from '../pages/password-change-form/password-change-form';
import {NewsListPage} from '../pages/news-list/news-list';
import {NewsDetailsPage} from '../pages/news-details/news-details';
import {MediaCommunicationListPage} from '../pages/media-communication-list/media-communication-list';
import {MediaCommunicationDetailsPage} from '../pages/media-communication-details/media-communication-details';
import {ForgotPasswordFormPage} from '../pages/forgot-password-form/forgot-password-form';
import {ForgotPasswordCompletePage} from '../pages/forgot-password-complete/forgot-password-complete';
import {ExternalAccountLoginPage} from '../pages/external-account-login/external-account-login';
import {DeeplinkQuestionnaireOpenerPage} from '../pages/deeplink-questionnaire-opener/deeplink-questionnaire-opener';
import {DashboardPage} from '../pages/dashboard/dashboard';
import {ContactPage} from '../pages/contact/contact';
import {ChooseLoginPage} from '../pages/choose-login/choose-login';
import {QuestionnaireDisclaimerPage} from '../pages/questionnaire-disclaimer/questionnaire-disclaimer';
import {QuestionnaireRepeatQuestionPage} from '../pages/questionnaire-repeat-question/questionnaire-repeat-question';
import {AuthRedirectGuard} from '../guards/auth-redirect';
import {PageLeaveGuard} from '../guards/page-leave';
import {UnauthorizedRedirectGuard} from '../guards/unauthorized-redirect';
import {DeeplinkRedirectPage} from '../pages/deeplink-redirect/deeplink-redirect';
import {ScanQrPage} from '../pages/scan-qr/scan-qr';
import {DashboardMorePage} from '../pages/dashboard-more/dashboard-more';
import {DashboardFooterLayoutComponent} from '../components/dashboard-footer-layout/dashboard-footer-layout.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'choose-login',
        pathMatch: 'full',
    },
    {
        path: 'choose-login',
        component: ChooseLoginPage,
        canActivate: [AuthRedirectGuard],
    },
    {
        path: 'deeplink/questionnaire/opener',
        component: DeeplinkQuestionnaireOpenerPage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: 'deeplink/questionnaire/opener/:base64data',
        component: DeeplinkQuestionnaireOpenerPage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: 'deeplink/redirect/:base64route',
        component: DeeplinkRedirectPage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: 'external-account-login',
        component: ExternalAccountLoginPage,
    },
    {
        path: 'forgot-password-complete',
        component: ForgotPasswordCompletePage,
    },
    {
        path: 'forgot-password-form',
        component: ForgotPasswordFormPage,
    },
    {
        path: 'password-change-form',
        component: PasswordChangeFormPage,
    },
    {
        path: 'concept/:conceptId/questionnaire/:questionnaireId/disclaimer',
        component: QuestionnaireDisclaimerPage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: 'concept/:conceptId/questionnaire/:questionnaireId/question-path/:questionPath/chapters',
        component: QuestionnaireChaptersPage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: 'concept/:conceptId/questionnaire/:questionnaireId/question-path/:questionPath/answer/:answerIndex',
        component: QuestionnairePage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: 'concept/:conceptId/questionnaire/:questionnaireId/question-path/:questionPath/repeat/answer/:answerIndex',
        component: QuestionnaireRepeatQuestionPage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: 'concept/:conceptId/questionnaire/:questionnaireId/send',
        component: SendQuestionnairePage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: 'concept/:conceptId/questionnaire-sent',
        component: QuestionnaireSentPage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: 'history/questionnaire/:questionnaireId/question-path/:questionPath/chapters',
        component: QuestionnaireChaptersPage,
        canActivate: [UnauthorizedRedirectGuard],
        data: {
            readonly: true,
        },
    },
    {
        path: 'history/questionnaire/:questionnaireId/question-path/:questionPath/answer/:answerIndex',
        component: QuestionnairePage,
        canActivate: [UnauthorizedRedirectGuard],
        data: {
            readonly: true,
        },
    },
    {
        path: 'history/questionnaire/:questionnaireId/question-path/:questionPath/repeat/answer/:answerIndex',
        component: QuestionnaireRepeatQuestionPage,
        canActivate: [UnauthorizedRedirectGuard],
        data: {
            readonly: true,
        },
    },
    {
        path: 'questionnaire-task/:task',
        component: QuestionnaireTaskPage,
        canActivate: [UnauthorizedRedirectGuard],
        canDeactivate: [PageLeaveGuard],
    },
    {
        path: 'registration',
        component: RegistrationPage,
    },
    {
        path: 'registration-complete',
        component: RegistrationCompletePage,
    },
    {
        path: 'registration-verify-sms',
        component: RegistrationVerifySmsPage,
    },

    {
        path: 'all/:structure_node_type/:id/questionnaires',
        component: SelectQuestionnairePage,
        canActivate: [UnauthorizedRedirectGuard],
        data: {
            questionnaireTypes: ['*'],
        },
    },
    {
        path: 'direct/:structure_node_type/:id/questionnaires/:questionnaire_id',
        component: SelectQuestionnairePage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: 'incident/:structure_node_type/:id/questionnaires',
        component: SelectQuestionnairePage,
        canActivate: [UnauthorizedRedirectGuard],
        data: {
            questionnaireTypes: ['incident'],
        },
    },
    {
        path: 'compliment/:structure_node_type/:id/questionnaires',
        component: SelectQuestionnairePage,
        canActivate: [UnauthorizedRedirectGuard],
        data: {
            questionnaireTypes: ['compliment'],
        },
    },
    {
        path: 'scan-qr',
        component: ScanQrPage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: 'support',
        component: SupportPage,
        canActivate: [UnauthorizedRedirectGuard],
    },
    {
        path: '',
        component: DashboardFooterLayoutComponent,
        children: [
            {
                path: 'dashboard',
                component: DashboardPage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'dashboard-more',
                component: DashboardMorePage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'contact',
                component: ContactPage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'questionnaire-history',
                component: QuestionnaireHistoryPage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'questionnaire-tasks',
                component: QuestionnaireTasksPage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'media-communication-details/:mediaCommunicationItemId',
                component: MediaCommunicationDetailsPage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'media-communication-list',
                component: MediaCommunicationListPage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'media-communication-list/:categoryId',
                component: MediaCommunicationListPage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'news-details/:id',
                component: NewsDetailsPage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'news-list',
                component: NewsListPage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'all/:structure_node_type',
                component: SelectStructureNodePage,
                canActivate: [UnauthorizedRedirectGuard],
                data: {
                    questionnaireTypes: ['*'],
                },
            },
            {
                path: 'all/:structure_node_type/:id',
                component: SelectStructureNodePage,
                canActivate: [UnauthorizedRedirectGuard],
                data: {
                    questionnaireTypes: ['*'],
                },
            },
            {
                path: 'direct/:structure_node_type/:questionnaire_id',
                component: SelectStructureNodePage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'direct/:structure_node_type/:id/:questionnaire_id',
                component: SelectStructureNodePage,
                canActivate: [UnauthorizedRedirectGuard],
            },
            {
                path: 'incident/:structure_node_type',
                component: SelectStructureNodePage,
                canActivate: [UnauthorizedRedirectGuard],
                data: {
                    questionnaireTypes: ['incident'],
                },
            },
            {
                path: 'incident/:structure_node_type/:id',
                component: SelectStructureNodePage,
                canActivate: [UnauthorizedRedirectGuard],
                data: {
                    questionnaireTypes: ['incident'],
                },
            },
            {
                path: 'compliment/:structure_node_type',
                component: SelectStructureNodePage,
                canActivate: [UnauthorizedRedirectGuard],
                data: {
                    questionnaireTypes: ['compliment'],
                },
            },
            {
                path: 'compliment/:structure_node_type/:id',
                component: SelectStructureNodePage,
                canActivate: [UnauthorizedRedirectGuard],
                data: {
                    questionnaireTypes: ['compliment'],
                },
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule {
}
