import {Injectable} from '@angular/core';
import {Browser} from '@capacitor/browser';
import {StatusbarService} from './statusbar.service';

@Injectable({
    providedIn: 'root',
})
export class BrowserService {


    constructor(
        private statusbarService: StatusbarService,
    ) {
    }

    public openUrl(url: string): void {
        Browser.open({url, presentationStyle: 'popover'})
            .then(() => {
                Browser.addListener('browserPageLoaded', async () => {
                    await this.statusbarService.setStyleLight();
                    await this.statusbarService.setBackgroundColor('#000000');
                });

                Browser.addListener('browserFinished', async () => {
                    await this.statusbarService.setStyleLight();
                    await this.statusbarService.setDefaultBackgroundColor();
                    await Browser.removeAllListeners();
                });
            });
    }
}
