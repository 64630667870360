import {ChoiceQuestion, Question, RepeatedQuestion, Chapters} from '../models/question';
import {Questionnaire} from '../models/questionnaire';
import {Concept} from '../models/concept';
import {UUID} from 'angular2-uuid';

export type ChoiceBranch = { type: 'choice'; choices: string[] };
export type RepeatBranch = { type: 'repeat'; repeats: number };
export type ChapterBranch = { type: 'chapters'; choices: string[] };
export type QuestionPath = { question: number; branch: ChoiceBranch | RepeatBranch | ChapterBranch | null }[];

export class QuestionnaireUtils {
    static readonly SupportedTypes = ['radio', 'checkbox', 'text', 'datetime', 'date', 'integer', 'decimal', 'latlng', 'image', 'chapters', 'information'];

    static hasChoices(question: Question) {
        return (question.type === 'radio' || question.type === 'checkbox' || question.type === 'chapters')
            && Array.isArray((question as ChoiceQuestion).choices) && (question as ChoiceQuestion).choices.length > 0;
    }

    static getQuestionUsingPath(questions: Question[], path: QuestionPath): Question {
        if (!path || path.length === 0) {return null;}

        const [head, ...tail] = path;
        if (!head.branch || tail.length === 0) {
            return questions[head.question];
        } else {
            if (head.branch.type === 'choice') {
                const choices = ((questions[head.question] as ChoiceQuestion).choices || [])
                    .filter(choice => (head.branch as ChoiceBranch).choices.indexOf(choice.title) !== -1);

                if (choices.length > 0) {
                    const questionsForChoices = choices.reduce((acc, choice) => acc.concat(choice.questions), []);

                    return QuestionnaireUtils.getQuestionUsingPath(questionsForChoices, tail);
                }
            } else if (head.branch.type === 'chapters') {
                const chapters = ((questions[head.question] as Chapters).choices || [])
                    .filter(choice => (head.branch as ChapterBranch).choices.indexOf(choice.title) !== -1);

                if (chapters.length > 0) {
                    const chapterChoices = chapters.reduce((acc, choice) => acc.concat(choice.questions), []);

                    return QuestionnaireUtils.getQuestionUsingPath(chapterChoices, tail);
                }
            } else if (head.branch.type === 'repeat') {
                return QuestionnaireUtils.getQuestionUsingPath((questions[head.question] as RepeatedQuestion).questions, tail);
            }
        }
        return null;
    }

    /**
     * Get the path for the next question to be shown to the user.
     */
    static getQuestionPathForNextQuestion(questions: Question[], path?: QuestionPath): QuestionPath {
        if (path && path.length > 0) {
            path = JSON.parse(JSON.stringify(path)); // Deep clone

            const lastSegment = path.slice().pop();

            if (lastSegment.branch !== null) {
                path.push({question: 0, branch: null});
            } else {
                lastSegment.question += 1;
            }

            const question = QuestionnaireUtils.getQuestionUsingPath(questions, path);

            if (question) {
                if (QuestionnaireUtils.isSupported(question)) {
                    return path;
                } else if (question.type === 'repeated') {
                    path[path.length - 1].branch = {
                        type: 'repeat',
                        repeats: 0,
                    };

                    path.push({question: 0, branch: null});
                    const repeatedQuestion = QuestionnaireUtils.getQuestionUsingPath(questions, path);

                    if (repeatedQuestion) {
                        return path;
                    } else {
                        return QuestionnaireUtils.getQuestionPathForNextQuestion(questions, path);
                    }
                }
            } else {
                const slice = path.slice(0, path.length - 1);

                if (slice.length > 0) {
                    // DO NOT REMOVE THIS UNTIL WE KNOW HOW TO PROPERLY HANDLE
                    // VALIDATION OF REPEATED QUESTION WITHIN CHAPTERS
                    slice[slice.length - 1].branch = null;
                    return QuestionnaireUtils.getQuestionPathForNextQuestion(questions, slice);
                } else {
                    return null;
                }
            }
        } else {
            return QuestionnaireUtils.getQuestionPathForNextQuestion(questions, [{question: -1, branch: null}]);
        }
    }

    static getParentQuestionPath(path: QuestionPath) {
        return path.slice(0, path.length - 1);
    }

    static isSupported(question: Question): boolean {
        return QuestionnaireUtils.SupportedTypes.indexOf(question.type) !== -1;
    }

    static getCurrentRepeat(path: QuestionPath): number {
        if (path[path.length - 1].branch) {
            return (path[path.length - 1].branch as RepeatBranch).repeats;
        } else {
            return 0;
        }
    }

    /**
     * Check if question is the last question of a repeated question series. (this is where the magic happens)
     */
    static isLastOfRepeatedQuestion(questions: Question[], currentQuestionPath: QuestionPath, nextQuestionPath: QuestionPath) {
        const isCurrentQuestionPartOfRepeatBranch = QuestionnaireUtils.isQuestionPartOfRepeat(currentQuestionPath);
        const isNextQuestionPartOfRepeatBranch = QuestionnaireUtils.isQuestionPartOfRepeat(nextQuestionPath);

        return (isCurrentQuestionPartOfRepeatBranch === true && (isCurrentQuestionPartOfRepeatBranch === !isNextQuestionPartOfRepeatBranch));
    }

    /**
     * Creates a concept questionnaire with deepcopy of the original questionnaire
     *
     * @param questionnaire
     * @param organisationId
     * @returns
     */
    static createNewQuestionnaireConcept(questionnaire: Questionnaire, organisationId?: number): Concept {
        const conceptId = UUID.UUID();

        const deepCopy: Questionnaire = JSON.parse(JSON.stringify(questionnaire));

        deepCopy.clientUuid = conceptId;
        if (organisationId) {
            deepCopy.organisation_id = organisationId;
        }

        return {
            id: conceptId,
            updated_at: new Date().toISOString(),
            latest_route: '',
            questionnaire: deepCopy,
        };
    }

    /**
     * Is there a repeat question in the current question path
     *
     * @param questionPath
     * @returns
     */
    static isQuestionPartOfRepeat(questionPath: QuestionPath) {
        if (!questionPath) {return false;}

        for (let i = questionPath.length - 1; i >= 0; i--) {
            if (questionPath[i].branch && questionPath[i].branch.type && questionPath[i].branch.type === 'repeat') {
                return true;
            }
        }
        return false;
    }

    /**
     * Returns the PATH up until the repeated question
     *
     * @param questionPath
     * @returns path
     */
    static getRepeatQuestionPath(questionPath: QuestionPath) {
        let path = JSON.parse(JSON.stringify(questionPath));

        for (let i = questionPath.length - 1; i >= 0; i--) {
            if (questionPath[i].branch && questionPath[i].branch.type && questionPath[i].branch.type === 'repeat') {
                path = path.slice(0, i + 1);
                return path;
            }
        }

        return path;
    }

    static isQuestionnaireOrChildOf(questionnaires: Questionnaire[], questionnaire: Questionnaire, questionnaireId: number): boolean {
        if (questionnaire === undefined) {
            return false;
        }
        if (questionnaireId === questionnaire.id) {
            return true;
        }
        if (questionnaire.parent_form) {
            return QuestionnaireUtils.isQuestionnaireOrChildOf(questionnaires, questionnaires.find(item => item.id === questionnaire.parent_form.id), questionnaireId);
        }
        return false;
    }

}
