import {Injectable} from '@angular/core';
import {UserFavorite} from '../models/user-favorite';
import {ProgressiveApiService, ProgressiveStrategy} from './progressive-api-service';
import {BehaviorSubject, firstValueFrom, lastValueFrom, Observable, takeWhile} from 'rxjs';
import {ApiService} from './api-service';
import {filter} from 'rxjs/operators';
import {StructureNodeType} from '../enums/structure-node-type';

@Injectable({
    providedIn: 'root',
})
export class UserFavoriteService {
    private readonly favoritesLoadingSubject = new BehaviorSubject(false);
    private readonly favoritesSubject = new BehaviorSubject<UserFavorite[]|null>(null);

    constructor(
        private progressiveApiService: ProgressiveApiService,
        private apiService: ApiService,
    ) {
    }

    public getFavorites(): Observable<UserFavorite[]> {
        if (this.favoritesSubject.value === null && this.favoritesLoadingSubject.value === false) {
            this.fetchFavorites().catch((err) => {
                console.error('Failed to fetch favorites', err);
            });
        }

        return this.favoritesSubject.pipe(
            filter(favorites => favorites !== null),
        );
    }

    public async addFavorite(structureNodeType: StructureNodeType, structureNodeId: number): Promise<void> {
        await this.ensureFavoritesLoaded();
        const favorites = [...this.favoritesSubject.value];
        if (!favorites.some(item => item.structure_node_id === structureNodeId && item.structure_node_type === structureNodeType)) {
            favorites.push({structure_node_type: structureNodeType, structure_node_id: structureNodeId});
            await this.postFavorites(favorites);
        }
    }

    public async removeFavorite(structureNodeType: StructureNodeType, structureNodeId: number): Promise<void> {
        await this.ensureFavoritesLoaded();
        const favorites = [...this.favoritesSubject.value];
        const index = favorites.findIndex(item => item.structure_node_id === structureNodeId && item.structure_node_type === structureNodeType);
        if (index !== -1) {
            favorites.splice(index, 1);
            await this.postFavorites(favorites);
        }

    }

    private async postFavorites(favorites: UserFavorite[]): Promise<void> {
        const previousFavorites = [...this.favoritesSubject.value];
        try {
            // Optimistically update the favoritesSubject
            this.favoritesSubject.next(favorites);
            await this.apiService.authenticatedPost('/favorites', favorites);
        } catch (err) {
            // Revert the update if it fails
            this.favoritesSubject.next(previousFavorites);
            throw err;
        }
    }

    private async ensureFavoritesLoaded(): Promise<void> {
        if (this.favoritesSubject.value === null) {
            if (this.favoritesLoadingSubject.value === true) {
                // Wait for the current fetch to complete
                await lastValueFrom(this.favoritesLoadingSubject.pipe(
                    takeWhile(loading => loading === true),
                ));
                if (this.favoritesSubject.value === null) {
                    throw new Error('Failed to load favorites');
                }
            } else {
                await this.fetchFavorites();
            }
        }
    }

    private async fetchFavorites() {
        try {
            this.favoritesLoadingSubject.next(true);
            this.favoritesSubject.next(
                await firstValueFrom(this.progressiveApiService.authenticatedGet<UserFavorite[]>('/favorites', ProgressiveStrategy.FALLBACK)),
            );
        } finally {
            this.favoritesLoadingSubject.next(false);
        }
    }
}

