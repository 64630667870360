import {Injectable} from '@angular/core';
import {ProgressiveApiService, ProgressiveStrategy} from './progressive-api-service';
import {DynamicChoiceListService} from './dynamic-choice-list-service';
import {Question} from '../models/question';
import {Questionnaire} from '../models/questionnaire';
import {forkJoin, merge, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {QuestionDefinitionPathService} from './questiondefinition-path-service';
import {LoadingService} from './loading-service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class QuestionnaireAssembleService {

    constructor(private progressiveApiService: ProgressiveApiService,
                private questionDefinitionPathService: QuestionDefinitionPathService,
                private dynamicChoiceListService: DynamicChoiceListService,
                private loadingService: LoadingService,
                private translateService: TranslateService,
    ) {
    }

    /**
     * Synchronizes these questionnaire ids and their possible connected option lists and contact lists
     *
     * @param ids
     */
    public async synchronizeQuestionnairesByIds(ids): Promise<any> {

        // Make the ids unique to prevent duplicate questionnaires from being sent
        const observables = Array.from(new Set(ids)).map((id) => {
            return this.getQuestionnaireById(id);
        });

        forkJoin(
            ...observables,
        ).pipe(
            map((data) => {
                let dynamicChoicePaths = [];
                data.forEach((questionnaire) => {
                    if (questionnaire) {
                        dynamicChoicePaths = [...dynamicChoicePaths, ...this.questionDefinitionPathService.findQuestionPaths(questionnaire,
                            this.filterDynamicListQuestions)];
                    }
                });

                return dynamicChoicePaths;
            }),
        ).subscribe((dynamicChoicePaths) => {
            const dynamicListObservables = [];

            Array.from(new Set(dynamicChoicePaths.filter((questionPath) => {
                return questionPath.question.optionListId;
            }).map((questionPath) => {
                return questionPath.question.optionListId;
            }))).forEach((optionListId) => {
                dynamicListObservables.push(this.dynamicChoiceListService.getOptionList(optionListId));
            });

            Array.from(new Set(dynamicChoicePaths.filter((questionPath) => {
                return questionPath.question.contactListId;
            }).map((questionPath) => {
                return questionPath.question.contactListId;
            }))).forEach((contactListId) => {
                dynamicListObservables.push(this.dynamicChoiceListService.getContactList(contactListId));
            });

            merge(
                ...dynamicListObservables,
            ).subscribe((data) => {
            });
        });
    }

    /**
     * Retrieves the questionnaire and assembles it completely if it includes option lists or contact lists
     *
     * @param id
     */
    public getAssembledQuestionnaireById(id): Observable<Questionnaire> {
        return this.getQuestionnaireById(id)
            .pipe(
                map((questionnaire) => {
                    const pathsWithDynamicLists = this.questionDefinitionPathService.findQuestionPaths(questionnaire,
                        this.filterDynamicListQuestions);

                    const usedListItems = [];
                    const observables: Observable<any>[] = [];
                    pathsWithDynamicLists.forEach((questionPath) => {
                        if (questionPath.question.optionListId) {
                            const optionListId = questionPath.question.optionListId;
                            if (!usedListItems.includes('opt-' + optionListId)) {
                                usedListItems.push('opt-' + optionListId);
                                observables.push(this.dynamicChoiceListService.getOptionList(optionListId));
                            }
                        } else if (questionPath.question.contactListId) {
                            const contactListId = questionPath.question.contactListId;
                            if (!usedListItems.includes('contact-' + contactListId)) {
                                usedListItems.push('contact-' + contactListId);
                                observables.push(this.dynamicChoiceListService.getContactList(contactListId));
                            }
                        }
                    });

                    return {questionnaire, pathsWithDynamicLists, observables};
                }),
                switchMap((data) => {
                    // No combining is needed, just return the questionnaire
                    if (data.observables.length === 0) {
                        return of(data.questionnaire);
                    }

                    this.loadingService.setMessage(this.translateService.instant('NOTIFICATION.retrieving_options'));
                    // Gather all the option and contact lists and combine them into a single questionnaire
                    return forkJoin(...data.observables)
                        .pipe(
                            map((dynamicLists) => {
                                this.loadingService.setMessage(this.translateService.instant('NOTIFICATION.aggregating'));
                                dynamicLists.forEach((dynamicList) => {
                                    if (dynamicList.contactListId) {
                                        data.pathsWithDynamicLists.forEach((questionPath) => {
                                            if (questionPath.question.contactListId === dynamicList.contactListId) {
                                                this.questionDefinitionPathService.updateQuestionsByPath(data.questionnaire, [questionPath.path], (question) => {
                                                    question.choices = dynamicList.choices;
                                                    question.manualAdditionsAllowed = dynamicList.manualAdditionsAllowed;
                                                    return question;
                                                });
                                            }
                                        });
                                    } else {
                                        data.pathsWithDynamicLists.forEach((questionPath) => {
                                            if (questionPath.question.optionListId === dynamicList.id) {
                                                this.questionDefinitionPathService.updateQuestionsByPath(data.questionnaire, [questionPath.path], (question) => {
                                                    question.choices = dynamicList.choices;
                                                    return question;
                                                });
                                            }
                                        });
                                    }
                                });

                                return data.questionnaire;
                            }),
                        );
                }),
            );
    }

    /**
     * Retrieve a single unassembled questionnaire by its id
     */
    private getQuestionnaireById(id): Observable<Questionnaire> {
        return this.progressiveApiService.authenticatedGet<Questionnaire>('/questionnaire/' + id, ProgressiveStrategy.FALLBACK);
    }

    /**
     * Filter to check if this is a dynamic list question that needs preloading
     *
     * @param question
     * @private
     */
    private filterDynamicListQuestions(question: Question): boolean {
        if (['checkbox', 'radio'].includes(question.type)) {
            return (question?.optionListId || question?.contactListId) &&

                // If an option list or contact list cannot be found on the backend
                // A single choice is returned with an error message to make sure the forms
                // Can still be sent in - In that case, we do not need to replace the choices as they do not exist
                question.choices.length === 0;
        }

        return false;
    }

}
