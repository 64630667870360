<ion-content class="has-header has-footer">
    <div class="questionnaire-header"></div>
    <div class="card">
        <ion-icon src="assets/images/icon-form-checkmark.svg" class="card__icon card__icon--primary card__icon--modal"></ion-icon>
        <div class="card__header">
            <h2 class="card__title">{{ 'SEND_QUESTIONNAIRE.title' | translate }}</h2>
            <p class="card__description">{{ 'SEND_QUESTIONNAIRE.description' | translate }}</p>
        </div>
        <div class="card__btns card__btns--space">
            <ion-button expand="block" shape="round" color="primary" class="card__btn" [disabled]="sending" (click)="sendQuestionnaire()">
                {{ 'SEND_QUESTIONNAIRE.confirm' | translate }}
            </ion-button>
            <ion-button expand="block" shape="round" fill="outline" class="card__btn card__btn--outline" (click)="prev()">
                {{ 'SEND_QUESTIONNAIRE.cancel' | translate }}
            </ion-button>
        </div>
    </div>
</ion-content>

<!--<app-questionnaire-footer-->
<!--    [hideChaptersButton]="!isChapterQuestionnaire"-->
<!--    [nextButtonEnabled]="!sending"-->
<!--    nextButtonText="BUTTONS.submit"-->
<!--    (prevButtonClick)="prev()"-->
<!--    (nextButtonClick)="sendQuestionnaire()"-->
<!--    (chaptersButtonClick)="backToChapters()"-->
<!--&gt;</app-questionnaire-footer>-->
