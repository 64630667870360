import {Pipe, PipeTransform} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {QuestionRoute} from '../models/question-route';
import {ChapterUtils} from '../utils/chapter-utils';
import {Concept} from '../models/concept';
import {QuestionnaireRouteService} from '../services/questionnaire-route.service';
import {map} from 'rxjs/operators';
import {QuestionnaireProgress} from '../models/questionnaire-progress';

@Pipe({
    name: 'conceptProgress',
})
export class ConceptProgressPipe implements PipeTransform {

    constructor(private questionnaireRouteService: QuestionnaireRouteService) {
    }

    transform(concept: Concept): Observable<QuestionnaireProgress> {
        const readonly = (concept.id === undefined || concept.id === null);
        const startOfQuestionnaire = concept.latest_route.includes('disclaimer');

        return forkJoin([
            this.questionnaireRouteService.getRouteForUrl(concept.questionnaire, concept.latest_route, readonly, startOfQuestionnaire),
            this.questionnaireRouteService.getQuestionRoutes(concept.questionnaire, startOfQuestionnaire, readonly),
        ]).pipe(
            map(([questionRoute, routes]: [QuestionRoute, QuestionRoute[]]) => {
                return ChapterUtils.getCurrentAndTotalQuestions(concept.questionnaire, questionRoute, routes);
            }),
        );
    }
}
