import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {Observable, ReplaySubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CurrentRouteService implements OnDestroy {

    private _currentRoute$ = new ReplaySubject<string>();
    private destroy$ = new Subject<void>();

    constructor(private router: Router) {

        router.events
            .pipe(
                takeUntil(this.destroy$),
                filter(event => event instanceof NavigationEnd),
            )
            .subscribe((event: NavigationStart) => {
                this._currentRoute$.next(event.url);
            });
    }


    get currentRoute$(): Observable<string> {
        return this._currentRoute$.asObservable();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
