import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionnaireProgress} from '../../models/questionnaire-progress';
import {Observable} from 'rxjs';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';

@Component({
    selector: 'app-questionnaire-footer',
    templateUrl: 'questionnaire-footer.html',
})
export class QuestionnaireFooterComponent implements OnInit {
    public isKeyboardOpen$: Observable<boolean>;
    @Input() progress?: QuestionnaireProgress;
    @Input() hideChaptersButton = true;
    @Input() disableBackButton = false;
    @Input() nextButtonEnabled: boolean;
    @Input() nextButtonText = 'BUTTONS.next';
    @Output() chaptersButtonClick = new EventEmitter();
    @Output() prevButtonClick = new EventEmitter();
    @Output() nextButtonClick = new EventEmitter();

    constructor(private deviceKeyboardService: DeviceKeyboardService) {
    }

    ngOnInit(): void {
        this.isKeyboardOpen$ = this.deviceKeyboardService.isKeyboardOpen();
    }

    toChapters() {
        this.chaptersButtonClick.emit();
    }

    prev() {
        this.prevButtonClick.emit();
    }

    next() {
        if (this.nextButtonEnabled) {
            this.nextButtonClick.emit();
        }
    }
}
