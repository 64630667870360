<app-dashboard-header [showBackButton]="true" [showDashboardButton]="false"></app-dashboard-header>

<ion-content class="has-header has-footer">
    <h2 class="light title ion-text-center">{{ 'PASSWORD_CHANGE.title' | translate }}</h2>
    <form [formGroup]="form" novalidate>
        <div class="fields">
            <ion-item>
                <ion-input formControlName="currentPassword" type="password" placeholder="{{ 'PASSWORD_CHANGE.current_password' | translate }}" inputfocus></ion-input>
            </ion-item>
        </div>
        <ion-item class="explanation">
            <ion-label class="light ion-text-wrap">{{ 'PASSWORD_CHANGE.password_explanation' | translate }}</ion-label>
        </ion-item>
        <div class="fields" formGroupName="newPassword">
            <ion-item>
                <ion-input formControlName="plainPassword" type="password" placeholder="{{ 'PASSWORD_CHANGE.new_password' | translate }}"></ion-input>
            </ion-item>
            <ion-item>
                <ion-input formControlName="plainPasswordRepeat" type="password" placeholder="{{ 'PASSWORD_CHANGE.repeat_new_password' | translate }}"></ion-input>
            </ion-item>
            <p *ngIf="showPasswordRepeatError()" class="text-color-danger ml-5">
                {{ 'PASSWORD_CHANGE.passwords_dont_match' | translate }}
            </p>
        </div>
    </form>
</ion-content>

<ion-footer class="ion-padding ion-no-border">
    <ion-toolbar class="ion-no-padding">
        <div class="footer-clear-floats">
            <ion-grid class="ion-no-padding">
                <ion-row class="ion-justify-content-center">
                    <ion-col size-xs="12" size-sm="9" size-lg="5" class="ion-no-padding">
                        <ion-button color="dark" (click)="submit()" expand="full">{{ 'PASSWORD_CHANGE.confirm' | translate }}</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </ion-toolbar>
</ion-footer>
