import {Choice} from '../models/choice';
import {ChapterBranch, QuestionnaireUtils, QuestionPath, RepeatBranch} from './questionnaire-utils';
import {Chapters, Question} from '../models/question';
import {Questionnaire} from '../models/questionnaire';
import {QuestionnaireProgress} from '../models/questionnaire-progress';
import {QuestionRoute} from '../models/question-route';
import {ContentTranslatePipe} from '../pipes/content-translate';

export class ChapterUtils {

    /**
     * Checks if the questions belong to a chapters questionnaire
     *
     * @returns
     * @param questionnaire
     */
    public static isChaptersQuestionnaire(questionnaire: Questionnaire) {
        return (questionnaire.questions.length > 0 && questionnaire.questions[0].type === 'chapters');
    }

    /**
     * Returns the index of the selected chapter
     *
     * @returns
     * @param chapter
     * @param chapters
     */
    public static getChapterIndex(chapter: Choice, chapters: Array<Choice>) {
        for (let i = 0; i < chapters.length; i++) {
            if (chapter === chapters[i]) {
                return i;
            }
        }
    }

    public static getQuestionIndex(currentChapterIndex: number, chapters: Array<Choice>, question: Question) {
        for (let i = 0; i < chapters[currentChapterIndex].questions.length; i++) {
            if (question === chapters[currentChapterIndex].questions[i]) {
                return i;
            }
        }
    }

    /**
     * Returns the chapter title including the index
     *
     * @param questions
     * @param currentQuestionPath
     * @param contentTranslatePipe
     * @returns
     */
    public static getChapterTitle(questions: Question[], currentQuestionPath: QuestionPath, contentTranslatePipe: ContentTranslatePipe) {
        let chapterTitle: string = (currentQuestionPath[0].branch as ChapterBranch).choices[0];
        const numChapters = (questions[0] as Chapters).choices.length;
        let currentChapterIndex = '-';

        for (let i = 0; i < numChapters; i++) {
            if ((questions[0] as Chapters).choices[i].title === chapterTitle) {
                currentChapterIndex = `${i + 1}`;
                chapterTitle = contentTranslatePipe.transform((questions[0] as Chapters).choices[i], 'title');
            }
        }

        return `${chapterTitle} - ${currentChapterIndex} / ${numChapters}`;
    }

    /**
     * Returns if the selected chapter has questions
     *
     * @param choice
     * @returns
     */
    public static chapterHasQuestions(choice: Choice) {
        return choice.questions.length > 0;
    }

    /**
     * Checks if the chapter is completed, a.k.a next level black magic
     *
     * @param questions
     * @param path
     * @returns
     */
    static isChapterCompleted(questions: Question[], path: QuestionPath): boolean {
        let isCompleted = true;
        let repeats = 0;
        let nextQuestionPath = QuestionnaireUtils.getQuestionPathForNextQuestion(questions, path);

        while (nextQuestionPath) {
            const nextQuestion = QuestionnaireUtils.getQuestionUsingPath(questions, nextQuestionPath);
            if (nextQuestion.type !== 'information' && nextQuestion.required === true) {
                if (nextQuestion.answers === undefined) {
                    isCompleted = false;
                    break;
                } else if (Array.isArray(nextQuestion.answers)) {
                    if (nextQuestion.answers[repeats] === undefined || nextQuestion.answers[repeats] === '') {
                        isCompleted = false;
                        break;
                    }
                }
            }

            let tmp = ChapterUtils.checkForChoices(nextQuestion, nextQuestionPath, repeats);
            tmp = ChapterUtils.checkLastOfRepeat(questions, tmp.nextQuestionPath, tmp.repeats);

            nextQuestionPath = tmp.nextQuestionPath;
            repeats = tmp.repeats;
        }

        return isCompleted;
    }

    /**
     * Checks if the question is completed
     *
     * @param question
     * @returns
     */
    static isQuestionCompleted(question): boolean {
        let isCompleted = true;

        if (question.required === true) {
            if (ChapterUtils.isQuestionAnswerEmpty(question)) {
                isCompleted = false;
            }
        }
        if (question.type === 'repeated') {
            if (question.questions && Array.isArray(question.questions)) {
                for (const repeatedQuestion of question.questions) {
                    const repeatedQuestionIsCompleted = this.isQuestionCompleted(repeatedQuestion);
                    if (!repeatedQuestionIsCompleted) {
                        isCompleted = false;
                    }
                }
            }
        }

        return isCompleted;
    }

    /**
     * Checks if the question answer is empty
     *
     * @param question
     * @returns
     */
    static isQuestionAnswerEmpty(question): boolean {
        let isEmpty = false;

        if (question.answers === undefined || question.answers === '') {
            isEmpty = true;
        } else if (Array.isArray(question.answers)) {
            if (question.answers[0] === undefined || question.answers[0] === '') {
                isEmpty = true;
            }
        }
        if (question.type === 'repeated') {
            if (question.questions && Array.isArray(question.questions)) {
                for (const repeatedQuestion of question.questions) {
                    const repeatedQuestionIsEmpty = this.isQuestionAnswerEmpty(repeatedQuestion);
                    if (repeatedQuestionIsEmpty) {
                        isEmpty = true;
                    }
                }
            }
        }
        return isEmpty;
    }

    /**
     * Check if the next question has choises and calculates the next questionPath accordingly
     *
     * @param nextQuestion
     * @param nextQuestionPath
     * @param repeats
     * @returns
     */
    private static checkForChoices(nextQuestion: Question, nextQuestionPath: QuestionPath, repeats: number) {
        if (QuestionnaireUtils.hasChoices(nextQuestion)) {
            repeats = 0;

            if (nextQuestionPath.length > 1) {
                const parentQuestionPath = QuestionnaireUtils.getParentQuestionPath(nextQuestionPath);

                for (let i = parentQuestionPath.length; i > 0; i--) {
                    if (parentQuestionPath[i - 1].branch.type === 'repeat') {
                        repeats = (parentQuestionPath[i - 1].branch as RepeatBranch).repeats;
                        break;
                    }
                }
            }

            const questionValue = Array.isArray(nextQuestion.answers) ? nextQuestion.answers[repeats] : '';
            const selectedChoices = Array.isArray(questionValue) ? questionValue : [questionValue];

            nextQuestionPath[nextQuestionPath.length - 1].branch = {
                type: 'choice',
                choices: selectedChoices,
            };
        }

        return {nextQuestionPath, repeats};
    }

    /**
     * resolves the nextQuestionPath based on if the current question is the last of a repeated question
     *
     * @param questions
     * @param nextQuestionPath
     * @param repeats
     * @returns
     */
    private static checkLastOfRepeat(questions: Question[], nextQuestionPath: QuestionPath, repeats: number) {
        const nextNextQuestionPath = QuestionnaireUtils.getQuestionPathForNextQuestion(questions, nextQuestionPath);

        if (QuestionnaireUtils.isLastOfRepeatedQuestion(questions, nextQuestionPath, nextNextQuestionPath)) {
            const repeatQuestionPath = QuestionnaireUtils.getRepeatQuestionPath(nextQuestionPath);
            const repeatQuestion = QuestionnaireUtils.getQuestionUsingPath(questions, repeatQuestionPath);

            if (repeatQuestion.answers !== null && repeatQuestion.answers !== undefined) {
                const currentAnswerIndex = QuestionnaireUtils.getCurrentRepeat(repeatQuestionPath);

                if (repeatQuestion.answers[currentAnswerIndex] === 'Ja') {
                    (repeatQuestionPath[repeatQuestionPath.length - 1].branch as RepeatBranch).repeats = currentAnswerIndex + 1;
                    nextQuestionPath = QuestionnaireUtils.getQuestionPathForNextQuestion(questions, repeatQuestionPath);
                } else {
                    repeats = 0;
                    nextQuestionPath = QuestionnaireUtils.getQuestionPathForNextQuestion(questions, nextQuestionPath);
                }
            } else {
                nextQuestionPath = QuestionnaireUtils.getQuestionPathForNextQuestion(questions, nextQuestionPath);
            }
        } else {
            nextQuestionPath = QuestionnaireUtils.getQuestionPathForNextQuestion(questions, nextQuestionPath);
        }

        return {nextQuestionPath, repeats};
    }

    /**
     * Returns the number of questions in de questionnaire or chapter, and the current cuestion index
     *
     * @param currentQuestionPath
     * @param currentQuestion
     * @param questions
     * @returns
     */
    public static getCurrentAndTotalQuestions(questionnaire: Questionnaire, currentRoute: QuestionRoute, routes: QuestionRoute[]) {
        const hasDisclaimerRoute = routes.length > 0 && routes[0].route.endsWith('/disclaimer');
        const hasSendRoute = routes.length > 0 && routes[routes.length-1].route.endsWith('/send');
        const totalQuestionsOffset = (hasDisclaimerRoute ? 1 : 0) + (hasSendRoute ? 1 : 0);

        let currentRouteIndex = routes.findIndex((route) => route.route === currentRoute.route);

        if (currentRouteIndex >= routes.length) {
            return new QuestionnaireProgress(routes.length - (hasDisclaimerRoute ? 1 : 0), routes.length - totalQuestionsOffset);
        }
        if (currentRoute.vintageQuestionPath?.[0].branch && currentRoute.vintageQuestionPath?.[0].branch.type === 'chapters') {
            const routesForCurrentChapter = routes.filter((route) => {
                return !!route.vintageQuestionPath && route.vintageQuestionPath[0].branch && route.vintageQuestionPath[0].branch.type === 'chapters' && currentRoute.vintageQuestionPath[0].branch.type === 'chapters';
            }).filter((route) => {
                const currentBranch = currentRoute.vintageQuestionPath[0].branch as ChapterBranch;
                const routeBranch = route.vintageQuestionPath[0].branch as ChapterBranch;
                return routeBranch.choices[0] === currentBranch.choices[0];
            });
            currentRouteIndex = routesForCurrentChapter.findIndex((route) => route.route === currentRoute.route);

            return new QuestionnaireProgress(currentRouteIndex + 1, routesForCurrentChapter.length);
        } else {
            return new QuestionnaireProgress(currentRouteIndex + 1 - (hasDisclaimerRoute ? 1 : 0), routes.length - totalQuestionsOffset);
        }
    }

    public static isFirstChapterQuestion(vintageQuestionPath: QuestionPath): boolean {
        return vintageQuestionPath.length === 2 && vintageQuestionPath[0].branch?.type === 'chapters' && vintageQuestionPath[vintageQuestionPath.length-1].question === 0;
    }

    public static questionsForSameChapter(question1Path: QuestionPath, question2Path: QuestionPath): boolean {
        if ((question1Path && question1Path[0].branch !== null && question1Path[0].branch.type === 'chapters') &&
            (question2Path && question2Path[0].branch !== null && question2Path[0].branch.type === 'chapters'))
        {
            const question1ChapterBranch = question1Path[0].branch as ChapterBranch;
            const question2ChapterBranch = question2Path[0].branch as ChapterBranch;

            return question1ChapterBranch.choices[0] === question2ChapterBranch.choices[0];
        }
        return false;
    }

}
