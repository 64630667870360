<app-generic-header [showOrganizationName]="false"></app-generic-header>

<ion-content class="has-header" *ngIf="(scanInProgress$ | async) === false">
    <div class="card">
        <div class="card__header">
            <h2 class="card__title" [ngClass]="{'scan-qr--scan-in-progress': (scanInProgress$ | async) === true}">
                {{ 'DASHBOARD.tile.scan_qr' | translate }}
            </h2>
        </div>
        <p *ngIf="hasIncorrectScanResult$ | async" class="card__description">
            {{ 'SCAN_QR.incorrect_result' | translate }}
        </p>
        <p *ngIf="(hasScanResult$ | async) === false && (hasPermission$ | async) === true" class="card__description">
            {{ 'SCAN_QR.no_result' | translate }}
        </p>
        <p *ngIf="(hasPermission$ | async) === false" class="card__description">
            {{ 'SCAN_QR.no_permission' | translate }}
        </p>
        <div class="card__btns">
            <ion-button color="primary" expand="full" shape="round" class="card__btn" (click)="startScan()">
                {{ 'SCAN_QR.try_again' | translate }}
            </ion-button>
        </div>
    </div>
</ion-content>
