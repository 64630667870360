import {ChangeDetectorRef, Component} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {Choice} from '../../models/choice';
import {Chapters, Question} from '../../models/question';
import {ToastService} from '../../services/toast-service';
import {ConceptService} from '../../services/concept-service';
import {ChapterUtils} from '../../utils/chapter-utils';
import {QuestionnaireRouteService} from '../../services/questionnaire-route.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HistoryService} from '../../services/history-service';
import {AbstractQuestionnairePage} from '../abstract-questionnaire/abstract-questionnaire';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';
import {TrackingService} from '../../services/tracking/tracking.service';
import {ContentTranslatePipe} from '../../pipes/content-translate';
import {NetworkService} from '../../services/network.service';
import {RemarkFocusService} from '../../services/remark-focus-service';

@Component({
    selector: 'page-questionnaire-chapters',
    templateUrl: 'questionnaire-chapters.html',
})
export class QuestionnaireChaptersPage extends AbstractQuestionnairePage {
    public skipRequirements: boolean;

    public chapters: Array<Choice>;

    public chaptersCompleted: Array<boolean>;

    public titleText = '';
    public descriptionText = '';

    public isCompleted = false;

    private currentChapterIndex: number;

    constructor(
        navCtrl: NavController,
        modalCtrl: ModalController,
        private toast: ToastService,
        conceptService: ConceptService,
        questionnaireRouteService: QuestionnaireRouteService,
        activatedRoute: ActivatedRoute,
        historyService: HistoryService,
        trackingService: TrackingService,
        router: Router,
        deviceKeyboardService: DeviceKeyboardService,
        private changeDetectorRef: ChangeDetectorRef,
        private contentTranslatePipe: ContentTranslatePipe,
        networkService: NetworkService,
        remarkFocusService: RemarkFocusService,
    ) {
        super(navCtrl, modalCtrl, conceptService, activatedRoute, historyService, trackingService, questionnaireRouteService, router, deviceKeyboardService, contentTranslatePipe, networkService, remarkFocusService);
    }

    async ionViewWillEnter() {
        await super.ionViewWillEnter();

        this.skipRequirements = this.currentQuestion.skipRequirements;
        this.chapters = (this.currentQuestion as Chapters).choices;
        this.titleText = this.contentTranslatePipe.transform(this.currentQuestion, 'title');
        this.descriptionText = this.contentTranslatePipe.transform(this.currentQuestion, 'description');

        this.chaptersCompleted = Array(this.chapters.length).fill(false);

        this.setDefaultChapterAnswers();
        await this.calculateChapterCompleted();

        // Force change detection
        this.changeDetectorRef.detectChanges();
    }

    /**
     * Returns if the chapter is completed
     *
     * @param chapter
     * @returns
     */
    public isChapterCompleted(chapter: Choice) {
        return this.chaptersCompleted[ChapterUtils.getChapterIndex(chapter, this.chapters)];
    }

    /**
     * Returns if the question is completed
     *
     * @param question
     * @returns
     */
    public isQuestionCompleted(question) {
        return ChapterUtils.isQuestionCompleted(question);
    }

    /**
     * Returns if the question answer is empty
     *
     * @param question
     * @returns
     */
    public isQuestionAnswerEmpty(question) {
        return ChapterUtils.isQuestionAnswerEmpty(question);
    }

    /**
     *
     * @param chapter
     */
    public conceptUpdated(chapter) {
        // This gets triggered by `chapter-item.ts` (event) to check the new state after changing required checks.
        this.calculateChapterCompleted().then(() => {
            this.isChapterCompleted(chapter);
        });
    }

    /**
     * Selects a chapter with questions
     *
     * @param choice
     */
    public async selectChapter(choice: Choice) {
        if (ChapterUtils.chapterHasQuestions(choice)) {
            this.currentChapterIndex = ChapterUtils.getChapterIndex(choice, this.chapters);
            const questionRoute = await this.questionnaireRouteService.getChapterQuestionRoute(this.questionnaire, this.currentChapterIndex, 0);
            await this.navCtrl.navigateForward(`${this.readonly ? '/history' : `/concept/${this.conceptId}`}${questionRoute.route}`);
        } else {
            await this.toast.showCustomMessages('Dit hoofdstuk bevat geen vragen');

            this.calculateChapterCompleted();
        }
    }

    /**
     * Goto a specific question within a chapter
     *
     * @param ChapterQuestion
     */
    public async selectQuestion(chapterQuestion: {choice: Choice; question: Question }) {
        const {choice, question} = chapterQuestion;

        if (ChapterUtils.chapterHasQuestions(choice)) {
            this.currentChapterIndex = ChapterUtils.getChapterIndex(choice, this.chapters);
            const questionIndex = ChapterUtils.getQuestionIndex(this.currentChapterIndex, this.chapters, question);
            const questionRoute = await this.questionnaireRouteService.getChapterQuestionRoute(this.questionnaire, this.currentChapterIndex, questionIndex);
            await this.navCtrl.navigateForward(`${this.readonly ? '/history' : `/concept/${this.conceptId}`}${questionRoute.route}`);
        } else {
            await this.toast.showCustomMessages('Dit hoofdstuk bevat geen vragen');

            this.calculateChapterCompleted();
        }
    }

    /**
     * Send the completed questionnaire
     *
     * @returns
     */
    public async sendQuestionnaire() {
        if (this.readonly) {
            return this.navCtrl.navigateBack(['/questionnaire-history']);
        } else {
            const allRoutes = await this.questionnaireRouteService.getQuestionRoutes(this.questionnaire, false, this.readonly);
            const sendRoute = allRoutes[allRoutes.length - 1];

            return this.navCtrl.navigateForward(`/concept/${this.conceptId}${sendRoute.route}`);
        }
    }

    private setDefaultChapterAnswers() {
        if (!this.currentQuestion.answers) {
            this.currentQuestion.answers = Array(this.chapters.length).fill(false);
        }
    }

    /**
     * Checks if the chapters that are visited are completed and valid
     */
    private async calculateChapterCompleted() {
        if (!this.readonly) {
            if (this.currentChapterIndex !== null && !!this.concept) {
                this.currentQuestion.answers[this.currentChapterIndex] = true;

                await this.conceptService.saveConcept(this.concept, this.router.url);
            }

            for (let i = 0; i < this.currentQuestion.answers.length; i++) {
                // If the requireChapter is set, and its set to false, we can always set completion on true
                // Because when we skip the require check, we don't want to force the viewer to enter every question
                if (this.chapters[i].hasOwnProperty('requireChapter') && !this.chapters[i].requireChapter) {
                    this.chaptersCompleted[i] = true;
                } else {
                    // if this is the first time we open the form, the answers array might not be set yet...
                    if (ChapterUtils.chapterHasQuestions(this.chapters[i])) {
                        this.vintageQuestionPath[this.vintageQuestionPath.length - 1].branch = {
                            type: 'chapters',
                            choices: [this.chapters[i].title],
                        };

                        this.chaptersCompleted[i] = ChapterUtils.isChapterCompleted(this.questionnaire.questions, this.vintageQuestionPath);
                    } else {
                        this.chaptersCompleted[i] = true;
                    }
                }
            }
        } else {
            this.chaptersCompleted = Array(this.chapters.length).fill(true);
        }

        this.isQuestionnaireCompleted();
    }

    /**
     * Checks is the questionnaire is completed
     */
    private isQuestionnaireCompleted() {
        this.isCompleted = true;

        this.chaptersCompleted.forEach(completed => {
            if (!completed) {
                this.isCompleted = false;
                return;
            }
        });
    }
}
