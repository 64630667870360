import {Component} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LoadingController, NavController} from '@ionic/angular';
import {ExternalUserService} from '../../services/external-user-service';
import {ToastService} from '../../services/toast-service';
import {ValidatorUtils} from '../../utils/validator-utils';
import {CaseUtils} from '../../utils/case-utils';
import {PrivacyPolicyService} from '../../services/privacy-policy.service';
import {LanguageService} from '../../services/language.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'page-registration',
    templateUrl: 'registration.html',
})
export class RegistrationPage {
    public registrationForm: UntypedFormGroup;

    constructor(
        fb: UntypedFormBuilder,
        private externalUserService: ExternalUserService,
        private loadingCtrl: LoadingController,
        private toast: ToastService,
        private navCtrl: NavController,
        private privacyPolicyService: PrivacyPolicyService,
        private languageService: LanguageService,
        private translateService: TranslateService,
    ) {
        this.createForm(fb);
    }

    async createAccount() {
        if (this.registrationForm.valid) {
            const loading = await this.loadingCtrl.create();

            try {
                await loading.present();

                let registrationFormData = this.registrationForm.value;
                registrationFormData.language = this.languageService.getCurrentLanguage();
                registrationFormData = RegistrationPage.sanitizeRegistrationFormData(registrationFormData);
                await this.externalUserService.postRegistration(registrationFormData);
                await loading.dismiss();
                await this.navCtrl.navigateForward(['/registration-verify-sms'], {
                    state: {
                        emailAddress: this.registrationForm.get('emailAddress').value,
                    },
                });
            } catch (exception) {
                await loading.dismiss();

                if (typeof exception === 'object' && exception.status === 409) {
                    await this.toast.showCustomMessages(this.translateService.instant('REGISTRATION.account_exists'));
                } else {
                    await this.toast.showCustomMessages(this.translateService.instant('REGISTRATION.error_something_wrong'));
                }
            }
        }
    }

    showPasswordRepeatError(): boolean {
        let result = false;

        if (this.registrationForm !== undefined) {
            const passwordsGroup = this.registrationForm.get('passwords') as UntypedFormGroup;
            const repeatedControl = passwordsGroup.get('plainPasswordRepeat') as UntypedFormControl;

            result = (repeatedControl.touched || repeatedControl.dirty) && RegistrationPage.passwordsEqual(passwordsGroup) != null;
        }

        return result;
    }

    private createForm(fb: UntypedFormBuilder) {
        this.registrationForm = fb.group({
            emailAddress: ['', Validators.email],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            mobileNumber: ['', Validators.required],
            passwords: fb.group({
                plainPassword: ['', [ValidatorUtils.PasswordValidator, ValidatorUtils.PasswordLengthValidator]],
                plainPasswordRepeat: ['', Validators.required],
            }, {validator: RegistrationPage.passwordsEqual}),
            acceptPrivacyPolicy: [false, Validators.requiredTrue],
        });
    }

    private static passwordsEqual(group: UntypedFormGroup) {
        if (group.get('plainPassword').value === group.get('plainPasswordRepeat').value) {
            return null;
        } else {
            return {
                passwordsEqual: {valid: false},
            };
        }
    }

    public openPrivacyPolicy(): void {
        this.privacyPolicyService.openPrivacyPolicyUrl();
    }

    private static sanitizeRegistrationFormData(data: any) {
        data.plainPassword = data.passwords.plainPassword;
        delete data.passwords;
        delete data.acceptPrivacyPolicy;

        return CaseUtils.objectKeysToSnakeCase(data);
    }

    public toFormControl(control: AbstractControl): UntypedFormControl {
        if (control instanceof UntypedFormControl) {
            return control;
        }
        throw new Error('Incorrect type for control with data' + JSON.stringify(control));
    }
}
