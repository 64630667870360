import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractChoiceQuestionComponent} from '../../classes/abstract-choice-question.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ValidatorUtils} from '../../utils/validator-utils';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-manual-choice-addition',
    templateUrl: 'manual-choice-addition.html',
})
export class ManualChoiceAdditionComponent implements OnInit, OnDestroy {
    @Input() public abstractChoiceQuestion: AbstractChoiceQuestionComponent;
    public newChoiceForm: UntypedFormGroup;
    private newChoiceFormValueChangeSubscription: Subscription;

    ngOnInit(): void {
        this.newChoiceForm = new UntypedFormGroup({
            title: new UntypedFormControl('', [Validators.required, ValidatorUtils.nonDuplicateChoiceTitle(this.abstractChoiceQuestion)]),
        });

        this.newChoiceFormValueChangeSubscription = this.newChoiceForm.valueChanges
            .subscribe(() => {
                if (this.getChoiceValue() === '') {
                    this.newChoiceForm.controls.title.markAsPristine();
                    this.newChoiceForm.controls.title.markAsUntouched();
                }
            });
    }

    ngOnDestroy(): void {
        this.newChoiceFormValueChangeSubscription.unsubscribe();
    }

    public addChoice(): void {
        if (this.newChoiceForm.valid) {
            this.abstractChoiceQuestion.addManualChoice(this.getChoiceValue());
            this.newChoiceForm.patchValue({title: ''});
        }
    }

    private getChoiceValue(): string {
        return this.newChoiceForm.value.title.trim();
    }
}
