export class PropUtils {

    public static toInteger(value: any): number {
        return parseInt(`${value}`, 10);
    }

    public static isNumber(value: any): boolean {
        return !isNaN(PropUtils.toInteger(value));
    }

    public static parseValue(value): number {
        const parsedValue = PropUtils.toInteger(value);
        return isNaN(parsedValue) ? 0 : parsedValue;
    }

}
