import {Injectable} from '@angular/core';
import {ProgressiveApiService, ProgressiveStrategy} from './progressive-api-service';
import {Observable} from 'rxjs';

@Injectable()
export class DynamicChoiceListService {

    constructor(private progressiveApiService: ProgressiveApiService) {
    }

    /**
     * Return a full option list by id
     *
     * @returns
     */
    public getOptionList(id): Observable<any> {
        return this.getDynamicChoiceList('optionlist', id);
    }

    /**
     * Return a full contact list by id
     *
     * @returns
     */
    public getContactList(id): Observable<any> {
        return this.getDynamicChoiceList('contactlist', id);
    }

    /**
     * Get the contents of a dynamic list
     *
     * @param dynamiclisttype
     * @param id
     * @private
     */
    private getDynamicChoiceList(dynamiclisttype, id): Observable<any> {
        return this.progressiveApiService.authenticatedGet(`/${dynamiclisttype}/${id}`, ProgressiveStrategy.FALLBACK);
    }

}
