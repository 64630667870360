import {ModalController, NavController} from '@ionic/angular';
import {Question} from '../../models/question';
import {Questionnaire} from '../../models/questionnaire';
import {QuestionnaireProgress} from '../../models/questionnaire-progress';
import {QuestionPath} from '../../utils/questionnaire-utils';
import {ConceptService} from '../../services/concept-service';
import {ChapterUtils} from '../../utils/chapter-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {HistoryService} from '../../services/history-service';
import {QuestionnaireRouteService} from '../../services/questionnaire-route.service';
import {QuestionRoute} from '../../models/question-route';
import {Concept} from '../../models/concept';
import {UUID} from 'angular2-uuid';
import {BehaviorSubject, Observable} from 'rxjs';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';
import {TrackingService} from '../../services/tracking/tracking.service';
import {ContentTranslatePipe} from '../../pipes/content-translate';
import {NetworkService} from '../../services/network.service';
import {RemarkFocusService} from '../../services/remark-focus-service';

export abstract class AbstractQuestionnairePage {

    public readonly: boolean;
    public currentQuestion: Question;
    public vintageQuestionPath: QuestionPath;
    public questionnaireProgress: QuestionnaireProgress = new QuestionnaireProgress(0, 0);
    public questionTitle: string;
    public isChapterQuestionnaire: boolean;
    public online = this.networkService.networkOnline$();
    public questionIndex: number;
    public answerIndex: number;
    public disableBackButton: boolean;

    protected conceptId: UUID;
    private questionnaireId: number;
    protected questionnaire: Questionnaire;
    protected questionRoute: QuestionRoute;
    public concept?: Concept;
    public isKeyboardOpen$: Observable<boolean>;
    public onRemarkFocus$: Observable<boolean>;

    protected constructor(
        protected navCtrl: NavController,
        private modalCtrl: ModalController,
        protected conceptService: ConceptService,
        protected activatedRoute: ActivatedRoute,
        private historyService: HistoryService,
        private trackingService: TrackingService,
        protected questionnaireRouteService: QuestionnaireRouteService,
        protected router: Router,
        private deviceKeyboardService: DeviceKeyboardService,
        private contentTranslationPipe: ContentTranslatePipe,
        protected networkService: NetworkService,
        private remarkFocusService: RemarkFocusService,
    ) {
    }

    async ionViewWillEnter() {
        this.conceptId = this.activatedRoute.snapshot.paramMap.get('conceptId');
        this.questionnaireId = parseInt(this.activatedRoute.snapshot.paramMap.get('questionnaireId'), 10);
        this.readonly = (this.conceptId === undefined || this.conceptId === null);
        this.answerIndex = parseInt(this.activatedRoute.snapshot.paramMap.get('answerIndex'), 10);

        if (!this.readonly) {
            this.concept = await this.conceptService.getConcept(this.conceptId);
            this.questionnaire = this.concept.questionnaire;
        } else {
            this.questionnaire = await this.historyService.getQuestionnaireHistoryItem(this.questionnaireId);
        }
        this.questionRoute = await this.questionnaireRouteService.getRouteForSnapshot(this.questionnaire, this.activatedRoute.snapshot, this.readonly);
        this.currentQuestion = this.questionRoute.question;
        this.vintageQuestionPath = this.questionRoute.vintageQuestionPath;

        const routes = await this.questionnaireRouteService.getQuestionRoutes(this.questionnaire, !this.readonly, this.readonly);
        this.questionnaireProgress = ChapterUtils.getCurrentAndTotalQuestions(this.questionnaire, this.questionRoute, routes);

        this.isChapterQuestionnaire = ChapterUtils.isChaptersQuestionnaire(this.questionnaire);

        if (this.isChapterQuestionnaire && this.vintageQuestionPath[0].branch !== null && this.vintageQuestionPath[0].branch.type === 'chapters') {
            // only use the chapter title if we're dealing with a chapter, not when we're e.g. showing the chapter overview.
            this.questionTitle = ChapterUtils.getChapterTitle(this.questionnaire.questions, this.vintageQuestionPath, this.contentTranslationPipe);
        } else {
            this.questionTitle = this.contentTranslationPipe.transform(this.currentQuestion, 'subtitle');
        }

        const disableKeyboardEvents = ['integer', 'decimal'].includes(this.currentQuestion.type);
        this.isKeyboardOpen$ = disableKeyboardEvents ? new BehaviorSubject(false) : this.deviceKeyboardService.isKeyboardOpen();
        this.remarkFocusService.reset();
        this.onRemarkFocus$ = disableKeyboardEvents ? new BehaviorSubject(false) : this.remarkFocusService.remarkFocus();

        this.disableBackButton =
            this.vintageQuestionPath.length === 0
            || (this.vintageQuestionPath.length === 1 && this.vintageQuestionPath[0].question === 0)
            // First question in repeated question
            || (this.vintageQuestionPath.length === 2 && this.vintageQuestionPath[0].question === 0 && this.vintageQuestionPath[0].branch.type === 'repeat' && this.vintageQuestionPath[1].question === 0);
    }

    ionViewDidEnter() {
        // update the concept with the currently active route
        setTimeout(() => {
            this.updateConcept();
        }, 100);
    }

    /**
     * Updates the concept with the new data
     *
     * @returns
     */
    protected async updateConcept() {
        if (!!this.concept) {
            try {
                await this.conceptService.saveConcept(this.concept, this.router.url);
            } catch (error) {
                console.error('error occurred while saving questionnaire', error);
                this.trackingService.exception(error);
            }
        }

        return Promise.resolve();
    }

}
