<form [formGroup]="questionForm" novalidate>
    <app-searchable-choices
        [choices]="question.choices"
        (choicesFiltered)="choicesFiltered($event)"
    ></app-searchable-choices>

    <app-manual-choice-addition
        *ngIf="manualAdditionsAllowed"
        [abstractChoiceQuestion]="this"
    ></app-manual-choice-addition>

    <div class="choice-block-wrapper" *ngIf="(isKeyboardClosed$ | async) && selectedChoices?.length > 0">
        <div class="choice-container">
            <p class="card__description">{{ 'COMPONENTS.checkbox_searchable.selected_choices' | translate }}</p>
            <div *ngFor="let choice of selectedChoices; let i = index" class="choice choice-block" [class.checked]="true" (click)="choose(choice)">
                <span class="icon icon-checked"></span>
                <span class="choice-description">
                    {{choice | contentTranslate:'title'}}
                    <div *ngIf="choice | contentTranslate:'subtitle'" class="choice-subtitle">
                        {{ choice | contentTranslate:'subtitle' }}
                    </div>
                </span>
            </div>
        </div>
    </div>
    <div class="choice-block-wrapper">
        <div class="choice-container">
            <span class="multiple-choice-description light">{{ 'COMPONENTS.checkbox.multiple_choices_possible' | translate }}</span>
            <div *ngFor="let choice of listChoices; let i = index" class="choice choice-block" [class.checked]="getFormControl(i).value" (click)="choose(choice)">
                <span class="icon" [class.icon-checked]="getFormControl(i).value"></span>
                <span class="choice-description">
                    {{ choice | contentTranslate:'title' }}
                    <div *ngIf="choice | contentTranslate:'subtitle'" class="choice-subtitle">
                        {{ choice | contentTranslate:'subtitle' }}
                    </div>
                </span>
            </div>
        </div>
        <div class="mb-4" *ngIf="listChoices.length === 0">{{ 'COMPONENTS.checkbox_searchable.no_choices_found' | translate }}</div>
        <div class="mb-4 choices-limited" *ngIf="listChoices.length >= maxListChoices">{{ 'COMPONENTS.checkbox_searchable.visible_choices' | translate: { maxListChoices,filteredChoices: filteredChoices.length } }}<br>{{ 'COMPONENTS.checkbox_searchable.limit_choices' | translate }}</div>
        <app-scrollto *ngIf="listChoices.length > 10" [content]="content" (scrollingToBottom)="scrollingToBottom()"></app-scrollto>
        <ion-infinite-scroll threshold="50%" (ionInfinite)="appendToListChoices($event)">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </div>
</form>
