import {Injectable} from '@angular/core';
import {ProgressiveApiService, ProgressiveStrategy} from './progressive-api-service';
import {QuestionnaireListItem} from '../models/questionnaire';
import {Observable} from 'rxjs';
import {StructureNodeType} from '../enums/structure-node-type';
import {QuestionnaireFilterType} from '../enums/questionnaire-filter-type';

@Injectable()
export class QuestionnaireListService {

    constructor(private progressiveApiService: ProgressiveApiService) {
    }

    /**
     * Get the questionnaire lists for a specific leaf node
     * Set to FALLBACK since we want to have the actual organisation structure when e.g. submitting a pluim / incident or other form.
     * (PROGRESSIVE might mean 2 data objects (from cache, and from API), which causes subscriptions to fire twice).
     */
    public getStructureNodeQuestionnaires(structureType: StructureNodeType, structureNodeId: number, questionnaireFilterType: QuestionnaireFilterType): Observable<QuestionnaireListItem[]> {
        const progressiveStrategy = this.determineStrategyForFilterType(questionnaireFilterType);
        return this.progressiveApiService.authenticatedGet(`/${structureType}/${structureNodeId}/questionnaires`, progressiveStrategy);
    }

    /** 2021-10-04: changed the ALL type to fallback as well, due to various issues observed when executing the last mile of testing */
    private determineStrategyForFilterType(questionnaireFilterType: QuestionnaireFilterType): ProgressiveStrategy {
        switch (questionnaireFilterType) {
            case QuestionnaireFilterType.DIRECT:
            case QuestionnaireFilterType.COMPLIMENT:
            case QuestionnaireFilterType.INCIDENT:
            case QuestionnaireFilterType.ALL:
                return ProgressiveStrategy.FALLBACK;
        }
    }
}
