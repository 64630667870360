import {Component, Input, EventEmitter, Output} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Component({
    selector: 'app-scrollto',
    templateUrl: 'scrollto.html',
})
export class ScrolltoComponent {

    @Input() public content: HTMLIonContentElement;
    @Output() private scrollingToTop: EventEmitter<void> = new EventEmitter();
    @Output() private scrollingToBottom: EventEmitter<void> = new EventEmitter();

    private scrollDuration = 600;
    private loader: HTMLIonLoadingElement;

    constructor(
        private loadingCtrl: LoadingController,
    ) {
    }

    async scrollToTop() {
        await this.startLoading(this.scrollingToTop);
        await this.waitForNextFrameRender();
        this.stopLoading();
        this.content.scrollToTop(this.scrollDuration);
    }

    async scrollToBottom() {
        await this.startLoading(this.scrollingToBottom);
        await this.waitForNextFrameRender();
        this.stopLoading();
        this.content.scrollToBottom(this.scrollDuration);
    }

    private startLoading(eventEmitter: EventEmitter<void>): Promise<void> {
        return new Promise(async (resolve) => {
            this.loader = await this.loadingCtrl.create();
            this.loader.present().then(async () => {
                eventEmitter.emit();
                await this.waitForNextFrameRender();
                resolve();
            });
        });
    }

    private stopLoading(): void {
        this.loader.dismiss();
    }

    // Wait for events/rendering to be finished
    private waitForNextFrameRender(): Promise<void> {
        return new Promise((resolve) => {
            window.requestAnimationFrame(() => {
                resolve();
            });
        });
    }

}
