import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RemarkFocusService {

    private remarkFocus$ = new BehaviorSubject<boolean>(false);

    reset(): void {
        this.remarkFocus$.next(false);
    }

    remarkFocus(): Observable<boolean> {
        return this.remarkFocus$.asObservable();
    }

    setHasFocus(focus: boolean): void {
        this.remarkFocus$.next(focus);
    }
}
