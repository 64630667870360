export const TRANSLATION_DE = {
    DATE: {
        long: 'DD MMMM YYYY [-] HH:mm [Uhr]',
        date: 'DD-MM-YYYY',
    },
    LOGIN: {
        title: 'Willkommen!',
        slogan: 'Seien Sie wachsam! Sicherheit geht vor!',
        buttons: {
            external: 'Ich bin ein externer Mitarbeiter',
            internal: 'Ich bin ein Mitarbeiter von VolkerWessels',
        },
    },
    BUTTONS: {
        remove: 'Löschen',
        ok: 'OK',
        yes: 'Ja',
        yes_and_send: 'Ja, und senden',
        no: 'Nein',
        cancel: 'Abbrechen',
        again: 'Nochmal',
        back: 'Zurück',
        submit: 'Senden',
        skip: 'Überspringen',
        next: 'Weiter',
    },
    DASHBOARD: {
        tile: {
            home: 'Startseite',
            forms: 'Formulare',
            compliment: 'Anerkennung',
            compliment_desc: 'Ich möchte einem Kollegen ein Kompliment für seine sichere Arbeit machen.',
            scan_qr: 'QR scannen',
            scan_qr_desc: 'Ich möchte einen QR-Code, damit ich zum richtigen Formular gelangen kann.',
            instruction: 'WAVE-Programm',
            more: 'Mehr',
            title: 'Hallo, was wirst du tun?',
            subtitle: 'Bitte treffen Sie unten eine Auswahl',
        },
        report: {
            incident: 'Vorfall oder MOHS melden',
            incident_desc: 'Ich möchte einen Vorfall, eine unsichere Praxis, eine Umgebung und/oder eine Explosion oder einen Brand melden.',
        },
        tasks: {
            notification: {
                singular: 'You have <b>{{value}} open</b> task',
                plural: 'You have <b>{{value}} open</b> tasks',
            },
        },
        deprecation: {
            title: 'Bitte beachten Sie',
            text: 'Vanaf 1 september dient u gebruik te maken van de ETT WAVE app. Klik hier om deze te downloaden.',
        },
    },
    DASHBOARD_MORE: {
        tile: {
            history: 'Geschichte',
            news: 'Neuigkeiten',
            cardGame: 'WAVE Accelerator',
            tasks: 'Aufgaben',
        },
        tasks: {
            notification: {
                singular: '{{value}} open task',
                plural: '{{value}} open tasks',
            },
        },
    },
    HEADER: {
        structure: 'New form',
    },
    PUSH: {
        news: {
            title: 'Neuigkeiten erhalten',
        },
        task: {
            title: 'Aufgabe zugewiesen',
        },
        instruction: {
            title: 'WAVE-Programm erhalten',
        },
    },
    DISCLAIMER: {
        heading: 'HAFTUNGSAUSSCHLUSS',
        title: 'Wichtig!',
        content: 'Geben Sie keine persönlichen Daten (wie Namen, Telefonnummern, Kennzeichen) ein, es sei denn, wir fragen ausdrücklich danach. '
            + 'Wenn Sie ein Foto hochladen müssen, stellen Sie sicher, dass keine Personen auf dem Bild zu erkennen sind.',
        buttons: {
            agree: 'Ja, ich habe den Haftungsausschluss gelesen',
        },
    },
    HELP: {
        title: 'Help Centre',
        app_version: 'Version der Anwendung:',
        intro: 'Im Folgenden finden Sie die am häufigsten gestellten Fragen zur WAVE-App. Wenn Sie hier keine Antwort auf Ihre Frage finden, wenden Sie sich bitte an den IT-Helpdesk Ihres Unternehmens.',
        phone_number: 'Telefon-Nummer',
        tenant_phone_numbers: {
            'be36ab0a-ee39-47de-9356-a8a501a9c832': '088 186 0606', // VWT
            '0ef544fb-3d5c-4c60-932e-735bcef00405': '088 186 7777', // BVGO
            'cdc477bf-b6e3-4345-b1be-3b225394e17e': '088 186 2555', // VW Infra
            '957801e8-a608-42e6-afc7-a2e6399ec203': '085 800 1221', // V&SH
        },
        buttons: {
            privacy: 'Datenschutzerklärung',
            instruction_film: 'Video mit Anleitungen',
        },
    },
    CONTACT: {
        title: 'Kontaktinformationen',
        subtitle: 'See your account information, log out or change the app language.',
        email_address: 'E-Mail-Adresse',
        phone_number: 'Telefonnummer',
        fill_phone_number: 'Geben Sie Ihre Telefonnummer ein',
        language_selection: 'Sprachauswahl',
        save: 'Änderungen speichern',
        logout: 'Abmelden',
        change_password: 'Passwort ändern',
        remove_account: 'Konto löschen',
        confirm_external_modal: {
            heading: 'Konto löschen',
            body: 'Sind Sie sicher, dass Sie das Konto löschen möchten?',
            button: 'Ja, und Konto löschen',
        },
        removing_account: 'Konto wird gelöscht',
        account_removed: 'Ihr Konto wurde erfolgreich gelöscht.',
    },
    DEEPLINK_QUESTIONNAIRE: {
        title: 'Formular öffnen',
        unfortunately: 'Leider...',
        ok_and_close: 'OK, und Seite schließen',
    },
    EXTERNAL_LOGIN: {
        title: 'Externes Konto',
        email: 'E-Mail',
        password: 'Passwort (8 oder mehr Zeichen)',
        login: 'Anmelden',
        forgot_password: 'Passwort vergessen',
        create_account: 'Konto erstellen',
    },
    FAQ: {
        title: 'FAQs',
        sections: [{
            title: '',
            questions: [{
                question: 'An wen kann ich mich wenden, wenn ich Fragen zu den Formularen habe?',
                answer: 'Bei Fragen zum Inhalt der Formulare in der App können Sie sich an den Funktionsadministrator (in der Regel ein QHSE-Kollege oder Sicherheitsexperte) in Ihrem Unternehmen wenden.',
                open: false,
            }, {
                question: 'Was tue ich, wenn ich nicht alle Fragen beantworten kann?',
                answer: 'Es kann natürlich sein, dass Sie nicht auf alle Fragen eine Antwort wissen. Antworten Sie nur, wenn Sie sich sicher sind. Andernfalls vermerken Sie bitte, dass Sie die Antwort nicht wissen.',
                open: false,
            }, {
                question: 'An wen kann ich mich wenden, wenn ich technische Fragen zur App habe?',
                answer: 'Bei technischen Fragen zur App können Sie sich an den IT-Helpdesk Ihres Unternehmens wenden. Die Telefonnummer finden Sie oben auf dieser Seite.',
                open: false,
            }, {
                question: 'Was bedeutet die orangefarbene Zahl unter „Formulare“?',
                answer: 'Auf Ihrem Dashboard wird eine Zahl angezeigt, wenn ein oder mehrere Formulare nicht ausgefüllt und/oder abgeschickt worden sind. Die Zahl zeigt an, wie viele Formulare es noch gibt. ' +
                    'Die Formulare, die noch nicht ausgefüllt und/oder abgeschickt wurden, finden Sie unten auf der Formularseite. Wenn Sie darauf klicken, können Sie die Formulare ausfüllen und abschicken. Es ist auch möglich, Formulare zu löschen, die noch nicht ausgefüllt und/oder abgeschickt wurden.',
                open: false,
            }, {
                question: 'Kann ich Änderungen an einem Bericht vornehmen, den ich abgeschickt habe?',
                answer: 'Nein, das ist leider nicht möglich. Sie können sich jedoch jederzeit an den Funktionsadministrator (in der Regel ein QHSE-Kollege oder Sicherheitsexperte) in Ihrem Unternehmen wenden, um eventuelle Änderungen weiterzugeben.',
                open: false,
            }, {
                question: 'Was geschieht mit meinem Bericht, nachdem ich ihn abgeschickt habe?',
                answer: 'Die Berichte werden automatisch an die für das Projekt zuständige Person gesendet. Wer das ist, kann je nach Formular variieren. ' +
                    'Der Funktionsadministrator (in der Regel ein QHSE-Kollege oder Sicherheitsexperte) erhält eine Kopie des Formulars. Außerdem erhalten auch Sie eine Kopie des Formulars per E-Mail.',
                open: false,
            }, {
                question: 'Kann ich einen Bericht löschen?',
                answer: 'Ja, das können Sie. Allerdings ist dies nicht über die App möglich. Wenden Sie sich an den Funktionsadministrator (normalerweise ein QHSE-Kollege oder Sicherheitsexperte) in Ihrem Unternehmen, um den Bericht zu löschen.',
                open: false,
            }, {
                question: 'Kann ich mit der App auch offline arbeiten?',
                answer: 'Ja, das können Sie. Sie können jederzeit ein Formular ausfüllen und in der App speichern. Wenn das Formular nicht vollständig ist, erhalten Sie eine Meldung, dass es nicht gesendet werden kann. Das Formular wird dann als Entwurf gespeichert. ' +
                    'Der Entwurf wird nicht automatisch gesendet, sobald Sie mit dem Internet verbunden sind. Sie müssen den Entwurf öffnen und ihn dann versenden.  ' +
                    'Leider können externe Benutzer nicht alle Formulare ausfüllen, wenn sie offline arbeiten. Sie können nur unsichere Situationen offline melden. ' +
                    'Für die anderen Formulare müssen sie online arbeiten.',
                open: false,
            }, {
                question: 'Kann ein Subunternehmer die App verwenden?',
                answer: 'Ja, das können sie. Ein Subunternehmer kann sich als externer Benutzer anmelden.',
                open: false,
            }, {
                question: 'Kann ich meine Telefonnummer ändern?',
                answer: 'Ja, das können Sie. Alle Mitarbeiter von VolkerWessels können ihre Telefonnummern ändern. Sie können dies in Ihrem Profil in der App tun.',
                open: false,
            }],
        }],
    },
    NOTIFICATION: {
        title: 'Bericht',
        warning: 'Warnung',
        close: 'Schließen',
        open: 'Offen',
        error_general: 'Es ist ein unerwarteter Fehler aufgetreten.',
        error_retrieving_profile: 'Beim Abrufen Ihres Profils ist ein Fehler aufgetreten',
        something_wrong_saving: 'Beim Absenden des Formulars ist ein Fehler aufgetreten',
        something_wrong_task: 'Beim Senden der Aufgabe ist ein Fehler aufgetreten Vervollständigen Sie die Kommentare oder versuchen Sie es später noch einmal.',
        something_wrong_logout: 'Beim Abmelden ist ein Fehler aufgetreten.',
        offline: 'Sie arbeiten derzeit offline. Daher ist diese Funktion derzeit nicht verfügbar.',
        account_removed_failed: 'Beim Löschen Ihres Kontos ist ein Fehler aufgetreten. Versuchen Sie es später noch einmal.',
        inactive_form: 'Dieses Formular ist nicht mehr gültig. Füllen Sie das Formular erneut aus.',
        no_access: 'Sie haben keinen Zugang zu WAVE. Kontaktieren Sie Ihren Administrator',
        error_message: 'Fehlermeldung:',
        login_failed: 'Anmeldung fehlgeschlagen',
        error_logged_out: 'Leider ist beim Abrufen der Daten etwas schief gelaufen. Sie werden abgemeldet',
        retrieving_options: 'Optionen/Kontakte abrufen',
        aggregating: 'Daten zusammenfassen',
        update_failed: 'Editieren fehlgeschlagen',
        retrieval_failed: 'Abruf fehlgeschlagen',
        error_forms_no_network: 'Die Formulare können nicht bearbeitet werden (keine Netzwerkverbindung). Versuchen Sie, die Formulare erneut zu bearbeiten?',
        error_forms_slow_network: 'Formulare können nicht bearbeitet werden (langsame Netzwerkverbindung). Versuchen Sie, die Formulare erneut zu bearbeiten?',
        error_forms_retrieval_no_network: 'Formulare können nicht abgerufen werden (keine Netzwerkverbindung). Überprüfen Sie die Netzwerkverbindung und versuchen Sie es erneut.',
        error_forms_retrieval_slow_network: 'Formulare können nicht abgerufen werden (langsame Netzwerkverbindung). Überprüfen Sie die Netzwerkverbindung und versuchen Sie es erneut.',
        error_popup_blocked: 'Erlauben Sie Pop-Ups, damit Sie einloggen können.',
        image_file_needed: 'Datei ist kein gültiges Bild',
        route_not_found: 'Umleitung fehlgeschlagen',
        route_not_found_explanation: 'Umleitung auf die Seite oder das Formular ist fehlgeschlagen. Die Ursache kann sein, dass Sie nicht darauf zugreifen können, die Seite oder das Formular nicht mehr existiert oder das Formular nicht in Ihrer Sprache verfügbar ist.',
    },
    FORGOT_PASSWORD: {
        title: 'Passwort vergessen',
        subtitle: 'Wenn Ihre E-Mail-Adresse bekannt ist, erhalten Sie eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts.',
    },
    FORGOT_PASSWORD_FORM: {
        title: 'E-Mail Adresse eingeben',
        email: 'E-Mail',
        next: 'Weiter',
    },
    NEWS: {
        title: 'Neuigkeiten',
        subtitle: 'WAVE nieuws berichten',
        search: 'Search by news',
    },
    MEDIA_COMMUNICATION: {
        subtitle: {
            theme: 'Choose a theme',
            subject: 'Choose a topic',
        },
        search: {
            theme: 'Search by theme',
            subject: 'Search by topic',
        },
    },
    SEARCH_PLACEHOLDER: 'Suche',
    PASSWORD_CHANGE: {
        title: 'Passwort ändern',
        current_password: 'Aktuelles Passwort',
        password_explanation: 'Das Passwort muss mindestens 8 Zeichen lang sein und einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten',
        new_password: 'Neues Passwort',
        repeat_new_password: 'Wiederholen Sie das neue Passwort',
        passwords_dont_match: 'Passwörter stimmen nicht überein',
        password_changed: 'Ihr Passwort wurde geändert Sie müssen sich erneut anmelden',
        current_password_incorrect: 'Das eingegebene Passwort stimmt nicht mit Ihrem aktuellen Passwort überein',
        confirm: 'Bestätigen',
    },
    QUESTIONNAIRE: {
        part: 'Komponente',
        map_offline: 'Sie arbeiten offline. Die Karte ist nur im Online-Modus verfügbar.',
        from: 'from',
    },
    QUESTIONNAIRE_HISTORY: {
        title: 'Gesendete Formulare',
        no_forms: 'Es wurden noch keine Berichte gesendet.',
        submitted_at: 'Übermittelt am',
        processed_at: 'Bearbeitet am',
        processed_submitted_at: 'Bearbeitet, übermittelt am',
    },
    QUESTIONNAIRE_REPEAT_QUESTION: {
        part: 'Komponente',
    },
    QUESTIONNAIRE_SENT: {
        title: 'Vielen Dank',
        subtitle: 'Das Formular wird gerade bearbeitet. Eine Kopie des Formulars wird Ihnen per E-Mail zugesandt. Sie finden das ausgefüllte Formular auch unter Verlauf in dieser App.',
    },
    QUESTIONNAIRE_TASK: {
        created_on: 'Erstellt am:',
        completed_on: 'Erledigt am:',
        place_remark: 'Geben Sie hier Ihre(n) Kommentar(e) ein',
        remark: 'Kommentar(e):',
        task_denied: 'Nicht bearbeitet',
        task_completed: 'Erledigt',
        are_you_sure:  'Sind Sie sicher, dass Sie diese Aufgabe „{{statusLabel}}“ erstellen möchten?',
        page_leave: 'Seite verlassen',
        page_leave_explanation: 'Sie haben das Feld „Kommentare“ für die Aufgabe ausgefüllt. Wenn Sie die Seite verlassen, geht diese Änderung verloren. Sind Sie sicher, dass Sie die Seite verlassen wollen?',
    },
    QUESTIONNAIRE_TASKS: {
        title: 'Aufgaben in Bearbeitung',
        subtitle: 'Click on a task to execute it',
        planned_forms: 'Geplante Formulare',
        planned_forms_subtitle: 'Below are forms that must be sent by a certain date',
        closed_tasks: 'Erledigte Aufgaben',
        closed_tasks_subtitle: 'Below are tasks which have been completed',
        closed_on: 'Erledigt am:',
        deadline_passed: 'Deadline überschritten am:',
        deadline_at: 'Deadline verstrijkt op:',
        created_at: 'Erstellt am:',
    },
    CARD_GAME: {
        question: {
            label: 'Frage',
        },
        toolbar: {
            button_label: 'Neue Karte',
            close: 'Schließen',
        },
    },
    ERROR: {
        form: {
            warning: 'Warnung',
            not_found: 'Es sind noch keine Formulare verfügbar in Ihrer Sprache.',
            directIncidentOnly: 'Das Formular für „Vorfall oder MOHS melden“ ist derzeit nicht verfügbar. Bitte wenden Sie sich an Ihren Systemadministrator.',
            directComplimentOnly: 'Das Formular ist in Ihrer Sprache nicht verfügbar.',
            questionnaireIdToSelect: 'Diese Aufgabe ist für die ausgewählte Organisationseinheit derzeit nicht verfügbar. Bitte wählen Sie eine andere Organisationseinheit oder kontaktieren Sie Ihren Systemadministrator.',
        },
        no_access: {
            title: 'Keine Rechte',
            explanation: 'Sie haben keinen Zugriff auf diese Formulare. Gehen Sie zurück zum Startbildschirm.',
        },
    },
    REGISTRATION: {
        title: 'Neues Konto erstellen',
        email: 'E-Mail',
        first_name: 'Vorname',
        sur_name: 'Nachname',
        mobile_phone: 'Handynummer',
        password: 'Passwort',
        password_explanation: 'Das Passwort muss mindestens 8 Zeichen lang sein und einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten',
        repeat_password: 'Passwort wiederholen',
        passwords_dont_match: 'Passwörter stimmen nicht überein',
        approve: 'Ich stimme zu',
        privacy: 'Datenschutzrichtlinie',
        create_account: 'Konto erstellen',
        account_exists: 'Es ist bereits ein Konto mit dieser E-Mail-Adresse verknüpft',
        error_something_wrong: 'Bei der Registrierung ist ein Fehler aufgetreten.',
    },
    REGISTRATION_COMPLETE: {
        title: 'Bestätigen Sie Ihre E-Mail-Adresse',
        subtitle: 'Vielen Dank, dass Sie ein Konto erstellt haben. In wenigen Augenblicken erhalten Sie eine E-Mail, in der Sie gebeten werden, Ihre E-Mail-Adresse zu bestätigen.',
    },
    REGISTRATION_SMS: {
        title: 'Überprüfung der Telefonnummer',
        subtitle: 'Geben Sie hier den Verifizierungscode ein, den Sie per Textnachricht erhalten haben. Dieser Code wird an die angegebene Telefonnummer gesendet.',
        verification_code: 'Verifizierungscode',
        verify: 'Verifizierungscode prüfen',
        incorrect_code: 'Ungültiger Verifizierungscode',
        error_something_wrong: 'Bei der Verifizierung ist etwas schief gelaufen.',
    },
    SELECT_QUESTIONNAIRE: {
        open_forms: 'Noch nicht ausgefüllte Formulare',
        open_forms_subtitle: 'Click on a form to continue',
        new_form: 'New form',
        new_form_subtitle: 'Click to start a new form',
        planned_forms: 'Geplante Formulare',
        available_forms: 'Verfügbare Formulare',
        workplace_forms: 'Inspektion des Arbeitsbereichs',
        retrieve_forms: 'Formulare abrufen',
        retrieve_form: 'Formular abrufen',
        choose_action: 'Wählen Sie Ihre Aktion',
        open: 'Offen',
        upload: 'Online stellen',
        download_and_open: 'Formular herunterladen und öffnen',
        remove: 'Löschen',
        something_went_wrong: 'Beim Abrufen der Formulare ist etwas schief gelaufen',
    },
    SELECT_STRUCTURE: {
        choose_project: 'Wählen Sie Ihr Projekt',
        choose_business_unit: 'Wählen Sie Ihre Geschäftseinheit',
        no_organisations: 'Keine Organisationen verfügbar.',
        search_on: 'Suchen nach',
        choose_your: 'Select your',
        organisation: 'Unternehmen',
        project: 'Projekt',
        projects: 'Projekte',
        business_unit: 'Geschäftseinheit',
        business_units: 'Geschäftseinheiten',
    },
    SEND_QUESTIONNAIRE: {
        title: 'Möchten Sie das Formular absenden?',
        description: 'Durch das Absenden können Sie an diesem gesendeten Formular nichts mehr ändern.',
        account_blocked: 'Dieses Formular kann erst abgeschickt werden, wenn Ihr Konto genehmigt wurde.',
        offline: 'Sie arbeiten gerade offline und können das Formular daher nicht versenden. Senden Sie das Formular ab, sobald Sie wieder online sind.',
        something_went_wrong: 'Beim Absenden des Formulars ist ein Fehler aufgetreten.',
        confirm: 'Ja, senden',
        cancel: 'Kein Zurück',
    },
    SCAN_QR: {
        incorrect_result: 'Falscher QR-Code. Scannen Sie einen korrekten WAVE-QR-Code.',
        no_result: 'Kein QR-Code gescannt.',
        no_permission: 'Die App kann nicht auf die Kamera zugreifen. Geben Sie der App die Erlaubnis, bevor Sie einen QR-Code scannen.',
        try_again: 'Erneut scannen',
    },
    COMPONENTS: {
        attachments_remarks: {
            add: 'Hinzufügen',
            place_remark: 'Geben Sie hier Ihre(n) Kommentar(e) ein',
            max_attachments_reached: 'Die maximale Anzahl von Anhängen wurde erreicht.',
            note: 'Note',
            picture: 'Picture',
        },
        checkbox: {
            multiple_choices_possible: 'Mehrere Optionen möglich',
            no_choices_selected: 'Es wurde(n) keine Option(en) ausgewählt.',
        },
        checkbox_searchable: {
            selected_choices: 'Ausgewählte Option(en)',
            no_choices_found: 'Es wurde(n) keine Option(en) gefunden.',
            visible_choices: '{{maxListChoices}} der {{filteredChoices}} Optionen sind sichtbar.',
            limit_choices: 'Schränken Sie die Optionsliste durch eine Suche ein.',
        },
        lat_long: {
            current_location: 'Aktuellen Standort verwenden',
            or: 'Oder',
            city: 'Stadt',
            address: 'Adresse',
            invalid_address: 'Dies ist keine gültige Adresse.',
        },
        manual_choice: {
            new_choice: 'Neue Option eingeben',
            choice_exists: 'Diese Option existiert bereits.',
        },
        photo: {
            subtitle: 'Fügen Sie Fotos hinzu, indem Sie auf das Pluszeichen klicken',
            gallery: 'Foto-Galerie',
            drop_explanation: 'Klicken, ziehen oder fügen Sie Fotos hier ein, um sie hinzuzufügen',
            max_reached: 'Die maximale Anzahl von Fotos, die angehängt werden können, wurde erreicht.',
            take_a_photo: 'Mach ein Foto',
            upload_from_gallery: 'Aus der Galerie hochladen',
        },
        questionnaire_footer: {
            chapters: 'Kapitel',
            previous: 'Zurück',
        },
        questionnaire_overlay: {
            help_text: 'Nachdem Sie die Frage beantwortet haben, klicken Sie auf „Weiter“, um zur nächsten Frage zu gelangen.',
        },
        radio: {
            choose: 'Eine Auswahl treffen',
            no_choice_selected: 'Es wurde keine Option ausgewählt.',
        },
        radio_searchable: {
            selected_choice: 'Ausgewählte Option',
            no_choice_found: 'Es wurden keine Optionen gefunden.',
            visible_choices: '{{maxListChoices}} der {{filteredChoices}} Optionen sind sichtbar.',
            limit_choices: 'Schränken Sie die Optionsliste durch eine Suche ein.',
        },
        searchable_choices: {
            search: 'Suche',
        },
        select_concept: {
            last_change: 'Zuletzt bearbeitet:',
        },
        select_item: {
            submit_before: 'Davor abschicken ',
        },
    },
};
