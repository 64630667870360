import {ApiService} from './api-service';
import {Injectable} from '@angular/core';
import {Login} from '../models/login';
import {Token} from '../models/token';
import {PasswordReset} from '../models/password-reset';
import {PasswordChange} from '../models/password-change';

@Injectable({
    providedIn: 'root',
})
export class ExternalUserService {

    constructor(
        private api: ApiService,
    ) {
    }

    public async postLogin(login: Login): Promise<Token> {
        return this.api.post('/login/external', login);
    }

    public postRegistration(registrationData): Promise<any> {
        return this.api.post('/registration/external', registrationData);
    }

    public postVerifySms(verificationData): Promise<any> {
        return this.api.post('/registration/external/verify_sms', verificationData);
    }

    public postPasswordResetRequest(passwordReset: PasswordReset): Promise<any> {
        return this.api.post('/password/reset', passwordReset);
    }

    public postPasswordChange(passwordChange: PasswordChange): Promise<any> {
        const passwordData = {
            current_password: passwordChange.currentPassword,
            new_password: {
                first: passwordChange.newPassword,
                second: passwordChange.newPasswordRepeat,
            },
        };

        return this.api.authenticatedPost('/password/change', passwordData);
    }

    public async removeRegisteredAccount(): Promise<boolean> {
        return this.api.authenticatedDelete('/registration/account');
    }

}
