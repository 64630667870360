<app-dashboard-header *ngIf="structureNodeType === structureNodeTypeEnum.CONCERN" [showDashboardButton]="false" [showHelpButton]="true"></app-dashboard-header>
<app-generic-header *ngIf="structureNodeType !== structureNodeTypeEnum.CONCERN"></app-generic-header>

<ion-content class="has-header">
    <div *ngIf="((dataLoaded$ | async) === true && organisationListItemsSubject.value?.length === 0) || isAppDeprecated(); else showContent" class="card">
        <h2 class="card__title">{{ 'ERROR.no_access.title' | translate }}</h2>
        <h3>{{ 'ERROR.no_access.explanation' | translate }}</h3>
    </div>
</ion-content>

<ng-template #showContent>
    <!-- concepts -->
    <div class="card" *ngIf="prefix === filterTypeEnum.ALL && (concepts.length > 0 || uploadedConcepts.length > 0)">
        <div class="card__header">
            <h2 class="card__title">{{ 'SELECT_QUESTIONNAIRE.open_forms' | translate }}</h2>
            <p class="card__description">{{ 'SELECT_QUESTIONNAIRE.open_forms_subtitle' | translate }}</p>
        </div>
        <div class="card__concepts">
            <app-select-concept *ngFor="let concept of concepts; trackBy: conceptQuestionnaireService.trackConceptById"
                                [concept]="concept"
                                (click)="conceptQuestionnaireService.selectConcept(concept)"></app-select-concept>
            <ng-container *ngIf="uploadedConcepts.length > 0">
                <hr>
                <h3>Online beschikbaar</h3>
                <app-select-uploaded-concept *ngFor="let concept of uploadedConcepts; trackBy: conceptQuestionnaireService.trackUploadedConceptById"
                                             [concept]="concept"
                                             (click)="conceptQuestionnaireService.selectUploadedConcept(concept)"></app-select-uploaded-concept>
            </ng-container>
        </div>
    </div>

    <div class="card">
        <!-- Forms Flow -->
        <div class="card__header" *ngIf="prefix === filterTypeEnum.ALL">
            <h2 class="card__title">{{ 'HEADER.structure' | translate }}</h2>
            <p class="card__description">{{ subTitleText }}</p>
        </div>
        <!-- Give Compliment or Report Incident flow -->
        <div class="card__header" *ngIf="prefix !== filterTypeEnum.ALL">
            <h2 class="card__title">{{ subTitleText }}</h2>
        </div>
        <ion-searchbar [formControl]="searchControl" placeholder="{{searchPlaceholderText}}" ionAutoMode class="card__search"></ion-searchbar>

        <!-- organizations -->
        <app-select-item *ngFor="let organisation of filteredOrganisations$|async; trackBy: trackOrganisationListItemByTypeAndId"
                         [imageData]="organisation.imageData"
                         [title]="organisation.title"
                         [subTitle]="organisation.description"
                         [numberOfConcepts]="organisation.badgeAmount"
                         (click)="openRoute(organisation)">
            <ion-icon src="assets/images/icon-favorite.svg"
                      class="select-item__icon-favorite"
                      [class.is-favorite]="organisation.isFavorite"
                      (click)="toggleFavorite($event, organisation)"></ion-icon>
        </app-select-item>

        <!-- projects -->
        <ng-container *ngIf="filteredProjects$|async as filteredProjects">
            <div *ngIf="filteredProjects.length > 0" class="project-list">
                <button type="button" class="card__toggle" (click)="toggleProjects()">
                    {{ 'SELECT_STRUCTURE.projects' | translate }}
                    <span class="card__toggle-icon" [class.card__toggle-icon--active]="projectsExpanded"></span>
                </button>

                <div [hidden]="!projectsExpanded">
                    <app-select-item *ngFor="let project of filteredProjects; trackBy: trackOrganisationListItemByTypeAndId"
                                     [title]="project.title"
                                     [numberOfConcepts]="project.badgeAmount"
                                     [hasImagePlaceholder]="false"
                                     (click)="openRoute(project)">
                        <ion-icon src="assets/images/icon-favorite.svg"
                                  class="select-item__icon-favorite"
                                  [class.is-favorite]="project.isFavorite"
                                  (click)="toggleFavorite($event, project)"></ion-icon>
                    </app-select-item>
                </div>
            </div>
        </ng-container>

        <!-- business units -->
        <ng-container *ngIf="filteredBusinessUnits$|async as filteredBusinessUnits">
            <div *ngIf="filteredBusinessUnits.length > 0">
                <button type="button" class="card__toggle" (click)="toggleBusinessUnits()">
                    {{ 'SELECT_STRUCTURE.business_units' | translate }}
                    <span class="card__toggle-icon" [class.card__toggle-icon--active]="businessUnitsExpanded"></span>
                </button>

                <div [hidden]="!businessUnitsExpanded">
                    <app-select-item *ngFor="let businessUnit of filteredBusinessUnits; trackBy: trackOrganisationListItemByTypeAndId"
                                     [title]="businessUnit.title"
                                     [numberOfConcepts]="businessUnit.badgeAmount"
                                     [hasImagePlaceholder]="false"
                                     (click)="openRoute(businessUnit)">
                        <ion-icon src="assets/images/icon-favorite.svg"
                                  class="select-item__icon-favorite"
                                  [class.is-favorite]="businessUnit.isFavorite"
                                  (click)="toggleFavorite($event, businessUnit)"></ion-icon>
                    </app-select-item>
                </div>
            </div>
        </ng-container>


        <div *ngIf="showError === true" class="ion-margin-top">
            <span class="text-color-lightText ion-margin-start">{{ 'SELECT_STRUCTURE.no_organisations' | translate }}</span>
        </div>
    </div>
</ng-template>
