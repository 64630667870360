import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {BrowserService} from './browser-service';

@Injectable({
    providedIn: 'root',
})
export class PrivacyPolicyService {

    constructor(
        private browserService: BrowserService,
    ) {
    }

    public openPrivacyPolicyUrl(): void {
        this.browserService.openUrl(environment.privacyPolicyUrl);
    }

}
