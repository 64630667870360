import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {catchError, debounceTime, map} from 'rxjs/operators';
import {AbstractQuestionComponent} from '../../classes/abstract-question.component';
import {PositionService} from '../../services/position-service';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';
import {TrackingService} from '../../services/tracking/tracking.service';
import {ForwardOptions, NativeGeocoder} from '@capgo/nativegeocoder';
import {HttpClient} from '@angular/common/http';
import {Capacitor} from '@capacitor/core';

@Component({
    selector: 'app-lat-lng',
    templateUrl: 'lat-lng.html',
    styleUrls: [
        './lat-lng.scss',
    ],
})
export class LatLngComponent extends AbstractQuestionComponent implements OnInit {

    public options: google.maps.MapOptions = {
        center: {
            lat: LatLngComponent.DEFAULT_LAT,
            lng: LatLngComponent.DEFAULT_LNG,
        },
    };

    // Amersfoort was the center of the VolkerWessels universe
    private static readonly DEFAULT_LAT: number = 52.192561;
    private static readonly DEFAULT_LNG: number = 5.437332;
    private static readonly DISABLED_GESTURE_HANDLING: google.maps.GestureHandlingOptions = 'none';
    private static readonly ADDITIONAL_DISABLED_OPTIONS = {
        gestureHandling: LatLngComponent.DISABLED_GESTURE_HANDLING,
        panControl: false,
        scrollwheel: false,
    };

    public lat: number = LatLngComponent.DEFAULT_LAT;
    public lng: number = LatLngComponent.DEFAULT_LNG;
    public zoom = 15;
    public geocodingForm: UntypedFormGroup;
    public geocodingError = false;
    public isKeyboardOpen$: Observable<boolean>;

    private apiKey = 'AIzaSyB9p46c6avQXI4Q3kLZcNj_pVX3gSKOyw4';
    private nativeGeocoder = NativeGeocoder;

    public googleMapsApiLoaded$ = new BehaviorSubject(false);

    markerOptions: google.maps.MarkerOptions = {draggable: false};
    markerPositions: google.maps.LatLngLiteral[] = [];

    constructor(
        private positionService: PositionService,
        private deviceKeyboardService: DeviceKeyboardService,
        private trackingService: TrackingService,
        fb: UntypedFormBuilder,
        private httpClient: HttpClient,
    ) {
        super(fb);

        this.createGeocodingForm();
        this.loadGoogleMapsApi();
    }

    async ngOnInit() {
        super.ngOnInit();

        this.initGoogleMapOptions();

        this.isKeyboardOpen$ = this.deviceKeyboardService.isKeyboardOpen();
        await this.initializePosition();
    }

    private loadGoogleMapsApi(): void {
        this.httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${this.apiKey}`, 'callback')
            .pipe(
                map(() => {
                    this.googleMapsApiLoaded$.next(true);
                }),
                catchError((err) => {
                    this.googleMapsApiLoaded$.next(false);
                    return throwError(err);
                }),
            ).subscribe();
    }

    private async initializePosition(): Promise<void> {
        let getCurrentPosition = true;

        if (this.currentValue) {
            const tmp = this.currentValue.toString().split(',', 4);
            // BE CAREFUL! Both backend AND app rely on the longitude to be on the first position!
            this.lng = parseFloat(tmp[0]);
            this.lat = parseFloat(tmp[1]);

            if (tmp.length > 3) {
                this.geocodingForm.patchValue({
                    city: tmp[2],
                    address: tmp[3],
                });
            }

            getCurrentPosition = false;
            await this.setPosition({lat: this.lat, lng: this.lng});
        }

        if (getCurrentPosition) {
            try {
                const position = await this.positionService.getCurrentPosition();
                if (position) {
                    await this.navigateToPosition({lat: position.coords.latitude, lng: position.coords.longitude});
                }
            } catch (exception) {
                console.error('Could not get position', exception);
                this.trackingService.exception(exception);
            }
        }
    }

    public async setMyLocationAsCoords(): Promise<void> {
        if (!this.disabled) {
            const position = await this.positionService.getCurrentPosition();
            await this.setPosition({lat: position.coords.latitude, lng: position.coords.longitude});
        }
    }

    private createGeocodingForm(): void {
        this.geocodingForm = this.fb.group({
            city: [''],
            address: [''],
        });

        this.geocodingForm.valueChanges
            .pipe(
                debounceTime(700),
            )
            .subscribe((value) => {
                if (value.city !== '' && value.address !== '') {
                    this.geocodeAddress(value.city, value.address);
                }
            });
    }

    private geocodeAddress(city: string, address: string): void {
        const geoCodeOptions: ForwardOptions = {
            addressString: address + ', ' + city,
            useLocale: true,
            maxResults: 1,
        };
        if (Capacitor.getPlatform() === 'web') {
            geoCodeOptions.apiKey = this.apiKey;
        }
        this.nativeGeocoder.forwardGeocode(geoCodeOptions)
            .then((results) => {
                if (results.addresses.length > 0) {
                    this.geocodingError = false;

                    this.lat = results.addresses[0].latitude;
                    this.lng = results.addresses[0].longitude;

                    this.setPosition({
                        lat: this.lat,
                        lng: this.lng,
                    });
                } else {
                    this.geocodingError = true;
                }
            })
            .catch((err) => {
                console.log('geocoding error', err);
                this.geocodingError = true;
            });
    }

    // Sets the position as being the active position and centers it on the map.
    // Also marks the selected position on the map.
    private async setPosition(position: google.maps.LatLngLiteral): Promise<void> {

        this.lat = position.lat;
        this.lng = position.lng;
        // BE CAREFUL! Both backend AND app rely on the longitude to be on the first position!
        this.valueChange.emit(position.lng + ',' + position.lat + ',' + this.geocodingForm.value.city + ',' + this.geocodingForm.value.address);
        await this.navigateToPosition(position);

        await this.markPosition(position);
    }

    private async navigateToPosition(position: google.maps.LatLngLiteral): Promise<void> {
        // force redraw by resetting the options element instead of just updating the center.
        this.options = {
            ...this.options,
            center: position,
        };
    }

    private async markPosition(position: google.maps.LatLngLiteral): Promise<void> {
        this.markerPositions = [];

        this.markerPositions.push(position);
    }

    private initGoogleMapOptions(): void {
        this.options = {
            center: {
                lat: this.lat,
                lng: this.lng,
            },
            zoom: this.zoom,
            disableDefaultUI: true,
        };
        if (this.disabled) {
            this.options = {
                ...this.options,
                ...LatLngComponent.ADDITIONAL_DISABLED_OPTIONS,
            };
        }
    }

    handleMapClickEvent(event: google.maps.MapMouseEvent): void {
        if (!this.disabled) {
            this.setPosition(event.latLng.toJSON());
        }
    }
}
