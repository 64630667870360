<app-generic-header [showOrganizationName]="false"></app-generic-header>

<ion-content class="has-header">
    <div class="content__full-height">
        <div class="card">
            <h2 class="card__title">{{ 'NEWS.title' | translate }}</h2>
            <ion-searchbar (ionInput)="filterNews($event)" placeholder="{{ 'NEWS.search' | translate }}" ionAutoMode class="card__search"></ion-searchbar>
            <app-select-item *ngFor="let newsListItem of (filtering ? filteredNewsListItems : newsListItems)"
                             [title]="newsListItem.title"
                             [subTitle]="newsListItem.subtitle"
                             [hasImagePlaceholder]="false"
                             (click)="goToNewsDetails(newsListItem.id)"
            ></app-select-item>
        </div>
    </div>
</ion-content>
