import {Component, OnInit} from '@angular/core';
import {AlertController, NavController, Platform} from '@ionic/angular';
import {QuestionnaireTask} from '../../models/questionnaire-task';
import {LoadingService} from '../../services/loading-service';
import {ApiService} from '../../services/api-service';
import {ToastService} from '../../services/toast-service';
import {EventService} from '../../services/event-service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';
import {CanLeavePage} from '../../models/can-leave-page';
import {TrackingService} from '../../services/tracking/tracking.service';
import {TranslateService} from '@ngx-translate/core';
import {QuestionnaireDataService} from '../../services/questionnaire-data-service';

@Component({
    selector: 'page-questionnaire-task',
    templateUrl: 'questionnaire-task.html',
})
export class QuestionnaireTaskPage implements OnInit, CanLeavePage {

    public task$ = new BehaviorSubject<QuestionnaireTask | undefined>(undefined);
    public isKeyboardClosed$: Observable<boolean>;

    private actionHolderRemarks: string;
    private sendTaskSuccesful: boolean;

    constructor(
        private alertCtrl: AlertController,
        private loadingService: LoadingService,
        private apiService: ApiService,
        private toastService: ToastService,
        private navCtrl: NavController,
        private events: EventService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private deviceKeyboardService: DeviceKeyboardService,
        private platform: Platform,
        private trackingService: TrackingService,
        private translateService: TranslateService,
        private questionnairesDataService: QuestionnaireDataService,
    ) {
        activatedRoute.queryParams.subscribe(async params => {
            if (this.router.getCurrentNavigation().extras.state) {
                this.task$.next(this.router.getCurrentNavigation().extras.state.task);
            } else {
                const taskId = this.activatedRoute.snapshot.paramMap.get('task');
                this.loadingService.start().then(() => {
                    this.questionnairesDataService.getQuestionnaireTask(taskId)
                        .then(async task => {
                            this.task$.next(task);
                            await this.loadingService.stop();
                        });
                });
            }
        });
    }

    ngOnInit() {
        this.actionHolderRemarks = '';
        this.sendTaskSuccesful = false;
        this.isKeyboardClosed$ = this.deviceKeyboardService.isKeyboardClosed();
    }

    async canLeave(): Promise<boolean> {
        if (this.sendTaskSuccesful || !this.hasActionHolderRemarks()) {
            return true;
        }
        return await this.confirmLeave();
    }

    ionViewWillEnter() {
        this.deviceKeyboardService.hideFormAccessoryBar();
    }

    ionViewWillLeave() {
        this.deviceKeyboardService.hideFormAccessoryBar();
    }

    public isReadonly(): boolean {
        return this.task$.value?.status !== 'open';
    }

    public setActionHolderRemarks(value: string) {
        this.actionHolderRemarks = value.trim();
    }

    public hasActionHolderRemarks(): boolean {
        return this.actionHolderRemarks !== '';
    }

    public submitTaskDialog(status) {
        const statusTranslationKey = status === 'denied' ? 'task_denied' : 'task_completed';
        const statusLabel = this.translateService.instant(
            'QUESTIONNAIRE_TASK.' + statusTranslationKey)
            .toLowerCase();
        this.alertCtrl.create({
            mode: this.platform.is('ios') ? 'ios' : 'md',
            message: this.translateService.instant('QUESTIONNAIRE_TASK.are_you_sure', {statusLabel}),
            buttons: [{
                text: this.translateService.instant('BUTTONS.no'),
                role: 'cancel',
            }, {
                text: this.translateService.instant('BUTTONS.yes_and_send'),
                handler: () => {
                    this.sendTask(status, statusLabel);
                },
            }],
        }).then((alert) => alert.present());
    }

    private async sendTask(status, statusLabel) {
        if (this.task$.value === undefined) {
            return;
        }
        await this.loadingService.start();
        this.apiService.authenticatedPost(`/actions/${this.task$.value?.id}`, {
            status,
            remarks: this.actionHolderRemarks,
        }).then(async () => {
            this.sendTaskSuccesful = true;
            await this.loadingService.stop();
            await this.navCtrl.back();
            this.events.publish('dashboard:update');
        }).catch(async (err) => {
            console.error(err);
            this.trackingService.exception(err);
            await this.loadingService.stop();
            await this.toastService.showCustomMessages(
                this.translateService.instant('NOTIFICATION.something_wrong_task'),
                this.translateService.instant('NOTIFICATION.offline'),
            );
        });
    }

    private confirmLeave(): Promise<boolean> {
        return new Promise((resolve) => {
            this.alertCtrl.create({
                mode: this.platform.is('ios') ? 'ios' : 'md',
                header: this.translateService.instant('QUESTIONNAIRE_TASK.page_leave'),
                message: this.translateService.instant('QUESTIONNAIRE_TASK.page_leave_explanation'),
                buttons: [{
                    text: this.translateService.instant('BUTTONS.no'),
                    role: 'cancel',
                    handler: () => resolve(false),
                }, {
                    text: this.translateService.instant('BUTTONS.yes'),
                    handler: () => resolve(true),
                }],
            }).then((alert) => alert.present());
        });
    }

}
