import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Keyboard} from '@capacitor/keyboard';
import {Device} from '@capacitor/device';

@Injectable({
    providedIn: 'root',
})
export class DeviceKeyboardService {

    private isKeyboardPresent$ = new BehaviorSubject(false);
    private isKeyboardFullyOpened$ = new BehaviorSubject(false);

    constructor(
        private ngZone: NgZone,
    ) {
        Device.getInfo()
            .then((deviceInfo) => {

                if (deviceInfo.platform !== 'web') {
                    Keyboard.addListener('keyboardWillShow', this.keyboardOpen.bind(this));
                    Keyboard.addListener('keyboardDidHide', this.keyboardClosed.bind(this));
                    Keyboard.addListener('keyboardDidShow', this.keyboardAfterOpened.bind(this));
                    Keyboard.addListener('keyboardWillHide', this.keyboardBeforeClosed.bind(this));
                }
            });
    }

    public isKeyboardOpen(): Observable<boolean> {
        return this.isKeyboardPresent$.asObservable();
    }

    public isKeyboardClosed(): Observable<boolean> {
        return this.isKeyboardPresent$.asObservable()
            .pipe(
                map((isKeyboardOpen) => !isKeyboardOpen),
            );
    }

    public isKeyboardFullyOpened(): Observable<boolean> {
        return this.isKeyboardFullyOpened$.asObservable();
    }

    private keyboardOpen(): void {
        this.ngZone.run(() => {
            this.isKeyboardPresent$.next(true);
        });
    }

    private keyboardClosed(): void {
        this.ngZone.run(() => {
            this.isKeyboardPresent$.next(false);
        });
    }

    private keyboardAfterOpened(): void {
        this.ngZone.run(() => {
            this.isKeyboardFullyOpened$.next(true);
        });
    }

    private keyboardBeforeClosed(): void {
        this.ngZone.run(() => {
            this.isKeyboardFullyOpened$.next(false);
        });
    }

    public async hideFormAccessoryBar(): Promise<void> {
        await Keyboard.setAccessoryBarVisible({isVisible: false});
    }

    public async showFormAccessoryBar(): Promise<void> {
        await Keyboard.setAccessoryBarVisible({isVisible: true});
    }
}
