import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {ToastTheme} from '../models/toast-theme';
import {NetworkService} from './network.service';

@Injectable({
    providedIn: 'root',
})
export class ToastService {

    private toast: HTMLIonToastElement;
    static readonly DURATION = 6000;

    constructor(
        private toastCtrl: ToastController,
        private networkService: NetworkService,
    ) {
    }

    /**
     * Dismisses a current toast
     *
     * @returns
     */
    private dismiss(): Promise<any> {
        if (this.toast) {
            return this.toast.dismiss();
        } else {
            return Promise.resolve();
        }
    }

    /**
     * Show a toast message
     *
     * @returns
     * @param errorMessage
     * @param offlineMessage
     */
    public showCustomMessages(errorMessage: string, offlineMessage?: string, theme?: ToastTheme): Promise<any> {
        let message = errorMessage;
        const buttons = [];

        if (!this.networkService.isOnline() && offlineMessage) {
            message = offlineMessage;
        }

        if (!!theme) {
            // The only way to add icon inside shadowed toast component is by adding a button with icon
            buttons.push({
                side: 'start',
                icon: this.getIconNameForTheme(theme),
            });
        }

        return this.dismiss()
            .then(async () => {
                this.toast = await this.toastCtrl.create({
                    message,
                    duration: ToastService.DURATION,
                    cssClass: theme ? `toast-theme-${theme}` : '',
                    buttons,
                    mode: 'ios',
                });

                return this.toast.present();
            });
    }

    private getIconNameForTheme(theme: ToastTheme): string {
        switch (theme) {
            case 'success':
                return 'checkmark-circle';
            case 'error':
                return 'close-circle';
            default:
                return '';
        }
    }

}
