import {Component} from '@angular/core';
import {ConceptService} from '../../services/concept-service';
import {QuestionnaireRouteService} from '../../services/questionnaire-route.service';
import {NavController} from '@ionic/angular';
import {ActivatedRoute} from '@angular/router';
import {DisclaimerService} from '../../services/disclaimer-service';

@Component({
    selector: 'page-questionnaire-disclaimer',
    templateUrl: 'questionnaire-disclaimer.html',
})
export class QuestionnaireDisclaimerPage {

    constructor(
        private conceptService: ConceptService,
        private questionnaireRouteService: QuestionnaireRouteService,
        private activatedRoute: ActivatedRoute,
        private disclaimerService: DisclaimerService,
        private navCtrl: NavController,
    ) {
    }

    async next() {
        const conceptId = this.activatedRoute.snapshot.paramMap.get('conceptId');
        const concept = await this.conceptService.getConcept(conceptId);
        const currentRoute = await this.questionnaireRouteService.getRouteForSnapshot(concept.questionnaire, this.activatedRoute.snapshot, false, true);

        const nextRoute = await this.questionnaireRouteService.getNextQuestionRouteFor(concept.questionnaire, currentRoute, false);

        const nextUrl = `/concept/${conceptId}${nextRoute.route}`;
        await this.conceptService.saveConcept(concept, nextUrl);

        await this.disclaimerService.storeShowedFor(concept.questionnaire.id);

        await this.navCtrl.navigateForward(nextUrl, { replaceUrl: true});
    }

    public async navigateBack(): Promise<void> {
        await this.navCtrl.pop();
    }
}
