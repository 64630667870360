import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {AuthService} from './auth-service';
import {StorageService} from './storage-service';
import {API_SUFFIX} from '../app/app.module';
import {environment} from '../environments/environment';
import {defer, from, Observable, of, throwError} from 'rxjs';
import {catchError, concatMap, filter, map, mergeMap, share, switchMap, take, timeout} from 'rxjs/operators';
import {TrackingService} from './tracking/tracking.service';
import {NetworkService} from './network.service';
import {ApiService} from './api-service';
import {Platform} from '@ionic/angular';

export enum ProgressiveStrategy {
    PROGRESSIVE = 'progressive', // Returns the local value first, then the server value when it has loaded in
    FALLBACK = 'fallback', // Waits for the server to respond, if there is an error or a connection issue, return the local data
    LIVE = 'live', // Live data only
    LOCAL = 'local', // Local data only - Only when we are certain that the data should be loaded f.ex in the incident form data
}

export enum ProgressiveSource {
    LIVE = 'live',
    LOCAL = 'local'
}

export interface HttpResponseWithData<T> {
    data: T;
}

export interface MultiResponseWithData<T> extends Partial<HttpResponseWithData<T>> {
    body?: HttpResponseWithData<T>;
}

@Injectable()
export class ProgressiveApiService {
    public static STORAGE_PREFIX = 'NETWORK_CACHE|';

    private sharedRequests = new Map();
    private baseUrl = `${environment.api}${API_SUFFIX}`;

    private timeout = environment.apiTimeout;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private storageService: StorageService,
        private trackingService: TrackingService,
        private apiService: ApiService,
        private networkService: NetworkService,
        private platform: Platform,
    ) {
    }

    /**
     * Perform an authenticated get that will not be cached by the browser.
     * Has fallbacks to the local storage if the network connectivity is compromised.
     *
     * @param url                      The url to retrieve from the server
     * @param strategy                 The strategy to choose for loading data
     * @param requestOptions           Request options that may be added
     * @return
     */
    authenticatedGet<T>(url: string, strategy: ProgressiveStrategy, requestOptions?: { headers?: HttpHeaders }): Observable<T> {
        return this.authenticatedGetWithSource<T>(url, strategy, requestOptions).pipe(
            map(([data, source]) => {
                return data;
            }),
        );
    }

    /**
     * Perform an authenticated get that will not be cached by the browser.
     * Has fallbacks to the local storage if the network connectivity is compromised.
     * Returns the source of the data to allow the application to do different strategies based on the source it came from
     *
     * @param url                      The url to retrieve from the server
     * @param strategy                 The strategy to choose for loading data
     * @param requestOptions           Request options that may be added
     * @return
     */
    private authenticatedGetWithSource<T>(url: string, strategy: ProgressiveStrategy, requestOptions?: { headers?: HttpHeaders }): Observable<[T, ProgressiveSource]> {
        if (!requestOptions) {
            requestOptions = {
                headers: new HttpHeaders(),
            };
        }
        requestOptions.headers.append('Cache-Control', 'no-cache');
        const fullUrl = this.baseUrl + url;

        return defer((): Observable<any> => {
            let progressiveObservable: Observable<any>;

            if (strategy === ProgressiveStrategy.LIVE) {
                progressiveObservable = this.sharedGet<T>(fullUrl, requestOptions)
                    .pipe(
                        map((data: T) => {
                            return this.mapLiveData<T>(data);
                        }),
                        catchError((error) => this.apiService.handleHttpError(error)),
                    );

            } else if (strategy === ProgressiveStrategy.FALLBACK) {
                progressiveObservable = this.sharedGet<T>(fullUrl, requestOptions)
                    .pipe(
                        map((data: T) => {
                            return this.mapLiveData<T>(data);
                        }),
                        catchError(err => {
                            return from(this.storageService.get(ProgressiveApiService.STORAGE_PREFIX + fullUrl))
                                .pipe(
                                    map((localData) => {
                                        if (!localData) {
                                            this.apiService.handleHttpError(err, 'Unable to retrieve local data');
                                        } else {
                                            return [localData, ProgressiveSource.LOCAL];
                                        }
                                    }),
                                );
                        }),
                    );

            } else if (strategy === ProgressiveStrategy.PROGRESSIVE) {
                const stages = ['local'];
                if (this.networkService.isOnline()) {
                    stages.push('live');
                }

                progressiveObservable = from(stages)
                    .pipe(
                        mergeMap(
                            (stage) => {
                                if (stage === 'local') {
                                    return from(this.storageService.get(ProgressiveApiService.STORAGE_PREFIX + fullUrl))
                                        .pipe(map((data: T) => this.mapLocalData<T>(data)));
                                } else {
                                    return this.sharedGet<T>(fullUrl, requestOptions)
                                        .pipe(map((data: T) => this.mapLiveData<T>(data)));
                                }
                            }, 2),
                        filter((data) => {
                            return !!data;
                        }),
                    );

            } else if (strategy === ProgressiveStrategy.LOCAL) {
                progressiveObservable = from(this.storageService.get(ProgressiveApiService.STORAGE_PREFIX + fullUrl))
                    .pipe(map((data: T) => this.mapLocalData<T>(data)));
            }

            return from(this.authService.enforceJwt())
                .pipe(
                    switchMap(() => {
                        return progressiveObservable;
                    }),
                );
        });
    }

    public sharedGet<ReturnType>(url: string, requestOptions): Observable<ReturnType> {
        const urlObject = new URL(url);
        const urlForStorageKey = url;

        // VW-3382 - Enable caching for everything except iOS due to safari caching bug. See VW-2895 for iOS.
        if (this.platform.is('ios')) {
            // VW-2876 - Temporary disable ETags by adding query parameter with timestamp and headers.
            urlObject.searchParams.set('_', new Date().getTime().toString());
            url = `${urlObject.toString()}`;
            requestOptions.headers = {'Cache-Control': 'no-cache', 'If-None-Match': 'disables-etags'};
        }

        return this.sharedRequest<ReturnType>(this.http.get<HttpResponseWithData<ReturnType>>(url, requestOptions)
            .pipe(
                timeout(this.timeout),
                concatMap<MultiResponseWithData<ReturnType>, Promise<ReturnType>>(async (response) => {
                    const responseData = response.body?.data || response.data;

                    // Persist locally after retrieval for later reuse if network connectivity dropped
                    await this.storageService.set(ProgressiveApiService.STORAGE_PREFIX + urlForStorageKey, responseData);

                    return responseData;
                }),
            ), `GET|${url}`);
    }

    private sharedRequest<ReturnType>(observable$: Observable<ReturnType>, shareKey?: string): Observable<ReturnType> {
        if (!!shareKey && !this.sharedRequests.has(shareKey)) {
            this.sharedRequests.set(shareKey, observable$.pipe(share()));
        }
        if (!!shareKey && this.sharedRequests.has(shareKey)) {
            observable$ = this.sharedRequests.get(shareKey);
        }

        return observable$.pipe(take(1));
    }

    private mapLocalData<T>(localData: T): [T, ProgressiveSource] | null {
        if (localData) {
            return [localData, ProgressiveSource.LOCAL];
        }

        return null;
    }

    private mapLiveData<T>(liveData: T): [T, ProgressiveSource] | null {
        if (liveData) {
            return [liveData, ProgressiveSource.LIVE];
        }

        return null;
    }

}
