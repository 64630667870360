<ion-content class="has-header">
    <div class="choose-login__header">
        <app-dashboard-header [showDashboardButton]="false" [showLanguageSelection]="true" color="transparent"></app-dashboard-header>
    </div>
    <h1>{{ 'LOGIN.title' | translate }}</h1>
    <h2>{{ 'LOGIN.slogan' | translate }}</h2>

    <div class="bottom-content">
        <ion-button id="button-login-internal" expand="block" shape="round" color="primary" class="card__btn" (click)="azureAuthenticate()">{{ 'LOGIN.buttons.internal' | translate }}</ion-button>
        <ion-button id="button-login-external" expand="block" shape="round" class="card__btn card__btn--light" (click)="goToExternalAccountLogin()">{{ 'LOGIN.buttons.external' | translate }}</ion-button>
    </div>
</ion-content>
