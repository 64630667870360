import {Component, OnDestroy, OnInit} from '@angular/core';
import {StructureNodeType} from '../../enums/structure-node-type';
import {Subject} from 'rxjs';
import {DashboardStatusService} from '../../services/dashboard-status-service';
import {takeUntil} from 'rxjs/operators';
import {DashboardStatus} from '../../models/dashboard-status';
import {OrganisationStructureService} from '../../services/organisation-structure-service';
import {PropUtils} from '../../utils/prop-utils';
import {CurrentRouteService} from '../../services/current-route.service';

@Component({
    selector: 'app-dashboard-footer',
    templateUrl: './dashboard-footer.component.html',
    styleUrls: ['./dashboard-footer.component.scss'],
})
export class DashboardFooterComponent implements OnInit, OnDestroy {
    hasFormsBadge = false;
    hasOpenTasks = false;
    isDashboardRoute = false;
    isMoreRoute = false;
    private readonly dashboardRoute: string = '/dashboard';
    private readonly moreRoutes: string[] = [
        '/dashboard-more',
        '/contact',
        '/questionnaire-history',
        '/questionnaire-tasks',
        '/news-list',
        '/news-details',
        '/media-communication-list',
        '/media-communication-details',
    ];
    private destroy$ = new Subject<void>();

    constructor(private statusService: DashboardStatusService,
                public organisationStructureService: OrganisationStructureService,
                private currentRouteService: CurrentRouteService) {
    }

    ngOnInit() {
        // watch for dashboard changes
        this.statusService.onChange()
            .pipe(takeUntil(this.destroy$))
            .subscribe((status: DashboardStatus) => {
                this.hasFormsBadge = this.organisationStructureService.calculateBadgeAmountFor(StructureNodeType.CONCERN, 0) > 0;
                this.hasOpenTasks = PropUtils.parseValue(status.task_statuses.open_actions) > 0;
            });

        // load data for the first time
        this.organisationStructureService.loadOrganisationStructure()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.hasFormsBadge = this.organisationStructureService.calculateBadgeAmountFor(StructureNodeType.CONCERN, 0) > 0;
            });

        this.currentRouteService.currentRoute$
            .pipe(takeUntil(this.destroy$))
            .subscribe((currentRoute: string) => {
                this.isDashboardRoute = currentRoute === this.dashboardRoute;
                this.isMoreRoute = this.moreRoutes.some((route: string) => currentRoute.startsWith(route));
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
