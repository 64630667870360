<app-dashboard-header [showDashboardButton]="false" [showHelpButton]="true" [showDashboardTitle]="true"></app-dashboard-header>

<ion-content class="has-header">
    <ion-button *ngIf="isUserEmailDeprecated()" class="dashboard__button dashboard__button--notification" fill="clear" (click)="downloadETT()">
        <div class="dashboard__btn-wrap dashboard__btn-wrap--big">
        <ion-icon src="assets/images/icon-exclamation.svg" class="dashboard__icon dashboard__icon--bell"></ion-icon>
         <span class="dashboard__icon-text">
                <span class="dashboard__icon-title">
                    {{ 'DASHBOARD.deprecation.title' | translate }}
                </span>
                <span class="">
                    {{ 'DASHBOARD.deprecation.text' | translate }}
                </span>
            </span>
        </div>
    </ion-button>
    <ion-button *ngIf="openTasksCount"
                class="dashboard__button dashboard__button--notification"
                fill="clear"
                [routerLink]="['/questionnaire-tasks']"
                [disabled]="isAppDeprecated()"
                routerDirection="forward">
        <div class="dashboard__btn-wrap">
            <ion-icon src="assets/images/icon-bell.svg" class="dashboard__icon dashboard__icon--bell"></ion-icon>
            <span [innerHtml]="'DASHBOARD.tasks.notification' | pluralization:openTasksCount | translate:{value: openTasksCount}"></span>
        </div>
    </ion-button>
    <ion-button class="dashboard__button" [disabled]="isAppDeprecated()"
                fill="clear"
                (click)="organisationStructureService.determineQuestionnairePage('compliment')">
        <div class="dashboard__btn-wrap dashboard__btn-wrap--big">
            <ion-icon src="assets/images/icon-clap.svg" class="dashboard__icon dashboard__icon--big"></ion-icon>
            <span class="dashboard__icon-text">
                <span class="dashboard__icon-title">
                    {{ 'DASHBOARD.tile.compliment' | translate }}
                </span>
                <span class="dashboard__icon-description">
                    {{ 'DASHBOARD.tile.compliment_desc' | translate }}
                </span>
            </span>
        </div>
    </ion-button>
    <ion-button *ngIf="supportsQr"
                class="dashboard__button"
                fill="clear"
                [routerLink]="['/scan-qr']"
                [disabled]="isAppDeprecated()"
                routerDirection="forward">
        <div class="dashboard__btn-wrap dashboard__btn-wrap--big">
            <ion-icon src="assets/images/icon_qr.svg" class="dashboard__icon dashboard__icon--big"></ion-icon>
            <span class="dashboard__icon-text">
                <span class="dashboard__icon-title">
                    {{ 'DASHBOARD.tile.scan_qr' | translate }}
                </span>
                <span class="dashboard__icon-description">
                    {{ 'DASHBOARD.tile.scan_qr_desc' | translate }}
                </span>
            </span>
        </div>
    </ion-button>

    <ion-button *ngIf="hasInstructionItems"
                class="dashboard__button"
                fill="clear"
                (click)="goToMediaCommunicationCategoryList()"
                [disabled]="isAppDeprecated()"
                routerDirection="forward">
        <div class="dashboard__btn-wrap dashboard__btn-wrap--big">
            <ion-icon src="assets/images/icon-encyclopedia.svg" class="dashboard__icon dashboard__icon--big"></ion-icon>
            <span class="dashboard__icon-text">
                <span class="dashboard__icon-title">
                    {{ 'DASHBOARD.tile.instruction' | translate }}
                </span>
            </span>
        </div>
    </ion-button>
    <ion-button class="dashboard__button"
                fill="clear"
                [disabled]="isAppDeprecated()"
                (click)="organisationStructureService.determineQuestionnairePage('incident')">
        <div class="dashboard__btn-wrap dashboard__btn-wrap--big">
            <ion-icon src="assets/images/icon-warning.svg" class="dashboard__icon dashboard__icon--big"></ion-icon>
            <span class="dashboard__icon-text">
                <span class="dashboard__icon-title">
                    {{ 'DASHBOARD.report.incident' | translate }}
                </span>
                <span class="dashboard__icon-description">
                    {{ 'DASHBOARD.report.incident_desc' | translate }}
                </span>
            </span>
        </div>
    </ion-button>
</ion-content>
