import {Component, Input} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {AbstractQuestionComponent} from '../../classes/abstract-question.component';

@Component({
    selector: 'app-text',
    templateUrl: 'text.html',
})
export class TextComponent extends AbstractQuestionComponent {

    @Input() public placeholder = '';
    @Input() public maxLength: number;

    constructor(fb: UntypedFormBuilder) {
        super(fb);
    }

    changed() {
        this.valueChange.emit(this.questionForm.get('current').value);
    }

}
