import {Injectable} from '@angular/core';
import {ProgressiveApiService, ProgressiveStrategy} from './progressive-api-service';
import {QuestionnaireReminder} from '../models/questionnaire-reminder';
import {Observable, BehaviorSubject} from 'rxjs';
import {DashboardStatus, DashboardStatusDefault} from '../models/dashboard-status';
import {ConceptService} from './concept-service';
import {EventService} from './event-service';

@Injectable({
    providedIn: 'root',
})
export class DashboardStatusService {

    private dashboardStatus$: BehaviorSubject<DashboardStatus>;

    constructor(
        private progressiveApiService: ProgressiveApiService,
        private events: EventService,
        private conceptService: ConceptService,
    ) {
        this.dashboardStatus$ = new BehaviorSubject(DashboardStatusDefault);
    }

    public init(): void {
        this.events.subscribe('auth:initiated', () => this.refresh());
        this.events.subscribe('auth:token_stored', () => this.refresh());
        this.events.subscribe('auth:login_external', () => this.refresh());
        this.events.subscribe('dashboard:update', () => this.refresh());
        this.events.subscribe('auth:logout', () => this.clear());
    }

    public refresh() {
        this.progressiveApiService.authenticatedGet<DashboardStatus>('/dashboard/statuses', ProgressiveStrategy.PROGRESSIVE)
            .subscribe((data) => {
                this.conceptService.getConcepts().then((concepts) => {
                    data.concepts = concepts;
                    this.dashboardStatus$.next(data);
                });
            });
    }

    private clear() {
        this.dashboardStatus$.next(DashboardStatusDefault);
    }

    public onChange(): Observable<DashboardStatus> {
        return this.dashboardStatus$.asObservable();
    }

    public async getQuestionReminders(): Promise<QuestionnaireReminder[]> {
        return this.progressiveApiService.authenticatedGet<DashboardStatus>('/dashboard/statuses', ProgressiveStrategy.FALLBACK)
            .toPromise().then((data) => {
                return data.questionnaire_reminders;
            });
    }
}
