<app-questionnaire-header [showChapterButton]="isChapterQuestionnaire"></app-questionnaire-header>

<ion-content class="has-header has-footer">
    <div class="content__full-height content__full-height--questionnaire">
        <div class="questionnaire">
            <ng-container *ngIf="currentQuestion">
                <div [hidden]="(isKeyboardOpen$ | async) || (!questionTitle)" class="questionnaire-sub-title">
                    <h2>
                        {{questionTitle}}
                    </h2>
                </div>
                <div class="questionnaire__body">
                    <div [hidden]="isKeyboardOpen$ | async" class="card__header">
                        <h2 class="card__title">
                            {{ currentQuestion | contentTranslate:'title' }}
                        </h2>
                        <p class="card__description" *ngIf="currentQuestion.description && currentQuestion.type !== 'text'">
                            {{ currentQuestion | contentTranslate:'description' }}
                        </p>
                    </div>
                    <ng-container *ngIf="!readonly">
                        <div *ngIf="(onRemarkFocus$ | async) === false || (isKeyboardOpen$ | async) === false" class="questionnaire__content">
                            <app-radio *ngIf="currentQuestion.type === 'radio' && !requiresSearchableChoices(currentQuestion)"
                                       (valueChange)="setCurrentQuestionValue($event)"
                                       [question]="currentQuestion"
                                       [manualAdditionsAllowed]="currentQuestion.manualAdditionsAllowed"
                                       [currentValue]="getQuestionValue(currentQuestion)"
                                       [disabled]="false"></app-radio>
                            <app-radio-searchable *ngIf="currentQuestion.type === 'radio' && requiresSearchableChoices(currentQuestion)"
                                                  (valueChange)="setCurrentQuestionValue($event)"
                                                  [content]="content"
                                                  [question]="currentQuestion"
                                                  [manualAdditionsAllowed]="currentQuestion.manualAdditionsAllowed"
                                                  [currentValue]="getQuestionValue(currentQuestion)"
                                                  [disabled]="false"></app-radio-searchable>
                            <app-checkbox *ngIf="currentQuestion.type === 'checkbox' && !requiresSearchableChoices(currentQuestion)"
                                          (valueChange)="setCurrentQuestionValue($event)"
                                          [question]="currentQuestion"
                                          [manualAdditionsAllowed]="currentQuestion.manualAdditionsAllowed"
                                          [currentValue]="getQuestionValue(currentQuestion)"
                                          [disabled]="false"></app-checkbox>
                            <app-checkbox-searchable *ngIf="currentQuestion.type === 'checkbox' && requiresSearchableChoices(currentQuestion)"
                                                     (valueChange)="setCurrentQuestionValue($event)"
                                                     [content]="content"
                                                     [question]="currentQuestion"
                                                     [manualAdditionsAllowed]="currentQuestion.manualAdditionsAllowed"
                                                     [currentValue]="getQuestionValue(currentQuestion)"
                                                     [disabled]="false"></app-checkbox-searchable>
                            <app-text *ngIf="currentQuestion.type === 'text'"
                                      (valueChange)="setCurrentQuestionValue($event)" [currentValue]="getQuestionValue(currentQuestion)"
                                      [placeholder]="currentQuestion | contentTranslate:'description'"
                                      [disabled]="false"></app-text>
                            <app-datetime *ngIf="currentQuestion.type === 'date' || currentQuestion.type === 'datetime'"
                                          [mode]="currentQuestion.type"
                                          [requirements]="getQuestionRequirements(currentQuestion)"
                                          (valueChange)="setCurrentQuestionValue($event)"
                                          (validityChange)="setValidity($event)"
                                          [currentValue]="getQuestionValue(currentQuestion)"
                                          [disabled]="false">
                            </app-datetime>
                            <app-number *ngIf="currentQuestion.type === 'integer'" [integer]="true"
                                        (validityChange)="setValidity($event)"
                                        (valueChange)="setCurrentQuestionValue($event)" [currentValue]="getQuestionValue(currentQuestion)"
                                        [disabled]="false"></app-number>
                            <app-number *ngIf="currentQuestion.type === 'decimal'" [integer]="false"
                                        (validityChange)="setValidity($event)"
                                        (valueChange)="setCurrentQuestionValue($event)" [currentValue]="getQuestionValue(currentQuestion)"
                                        [disabled]="false"></app-number>
                            <app-lat-lng *ngIf="(online | async) && currentQuestion.type === 'latlng'"
                                         (valueChange)="setCurrentQuestionValue($event)" [currentValue]="getQuestionValue(currentQuestion)"
                                         [disabled]="false"></app-lat-lng>
                            <app-photo *ngIf="currentQuestion.type === 'image'"
                                       (valueChange)="setCurrentQuestionValue($event)" [currentValue]="getQuestionValue(currentQuestion)"
                                       [addPhoto]="pasteObservable | async"
                                       [disabled]="false"></app-photo>
                            <div *ngIf="(online | async) === false && currentQuestion.type === 'latlng'">
                                <span>{{ 'QUESTIONNAIRE.map_offline' | translate }}</span>
                            </div>
                        </div>
                        <app-attachments-and-remarks
                            *ngIf="currentQuestion.allowAttachment || currentQuestion.allowRemarks"
                            [currentQuestion]="currentQuestion"
                            [answerIndex]="answerIndex"
                            [hidden]="(isKeyboardOpen$ | async) && (onRemarkFocus$ | async) === false"
                            [disabled]="false"
                            [addPhotoAttachment]="pasteObservable | async"
                            (attachmentsChanged)="setCurrentQuestionAttachment($event)"
                            (remarksChanged)="setCurrentQuestionRemarks($event)"
                        ></app-attachments-and-remarks>
                    </ng-container>

                    <ng-container *ngIf="readonly">
                        <div class="questionnaire__content">
                            <app-radio *ngIf="currentQuestion.type === 'radio'"
                                       [question]="currentQuestion"
                                       [currentValue]="getQuestionValue(currentQuestion)"
                                       [disabled]="true"></app-radio>
                            <app-checkbox *ngIf="currentQuestion.type === 'checkbox'"
                                          [question]="currentQuestion"
                                          [currentValue]="getQuestionValue(currentQuestion)"
                                          [disabled]="true"></app-checkbox>
                            <app-text *ngIf="currentQuestion.type === 'text'"
                                      [currentValue]="getQuestionValue(currentQuestion)"
                                      [disabled]="true"></app-text>
                            <app-datetime *ngIf="currentQuestion.type === 'date' || currentQuestion.type === 'datetime'"
                                          [mode]="currentQuestion.type"
                                          [currentValue]="getQuestionValue(currentQuestion)"
                                          [disabled]="true">
                            </app-datetime>
                            <app-number *ngIf="currentQuestion.type === 'integer'"
                                        [integer]="true"
                                        [currentValue]="getQuestionValue(currentQuestion)"
                                        [disabled]="true"></app-number>
                            <app-number *ngIf="currentQuestion.type === 'decimal'"
                                        [integer]="false"
                                        [currentValue]="getQuestionValue(currentQuestion)"
                                        [disabled]="true"></app-number>
                            <app-lat-lng *ngIf="(online | async) && currentQuestion.type === 'latlng'"
                                         [currentValue]="getQuestionValue(currentQuestion)"
                                         [disabled]="true"></app-lat-lng>
                            <app-photo *ngIf="currentQuestion.type === 'image'"
                                       [currentValue]="getQuestionValue(currentQuestion)"
                                       [disabled]="true"></app-photo>
                            <div *ngIf="(online | async) === false && currentQuestion.type === 'latlng'">
                                <span>{{ 'QUESTIONNAIRE.map_offline' | translate }}</span>
                            </div>
                        </div>

                        <app-attachments-and-remarks
                            *ngIf="currentQuestion.allowAttachment || currentQuestion.allowRemarks"
                            [currentQuestion]="currentQuestion"
                            [answerIndex]="answerIndex"
                            [disabled]="true"
                        ></app-attachments-and-remarks>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</ion-content>

<app-questionnaire-footer
    *ngIf="currentQuestion"
    [hideChaptersButton]="!isChapterQuestionnaire"
    [disableBackButton]="disableBackButton"
    [nextButtonEnabled]="isValid()"
    [nextButtonText]="'BUTTONS.' + ((currentQuestion.type === 'latlng' && (online | async) === false) ? 'skip' : 'next')"
    [progress]="questionnaireProgress"
    (prevButtonClick)="prev()"
    (nextButtonClick)="next()"
    (chaptersButtonClick)="backToChapters()"
></app-questionnaire-footer>
