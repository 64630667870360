import {ModalController, NavController} from '@ionic/angular';
import {ChangeDetectorRef, Component} from '@angular/core';
import {Question} from '../../models/question';
import {QuestionnaireUtils} from '../../utils/questionnaire-utils';
import {ChapterUtils} from '../../utils/chapter-utils';
import {ActivatedRoute, Router} from '@angular/router';
import {HistoryService} from '../../services/history-service';
import {QuestionnaireRouteService} from '../../services/questionnaire-route.service';
import {ConceptService} from '../../services/concept-service';
import {AbstractQuestionnairePage} from '../abstract-questionnaire/abstract-questionnaire';
import {DeviceKeyboardService} from '../../services/device-keyboard.service';
import {TrackingService} from '../../services/tracking/tracking.service';
import {ContentTranslatePipe} from '../../pipes/content-translate';
import { NetworkService } from 'src/services/network.service';
import {RemarkFocusService} from '../../services/remark-focus-service';

@Component({
    selector: 'page-questionnaire-repeat-question',
    templateUrl: 'questionnaire-repeat-question.html',
})
export class QuestionnaireRepeatQuestionPage extends AbstractQuestionnairePage {

    public returnToChapters = false;

    public repeatQuestion: Question;
    public isChapterQuestionnaire: boolean;
    public currentAnswer: string;

    constructor(
        navCtrl: NavController,
        modalCtrl: ModalController,
        activatedRoute: ActivatedRoute,
        historyService: HistoryService,
        trackingService: TrackingService,
        questionnaireRouteService: QuestionnaireRouteService,
        conceptService: ConceptService,
        router: Router,
        deviceKeyboardService: DeviceKeyboardService,
        private changeDetectorRef: ChangeDetectorRef,
        private contentTranslatePipe: ContentTranslatePipe,
        networkService: NetworkService,
        remarkFocusService: RemarkFocusService,
    ) {
        super(navCtrl, modalCtrl, conceptService, activatedRoute, historyService, trackingService, questionnaireRouteService, router, deviceKeyboardService, contentTranslatePipe, networkService, remarkFocusService);
    }

    async ionViewWillEnter() {
        await super.ionViewWillEnter();

        this.repeatQuestion = QuestionnaireUtils.getQuestionUsingPath(this.questionnaire.questions, this.vintageQuestionPath);
        if (this.repeatQuestion.answers && this.repeatQuestion.answers.length > 0) {
            this.currentAnswer = this.repeatQuestion.answers[this.answerIndex];
        }

        // Force change detection
        this.changeDetectorRef.detectChanges();
    }

    async setAnswer(answer: string) {
        if (!this.repeatQuestion.answers) {
            this.repeatQuestion.answers = [];
        }

        const answerIndex = this.answerIndex || 0;
        this.repeatQuestion.answers[answerIndex] = answer;
        while (answer === 'Nee' && this.repeatQuestion.answers.length > (answerIndex + 1)) {
            // remove repeating answers further down the road. Situation in which this can happen:
            //  a user fills the repeated group 3 times, and then changes his/her mind and changes
            //  the answer to No on the first repeating question.
            this.repeatQuestion.answers.pop();
        }
        this.currentAnswer = answer;
        await this.conceptService.saveConcept(this.concept, this.router.url);
    }

    async prev() {
        const prevQuestionRoute = await this.questionnaireRouteService.getPrevQuestionRouteFor(this.questionnaire, this.questionRoute, this.readonly);
        if (prevQuestionRoute) {
            const prevUrl = `${this.readonly ? '/history' : `/concept/${this.conceptId}`}${prevQuestionRoute.route}`;

            await this.navCtrl.navigateBack(prevUrl);
        } else {
            await this.navCtrl.back();
        }
    }

    /**
     * Determine next page
     *
     * @returns
     */
    async next() {
        const nextQuestionRoute = await this.questionnaireRouteService.getNextQuestionRouteFor(this.questionnaire, this.questionRoute, this.readonly);
        if (this.isChapterQuestionnaire && !ChapterUtils.questionsForSameChapter(this.vintageQuestionPath, nextQuestionRoute.vintageQuestionPath)) {
            await this.backToChapters();
        } else {
            if (nextQuestionRoute) {
                const nextUrl = `${this.readonly ? '/history' : `/concept/${this.conceptId}`}${nextQuestionRoute.route}`;
                await this.navCtrl.navigateForward(nextUrl);
            } else {
                await this.navCtrl.navigateBack(this.readonly ? '/questionnaire-history' : '/dashboard');
            }
        }
    }

    isValid() {
        return this.currentAnswer !== undefined && this.currentAnswer !== null && this.currentAnswer !== '';
    }

    public async backToChapters(): Promise<void> {
        if (this.isChapterQuestionnaire) {
            const route = (await this.questionnaireRouteService.getQuestionRoutes(this.questionnaire, false, false))[0];
            const chaptersUrl = `${this.readonly ? '/history' : `/concept/${this.conceptId}`}${route.route}`;

            await this.navCtrl.navigateForward([chaptersUrl], {replaceUrl: true, animationDirection: 'back'});
        } else {
            console.error('Not a chapters questionnaire!');
        }
        return Promise.resolve();
    }
}
