import {Injectable} from '@angular/core';
import {TrackingService} from './tracking/tracking.service';
import {Geolocation} from '@capacitor/geolocation';

@Injectable({
    providedIn: 'root',
})
export class PositionService {

    private position: GeolocationPosition;

    constructor(
        private trackingService: TrackingService,
    ) {
    }

    async getCurrentPosition(): Promise<GeolocationPosition> {
        try {
            await new Promise<void>((resolve) => {
                Geolocation.getCurrentPosition({
                    enableHighAccuracy: true,
                    timeout: 10000,
                }).then((newPosition) => {
                    if (newPosition.coords !== undefined) {
                        this.position = newPosition;
                    }
                    resolve();
                }).catch((exception) => {
                    console.error(exception);
                    this.trackingService.exception(exception);
                    resolve();
                });
            });
        } catch (exception) {
            console.error(exception);
        }

        return this.position;
    }

}
