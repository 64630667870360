import {Injectable} from '@angular/core';
import {ApiService} from './api-service';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    constructor(private api: ApiService) {
    }

    fetchTheme(): Promise<any> {
        return this.api.authenticatedGet('/theme', this.api.getRequestOptions(true));
    }
}
