import {ProgressiveApiService, ProgressiveStrategy} from './progressive-api-service';
import {DashboardStatusService} from './dashboard-status-service';
import {QuestionnaireAssembleService} from './questionnaire-assemble-service';
import {Injectable} from '@angular/core';
import {OrganisationListItem, OrganisationStructure} from '../models/organisation-structure';
import {DashboardStatus} from '../models/dashboard-status';
import {StructureNodeType} from '../enums/structure-node-type';
import {QuestionnairesFilter} from '../utils/questionnaires-filter';
import {OrganisationStructureParams} from '../models/organisation-params';
import {Organisation} from '../models/organisation';
import {BusinessUnit} from '../models/business-unit';
import {Project} from '../models/project';
import {OrganisationLink} from '../models/organisation-link';
import {Questionnaire} from '../models/questionnaire';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {NavController} from '@ionic/angular';

export interface OrganisationNodeRoute {
    route: string;
    items: OrganisationListItem[];
}

@Injectable()
export class OrganisationStructureService {

    private currentOrganisationStructure: OrganisationStructure;
    private dashboardStatus: DashboardStatus;

    constructor(private progressiveApiService: ProgressiveApiService,
                private questionnaireAssembleService: QuestionnaireAssembleService,
                private dashboardStatusService: DashboardStatusService,
                private navCtrl: NavController) {
        this.dashboardStatusService.onChange().subscribe((status) => {
            this.dashboardStatus = status;
        });
    }

    /**
     * Get the organisation structure from the server
     */
    public loadOrganisationStructure(): Observable<OrganisationStructure> {
        return this.progressiveApiService.authenticatedGet<OrganisationStructure>('/organisation_structure', ProgressiveStrategy.FALLBACK)
            .pipe(
                tap({
                    next: (organisationStructure) => {
                        this.currentOrganisationStructure = organisationStructure;
                    },
                }),
            );
    }

    public preloadQuestionnairesForConcern(): void {
        // Only start synchronizing if we have a live source
        this.questionnaireAssembleService.synchronizeQuestionnairesByIds(this.getPreloadedQuestionnaireIdsForNode(StructureNodeType.CONCERN));
    }

    /**
     * Gets the unique preloaded questionnaire ids for a specific node or for the concern level
     *
     * @param structureType
     * @param structureId
     *
     * @return number[]
     */
    private getPreloadedQuestionnaireIdsForNode(structureType: StructureNodeType, structureId: number = 0): number[] {
        const nodeFilter = (node) => {
            return node.id === structureId;
        };
        const preloadQuestionnaireMap = (node) => {
            return node.preload_questionnaire_ids;
        };

        let preloadQuestionnaireIds = [];
        if (structureType === StructureNodeType.CONCERN) {
            preloadQuestionnaireIds = Array.from(new Set([
                ...this.currentOrganisationStructure.organisations.map(preloadQuestionnaireMap).reduce((acc, val) => acc.concat(val), []),
                ...this.currentOrganisationStructure.business_units.map(preloadQuestionnaireMap).reduce((acc, val) => acc.concat(val), []),
                ...this.currentOrganisationStructure.projects.map(preloadQuestionnaireMap).reduce((acc, val) => acc.concat(val), []),
            ]));
        } else if (structureType === StructureNodeType.ORGANISATION) {
            preloadQuestionnaireIds = this.currentOrganisationStructure.organisations.filter(nodeFilter).map(preloadQuestionnaireMap).reduce((acc, val) => acc.concat(val), []);
        } else if (structureType === StructureNodeType.BUSINESSUNIT) {
            preloadQuestionnaireIds = this.currentOrganisationStructure.business_units.filter(nodeFilter).map(preloadQuestionnaireMap).reduce((acc, val) => acc.concat(val), []);
        } else if (structureType === StructureNodeType.PROJECT) {
            preloadQuestionnaireIds = this.currentOrganisationStructure.projects.filter(nodeFilter).map(preloadQuestionnaireMap).reduce((acc, val) => acc.concat(val), []);
        }

        return Array.from(new Set(preloadQuestionnaireIds));
    }

    /**
     * Get the (freshly updated) incident form for the node
     */
    public getIncidentFormForNode(structureType: StructureNodeType, structureId: number): Observable<Questionnaire> {
        const idsThatArePreloaded = this.getPreloadedQuestionnaireIdsForNode(structureType, structureId);
        const observables = idsThatArePreloaded.map((id) => {
            return this.progressiveApiService.authenticatedGet<Questionnaire>('/questionnaire/' + id, ProgressiveStrategy.LOCAL);
        });

        return forkJoin(observables)
            .pipe(
                map((result) => {
                    return result.find((questionnaire) => {
                        return questionnaire.concern_questionnaire;
                    });
                }),
                switchMap((questionnaire) => {
                    if (questionnaire.id) {
                        return this.questionnaireAssembleService.getAssembledQuestionnaireById(questionnaire.id);
                    } else {
                        return of(null);
                    }
                }),
            );
    }

    /**
     * Gets all the organisations, business units and projects that should be visible if we are entering this node
     * Handles automatic traversing to a lower level if only one option is available
     *
     * Do note that if only one route is returned, it is expected that we retrieve the questionnaires from that leaf node as a next page
     *
     * @param routePrefix                       Used to determine the question type that needs to be retrieved eventually ( incident, compliment or all )
     * @param structureType                     The structure type to retrieve
     * @param structureId                       The identifier of the structure node
     *
     * @retunn OrganisationListItem[]           Three return states
     *                                          - Multiple organisation list items: We want to show a choice to pick a new structure node
     *                                          - Single organisation list item: Expected is that we want to show the questionnaires to pick from ( Or auto-match with incident for example )
     *                                          - Empty - Organisation node could not be found - Invalid link
     */
    public getOrganisationListForNode(routePrefix: string, structureType: StructureNodeType, structureId: number = 0): OrganisationNodeRoute {
        const postfix = '/questionnaires';
        let organisationRouteNode: OrganisationNodeRoute;

        if (structureType === StructureNodeType.CONCERN) {
            organisationRouteNode = this.getOrganisationListForConcern(routePrefix);
        // Everything except organisations can be found at this level
        } else if (structureType === StructureNodeType.ORGANISATION) {
            organisationRouteNode = this.getOrganisationListForOrganisation(routePrefix, structureId);

        // Only projects can be found here
        } else if (structureType === StructureNodeType.BUSINESSUNIT) {
            organisationRouteNode = this.getOrganisationListForBusinessunit(routePrefix, structureId);

        // Project level - No further options are possible
        } else {
            organisationRouteNode = {
                route: `${routePrefix}/project/${structureId}`,
                items: [],
            };
        }

        // Add the prefix to all the routes
        organisationRouteNode.items = organisationRouteNode.items.map((organisationListItem) => {

            // If the prefix has already been added, we know that the recursion has already occurred
            // Meaning woe do not have to add the prefix or the postfix
            if (!organisationListItem.route.startsWith(routePrefix)) {
                organisationListItem.route = routePrefix + organisationListItem.route;

                // Check if we need to add the postfix to this organisationListItem
                if (organisationListItem.type === StructureNodeType.PROJECT && organisationListItem.route.includes('project')) {
                    organisationListItem.route += postfix;

                // Recursive check to see if there are items underneath this structure node to add the postfix
                } else {
                    const [lowerStructureId, lowerStructureType] = organisationListItem.route.split('/').reverse();
                    const routesForLowerLevel = this.getOrganisationListForNode('', lowerStructureType as StructureNodeType, parseInt(lowerStructureId, 10));
                    if (routesForLowerLevel.items.length === 0) {
                        // VW-3158: In case there are no items, take the route from the lower level. Takes care of auto-selecting in case there's only one element.
                        organisationListItem.route = routePrefix + routesForLowerLevel.route;
                    }
                }
            }

            return organisationListItem;
        });

        // Add the postfix only if we hit a leaf node
        if (organisationRouteNode.items.length === 0 && !organisationRouteNode.route.endsWith(postfix)) {
            organisationRouteNode.route += postfix;
        }

        return organisationRouteNode;
    }

    /**
     * Calculate the amount of concepts still available at the given level
     *
     * @param structureType
     * @param structureId
     * @return number
     */
    public calculateBadgeAmountFor(structureType: StructureNodeType, structureId: number): number {
        const organisationStructureParams = this.getOrganisationStructureParamsFor(structureType, structureId);
        if (!organisationStructureParams) {
            return 0;
        }

        let concepts = QuestionnairesFilter.filterBadgesConcepts(this.dashboardStatus.concepts, organisationStructureParams);

        // On the dashboard where the concern is shown, the only badge that seems to be necessary is the concept count
        // So we do not need reminders for the concern level
        // VW-2726: we also do NOT need it for organisations / projects  / bu's, only for the task section. So, don't have a
        // look at all for reminders (a.k.a. plannable forms which are planned for the current user.

        // VW-2990 - Filter out concepts that are linked to projects, business units or organisations that are not available in the organisation structure
        concepts = concepts.filter((concept) => {
            return this.currentOrganisationStructure && (
                !!(concept.questionnaire.project_id && this.currentOrganisationStructure.projects.find((proj) => proj.id === concept.questionnaire.project_id)) ||
                !!(concept.questionnaire.businessunit_id && this.currentOrganisationStructure.business_units.find((bu) => bu.id === concept.questionnaire.businessunit_id)) ||
                !!(concept.questionnaire.organisation_id && this.currentOrganisationStructure.organisations.find((o) => o.id === concept.questionnaire.organisation_id)) );
        });

        return concepts.length;
    }

    /**
     * Get the orgnaisation structure params for the given structure type
     *
     * @param structureType
     * @param structureId
     */
    public getOrganisationStructureParamsFor(structureType: StructureNodeType, structureId: number): OrganisationStructureParams|undefined {
        if (structureType === StructureNodeType.CONCERN) {
            return new OrganisationStructureParams(null, null, null);
        } else if (structureType === StructureNodeType.ORGANISATION) {
            return this.generateOrganisationStructureParamsForOrganisation(structureId);
        } else if (structureType === StructureNodeType.BUSINESSUNIT) {
            return this.generateOrganisationStructureParamsForBusinessUnit(structureId);
        } else {
            return this.generateOrganisationStructureParamsForProject(structureId);
        }
    }

    /**
     * Get the organisation list for the concern layer
     * Return all organisations that are visible to the user
     *
     * @param routePrefix
     * @private
     */
    private getOrganisationListForConcern(routePrefix: string): OrganisationNodeRoute {
        const organisationListItems = this.currentOrganisationStructure.organisations.filter((organisation) => {
            return organisation.visibility;
        }).map(this.mapOrganisationToOrganisationListItem());

        // If we have only one node on the concern level
        // Traverse downward until we find multiple options
        if (organisationListItems.length === 1) {
            const [lowerStructureId, lowerStructureType] = organisationListItems[0].route.split('/').reverse();
            return this.getOrganisationListForNode(routePrefix, lowerStructureType as StructureNodeType, parseInt(lowerStructureId, 10));
        }

        return {route: `${routePrefix}/concern`, items: organisationListItems};
    }

    getOrganisationById(id: number): Organisation | null {
        return this.currentOrganisationStructure.organisations.find((organisation: Organisation) => organisation.id === id) ?? null;
    }

    getQuestionnaireParentNameById(questionnaire: Questionnaire): string {
        if (questionnaire.project_id) {
            return this.currentOrganisationStructure.projects.find((project: Project) => project.id === questionnaire.project_id)?.name ?? '';
        }

        if (questionnaire.businessunit_id) {
            return this.currentOrganisationStructure.business_units.find((businessUnit: BusinessUnit) => businessUnit.id === questionnaire.businessunit_id)?.name ?? '';
        }

        if (questionnaire.organisation_id) {
            return this.currentOrganisationStructure.organisations.find((organisation: Organisation) => organisation.id === questionnaire.organisation_id)?.name ?? '';
        }
    }

    /**
     * Get the organisatoin list for the given organisation
     * Everything except organisations can be found at this level
     *
     * @param routePrefix
     * @param structureId
     * @private
     */
    private getOrganisationListForOrganisation(routePrefix: string, structureId: number): OrganisationNodeRoute {
        const route = `${routePrefix}/organisation/${structureId}`;
        let organisationListItems = [];

        // Check if the organisation exists first
        const organisations = this.currentOrganisationStructure.organisations.filter((organisation) => {
            return organisation.id === structureId;
        });

        if (organisations.length > 0) {
            const combinedOrganisationListItems = [
                ...this.currentOrganisationStructure.business_units.filter((businessUnit) => {
                    return businessUnit.organisation_id === structureId;
                }).map(this.mapBusinessUnitToOrganisationListItem()),
                ...this.currentOrganisationStructure.projects.filter((project) => {
                    return project.organisation_id === structureId;
                }).map(this.mapProjectToOrganisationListItem()),
                ...this.currentOrganisationStructure.organisation_links.filter((organisationLink) => {
                    return organisationLink.parent_organisation_id === structureId;
                }).map(this.mapOrganisationLinkToOrganisationListItem()),
            ];

            // If we have only one node inside this organisation
            // Traverse downward until we find multiple options
            if (combinedOrganisationListItems.length === 1) {
                const [lowerStructureId, lowerStructureType] = combinedOrganisationListItems[0].route.split('/').reverse();
                return this.getOrganisationListForNode(routePrefix, lowerStructureType as StructureNodeType, parseInt(lowerStructureId, 10));

            // Return the multiple found items underneath organisation
            } else {
                organisationListItems = combinedOrganisationListItems;
            }
        }

        return {route, items: organisationListItems};
    }

    /**
     * Get the organisation list for the given business unit
     * Only projects and organisation links can be found here
     *
     * @param routePrefix
     * @param structureId
     * @private
     */
    private getOrganisationListForBusinessunit(routePrefix: string, structureId: number): OrganisationNodeRoute|undefined {
        const route = `${routePrefix}/businessunit/${structureId}`;
        let organisationListItems = [];

        // Check if the business unit exists in our structure
        const businessUnits = this.currentOrganisationStructure.business_units.filter((businessUnit) => {
            return businessUnit.id === structureId;
        });

        if (businessUnits.length > 0) {
            const combinedOrganisationListItems = [
                ...this.currentOrganisationStructure.projects.filter((project) => {
                    return project.business_unit_id === structureId;
                }).map(this.mapProjectToOrganisationListItem()),
            ];

            if (combinedOrganisationListItems.length === 0) {
                organisationListItems = [];

                // If we have only one node inside this organisation
                // Traverse downward until we find multiple options
            } else if (combinedOrganisationListItems.length === 1) {
                const [lowerStructureId, lowerStructureType] = combinedOrganisationListItems[0].route.split('/').reverse();
                return this.getOrganisationListForNode('', lowerStructureType as StructureNodeType, parseInt(lowerStructureId, 10));

                // Return the multiple found items underneath business unit
            } else {
                organisationListItems = combinedOrganisationListItems;
            }
        }

        return {route, items: organisationListItems};
    }

    /**
     * Map organisation to organisation list item
     */
    private mapOrganisationToOrganisationListItem(this: OrganisationStructureService): (organisation: Organisation) => OrganisationListItem {
        return (organisation) => {
            return {
                id: organisation.id,
                type: StructureNodeType.ORGANISATION,
                title: organisation.name,
                description: organisation.description,
                route: '/organisation/' + organisation.id,
                imageData: organisation.logo,
                badgeAmount: this.calculateBadgeAmountFor(StructureNodeType.ORGANISATION, organisation.id),
            };
        };
    }

    /**
     * Map business unit to organisation list item
     */
    private mapBusinessUnitToOrganisationListItem(this: OrganisationStructureService): (businessUnit: BusinessUnit) => OrganisationListItem {
        return (businessUnit) => {
            return {
                id: businessUnit.id,
                type: StructureNodeType.BUSINESSUNIT,
                title: businessUnit.name,
                description: '',
                route: '/businessunit/' + businessUnit.id,
                imageData: '',
                badgeAmount: this.calculateBadgeAmountFor(StructureNodeType.BUSINESSUNIT, businessUnit.id),
            };
        };
    }

    /**
     * Map project to organisation list item
     */
    private mapProjectToOrganisationListItem(this: OrganisationStructureService): (project: Project) => OrganisationListItem {
        return (project) => {
            return {
                id: project.id,
                type: StructureNodeType.PROJECT,
                title: project.name,
                description: '',
                route: '/project/' + project.id,
                imageData: '',
                badgeAmount: this.calculateBadgeAmountFor(StructureNodeType.PROJECT, project.id),
            };
        };
    }

    /**
     * Map organisation link to organisation list item
     */
    private mapOrganisationLinkToOrganisationListItem(this: OrganisationStructureService): (organisationLink: OrganisationLink) => OrganisationListItem {
        return (organisationLink) => {
            let type = StructureNodeType.PROJECT;
            let id = organisationLink.project_id;
            if (!id && organisationLink.businessunit_id) {
                id = organisationLink.businessunit_id;
                type = StructureNodeType.BUSINESSUNIT;
            } else if (!id && organisationLink.organisation_id) {
                id = organisationLink.organisation_id;
                type = StructureNodeType.ORGANISATION;
            }

            return {
                id: organisationLink.id,
                type: StructureNodeType.PROJECT, // Keep this as project to make sure the organisation links are put in that bracket
                title: organisationLink.name,
                description: '',
                route: `/${type}/${id}`,
                imageData: '',
                badgeAmount: this.calculateBadgeAmountFor(type, id),
            };
        };
    }

    /**
     * Generate the organisation structure params for the organisation id
     *
     * @private
     */
    private generateOrganisationStructureParamsForOrganisation(structureId): OrganisationStructureParams | undefined {
        const foundOrganisation = this.currentOrganisationStructure.organisations.find((organisation) => {
            return organisation.id === structureId;
        });

        if (foundOrganisation) {
            return new OrganisationStructureParams(foundOrganisation.sector_id, foundOrganisation.region_id, structureId);
        } else {
            return undefined;
        }
    }

    /**
     * Generate the organisation structure params for the businessunit id
     *
     * @private
     */
    private generateOrganisationStructureParamsForBusinessUnit(structureId): OrganisationStructureParams | undefined {
        const foundBusinessUnit = this.currentOrganisationStructure.business_units.find((businessunit) => {
            return businessunit.id === structureId;
        });

        if (foundBusinessUnit) {
            const foundOrganisation = this.currentOrganisationStructure.organisations.find((organisation) => {
                return organisation.id === foundBusinessUnit.organisation_id;
            });

            if (foundOrganisation) {
                return new OrganisationStructureParams(foundOrganisation.sector_id, foundOrganisation.region_id, foundOrganisation.id, structureId);
            }
        }

        return undefined;
    }

    /**
     * Generate the organisation structure params for the project id
     *
     * @private
     */
    private generateOrganisationStructureParamsForProject(structureId): OrganisationStructureParams | undefined {
        let businessUnitId = null;

        const foundProject = this.currentOrganisationStructure.projects.find((project) => {
            return project.id === structureId;
        });

        if (foundProject) {
            let foundOrganisation = null;

            if (foundProject.business_unit_id) {
                businessUnitId = foundProject.business_unit_id;
                const foundBusinessUnit = this.currentOrganisationStructure.business_units.find((businessunit) => {
                    return businessunit.id === foundProject.business_unit_id;
                });

                if (foundBusinessUnit) {
                    foundOrganisation = this.currentOrganisationStructure.organisations.find((organisation) => {
                        return organisation.id === foundBusinessUnit.organisation_id;
                    });
                }
            } else {
                foundOrganisation = this.currentOrganisationStructure.organisations.find((organisation) => {
                    return organisation.id === foundProject.organisation_id;
                });
            }

            if (foundOrganisation) {
                return new OrganisationStructureParams(foundOrganisation.sector_id, foundOrganisation.region_id, foundOrganisation.id,
                    businessUnitId, structureId);
            }
        }

        return undefined;
    }

    public determineQuestionnairePage(type: 'all' | 'incident' | 'compliment') {
        const routeData = this.getOrganisationListForNode(type, StructureNodeType.CONCERN);

        return this.navCtrl.navigateForward([`/${routeData.route}`], {
            state: {
                items: routeData.items,
            },
        });
    }
}
