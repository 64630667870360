<app-dashboard-header [showBackButton]="false"></app-dashboard-header>

<ion-content class="has-header has-footer ion-text-center">
    <div class="questionnaire-header"></div>
    <div class="card">
        <ion-icon src="assets/images/icon-mailbox.svg" class="card__icon"></ion-icon>
        <div class="card__header">
            <h2 class="card__title">{{ 'REGISTRATION_COMPLETE.title' | translate }}</h2>
            <p class="card__description">{{ 'REGISTRATION_COMPLETE.subtitle' | translate }}</p>
        </div>
        <div class="card__btns card__btns--space">
            <ion-button expand="block" shape="round" color="primary" class="card__btn" (click)="goToChooseLoginPage()">{{ 'BUTTONS.next' | translate }}</ion-button>
        </div>
    </div>
</ion-content>
