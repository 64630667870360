import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {Platform} from '@ionic/angular';
import {AbstractQuestionComponent} from '../../classes/abstract-question.component';
import {ToastService} from '../../services/toast-service';
import * as moment from 'moment';

@Component({
    selector: 'app-datetime',
    templateUrl: 'datetime.html',
})
export class DatetimeComponent extends AbstractQuestionComponent implements OnInit {

    @Input() public mode = 'date';
    @Input() public requirements: any = null;
    maximumDate = '2100-12-31T23:59';

    constructor(
        fb: UntypedFormBuilder,
        private platform: Platform,
        private toast: ToastService,
    ) {
        super(fb);
    }

    ngOnInit() {
        if (!this.futureDateAllowed() && !this.disabled) {
            this.maximumDate = moment(Date.now()).format('YYYY-MM-DDTHH:mm');
        }
        this.initialValue();

        super.ngOnInit();
    }

    futureDateAllowed(): boolean {
        let futureDateAllowed = false;

        if (Array.isArray(this.requirements)) {
            this.requirements.forEach((requirement) => {
                if (typeof requirement === 'object' && requirement != null && requirement.type === 'allowFutureDate') {
                    futureDateAllowed = true;
                }
            });
        }

        return futureDateAllowed;
    }

    changed(): void {
        const value = String(this.questionForm.get('current').value);

        if (this.questionForm.valid && value !== '') {
            if (!this.futureDateAllowed() && moment(value).isAfter(moment())) {
                this.toast.showCustomMessages('Je kunt geen tijdstip in de toekomst kiezen');
                this.validityChange.emit(false);
                return;
            }
            this.valueChange.emit(value);
        } else {
            this.valueChange.emit(undefined);
        }
        this.validityChange.emit(this.questionForm.valid);
    }

    private initialValue() {
        let formatString = 'YYYY-MM-DD';
        if (this.mode !== 'date') {
            formatString += 'THH:mm';
        }
        if (this.currentValue === null || this.currentValue === '') {
            this.currentValue = moment(Date.now()).format(formatString);
            this.valueChange.emit(this.currentValue);
        } else {
            this.currentValue = moment(this.currentValue).format(formatString);
        }
    }

}
