import {Component} from '@angular/core';
import {NavController} from '@ionic/angular';

@Component({
  selector: 'page-forgot-password-complete',
  templateUrl: 'forgot-password-complete.html',
})
export class ForgotPasswordCompletePage {

    constructor(
        private navCtrl: NavController,
    ) {
    }

    goToChooseLoginPage() {
        this.navCtrl.navigateRoot(['/choose-login']);
    }

}
