import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertController, IonContent, NavController, Platform} from '@ionic/angular';
import {AuthService} from '../../services/auth-service';
import {LoadingService} from '../../services/loading-service';
import {ToastService} from '../../services/toast-service';
import {ProfileService} from '../../services/profile-service';
import {ThemeService} from '../../services/theme-service';
import {DashboardStatusService} from '../../services/dashboard-status-service';
import {OrganisationStructureService} from '../../services/organisation-structure-service';
import {EventService} from '../../services/event-service';
import {Router} from '@angular/router';
import {TrackingService} from '../../services/tracking/tracking.service';
import {TranslateService} from '@ngx-translate/core';
import {NetworkService} from '../../services/network.service';
import {Capacitor} from '@capacitor/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DashboardStatus} from '../../models/dashboard-status';
import {PropUtils} from '../../utils/prop-utils';
import {Badge} from '@capawesome/capacitor-badge';
import {StructureNodeType} from '../../enums/structure-node-type';
import {DeprecationService} from '../../services/deprecation-service';

@Component({
    selector: 'page-dashboard',
    templateUrl: 'dashboard.html',
})
export class DashboardPage implements OnInit, OnDestroy {
    @ViewChild(IonContent) content: IonContent;
    hasInstructionItems = false;
    numberOfTasks = 0;
    email: string;
    supportsQr = Capacitor.getPlatform() !== 'web';
    openTasksCount: number | string = 0;
    private destroy$ = new Subject<void>();

    constructor(
        private authService: AuthService,
        private loadingService: LoadingService,
        private alertCtrl: AlertController,
        private toast: ToastService,
        private profileService: ProfileService,
        private themeService: ThemeService,
        private statusService: DashboardStatusService,
        public organisationStructureService: OrganisationStructureService,
        private events: EventService,
        private router: Router,
        private platform: Platform,
        private trackingService: TrackingService,
        private translateService: TranslateService,
        private networkService: NetworkService,
        private deprecationService: DeprecationService,
        private navCtrl: NavController,
    ) {
    }

    isUserEmailDeprecated(){
        return this.deprecationService.isUserEmailDeprecated();
    }

    isAppDeprecated(){
        return this.deprecationService.isAppDeprecated();
    }
    async ngOnInit() {
        this.events.subscribe('auth:token_stored', () => this.initializeDashboard());
        this.events.subscribe('auth:initiated', () => this.initializeDashboard());
        await this.initializeDashboard();
        this.profileService.fetchProfile().then((profile) => {
            this.email = profile.data.username;
        });
        this.statusService.onChange()
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (status: DashboardStatus) => {
                const numberOfConcepts = this.organisationStructureService.calculateBadgeAmountFor(StructureNodeType.CONCERN, 0);
                const openTasksCount = PropUtils.parseValue(status.task_statuses.open_actions);
                this.openTasksCount = openTasksCount > 99 ? '99+' : openTasksCount;
                const doScrollToBottom = this.hasInstructionItems !== status.has_instruction_items;
                this.hasInstructionItems = status.has_instruction_items;

                // Badge will contain the number of open tasks, reminders (for plannable forms) and concepts.
                await this.updateBadge(openTasksCount + status.questionnaire_reminders.length + numberOfConcepts);

                if (doScrollToBottom) {
                    setTimeout(() => {
                        this.content.scrollToBottom(0);
                    }, 50);
                }
            });
    }

    goToMediaCommunicationCategoryList() {
        return this.navCtrl.navigateForward(['/media-communication-list'], {
            state: {
                showCategories: true,
            },
        });
    }

    ionViewWillEnter() {
        this.content.scrollToBottom(0);
    }

    ionViewDidEnter() {
        this.statusService.refresh();
    }

    private async initConcepts() {
        if (this.router.getCurrentNavigation()?.extras.state.invalidSignature) {
            await this.toast.showCustomMessages(this.translateService.instant('NOTIFICATION.inactive_form'));
        }
    }

    private initTheme(): Promise<void> {
        if (!this.networkService.isOnline()) {
            return Promise.resolve();
        }
        return this.themeService.fetchTheme()
            .then((response) => {
                const theme = response.data;
                this.events.publish('theme:change', theme);
            })
            .catch(err => {
                this.trackingService.exception(err);
            });
    }

    private initializeDashboard(): Promise<void> {
        return this.verifyAccess()
            .then(() => {
                return this.initTheme();
            })
            .then(() => {
                return this.initConcepts();
            })
            .then(() => {
                this.preloadData();
            });
    }

    private verifyAccess(): Promise<any> {
        return this.authService.isAccessVerified()
            .catch(err => {
                return this.loadingService.start()
                    .then(() => {
                        return this.profileService.fetchProfile();
                    })
                    .then((profile) => {
                        this.email = profile.email;
                        return this.authService.setAccessVerified();
                    })
                    .catch(exception => {
                        this.trackingService.exception(exception);
                        return this.loadingService.stop()
                            .then(async () => {
                                let errorMessage = this.translateService.instant('NOTIFICATION.error_message');

                                if (exception.status !== undefined && exception.status === 403) {
                                    if (exception._body !== undefined) {
                                        const bodyResponse = JSON.parse(exception._body);
                                        if (bodyResponse !== null && bodyResponse.errors !== undefined && bodyResponse.errors.length > 0) {
                                            errorMessage += ' ' + this.translateService.instant('NOTIFICATION.error_message') + ' ' + bodyResponse.errors[0].title;
                                        }
                                    }

                                    await this.alertCtrl.create({
                                        mode: this.platform.is('ios') ? 'ios' : 'md',
                                        header: this.translateService.instant('NOTIFICATION.warning'),
                                        subHeader: errorMessage,
                                        buttons: [this.translateService.instant('BUTTONS.ok')],
                                    }).then((alert) => alert.present());

                                    return this.authService.logout();
                                }
                            })
                            .then(() => {
                                this.events.publish('auth:logout');
                            });
                    });
            });
    }

    /**
     * preload data once we're on the dashboard.
     * data to preload: organisation structure, and direct incident forms
     */
    private preloadData(): void {
        // force organisation structure to be (pre) loaded
        this.organisationStructureService.loadOrganisationStructure()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: async () => {
                    this.organisationStructureService.preloadQuestionnairesForConcern();
                    await this.loadingService.stop();
                },
                error: async () => {
                    await this.loadingService.stop();
                },
            });
    }

    private async updateBadge(badgeCount: number) {
        if (badgeCount <= 0) {
            await Badge.clear();
        } else {
            await Badge.set({count: badgeCount});
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    downloadETT() {
        // depending on the platform we open the playstore or appstore link to the app in an external browser
        const url = this.platform.is('ios') ? 'https://apps.apple.com/app/ett-wave/id6550915773' : 'https://play.google.com/store/apps/details?id=nl.ett.wave';
        window.open(url, '_system');
    }
}
