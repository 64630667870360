import {Injectable} from '@angular/core';
import {QuestionnaireRouteService} from './questionnaire-route.service';
import {LoadingService} from './loading-service';
import {ActionSheetController, NavController, Platform} from '@ionic/angular';
import {DashboardStatusService} from './dashboard-status-service';
import {TranslateService} from '@ngx-translate/core';
import {ConceptService} from './concept-service';
import {Concept, UploadedConcept} from '../models/concept';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {UUID} from 'angular2-uuid';
import {Router} from '@angular/router';
import {OrganisationStructureService} from './organisation-structure-service';
import {UploadedConceptService} from './uploaded-concept.service';

@Injectable({
    providedIn: 'root',
})
export class ConceptQuestionnaireService {

    private _reloadConcepts$ = new Subject<void>();
    currentQuestionnaireOrigin$ = new BehaviorSubject<string | null>(null);
    selectedOrganisationName$ = new BehaviorSubject<string>('');

    constructor(private conceptService: ConceptService,
                private uploadedConceptService: UploadedConceptService,
                private questionnaireRouteService: QuestionnaireRouteService,
                private loadingService: LoadingService,
                private navCtrl: NavController,
                private actionSheetCtrl: ActionSheetController,
                private platform: Platform,
                private dashboardStatusService: DashboardStatusService,
                private translateService: TranslateService,
                private organisationStructureService: OrganisationStructureService,
                private router: Router) {
        (window as any)._reloadConcepts$ = this._reloadConcepts$;
    }

    /**
     * Select concept action
     *
     * @param concept
     * @returns
     */
    async selectConcept(concept: Concept) {
        const actionSheet = await this.actionSheetCtrl.create({
            header: this.translateService.instant('SELECT_QUESTIONNAIRE.choose_action'),
            mode: this.platform.is('ios') ? 'ios' : 'md',
            buttons: [
                {
                    text: this.translateService.instant('BUTTONS.back'),
                    handler: () => {
                        actionSheet.dismiss();
                    },
                },
                {
                    text: this.translateService.instant('SELECT_QUESTIONNAIRE.open'),
                    handler: () => {
                        const organisation = this.organisationStructureService.getOrganisationById(concept.questionnaire.organisation_id);
                        this.selectedOrganisationName$.next(organisation.name);
                        this.currentQuestionnaireOrigin$.next(this.router.url);

                        return this.openConcept(concept, false, true);
                    },
                },
                {
                    text: this.translateService.instant('SELECT_QUESTIONNAIRE.upload'),
                    handler: async () => {
                        try {
                            this.loadingService.start();
                            await this.uploadedConceptService.uploadConcept(concept);
                            this._reloadConcepts$.next();
                        } catch (err) {
                            console.error(err);
                        } finally {
                            this.loadingService.delayedStop();
                        }
                    },
                },
                {
                    text: this.translateService.instant('SELECT_QUESTIONNAIRE.remove'),
                    role: 'destructive',
                    cssClass: 'actionsheet-destructive-red',
                    handler: () => {
                        this.conceptService.deleteConcept(concept)
                            .then(() => {
                                    // refresh the dashboard status, since we removed a concept.
                                    this.dashboardStatusService.refresh();
                                    this._reloadConcepts$.next();
                                },
                            );
                    },
                },
            ],
        });

        return actionSheet.present();
    }

    /**
     * Select concept action
     *
     * @param uploadedConcept
     * @returns
     */
    async selectUploadedConcept(uploadedConcept: UploadedConcept) {
        const actionSheet = await this.actionSheetCtrl.create({
            header: this.translateService.instant('SELECT_QUESTIONNAIRE.choose_action'),
            mode: this.platform.is('ios') ? 'ios' : 'md',
            buttons: [
                {
                    text: this.translateService.instant('BUTTONS.back'),
                    role: 'cancel',
                },
                {
                    text: this.translateService.instant('SELECT_QUESTIONNAIRE.download_and_open'),
                    handler: async () => {
                        this.loadingService.start();

                        const concept = await this.uploadedConceptService.downloadConcept(uploadedConcept);
                        this._reloadConcepts$.next();

                        const organisation = this.organisationStructureService.getOrganisationById(concept.questionnaire.organisation_id);
                        this.selectedOrganisationName$.next(organisation.name);
                        this.currentQuestionnaireOrigin$.next(this.router.url);

                        return this.openConcept(concept, false, true);
                    },
                },
                {
                    text: this.translateService.instant('SELECT_QUESTIONNAIRE.remove'),
                    role: 'destructive',
                    cssClass: 'actionsheet-destructive-red',
                    handler: () => {
                        this.loadingService.start();
                        this.uploadedConceptService.deleteConcept(uploadedConcept)
                            .then(() => {
                                    // refresh the dashboard status, since we removed a concept.
                                    this.dashboardStatusService.refresh();
                                    this._reloadConcepts$.next();
                                    this.loadingService.delayedStop();
                                },
                            );
                    },
                },
            ],
        });

        return actionSheet.present();
    }

    /**
     * Opens a created questionnaire concept for editing
     */
    async openConcept(concept: Concept, destroyPreviousRoute: boolean, animated: boolean): Promise<void> {

        try {
            await this.loadingService.start();
            let startRoute = concept.latest_route;
            if (startRoute && !startRoute.startsWith('/concept/')) {
                console.error('Concept did not have a proper latest route: ' + concept.latest_route);
                startRoute = '';
            }

            if (!startRoute) {
                const routeSuffix = (await this.questionnaireRouteService.getFirstRouteFor(concept.questionnaire, false)).route;
                startRoute = `/concept/${concept.id}${routeSuffix}`;
            }

            await this.conceptService.saveConcept(concept, startRoute);

            await this.navCtrl.navigateForward([startRoute], {replaceUrl: destroyPreviousRoute, animated});
        } finally {
            await this.loadingService.stop();
        }
    }

    public trackConceptById(_: unknown, item: Concept): UUID | null {
        if (!item) {
            return null;
        } else {
            return item.id;
        }
    }

    public trackUploadedConceptById(_: unknown, item: UploadedConcept): number {
        if (!item) {
            return null;
        } else {
            return item.id;
        }
    }

    get reloadConcepts$(): Observable<void> {
        return this._reloadConcepts$.asObservable();
    }
}
