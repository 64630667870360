import {Component} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl} from '@angular/forms';
import {Choice} from '../../models/choice';
import {AbstractChoiceQuestionComponent} from '../../classes/abstract-choice-question.component';

@Component({
    selector: 'app-checkbox',
    templateUrl: 'checkbox.html',
})
export class CheckboxComponent extends AbstractChoiceQuestionComponent {

    constructor(fb: UntypedFormBuilder) {
        super(fb);
    }

    public choose(choice: Choice, newAddition?: boolean): void {
        if (this.disabled) {
            return;
        }

        const current: UntypedFormArray = this.questionForm.get('current') as UntypedFormArray;
        if (!!newAddition) {
            current.push(this.fb.control(false));
        }

        const index = this.question.choices.indexOf(choice);
        if (index !== -1) {
            const control = current.controls[index];
            control.setValue(!control.value);
        }

        const choiceTitles = current.value.map((isSelected, choiceIndex) => isSelected ? this.question.choices[choiceIndex].title : null)
            .filter(element => element);

        this.valueChange.emit(choiceTitles);
    }

    protected createForm(fb: UntypedFormBuilder): void {
        const controls = [];

        this.question.choices.forEach((choice) => controls.push(fb.control((this.currentValue.indexOf(choice.title) !== -1))));
        this.questionForm = fb.group({
            current: fb.array(controls, this.validateArray),
        });
    }

    validateArray(current: UntypedFormArray) {
        let valid = false;
        current.controls.forEach((control: UntypedFormControl) => {
            valid = valid || control.value;
        });

        return valid ? null : {
            validateArray: {
                valid,
            },
        };
    }

    public getFormArray(control: AbstractControl): UntypedFormArray {
        if (control instanceof UntypedFormArray) {
            return control as UntypedFormArray;
        } else {
            throw new Error('Cannot get formarray, control: ' + JSON.stringify(control));
        }

    }
}
