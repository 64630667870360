import {Component} from '@angular/core';
import {IMediaCommunicationItem} from '../../models/media-communication-item';
import {BrowserService} from '../../services/browser-service';
import {MediaCommunicationItemsService} from '../../services/media-communication-items.service';
import {LoadingService} from '../../services/loading-service';
import {ToastService} from '../../services/toast-service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'page-media-communication-details',
    templateUrl: 'media-communication-details.html',
})
export class MediaCommunicationDetailsPage {

    public mediaCommunicationItemId: string;
    public mediaCommunicationItem: IMediaCommunicationItem;
    private subscriptions: Subscription[] = [];

    constructor(
        private mediaCommunicationItemsService: MediaCommunicationItemsService,
        private browserService: BrowserService,
        private loadingService: LoadingService,
        private toast: ToastService,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
    ) {
    }

    async ionViewWillEnter() {
        this.mediaCommunicationItemId = this.activatedRoute.snapshot.paramMap.get('mediaCommunicationItemId');
        await this.loadingService.start();

        this.subscriptions.push(
            this.mediaCommunicationItemsService.getMediaCommunicationItem(this.mediaCommunicationItemId)
                .pipe(
                    tap({
                        next: async (result) => {
                            this.mediaCommunicationItem = result;
                            this.loadingService.stop();
                        },
                        error: async (_) => {
                            await this.loadingService.stop();
                            await this.toast.showCustomMessages(
                                this.translateService.instant('NOTIFICATION.error_general'),
                                this.translateService.instant('NOTIFICATION.offline'),
                            );
                        },
                    },
                ),
            )
            .subscribe(),
        );
    }

    async ionViewWillLeave() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public openPictureLink(): void {
        if (!!this.mediaCommunicationItem.picture_link) {
            this.browserService.openUrl(this.mediaCommunicationItem.picture_link);
        }
    }

    public openHtmlUrl($event): void {
        $event.preventDefault();
        $event.stopPropagation();
        $event.stopImmediatePropagation();
        if ($event.target instanceof HTMLAnchorElement) {
            this.browserService.openUrl($event.target.href);
        } else if ($event.target.closest('a')) {
            this.browserService.openUrl($event.target.closest('a').href);
        }
    }

    public isVideoType(type: string) {
        return type === 'video';
    }
}
