import {Injectable} from '@angular/core';
import {Question} from '../models/question';
import {Questionnaire} from '../models/questionnaire';
import {Choice} from '../models/choice';
import {QuestionIdentifier} from '../models/question-identifier';

@Injectable()
export class QuestionDefinitionPathService {

    private pathSeparator = '-';

    /**
     * Find all the question paths according to a specifc filter
     *
     * @param questionnaire        The questionnaire to look in
     * @param filter               The filter that will check if a question should be returned as a question path
     * @return QuestionPath[]
     */
    public findQuestionPaths(questionnaire: Questionnaire, filter: (question: Question) => boolean = () => true): QuestionIdentifier[] {
        const currentPath = ['questions'];

        return this.filterPaths(currentPath, questionnaire.questions, filter, undefined);
    }

    /**
     * Update a specific set of questions based on the given paths
     *
     * @param questionnaire        The questionnaire to look in
     * @param pathStrings          The path to update
     * @param update               The type of update to do when the path is found
     */
    public updateQuestionsByPath(questionnaire: Questionnaire, pathStrings: string[], update: (question: any) => void): void {
        pathStrings.forEach((pathString: string) => {
            let questionNode = questionnaire;
            const pathElements = pathString.split(this.pathSeparator);

            for (let i = 0, length = pathElements.length; i < length; i++) {
                const pathElement = pathElements[i];

                // Terminate our search if the path could not be found
                // And don't update anything with the path
                if (!questionNode || !questionNode.hasOwnProperty(pathElement)) {
                    break;
                } else {
                    questionNode = questionNode[pathElement];
                }

                // Leaf question - update it
                if (i + 1 === length) {
                    update(questionNode);
                }
            }
        });
    }

    /**
     * Filter the question path
     *
     * @param currentPath         The current path depth
     * @param questions           The questions to use
     * @param filter              The filter that will check if a question should be returned as a question path
     * @private
     *
     * @return QuestionPath[]
     */
    private filterPaths(currentPath: string[], questions: Question[], filter: (question: Question) => boolean, parentQuestion: Question, parentChoice?: Choice): QuestionIdentifier[] {
        const paths: QuestionIdentifier[] = [];

        for (let i = 0, length = questions.length; i < length; i++) {
            // Current level check
            const question = questions[i];
            if (filter(question)) {
                paths.push({
                    path: [...currentPath, `${i}`].join(this.pathSeparator),
                    question,
                    parentQuestion,
                    parentChoice,
                });
            }

            // Traverse deeper in the tree
            if (['chapters', 'radio', 'checkbox'].includes(question.type)) {
                const choices = question.choices;

                for (let j = 0, jlength = choices.length; j < jlength; j++) {
                    const choicePaths = this.filterPaths([...currentPath, `${i}`, 'choices', `${j}`, 'questions'], choices[j].questions, filter, question, choices[j]);
                    if (choicePaths.length > 0) {
                        paths.push(...choicePaths);
                    }
                }

            // Traverse the repeated questions questions
            } else if (question.type === 'repeated') {
                const repeatedQuestions = question.questions;
                const repeatedQuestionPaths = this.filterPaths([...currentPath, `${i}`, 'questions'], repeatedQuestions, filter, question);

                if (repeatedQuestionPaths.length > 0) {
                    paths.push(...repeatedQuestionPaths);
                }
            }
        }

        return paths;
    }

}
