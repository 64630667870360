<app-generic-header [showOrganizationName]="false"></app-generic-header>

<ion-content class="has-header">
    <div class="content__full-height">
        <div class="card card--full-height">
            <div class="card__header">
                <div class="card__title">{{ 'QUESTIONNAIRE_HISTORY.title' | translate }}</div>
                <p class="card__description">{{ 'QUESTIONNAIRE_HISTORY.subtitle' | translate }}</p>
            </div>
            <app-select-item
                *ngFor="let questionnaireListItem of questionnaireList; trackBy: trackQuestionnaireListItemById"
                [title]="questionnaireListItem | contentTranslate:'title'"
                [subTitle]="getSubTitle(questionnaireListItem)"
                (click)="navigateToQuestionnaire(questionnaireListItem)"
            ></app-select-item>
            <div *ngIf="questionnaireList && questionnaireList.length === 0" class="card__center">
                <ion-icon src="assets/images/icon-history-big.svg" class="card__icon card__icon--history"></ion-icon>
                <p class="card__description">{{ 'QUESTIONNAIRE_HISTORY.no_forms' | translate }}</p>
            </div>
        </div>
    </div>
</ion-content>
