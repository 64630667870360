import {QuestionnaireReminder} from './questionnaire-reminder';
import {Concept} from './concept';

export interface DashboardStatus {
    has_instruction_items: boolean;
    has_news_items: boolean;
    questionnaire_reminders: QuestionnaireReminder[];
    task_statuses: {
        open_actions: string;
        closed_action: string;
    };
    concepts: Concept[];
    card_game_playable: boolean;
}

export const DashboardStatusDefault: DashboardStatus = {
    has_instruction_items: false,
    has_news_items: false,
    questionnaire_reminders: [],
    task_statuses: {
        open_actions: '0',
        closed_action: '0',
    },
    concepts: [],
    card_game_playable: false,
};
