import { Component, NgZone } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthService } from '../../services/auth-service';
import { ApiService } from '../../services/api-service';
import { ConceptService } from '../../services/concept-service';
import { LoadingService } from '../../services/loading-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { QuestionnaireAssembleService } from '../../services/questionnaire-assemble-service';
import { OrganisationStructureService } from '../../services/organisation-structure-service';
import { take } from 'rxjs/operators';
import { TrackingService } from '../../services/tracking/tracking.service';
import { NetworkService } from '../../services/network.service';
import { DeeplinkRouteParserService } from '../../services/deeplink-route-parser-service';

@Component({
    selector: 'page-deeplink-redirect',
    templateUrl: 'deeplink-redirect.html',
})
export class DeeplinkRedirectPage {

    public error: boolean;
    public errorCode: number;
    public errorMessage: string;

    private subscriptions: Subscription[] = [];

    constructor(
        private auth: AuthService,
        private apiService: ApiService,
        private navCtrl: NavController,
        private conceptService: ConceptService,
        private organisationStructureService: OrganisationStructureService,
        private loadingService: LoadingService,
        private questionnaireAssembleService: QuestionnaireAssembleService,
        private trackingService: TrackingService,
        private route: ActivatedRoute,
        private router: Router,
        private networkService: NetworkService,
        private deeplinkRouteParserService: DeeplinkRouteParserService,
        private ngZone: NgZone,
    ) {
        this.subscriptions.push(this.route.params.subscribe(async (params) => {
            if (params.base64route) {
                await this.redirectToPage(params.base64route);
            } else {
                await this.navCtrl.navigateForward('dashboard', {
                    animated: false,
                    replaceUrl: true,
                });
            }
        }));

    }

    async ionViewWillEnter() {
        this.clearError();
    }

    async ionViewWillLeave() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    private async redirectToPage(base64RouteWithOptionalQueryParams) {
        // VW-3332: deeplink does not only contain base64 anymore, but also URL  parameters (e.g. disableweb=true)
        const base64Route = base64RouteWithOptionalQueryParams.split('?')[0];
        const route = atob(base64Route);
        await this.loadingService.start();
        const navOptions = {
            animated: false, // If we are coming from a deeplink, we do not need to animate to the next page
            replaceUrl: true, // Do not keep this route in history as it is a redirection for deeplink and for planned forms
        };

        if (!this.auth.isAuthenticated()) {
            this.setError({
                status: 401,
            });
        } else {
            this.organisationStructureService.loadOrganisationStructure()
                .pipe(
                    take(1), // We do not need a full sync if we have landed on this page through the tasks
                ).subscribe(async (_) => {
                const routeToNavigateTo = this.deeplinkRouteParserService.parseRouteUrl(route);
                await this.navCtrl.navigateForward(routeToNavigateTo, navOptions).catch((err) => {
                    console.log('Error', err);
                    this.errorMessage = base64Route;
                    this.setError({
                        status: 404,
                    });
                }).finally(() => {
                    this.loadingService.stop();
                });
            });
        }
    }

    private clearError() {
        this.error = false;
        this.errorCode = null;
        this.errorMessage = null;
    }

    private setError(error: any = {}) {
        this.ngZone.run(() => {
            let errorCode;
            try {
                errorCode = parseInt(error.status, 10);
            } catch (err) {
                console.error(err);
                this.trackingService.exception(err);
                errorCode = this.networkService.isOnline() ? -1 : 0;
            }
            this.error = true;
            this.errorCode = errorCode;
        });
    }

    public async closePage(): Promise<void> {
        await this.navCtrl.back();
    }

}
