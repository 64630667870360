import {Injectable} from '@angular/core';
import {ICardGameItem} from '../models/card-game';
import {ProgressiveApiService, ProgressiveStrategy} from './progressive-api-service';
import {BehaviorSubject} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {CardGamePage} from '../pages/card-game/card-game';
import {LoadingService} from './loading-service';
import {AuthService} from './auth-service';
import {filter, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CardGameService {
    public gameCards: ICardGameItem[];

    private isReady$ = new BehaviorSubject<boolean>(false);
    private gameCardModals: HTMLIonModalElement[] = [];
    private randomizedDeck: ICardGameItem[] = [];

    private modalLevels = {
        0: 'back',
        1: 'middle',
        2: 'front',
    };

    constructor(
        private progressiveApiService: ProgressiveApiService,
        private modalCtrl: ModalController,
        private loadingService: LoadingService,
        private authService: AuthService,
    ) {
    }

    async preLoadModals() {
        await this.loadingService.start();
        this.authService.isReady().then(() => {
            this.setGameCards();
        });
        this.isReady().then(() => {
            this.initialiseModals();
        });
    }

    async initialiseModals() {
        // Randomize the deck at every start with Fisher-Yates ( https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle )
        this.randomizedDeck = JSON.parse(JSON.stringify(this.gameCards));
        for (let a = this.randomizedDeck.length - 1; a > 0; a--) {
            const b = Math.floor(Math.random() * (a + 1));
            [this.randomizedDeck[a], this.randomizedDeck[b]] = [this.randomizedDeck[b], this.randomizedDeck[a]];
        }

        // generate 3 modals
        for (let i = 0; i < 3; i++) {
            await this.createCardModal(this.modalLevels[i]);
        }
        return this.loadingService.stop();
    }

    public setGameCards() {
        this.getCardGameItemsList().then((cards) => {
            this.gameCards = cards;
            this.isReady$.next(true);
        });
    }

    public getCardGameItemsList(): Promise<ICardGameItem[]> {
        return this.progressiveApiService.authenticatedGet<ICardGameItem[]>('/game-cards', ProgressiveStrategy.FALLBACK)
            .toPromise().then((data) => {
                return data;
            });
    }

    public modalsShown(): boolean {
        return this.gameCardModals.length > 0;
    }

    public dismissAllModals() {
        this.gameCardModals.forEach((modal) => modal.dismiss());
        this.gameCardModals = [];
    }

    public async addBottomCard() {
        // Remove top modal
        await this.modalCtrl.dismiss(null, null, 'ion-modal-front').then(async () => {
            this.gameCardModals = this.gameCardModals.filter(modal => modal.id !== 'ion-modal-front');

            this.gameCardModals.map(async (newModal) => {
                if (newModal.id === 'ion-modal-middle') {
                    newModal.setAttribute('id', 'ion-modal-front');
                }
                if (newModal.id === 'ion-modal-back') {
                    newModal.setAttribute('id', 'ion-modal-middle');
                }
            });

            await this.createCardModal('back');
        });
    }

    private async createCardModal(cardOrder: string) {
        await this.modalCtrl.create({
            component: CardGamePage,
            cssClass: 'card-game card-game-card__modal-' + cardOrder,
            componentProps: {
                card: this.pickNewGameCard(),
            },
            mode: 'md',
            swipeToClose: true,
            id: 'ion-modal-' + cardOrder,
            presentingElement: document.querySelector('ion-router-outlet'),
        }).then(cardModal => {
            this.gameCardModals.push(cardModal);
            cardModal.present();

            cardModal.onWillDismiss().then(modal => {
                if (modal.role === 'backdrop') {
                    this.dismissAllModals();
                }
            });
        });
    };

    /**
     * Grab a card and place it back on the bottom of the deck
     */
    private pickNewGameCard() {
        const gameCard = this.randomizedDeck[0];
        this.randomizedDeck.shift();
        this.randomizedDeck.push( gameCard );

        return gameCard;
    }

    private isReady(): Promise<void> {
        return new Promise((resolve) => {
            this.isReady$
                .pipe(
                    filter(isReady => !!isReady),
                    take(1),
                )
                .subscribe(() => resolve());
        });
    }
}
