import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
    selector: '[inputfocus]',
})
export class InputfocusDirective implements AfterViewInit {

    constructor(
        private element: ElementRef,
    ) {
    }

    ngAfterViewInit() {
        const parent = this.element.nativeElement;
        const selector = 'input, textarea';
        InputfocusDirective.setFocus(selector, parent);
    }

    static setFocus(selector: string, parent?: any): void {
        setTimeout(() => {
            (parent ? parent : document).querySelector(selector).focus();
        }, 500);
    }

}
