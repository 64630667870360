import {environment} from '../environments/environment';
import {API_SUFFIX} from '../app/app.module';
import {AuthService} from '../services/auth-service';

export const JwtOptionsFactory = (authService: AuthService) => {
    const allowedDomains = environment.whitelistedDomains;
    const allAnonymousApiEndPoints = [];
    const anonymousApiEndPoints = [
        `${API_SUFFIX}/login/external`,
        `${API_SUFFIX}/login/refresh_token`,
        `${API_SUFFIX}/registration/external`,
        `${API_SUFFIX}/registration/external/verify_sms`,
        `${API_SUFFIX}/password/reset`,
    ];

    for (const allowedDomain of allowedDomains) {
        for (const anonymousApiEndpoint of anonymousApiEndPoints) {
            allAnonymousApiEndPoints.push('//' + allowedDomain + anonymousApiEndpoint);
        }
    }

    return {
        tokenGetter: () => authService.token,
        allowedDomains,
        disallowedRoutes: allAnonymousApiEndPoints,
    };
};
