import {Pipe, PipeTransform} from '@angular/core';
import {ContentTranslatable} from '../models/content-translatable';
import {LanguageService} from '../services/language.service';

@Pipe({
    name: 'contentTranslate',
})
export class ContentTranslatePipe implements PipeTransform {

    constructor(
        private languageService: LanguageService,
    ) {
    }

    /**
     * Takes a ContentTranslatable and translates the given property into the given language
     */
    transform(value: ContentTranslatable, property: string): string {
        if (!value) {
            return '';
        }

        const locale = this.languageService.getCurrentLanguage();

        // We need to check for the translations property as they are not given in older data
        if (value.hasOwnProperty('translations') && value.translations.hasOwnProperty(locale) && value.translations[locale].hasOwnProperty(property)) {
            const propertyValue = value.translations[locale][property];

            // If the value has been set, but is empty - Do not fall back to dutch
            if (!propertyValue) {
                return '';
            } else {
                return propertyValue;
            }
        } else if (value.hasOwnProperty(property) && !!value[property]) {
            return value[property];
        } else {
            return '';
        }
    }
}
