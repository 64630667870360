import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
    /**
     * Takes a value and formats date
     */
    transform(value: any, format: string) {
        if (!value) {
            return '';
        }

        return moment(value).format(format);
    }
}
