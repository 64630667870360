<app-dashboard-header [showBackButton]="true"></app-dashboard-header>

<ion-content class="has-header">
    <div *ngIf="task$ | async as task" class="content__full-height content__full-height--questionnaire">
        <div class="questionnaire">
            <div class="questionnaire__body">
                <div *ngIf="isKeyboardClosed$ | async" class="card__header">
                    <h2 class="card__title">{{task?.ticket_number }}: {{ task?.comments }}</h2>
                    <p class="card__description" *ngIf="!isReadonly()">{{ 'QUESTIONNAIRE_TASK.created_on' | translate }} {{ task?.created_at | dateFormat: ('DATE.long' | translate) }}</p>
                    <p class="card__description" *ngIf="isReadonly()">{{ 'QUESTIONNAIRE_TASK.completed_on' | translate }} {{ task?.created_at | dateFormat: ('DATE.long' | translate) }}</p>
                </div>
                <div class="task-action-holder-remarks-edit" *ngIf="!isReadonly()">
                    <app-text
                        (valueChange)="setActionHolderRemarks($event)"
                        placeholder="{{ 'QUESTIONNAIRE_TASK.place_remark' | translate }}"
                        [disabled]="isReadonly()"
                        [maxLength]="1024"
                    ></app-text>
                </div>
                <div class="task-action-holder-remarks" *ngIf="isReadonly() && (task?.action_holder_remarks || '').trim() !== ''">
                    <div class="task-action-holder-remarks-label"></div>
                    <div [innerHTML]="task.action_holder_remarks | nl2br:true"></div>
                </div>
            </div>
        </div>
    </div>
</ion-content>

<div class="questionnaire-footer-wrap">
    <ion-footer class="questionnaire-footer questionnaire-footer--task" *ngIf="!isReadonly()">
        <ion-toolbar>
            <ion-buttons class="questionnaire-footer__btns questionnaire-footer__btns--full" slot="start">
                <ion-button class="questionnaire-footer__btn" expand="full" (click)="submitTaskDialog('denied')">{{ 'QUESTIONNAIRE_TASK.task_denied' | translate}}</ion-button>
            </ion-buttons>
            <ion-buttons class="questionnaire-footer__btns questionnaire-footer__btns--full" slot="end">
                <ion-button class="questionnaire-footer__btn questionnaire-footer__btn--next" expand="full" (click)="submitTaskDialog('finished')">{{ 'QUESTIONNAIRE_TASK.task_completed' | translate}}</ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>
</div>
