import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthService } from '../../services/auth-service';
import { ApiService } from '../../services/api-service';
import { ConceptService } from '../../services/concept-service';
import { LoadingService } from '../../services/loading-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { QuestionnaireAssembleService } from '../../services/questionnaire-assemble-service';
import { OrganisationStructureService } from '../../services/organisation-structure-service';
import { take } from 'rxjs/operators';
import { TrackingService } from '../../services/tracking/tracking.service';
import { DeeplinkRouteParserService } from '../../services/deeplink-route-parser-service';

export type DeeplinkData = {
    questionnaireId?: number;
    sectorId?: number;
    regionId?: number;
    organisationId?: number;
    businessUnitId?: number;
    projectId?: number;
};

@Component({
    selector: 'page-deeplink-questionnaire-opener',
    templateUrl: 'deeplink-questionnaire-opener.html',
})
export class DeeplinkQuestionnaireOpenerPage {

    public error: boolean;
    public errorCode: number;
    public errorMessage: string;

    private subscriptions: Subscription[] = [];

    constructor(
        private auth: AuthService,
        private apiService: ApiService,
        private navCtrl: NavController,
        private conceptService: ConceptService,
        private organisationStructureService: OrganisationStructureService,
        private loadingService: LoadingService,
        private questionnaireAssembleService: QuestionnaireAssembleService,
        private trackingService: TrackingService,
        private route: ActivatedRoute,
        private router: Router,
        private deeplinkRouteParserService: DeeplinkRouteParserService,
    ) {

        this.subscriptions.push(this.route.params.subscribe(async (params) => {
            const data = params.base64data ? this.getData(params.base64data) : this.router.getCurrentNavigation()?.extras?.state;
            await this.loadingService.start();
            if (data.questionnaireId) {
                const navOptions = {
                    animated: !params.base64data, // If we are coming from a deeplink, we do not need to animate to the next page
                    replaceUrl: true, // Do not keep this route in history as it is a redirection for deeplink and for planned forms
                };

                this.organisationStructureService.loadOrganisationStructure()
                    .pipe(
                        take(1), // We do not need a full sync if we have landed on this page through the tasks
                    ).subscribe((_) => {

                    const routeToNavigateTo = this.deeplinkRouteParserService.parseData(data);
                    this.loadingService.stop();
                    return this.navCtrl.navigateForward(routeToNavigateTo, navOptions);
                    // Organisation structure couldn't be loaded on the direct deep link - Show an error
                }, (error) => {
                    console.error('Error occurred: ', data, error);
                    this.loadingService.stop();
                    this.setError({
                        status: 400,
                        message: 'de organisatiestructuur kon niet worden ingeladen. Probeer het later nog een keer.',
                    });
                });
            } else {
                await this.loadingService.stop();
                this.setError({
                    status: 400,
                    message: 'formulier niet gevonden.',
                });
                console.error('Incorrect deeplink. Unable to find questionnaire. Parsed deeplink data was: ', data);
            }
        }));
    }

    async ionViewWillEnter() {
        this.clearError();

        if (!this.auth.isAuthenticated()) {
            this.setError({
                status: 401,
            });
        }
    }

    async ionViewWillLeave() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    private getData(b64data: string): DeeplinkData {
        let data;
        try {
            data = JSON.parse(atob(b64data));
        } catch (e) {
            data = {};
        }
        const dataModel = {
            s: null, // sectorId
            r: null, // regionId
            q: null, // questionnaireId
            o: null, // organisationId
            b: null, // businessUnitId
            p: null, // projectId
        };
        const dataMerged = {...dataModel, ...data};
        return {
            questionnaireId: dataMerged.q,
            sectorId: dataMerged.s,
            regionId: dataMerged.r,
            organisationId: dataMerged.o,
            businessUnitId: dataMerged.b,
            projectId: dataMerged.p,
        };
    }

    private clearError() {
        this.error = false;
        this.errorCode = null;
    }

    private setError(error: { status: number; message?: string }) {
        console.error('error in deeplink', JSON.stringify(error));
        this.error = true;
        this.errorCode = error.status;
        if (!!error.message) {
            this.errorMessage = error.message;
        } else {
            switch (this.errorCode) {
                case 0: {
                    this.errorMessage = 'Er is geen internetverbinding actief. Het formulier kan daarom niet worden geopend.';
                    break;
                }
                case 401: {
                    this.errorMessage = 'Je zal eerst moeten inloggen om het formulier te kunnen openen.';
                    break;
                }
                default: {
                    this.errorMessage = 'Het formulier kan niet (meer) worden geopend.';
                    break;
                }
            }
        }
    }

    public async closePage(): Promise<void> {
        await this.navCtrl.back();
    }

}
