import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router} from '@angular/router';
import {AuthService} from '../services/auth-service';
import {RouteUtil} from '../utils/route.util';
import {TrackingService} from '../services/tracking/tracking.service';
import { RedirectAfterLoginService } from '../services/redirect-after-login-service';

@Injectable({
    providedIn: 'root',
})
export class AuthRedirectGuard implements CanActivate, CanActivateChild {

    constructor(
        private authService: AuthService,
        private router: Router,
        private trackingService: TrackingService,
        private redirectAfterLoginService: RedirectAfterLoginService,
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        try {
            await this.authService.isReady();
            if (this.authService.isAuthenticated()) {
                await this.redirectAfterLoginService.redirect();

                return false;
            }
        } catch (e) {
            console.error('AuthRedirectGuard error', e);
            this.trackingService.exception(e);
        }

        return true;
    }

    async canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
        return this.canActivate(route);
    }

}
