import {Component} from '@angular/core';
import {NavController} from '@ionic/angular';
import {IMediaCommunicationCategory, IMediaCommunicationListItem} from '../../models/media-communication-item';
import {MediaCommunicationItemsService} from '../../services/media-communication-items.service';
import {LoadingService} from '../../services/loading-service';
import {ToastService} from '../../services/toast-service';
import {SearchUtil} from '../../utils/search-util';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {TrackingService} from '../../services/tracking/tracking.service';

@Component({
    selector: 'page-media-communication-list',
    templateUrl: 'media-communication-list.html',
})
export class MediaCommunicationListPage {
    public showCategories = false;
    public filteredCategories: IMediaCommunicationCategory[] = [];
    public categories: IMediaCommunicationCategory[];

    public categoryId: string;
    public filteredMediaCommunicationListItems: IMediaCommunicationListItem[] = [];
    public mediaCommunicationListItems: IMediaCommunicationListItem[];

    public filtering = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private navCtrl: NavController,
        private mediaCommunicationItemsService: MediaCommunicationItemsService,
        private loadingService: LoadingService,
        private toast: ToastService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private trackingService: TrackingService,
    ) {
        activatedRoute.queryParams.subscribe(params => {
            if (this.router.getCurrentNavigation().extras.state) {
                this.showCategories = this.router.getCurrentNavigation().extras.state?.showCategories;
            }
        });
    }

    async ionViewWillEnter() {
        this.categoryId = this.activatedRoute.snapshot.paramMap.get('categoryId');
        await this.loadingService.start();
        if (this.showCategories) {
            this.subscriptions.push(
                this.mediaCommunicationItemsService.getMediaCommunicationCategoryList().subscribe((categories) => {
                    this.categories = categories;
                }),
            );
        } else {
            try {
                this.subscriptions.push(
                    this.mediaCommunicationItemsService.getMediaCommunicationItemList(this.categoryId).subscribe((items) => {
                            this.mediaCommunicationListItems = items;
                        }),
                );
            } catch (e) {
                this.trackingService.exception(e);
                console.error(e);
            }
        }

        await this.loadingService.stop();
    }

    async ionViewWillLeave() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public goToMediaCommunicationSubjectList(categoryId: string) {
        this.categoryId = categoryId;
        this.navCtrl.navigateForward(['/media-communication-list', categoryId]);
    }

    public goToMediaCommunicationDetails(mediaCommunicationItemId: string) {
        this.navCtrl.navigateForward(['/media-communication-details', mediaCommunicationItemId]);
    }

    public filterMediaCommunication($event) {
        this.filteredCategories = this.filter<IMediaCommunicationCategory>($event.target.value, this.categories);
    }

    public filterMediaCommunicationList($event) {
        this.filteredMediaCommunicationListItems = this.filter<IMediaCommunicationListItem>($event.target.value, this.mediaCommunicationListItems);
    }

    private filter<FilterType>(filterString: string, haystackArray: any): FilterType[] {
        let filterArray = [];

        if (filterString) {
            filterArray = haystackArray
                .filter((item) =>
                    SearchUtil.hasMatch(item.title, filterString) ||
                    SearchUtil.hasMatch(item.subtitle || '', filterString),
                );
        }

        this.filtering = !!filterString;
        return filterArray;
    }

    public getSubTitle() {
        return this.showCategories ? `MEDIA_COMMUNICATION.subtitle.theme` : `MEDIA_COMMUNICATION.subtitle.subject`;
    }

    public getSearchPlaceholder() {
        return this.showCategories ? `MEDIA_COMMUNICATION.search.theme` : `MEDIA_COMMUNICATION.search.subject`;
    }
}
