<app-questionnaire-header [showChapterButton]="isChapterQuestionnaire"></app-questionnaire-header>

<ion-content class="has-header has-footer">
    <div class="card">
        <div class="card__header">
            <h2 [hidden]="!questionTitle" class="card__title">{{ questionTitle }}</h2>
            <h2 class="card__title">{{ currentQuestion | contentTranslate:'title' }}</h2>
            <p class="card__description">{{ currentQuestion | contentTranslate:'description' }}</p>
        </div>

        <app-radio (valueChange)="setAnswer($event)"
                   [question]="currentQuestion"
                   [currentValue]="currentAnswer"
                   [disabled]="readonly"></app-radio>
    </div>
</ion-content>

<app-questionnaire-footer
    [hideChaptersButton]="!isChapterQuestionnaire"
    [disableBackButton]="vintageQuestionPath?.length === 0 || (vintageQuestionPath?.length === 1 && vintageQuestionPath[0].question === 0)"
    [nextButtonEnabled]="isValid()"
    [progress]="questionnaireProgress"
    (prevButtonClick)="prev()"
    (nextButtonClick)="next()"
    (chaptersButtonClick)="backToChapters()"
></app-questionnaire-footer>
