import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LoadingController, NavController, NavParams} from '@ionic/angular';
import {RegistrationPage} from '../registration/registration';
import {Login} from '../../models/login';
import {ForgotPasswordFormPage} from '../forgot-password-form/forgot-password-form';
import {ExternalUserService} from '../../services/external-user-service';
import {ToastService} from '../../services/toast-service';
import {AuthService} from '../../services/auth-service';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../services/language.service';
import { RedirectAfterLoginService } from '../../services/redirect-after-login-service';

@Component({
    selector: 'page-external-account-login',
    templateUrl: 'external-account-login.html',
})
export class ExternalAccountLoginPage {
    public registrationPage = RegistrationPage;
    public forgotPasswordFormPage = ForgotPasswordFormPage;
    public loginForm: UntypedFormGroup;

    constructor(
        fb: UntypedFormBuilder,
        navParams: NavParams,
        private navCtrl: NavController,
        private externalUserService: ExternalUserService,
        private toast: ToastService,
        private loadingCtrl: LoadingController,
        private auth: AuthService,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private redirectAfterLoginService: RedirectAfterLoginService,
    ) {
        this.createForm(fb, navParams.get('username'));
    }

    private createForm(fb: UntypedFormBuilder, username?: string) {
        this.loginForm = fb.group({
            username: fb.control(username !== undefined ? decodeURIComponent(username) : '', Validators.required),
            password: fb.control('', Validators.required),
        });
    }

    async login() {
        if (this.loginForm.valid) {
            const loader = await this.loadingCtrl.create();
            const login = this.loginForm.value as Login;
            try {
                await loader.present();
                const language = this.languageService.getCurrentLanguage();
                const response = await this.externalUserService.postLogin(login);
                const token = response.data;
                await this.auth.loginComplete(token.access_token, token.refresh_token);
                await this.languageService.updateLanguageInProfile(language);

                await loader.dismiss();

                await this.redirectAfterLoginService.redirect();
            } catch (exception) {
                await loader.dismiss();
                await this.toast.showCustomMessages(this.translateService.instant('NOTIFICATION.login_failed'));
            }
        }
    }
}
