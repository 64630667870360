<app-dashboard-header [showBackButton]="true"></app-dashboard-header>

<ion-content class="has-header">
    <div class="deeplink-header">
        <div *ngIf="error" class="ion-margin">
            <h2 class="light">{{ 'NOTIFICATION.route_not_found' | translate }}</h2>
            <hr>
            <p><strong>{{ 'NOTIFICATION.route_not_found_explanation' | translate }}</strong></p>
            <p>[ CODE: <small>{{ errorMessage }}</small> ]</p>
            <ion-button color="dark" class="mt-4" expand="full" (click)="closePage()">{{ 'DEEPLINK_QUESTIONNAIRE.ok_and_close' | translate }}</ion-button>
        </div>
    </div>
</ion-content>
