<ion-item lines="none" class="select-concept">
    <div class="select-concept__item">
        <div class="select-concept__row">
            <div class="select-concept__meta">
                <ion-label class="select-concept__title">{{ concept.questionnaire | contentTranslate:'title' }}</ion-label>
                <ion-label class="select-concept__description">{{ parentName || '' }}</ion-label>
            </div>
            <div class="select-concept__badge">
                <ion-badge>{{ concept.updated_at | dateFormat: ('DATE.date' | translate) }}</ion-badge>
            </div>
        </div>
        <ng-container *ngIf="(concept | conceptProgress | async) as progress">
            <ion-progress-bar [value]="progress.currentQuestionIndex / progress.totalQuestions" class="select-concept__progress"></ion-progress-bar>
        </ng-container>
    </div>
</ion-item>
<!--original styles are in styles/components/select-item.scss-->
