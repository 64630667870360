import {Component, OnInit} from '@angular/core';
import {NavController, Platform} from '@ionic/angular';
import {environment} from '../environments/environment';
import {EventService} from '../services/event-service';
import {AuthService} from '../services/auth-service';
import {StorageService} from '../services/storage-service';
import {DeeplinkService} from '../services/deeplink-service';
import {Router} from '@angular/router';
import {CardGameService} from '../services/card-game-service';
import {ConceptService} from '../services/concept-service';
import {Capacitor} from '@capacitor/core';
import {StatusbarService} from '../services/statusbar.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { BackButtonEvent } from '@ionic/core';
import { App } from '@capacitor/app';
import { ScreenOrientation } from '@capacitor/screen-orientation';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {

    protected theme = '';

    constructor(
        private platform: Platform,
        private auth: AuthService,
        private events: EventService,
        private storageService: StorageService,
        private deeplinkService: DeeplinkService,
        private router: Router,
        private navCtrl: NavController,
        private cardGameService: CardGameService,
        private conceptService: ConceptService,
        private statusbarService: StatusbarService,
    ) {
        this.setTheme(environment.initialTheme);

        this.platform.ready()
            .then(async () => {
                this.statusbarService.initializeStatusbar();

                this.events.subscribe('auth:failed', () => this.navCtrl.navigateRoot(['/choose-login']));
                this.events.subscribe('auth:logout', () => this.navCtrl.navigateBack(['/choose-login']));
                this.events.subscribe('theme:change', (newTheme) => {
                    if (!newTheme || newTheme === '') {
                        newTheme = environment.initialTheme;
                    }
                    this.setTheme(newTheme);
                });

                if (this.platform.is('android')) {
                    this.enableHardwareBackButton();
                }

                this.deeplinkService.register();
                return this.auth.initAccessToken();
            })
            .then(() => {
                return this.initTheme();
            })
            .then(() => {
                SplashScreen.hide();
                // Trigger a reroute in case we're not logged in. IN all other cases: stay on the same page.
                if (!this.auth.isAuthenticated()) {
                    return this.navCtrl.navigateRoot('/choose-login');
                }
                // Handy for using livereload. Do NOT navigate but keep on same URL.
                // NOTE: back buttons and such will not work as expected once live reloading / bookmarking..
                if (Capacitor.getPlatform() !== 'web' || environment.production) {
                    return this.navCtrl.navigateRoot('/dashboard');
                }
            })
            .catch(() => {
                SplashScreen.hide();
                return this.navCtrl.navigateRoot(['/choose-login']);
            });
    }

    ngOnInit(): void {
        this.lockPortraitOrientation();
    }

    /**
     * Inits the theme for the application
     *
     * @returns
     */
    private initTheme() {
        return this.storageService.get(StorageService.THEME)
            .then(lastTheme => {
                if (typeof lastTheme === 'string' && lastTheme) {
                    this.setTheme(lastTheme);
                }
            });
    }

    private setTheme(newTheme: string) {
        const rootElement = document.querySelector(':root');
        if (this.theme !== '') {
            rootElement.classList.remove(this.theme);
        }
        if (newTheme !== '') {
            rootElement.classList.add(newTheme);
        }
        this.theme = newTheme;
        this.storageService.set(StorageService.THEME, newTheme);
    }

    private enableHardwareBackButton() {
        document.addEventListener('ionBackButton', (ev: BackButtonEvent) => {
            ev.detail.register(-1, () => {
                const rootUrls = ['/choose-login', '/dashboard'];

                if (this.cardGameService.modalsShown()) {
                    this.cardGameService.dismissAllModals();
                } else if (rootUrls.includes(this.router.url)) {
                    this.conceptService.storeConcepts().then(() => {
                        App.exitApp();
                    });
                } else {
                    this.navCtrl.back();
                }
            });
        });
    }

    private async lockPortraitOrientation() {
        try {
            await ScreenOrientation.lock({orientation: 'portrait'});
        } catch (e) {
            console.log('Error locking screen orientation', e);
        }
    }
}
