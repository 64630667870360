import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {AbstractQuestionComponent} from '../../classes/abstract-question.component';

@Component({
    selector: 'app-number',
    templateUrl: 'number.html',
})
export class NumberComponent extends AbstractQuestionComponent implements OnInit {

    @Input() public integer: boolean;
    @ViewChild('numberInput') protected input: HTMLIonInputElement;

    constructor(fb: UntypedFormBuilder) {
        super(fb);
    }

    focusInput() {
        this.input.setFocus();
    }

    protected createForm(fb: UntypedFormBuilder): void {
        const numericRegex = this.integer ? /^[0-9]+$/ : /^[0-9]+([\.,][0-9]+)?$/;

        this.questionForm = fb.group({
            current: fb.control({value: this.currentValue, disabled: this.disabled}, Validators.pattern(numericRegex)),
        });

        this.questionForm.valueChanges.subscribe(value => {
            this.validityChange.emit(this.questionForm.valid);
            this.changed();
        });
    }

    changed() {
        let value = String(this.questionForm.get('current').value);

        if (this.questionForm.valid && value !== '') {
            if (value.indexOf(',') !== -1) {
                value = value.replace(',', '.');
            }

            this.valueChange.emit(value);
        } else {
            this.valueChange.emit(undefined);
        }
    }

}
