import {Component} from '@angular/core';
import {INewsItem} from '../../models/news-item';
import {BrowserService} from '../../services/browser-service';
import {NewsItemsService} from '../../services/news-items-service';
import {LoadingService} from '../../services/loading-service';
import {ToastService} from '../../services/toast-service';
import {ActivatedRoute} from '@angular/router';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'page-news-details',
    templateUrl: 'news-details.html',
})
export class NewsDetailsPage {

    public newsItemId: string;
    public newsItem: INewsItem;
    private subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private newsItemsService: NewsItemsService,
        private browserService: BrowserService,
        private loadingService: LoadingService,
        private toast: ToastService,
        private translateService: TranslateService,
    ) {
    }

    async ionViewWillEnter() {
        this.newsItemId = this.route.snapshot.paramMap.get('id');

        await this.loadingService.start();
        this.subscriptions.push(
            this.newsItemsService.getNewsItem(this.newsItemId)
                .pipe(
                    tap({
                        next: async (result) => {
                            await this.loadingService.stop();
                            this.newsItem = result;
                        },
                        error: async (_) => {
                            await this.loadingService.stop();
                            await this.toast.showCustomMessages(
                                this.translateService.instant('NOTIFICATION.error_general'),
                                this.translateService.instant('NOTIFICATION.offline'),
                            );
                        },
                    }),
                )
                .subscribe(),
        );
    }

    async ionViewWillLeave() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public openPictureLink(): void {
        if (!!this.newsItem.picture_link) {
            this.browserService.openUrl(this.newsItem.picture_link);
        }
    }

    public isVideoType(type: string) {
        return type === 'video';
    }

    public openHtmlUrl($event): void {
        $event.preventDefault();
        $event.stopPropagation();
        $event.stopImmediatePropagation();
        if ($event.target instanceof HTMLAnchorElement) {
            this.browserService.openUrl($event.target.href);
        } else if ($event.target.closest('a')) {
            this.browserService.openUrl($event.target.closest('a').href);
        }
    }
}
