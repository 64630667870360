import {Injectable} from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {StatusBar, Style} from '@capacitor/status-bar';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class StatusbarService {

    private statusBarColor = environment.color.statusBar;

    public async initializeStatusbar(): Promise<void> {
        if (this.statusBarSupported()) {
            await this.setDefaultBackgroundColor();
            await StatusBar.setOverlaysWebView({overlay: false});
            await StatusBar.show();
            console.log('configured status bar');
        }
    }

    public async setOverlayAsBackgroundColor(): Promise<void> {
        await this.setBackgroundColor(this.statusBarColor.overlay);
    }

    public async setDefaultBackgroundColor(): Promise<void> {
        await this.setBackgroundColor(this.statusBarColor.default);
    }

    public  async setStyleLight() {
        if (this.statusBarSupported()) {
            await StatusBar.setStyle({style: Style.Light});
        }
    }

    public async setBackgroundColor(color: string): Promise<void> {
        if (this.statusBarSupported()) {
            await StatusBar.setBackgroundColor({color});
        }
    }

    private statusBarSupported() {
        return Capacitor.getPlatform() !== 'web';
    }

}
